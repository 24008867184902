import { Component, Input, OnInit} from '@angular/core';
import { SharedService } from '../../../shared/services/account.service';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';
import { PubsubService } from '../../pubsub.service';

@Component({
  selector: 'app-provider-config',
  templateUrl: './provider-config.component.html',
  styleUrls: ['./provider-config.component.scss']
})
export class ProviderConfigComponent implements OnInit {
  @Input() account: string;
  @Input() product: string;
  @Input() configuration: any;
  @Input() stickyEnable: any;

  errorModel: {} = {};
  loaderList: {} = {};
  conf: any;
  platforms: Array<string> = ['desktop', 'tablet', 'mobile'];
  constructor(private sharedService: SharedService,
              public pubsub: PubsubService,
              // private toasterService: ToasterService
              ) { }

  ngOnInit(): void {
    if (this.product === 'inimage-ads') {
        this.pubsub.imImageProvider.subscribe(data => {
            this.configuration['providers'][0]['configuration'].forEach(res=>{
                res['value'] = data['data']['old'][res['key']] ? (res['key'] == 'In_screen' ? data['data']['old'][res['key']]['data'] == 'true' : data['data']['old'][res['key']]['data']) : res['value'];
            });
            if(data['data']['old']['status']){
                this.configuration['providers'][0]['status'] = data['data']['old']['status']['data'] == 'active';
            }
            this.saveProviderConfiguration('inimage-ads', this.configuration['providers'][0]);
            if(data.data['old']['In_screen']){
                this.gumgumStickyConfiguration('inimage-ads', this.configuration['providers'][0], data.data['old']['In_screen']['data'] == 'true');
            }
        });
    } else if (this.product === 'highimpact-ads'){
      this.pubsub.highimpactProvider.subscribe(history => {
        this.configuration['providers'].forEach(res => {
          if (history['data']['meta']['accountprovider']['label'] === res['name']) {
            Object.keys(history['data']['old']).forEach((key) => {
              res[key] = key === 'status' ? history['data']['old'][key]['data'] === 'active' : history['data']['old'][key]['data'];
            });
            this.saveProviderConfiguration('highimpact-ads', res);
          }
        });
      });
    }
  }

saveProviderConfiguration(product: any, provider: any, ) {
    provider.isFieldChanged = false;
    let data: {} = {'configuration':{}, 'status':provider.status?'active':'inactive', 'enable_device':provider.enable_device};

    if(product === 'demandfusion') {

        for(let platform of this.platforms) {
            data['configuration'][platform] = {};
            for(let provideConfig of provider.configuration[platform]){
                data['configuration'][platform][provideConfig['key']] = provideConfig['value'].toString();
            }
            data['configuration'][platform] = <string> JSON.stringify(data['configuration'][platform]);
        }
    } else {
        for(let providerConfig of provider.configuration) {
            data['configuration'][providerConfig['key']] = providerConfig['value'].toString();
        }
    }
    this.sharedService.saveProductProviderData(this.account, product, provider.id, data)
        .subscribe(
            data => this.onSuccess(data, product, provider.name),
            error => this.showErrorMessage(error, product, provider));
}

updateEnabledDevice(device, provider) {
    if(provider.enable_device.indexOf(device) !== -1) {
        provider.enable_device.splice(provider.enable_device.indexOf(device),1);
    } else {
        provider.enable_device.push(device);
    }
    provider.isFieldChanged = true;
}

enableHighImpactDevice(provider) {
    if(provider.name === 'Distroscale') {
        provider.enable_device.push('tablet', 'mobile');
    } else {
        provider.enable_device.push('desktop', 'tablet', 'mobile');
    }
}

getProvisionId(provider) {
    this.loaderList[provider.id] = true;
    this.sharedService.saveProvisionData(this.account, provider.id)
        .subscribe(
            data => this.getProvision(data, provider.id),
            error => this.showErrorMessage(error, '', provider));
}

gumgumStickyConfiguration(product, provider, sticky_data) {
    this.sharedService.saveGumgumStickyConfig(this.account, provider.id, sticky_data)
        .subscribe(
            data => data,
            error => this.showStickyError(error));
}


popToast(type_: any, message: any) {
    let type = type_;
    // this.toasterService.pop(type , message);
}

clearError(product: string, providerId: any, key: string, platform: string) {
    if(this.errorModel[product] && this.errorModel[product].hasOwnProperty(providerId)) {
        this.errorModel[product][providerId].configuration[platform][key] = '';
    }
}

private getProvision(data, provider_id) {
    let providerconfig: any;
    for(let config of this.configuration.providers) {
        if (config.id === provider_id) {
            providerconfig = config;
        }
    }
    for(let provider of providerconfig.configuration) {

        if(provider['data_type'] === 'string') {
            provider['value'] = data['gumgum_id'];
        }
    }
    this.loaderList[provider_id] = false;

}

private showErrorMessage(error: any, product: any, provider: any) {
    this.popToast('error', 'Please fix errors below');
    this.errorModel[product] = {};
    this.errorModel[product][provider.id] = error;
    provider.isFieldChanged = true;
    this.loaderList[provider.id] = false;
}

private showStickyError(error: any) {
    this.popToast('error', error);
}

private onSuccess(data: any, product: any, provider: any) {
    this.popToast('success', 'Changes have been saved successfully!');
    if( data['configuration'].gumgum_id !=='') {
        this.stickyEnable[provider] = true;
    }
    if(product === 'inimage-ads') {
        if(this.stickyEnable[provider] === false) {
            this.stickyEnable[provider] = true;
        }
    }
    return data;
}

}
