import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { contentHeaders, Accountdetail, AccountAnalysis,
         DemandFusion, Flight, Product, Inimage, DisplayAds, VideoAds } from '../index';
// import { contentHeaders, Config , Accountdetail, AccountAnalysis,
//          DemandFusion, Flight, Product, Inimage, DisplayAds, VideoAds } from '../index';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class SharedService {

    accountID: any;
    analysisID: any;
    private accountUrl = environment.accountsUrl + '/account/';
    private servicesAccount = environment.apiUrl + 'account/';
    private adtagbrandingUrl = environment.apiUrl + '/product/branding/';
    private tierUrl = environment.tm3_base_url + '/tier/';
    private options = { headers: contentHeaders };

    constructor(private http: HttpClient) {}

    getProductList(): Observable<any> {
      return this.http.get(environment.apiUrl + 'product/', this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }


    getProductData(accountId, product): Observable<Product> {
        return this.http.get(this.servicesAccount + accountId + '/products/' + product + '/',  this.options).pipe(
          map((data: any) => data),
          catchError(error => this.handleError(error))
        );
    }

    getTier(): Observable<any> {
      return this.http.get(this.servicesAccount, this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }


    getAccountProduct(id: any): Observable<Accountdetail> {
      return this.http.get(this.servicesAccount + id + '/' + 'products/', this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    getCodeAnalysisData(id: number): Observable<AccountAnalysis> {
        return this.http.get(this.servicesAccount + id + '/' + 'analysis/', this.options).pipe(
          map((data: any) => data),
          catchError(error => this.handleError(error))
        );
    }


    getAccountDetail(id: any): Observable<Accountdetail> {
      return this.http.get(this.servicesAccount + id + '/', this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    getAdTagBrandingData(): Observable<Product> {
      return this.http.get(this.adtagbrandingUrl, this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    getHistoryData(id: any, pageNumber: number): Observable<Accountdetail> {
      return this.http.get(this.servicesAccount + id + '/activity/' + '?page=' + pageNumber, this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    getCodeshotHistory(id: any, pageNumber: number): Observable<Accountdetail> {
      return this.http.get(this.servicesAccount + id + '/analysis/history/' + '?page=' + pageNumber, this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    saveGumgumStickyConfig(accountId, providerId, sticky): Observable<Accountdetail> {
      const body = JSON.stringify({ 'sticky': sticky});
      return this.http.post(this.servicesAccount +
        accountId + '/sticky/' +  providerId + '/',  body,  this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );

    }

    saveProductProviderData(accountId, product, providerId, data): Observable<any[]> {
      const body = JSON.stringify(data);
      return this.http.patch(this.servicesAccount +
        accountId + '/products/' + product + '/provider/' +  providerId + '/',  body,  this.options).pipe(
        map((response: any) => response.json()),
        catchError(error => this.handleError(error))
      );
    }

    saveDemandfusionProductData(accountId, product, dfConfs, id, customDfConfiguration) {
      const dfConfigurations: any[] = JSON.parse(JSON.stringify(dfConfs));
      for (const conf of dfConfigurations) {
        conf.status = conf.status ? 'active' : 'inactive';
        if (conf.provider === 'appnexus') {
            // conf['configuration_common'][0]['search'] = conf['configuration_common'][0]['search']['name'];
        }
      }
      const status = 'active';
      const body = JSON.stringify({df_configurations: dfConfigurations, status, custom_df_configuration: customDfConfiguration});
      let url = this.servicesAccount + accountId + '/products/' + product + '/';
      if(id) {
          url = url + id + '/';
      }
      return this.http.patch(url, body, this.options).pipe(
        map((response: any) => response.json()),
        catchError(error => this.handleError(error))
      );
    }

    saveProductStatus(productData: any, id: any, value: any): Observable<Accountdetail> {
        let status = '';
        let body = '';
        if (value === true) {
            status = 'active';
        } else { status = 'inactive'; }
        if (productData['product'] === 'flight' || productData['product'] === 'demandfusion') {
            // tslint:disable-next-line: variable-name
            const df_configurations = [];
            body = JSON.stringify({ status, df_configurations });
        } else {
            body = JSON.stringify({ status,  });
        }
        return this.http.patch(this.servicesAccount + id + '/' + 'products/' + productData['product'] + '/', body, this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    saveAdStackStatus(accountId, isActive, id): Observable<any> {
        const body = JSON.stringify({ is_active: isActive });
        return this.http.patch(this.servicesAccount + accountId + '/products/' + 'display-ads/' + id + '/',  body,  this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    saveDemandFusionConfigData(model, accountId, name): Observable<DemandFusion> {
        const data: any = JSON.parse(JSON.stringify(model)) as DemandFusion;
        data.configuration.is_timeout_common = model.configuration.is_timeout_common ? model.configuration.is_timeout_common.toString():'false';
        data.configuration.console_log = model.configuration.console_log ?  model.configuration.console_log.toString() : 'false';
        data.configuration.mediadotnet = model.configuration.mediadotnet ?  model.configuration.mediadotnet.toString() : 'false';
        return this.http.patch(this.servicesAccount + accountId + '/products/' + name + '/', data, this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    saveFlightDataWithExclusion(model, accountId): Observable<Flight> {
      const modelData: any = JSON.parse(JSON.stringify(model));
      const deviceType = ['t_ad_sizes','d_ad_sizes','m_ad_sizes'];
      const options = ['d_enable', 'm_enable', 't_enable'];
      const refresh = ['d_refresh_ad', 'm_refresh_ad', 't_refresh_ad'];
      const cutoff = ['d_refresh_cutoff', 'm_refresh_cutoff', 't_refresh_cutoff'];
      const sticky = ['stick_for','is_sticky_common','sticky_enabled',
                    'common_stick_until_next_slot', 'd_stick_until_next_slot',
                    't_stick_until_next_slot', 'm_stick_until_next_slot']

      for (const field of sticky) {
        modelData.configuration[field] = JSON.stringify(modelData.configuration[field]);
      }
      modelData.configuration['exclusions'] = JSON.stringify(model.configuration['exclusions']);
      modelData.configuration['exclusion_urls'] = JSON.stringify(model.configuration['exclusion_urls']);
      for (const flight2config of modelData.configuration.flight2){
          // let active_devices = [];
          // for (let f2_device of flight2config.devices){
          //     console.log("Checking device:")
          //     console.log(f2_device)
          //     console.log(f2_device[0]+"_enable")
          //     if (model_data.configuration[f2_device[0]+"_enable"]){
          //         active_devices.push(f2_device)
          //     }
          // }
          // flight2config.devices = active_devices.join()
          flight2config.devices = flight2config.devices.join()
      }
      modelData.configuration['flight2'] = JSON.stringify(modelData.configuration['flight2']);
      modelData.configuration['position_floors'] = JSON.stringify(model.configuration['position_floors'])
      for (const d of deviceType) {
          modelData.configuration[d] = model.configuration[d] ? model.configuration[d].toString() : 'false'; }
      for (const op of options) {
          modelData.configuration[op] = model.configuration[op] ? model.configuration[op].toString() : 'false'; }

      for (const re of refresh) {
          modelData.configuration[re] = model.configuration[re] ? model.configuration[re].toString() : 'false'; }

      for (const cu of cutoff) {
          modelData.configuration[cu] = model.configuration[cu] ? model.configuration[cu].toString() : 'false'; }
      // model_data.configuration['advertise_with_us'] = model.configuration['advertise_with_us']?
      // model.configuration['advertise_with_us']:'center';
      const body = JSON.stringify(modelData);
      return this.http.patch(this.servicesAccount + accountId + '/products/flight/', body, this.options).pipe(
        map((response: any) => response.json()),
        catchError(error => this.handleError(error))
      );
    }

    saveInImageDataWithExclusion(model, accountId): Observable<Inimage> {
      const modelData: any = JSON.parse(JSON.stringify(model));
      modelData.configuration['exclusions'] = JSON.stringify(model.configuration['exclusions']);
      const body = JSON.stringify(modelData);

      return this.http.patch(this.servicesAccount + accountId + '/products/inimage-ads/', body, this.options).pipe(
        map((response: any) => response.json()),
        catchError(error => this.handleError(error))
      );
    }

    saveDisplayConfiguration(accountId, configuration, adType): Observable<any> {
      const body = JSON.stringify({ configuration });
      return this.http.patch(this.servicesAccount + accountId + '/products/' + adType + '/', body, this.options).pipe(
        map((response: any) => response.json()),
        catchError(error => this.handleError(error))
      );
    }

    saveDisplayAdData(model, accountId ): Observable<DisplayAds> {
      let adType = 'display';
      const data = JSON.parse(JSON.stringify(model)) as DisplayAds;
      data.is_active = model.is_active ? model.is_active.toString() : 'false';
      data.notification = model.notification ? model.notification.toString() : 'false';
      data.weight = model.weight ? model.weight : null;
      adType = data.ad_type === 'video' ? 'video-ads' : 'display-ads';
      if (model.line_break_toggle === true) {
        data.line_break = model.line_break.toLowerCase();
      } else {
        data.line_break = null;
      }
      data.branding = model.branding === 'None' ? null : model.branding;
      // data.positioning = model.positioning? model.positioning.toString():'false';
      return this.http.post(this.servicesAccount + accountId + '/products/' + adType + '/' , data, this.options).pipe(
        map((response: any) => response.json()),
        catchError(error => this.handleError(error))
      );
    }

    saveDisplayAdTagData(model, accountId ): Observable<DisplayAds> {
        let adType = 'display';
        const data = JSON.parse(JSON.stringify(model)) as DisplayAds;
        data.is_active = model.is_active ? model.is_active.toString() : 'false';
        data.notification = model.notification ? model.notification.toString() : 'false';
        data.display_desktop = model.display_desktop ? model.display_desktop.toString() : 'false';
        data.display_mobile = model.display_mobile ? model.display_mobile.toString() : 'false';
        data.display_tablet = model.display_tablet ? model.display_tablet.toString() : 'false';
        data.sticky = model.sticky.toLowerCase();
        data.line_break = model.line_break ? model.line_break.toLowerCase():'';
        data.branding = model.branding === 'None' ? null : model.branding;
        data.weight = model.weight ? model.weight : null;
        adType = model.ad_type === 'video' ? 'video-ads' : 'display-ads';
        // data.positioning = model.positioning? model.positioning.toString():'false';
        // data.custom_key_value[0]['device'] =  ['desktop', 'mobile']
        return this.http.patch(this.servicesAccount + accountId + '/products/' + adType + '/' + model.id + '/', data, this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    saveVideoAdTagData(model, accountId ): Observable<VideoAds> {
      let adType = 'video';
      let data: any = JSON.parse(JSON.stringify(model)) as VideoAds;
      data.is_active = model.is_active ? model.is_active.toString() : 'false';
      data.notification = model.notification ? model.notification.toString() : 'false';
      data.display_desktop = model.display_desktop ? model.display_desktop.toString() : 'false';
      data.display_mobile = model.display_mobile ? model.display_mobile.toString() : 'false';
      data.display_tablet = model.display_tablet ? model.display_tablet.toString() : 'false';
      data.line_break = model.line_break ? model.line_break.toLowerCase() : '';
      data.branding = model.branding === 'None' ? null : model.branding;
      data.weight = model.weight ? model.weight : null;
      for (const index in data.restrict_to) {
        if (data.restric_to.hasOwnProperty(index)) {
          data.restrict_to[index] = (data.restrict_to[index]['value']);
        }
      }
      adType = model.ad_type === 'video' ? 'video-ads' : 'display-ads';
      return this.http.patch(this.servicesAccount + accountId + '/products/' + adType + '/' + model.id + '/', data, this.options).pipe(
        map((response: any) => response.json()),
        catchError(error => this.handleError(error))
      );
    }

    saveVideoAdData(model, accountId ): Observable<VideoAds> {
        let adType = 'video';
        let data: any = JSON.parse(JSON.stringify(model)) as VideoAds;
        data.is_active = model.is_active ? model.is_active.toString() : 'false';
        data.notification = model.notification ? model.notification.toString() : 'false';
        data.weight = model.weight ? model.weight : null;
        adType = data.ad_type === 'video' ? 'video-ads' : 'display-ads';
        if (model.line_break_toggle === true) {
            data.line_break = model.line_break.toLowerCase();
        } else {
            data.line_break = null;
        }
        for (let index in data.restrict_to) {
          if (data.restrict_to.hasOwnProperty(index)) {
            data.restrict_to[index] = (data.restrict_to[index]['value']);
          }
        }
        data.branding = model.branding === 'None' ? null : model.branding;
        return this.http.post(this.servicesAccount + accountId + '/products/' + adType + '/' , data, this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    saveProvisionData(accountId, providerId): Observable<any[]> {
        return this.http.get(this.servicesAccount + accountId + '/provision/' + providerId + '/', this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    getDfProvision(accountId): Observable<any> {
        return this.http.get(this.servicesAccount + accountId + '/provision/demandfusion/', this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    getExclusionURL(accountId): Observable<any> {
        return this.http.get(this.servicesAccount + accountId + '/exclusion-url/', this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    saveExclusionURL(exclusionUrlModel, accountId): Observable<any> {
        const data: any = JSON.parse(JSON.stringify(exclusionUrlModel));
        return this.http.patch(this.servicesAccount + accountId + '/exclusion-url/', data, this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    getAdstxtAddition(accountId): Observable<any> {
        return this.http.get(this.servicesAccount + accountId + '/adstxt-addition/', this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    saveAdstxtAddition(provider_list, accountId): Observable<any> {
        const data: any = { 'providers' : provider_list};
        return this.http.patch(this.servicesAccount + accountId + '/adstxt-addition/', data, this.options).pipe(
          map((response: any) => response.json()),
          catchError(error => this.handleError(error))
        );
    }

    // getActivityTrackingURL(accountId) {
    //     return this.http.get(this.servicesAccount + accountId + '/activity-tracking-url/', this.options)
    //         .map(res => res.json())
    //         .catch(this.handleError);
    // }

    // saveActivityTrackingURL(activity_tracking_url_model, accountId): Observable<any> {
    //     let data: any = JSON.parse(JSON.stringify(activity_tracking_url_model));
    //     return this.http.patch(this.servicesAccount + accountId + '/activity-tracking-url/', data, this.options)
    //         .map(response => response.json())
    //         .catch(error => this.handleError(error));
    // }

    private handleError(err: any) {
        return throwError(err);
    }

}
