import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import '../shared/rxjs-operators';
import { AuthService } from '../../shared/services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  token: any;
  ticket: any;
  hash: any;
  isLoader = false;
  showLoader = false;
  service: any;
  returnUrl: string;
  public divMargin = '180px';

  constructor(private router: Router,
              private authService: AuthService,
              private cookieService: CookieService,
              private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    // this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] ||'/';
    // this.returnUrl  = localStorage.getItem('returnUrl');
    this.service = location.protocol + '//' + document.domain + (location.port === '' ? '' : (':' + location.port)) + '/#/login';
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.ticket) {
          this.showLoader = true;
          this.token = params.ticket;
          this.authService.login(this.token, this.service)
            .subscribe(
              response => this.getResponse(response),
              error => this.errorMessage = error as any
            );
        } else if (this.cookieService.get('token')) {
          this.router.navigate(['account']);
        } else {
          this.router.navigate(['login']);
        }
    });
  }

  encodeService(service: string): any {
    return encodeURIComponent(service);
  }

  activateLoader(): void {
    this.showLoader = true;
  }

  private getResponse(res: any): any {
    const urlValue = localStorage.getItem('returnUrl');
      // let search = urlValue ==='/' || ''?'':urlValue.split('search=')[1];
    const search = urlValue !== null && urlValue.search('search=') !== -1 ? urlValue.split('search=')[1] : '';
    this.router.navigate(['/account'], { queryParams: { search } });
    this.showLoader = true;
    return res;
  }

  private errorMessage(err:any) {
      //console.log('error',err);
  }

}
