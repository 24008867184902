import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalVaribleService {
    allFlags: {} = {
      'customScript': false,
      'demandFusion': false,
      'flight': false, 'flightCustom': false, 'amp': false,
      'inImageExclusion': false, 'accountDetail': false,
      'isFlightExclusionSelected': false, 'isFlightExclusionURLSelected': false,
      'isFlightPositionFloorsSelected': false, 'isInimageExclusionSelected': false,
      'url': false, 'historyResponse': false, 'otb': false ,
    };
    products: {} = {};
    providerListForFlags : {} = {};
    video: any = {'changeColor' : false};
    adTags: any = [];
    display: any = {'changeColor' : false};
    adDisplayTags: any = [];

    setInitGlogalVaribles(): void {
      this.products = {};
      this.allFlags = {
          'customScript': false,
          'demandFusion': false,
          'flight': false, 'flightCustom': false, 'amp': false,
          'inImageExclusion': false, 'accountDetail': false,
          'isFlightExclusionSelected': false, 'isFlightExclusionURLSelected': false,
          'isFlightPositionFloorsSelected': false, 'isInimageExclusionSelected': false,
          'url': false, 'historyResponse': false, 'otb': false ,
      };
      this.providerListForFlags  = {};
      this.video = {'changeColor' : false};
      this.adTags = [];
      this.display  = {'changeColor' : false};
      this.adDisplayTags = [];
    }
}
