import { Component, OnInit, } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router, ActivatedRoute } from '@angular/router';
// import { PaginationInstance } from 'ngx-pagination';
import { GlobalVaribleService } from '../../shared/services/index';
import { PageEvent } from '@angular/material/paginator';

import { AccountList } from '../../shared/constructor';
import { AccountListService } from './account-list.service';
import { AccountCreateService } from '../../shared/services/account-create.service';
import { PaginationInstance } from '../../shared/pipes/pagination-service';
@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {
  search_model = new AccountList('');
  // errorMessage: string;
  mode = 'Observable';
  data: any = { results: []};
  active = true;
  account: any;
  accountId: any;
  hash: any;
  accountListResponse: boolean;
  sub: any;
  page: any;
  search: any;
  loadingTable = false;
  show_disabled_accounts = false;
  errorModel: any = {};
  pageSizeOptions: number[] = [5, 10, 25, 100];
  public filter: string = '';
  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public paginationConfig: PaginationInstance = {
      id: 'advanced',
      itemsPerPage: 100,
      currentPage: 1,
      itemsPerPageOptions: [5, 10, 25, 100]
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  columnsToDisplay: string[] = ['ac_number', 'company_name', 'ticker', 'url', 'ad_unit'];
  displayColumn: any = {
    ac_number: 'Account #',
    company_name: 'Company Name',
    ticker: 'Ticker',
    url: 'URL',
    ad_unit: 'Inventory'
  };


  constructor(private accountcreateService: AccountCreateService,
              private accountService: AccountListService,
              private route: ActivatedRoute,
              private http: HttpClient,
              private globaleVaribleService: GlobalVaribleService,
              private router: Router) {
      globaleVaribleService.setInitGlogalVaribles();
  }

  ngOnInit(): void {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        console.log('params', params);
        this.page = + params['page'] || 1;
        this.search = params['search'] || '';
    });
    this.accountListResponse = false;
    this.onSearch(this.search);
    // this.getaccountdata(this.page)
  }

  goToBlogURL(pageLink: string): void {
      window.open('http://' + pageLink, '_blank');
  }

  getaccountdata(pageNumber: number): void {
      this.accountService.save(pageNumber)
          .subscribe(
              data  => this.getdata(data),
              error =>  this.errorMessage = error);
  }

  gotoTop(): void {
      window.scrollTo(0, 0);
  }

  onPageChange(pageEvent: PageEvent): void {
    const pageNumber: any = pageEvent.pageIndex + 1;
    console.log('pageNumber', pageNumber);
    console.log('pageEvent', pageEvent);
    this.accountListResponse = true;
    this.loadingTable = true;
    this.paginationConfig.currentPage = pageNumber;
    this.router.navigate(['/account/'], { queryParams: { page: pageNumber} });
    this.getaccountdata(pageNumber);
      //this.show_table_loader = false;
  }

  onSearch(search: any): void {
    console.log('onSearch()::search', search);
    this.loadingTable = true;
    this.router.navigate(['/account/'], { queryParams: { search } });
    this.accountService.searchFields(search)
          .subscribe(
              // data  => this.searchedData(data),
              data  => {
                this.getdata(data);
              },
              error =>  this.errorMessage(error));
  }

  clearSearch(): void {
      this.search_model.search = '';
      this.onSearch('');
  }

  rowClick(blogId: any): void {
    console.log('rowClicked', blogId);
    this.loadingTable = true;
    this.accountId = blogId;
    this.router.navigate(['/account/detail/', blogId]);
  }

  showLoader(event: any): void {
      this.loadingTable = true;
  }

  // private searchedData (data:any ) {
  //     this.data = data;
  //     this.paginationConfig.totalItems = data.meta.number_of_records;
  // }

  private getdata(data: any): void {
    console.log('getData()::data', data);
    this.accountListResponse = true;
    this.loadingTable = false;
    this.data = data;
    this.paginationConfig.totalItems = data.meta.number_of_records;
  }

  private errorMessage(error: any): void {
    this.loadingTable = false;
    this.errorModel = error;
    this.loadingTable = false;
  }

}
