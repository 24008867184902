import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PubsubService {
    imImageProvider: Subject<any>;
    dfProvider: Subject<any>;
    ampProvider: Subject<any>;
    highimpactProvider: Subject<any>;
    flightProvider: Subject<any>;
    videoProvider: Subject<any>;
    videoDFProvider: Subject<any>;
    deleteTag: Subject<any>;
    dfAdsProvider: Subject<any>;
    displayAdsProvider: Subject<any>;

    constructor() {
        this.imImageProvider = new Subject();
        this.dfProvider = new Subject();
        this.ampProvider = new Subject();
        this.highimpactProvider = new Subject();
        this.flightProvider = new Subject();
        this.videoProvider = new Subject();
        this.videoDFProvider = new Subject();
        this.deleteTag = new Subject();
        this.dfAdsProvider = new Subject();
        this.displayAdsProvider = new Subject();
    }
}
