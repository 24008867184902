import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { contentHeaders, Config } from '../../shared/index';
import { contentHeaders } from '../../shared/index';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountDetailService {
    private accountUrl = environment.apiUrl + '/account/';
    private servicesAccount = environment.apiUrl + 'account/';
    private options = { headers: contentHeaders };

    constructor(private http: HttpClient) {}

    save(model: any, aid: any): Observable<any> {
      let urls = model.alternate_urls;
      urls = (typeof urls === 'string') ? (urls.split(',').map((url: string) => url.trim())) : urls;
      model.alternate_urls = urls;
      return this.http.patch(this.servicesAccount + aid + '/', model , this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    deleteDisplayAdTag(accountId: any, tagId: any): Observable<any> {
      return this.http.delete(this.servicesAccount + accountId + '/products/display-ads/' + tagId + '/', this.options).pipe(
        map((res: any) => res),
        catchError(error => this.handleError(error))
      );
    }


    saveCustomDetail(customModel: any, accountId: any): Observable<any> {
      const js_postfix = customModel.js_postfix;
      const js_prefix = customModel.js_prefix;
      const css_style = customModel.css_style;
      const body = JSON.stringify({css_style, js_postfix, js_prefix});
      return this.http.patch(this.servicesAccount + accountId + '/', body , this.options)
      .pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    saveAccountNotes(customModel: any, accountId: any): Observable<any> {
      let accountNotes = customModel.account_notes;
      if (accountNotes !== (document.getElementById('account_notes') as HTMLInputElement).value) {
        accountNotes = (document.getElementById('account_notes') as HTMLInputElement).value;
      }
      const body = JSON.stringify({account_notes: accountNotes});
      return this.http.patch(this.servicesAccount + accountId + '/', body , this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    syncDetails(aid: any): Observable<any> {
        return this.http.get(this.servicesAccount + aid + '/sync/', this.options).pipe(
          map((data: any) => data),
          catchError(error => this.handleError(error))
        );
    }


    getAccountDetails(aid: any): Observable<any> {
      return this.http.get(this.servicesAccount + aid + '/', this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    accountActivity(aid: any, pageNumber: number): Observable<any> {
      return this.http.get(this.servicesAccount + aid + '/activity/' + '?page=' + pageNumber, this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }

    public getTierHistory(id: number): Observable<any> {
      return this.http.get(this.servicesAccount + id + '/tier-history/', this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
   }

    private handleError(err: Response): Observable<Error> {
        return throwError(err);
    }

    reportAnIssue(body: any): Observable<any> {
      return this.http.post(environment.tm3_base_url + '/create-jira-issue/', body , this.options).pipe(
        map((data: any) => data),
        catchError(error => this.handleError(error))
      );
    }
}
