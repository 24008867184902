<!-- Main Wrapper -->
<div class="account-list-container">
  <div>
      <div class="account-header">
        <div class="company-header">
          <h2>Accounts</h2>
        </div>
        <div class="company-header">
          <div>
            <mat-checkbox class="example-margin" (change)="show_disabled_accounts = !show_disabled_accounts">Show Disabled Accounts</mat-checkbox>
          </div>
        </div>
      </div>
      <div *ngIf="!accountListResponse" style="display: flex; justify-content: center; margin-top: 64px;">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="accountListResponse">
          <div class="panel-body">
              <div>
                <!-- Utility Bar -->
                <div class="row" style="padding-top: 8px; background-color: #FFFFFF;">
                  <div style="float: left;">
                    <mat-form-field class="search-bar">
                      <input matInput type="text" placeholder="Search" [(ngModel)]="search_model.search" (keyup.enter)="onSearch(search_model.search)">
                      <button mat-button *ngIf="search_model.search" matSuffix mat-icon-button aria-label="Clear" (click)="search_model.search=''; onSearch('')">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <button mat-flat-button (click)="onSearch(search_model.search)">
                      <mat-icon>search</mat-icon>
                    </button>
                  </div>
                  <div class="col-sm-7 center-pagination" *ngIf="data.results.length !== 0">
                    <mat-paginator [length]="paginationConfig.totalItems"
                      [pageSize]="paginationConfig.itemsPerPage"
                      (page)="pageEvent = onPageChange($event)">
                    </mat-paginator>
                  </div>
                  <div class=".progress-container">
                    <mat-progress-bar *ngIf="loadingTable" mode="query"></mat-progress-bar>
                  </div>
                </div>
                <!-- Account Table  -->
                <div class="row">
                  <div class="col-sm-12 headers-set">
                    <div class="text-align-center" [hidden]="data.results.length>0">
                      <span class="font-bold" >No record found!</span>
                    </div>
                    <table mat-table [dataSource]="data.results" class="mat-elevation-z8">
                      <ng-container [matColumnDef]="column" *ngFor="let column of columnsToDisplay">
                        <th mat-header-cell *matHeaderCellDef> {{displayColumn[column]}} </th>
                        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row class="table-row" (click)="rowClick(row.id)" *matRowDef="let row; columns: columnsToDisplay;"></tr>
                    </table>
                  </div>
                </div>
                <!-- Bottom Utility Bar -->
                <div class="row">
                    <div class="col-sm-7 center-pagination txt-rigth " [hidden]="data.results.length==0"><a><span (click)="gotoTop()" class="glyphicon glyphicon-circle-arrow-up fa-lg btnhoverblue backto-top backto-font" title="Back to Top"></span></a>
                      <mat-paginator [length]="paginationConfig.totalItems"
                        [pageSize]="paginationConfig.itemsPerPage"
                        (page)="pageEvent = onPageChange($event)">
                      </mat-paginator>
                    </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
