import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent {
  @Input() account:string;
  @Input() product:string;
  @Input() hasCustomDfConfiguration:boolean;
  @Input() showCustomDfConfigurationToggle:boolean;
  @Input() dfProviders:Array<any>;
  @Input() dfConfiguration:Array<any>;
  @Input() modelObjectId:any;
  @Input() dfProviderAdSupport:any;
  @Input() adType:any;
  @Input() tag:any;
  @Input() tagPreset:any;
  @Input() disableConfigToggle:boolean;
  //@Input() openxUrl:any;
  show: false;
  constructor() { }

}
