import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Config, contentHeaders } from '../../shared/index';
import { contentHeaders } from '../../shared/index';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
}
)export class AccountListService {

    // private accountUrl = environment.accountsUrl + 'account/';
    private servicesAccount = environment.apiUrl + 'account/';
    private options = { headers: contentHeaders };
    constructor(private http: HttpClient) {}

    save(pageNumber: number): Observable<any> {
        // let options = new RequestOptions({ headers: contentHeaders });
        return this.http.get(this.servicesAccount + '?page=' + pageNumber, this.options).pipe(
          // map((account: any) => account.json()),
          catchError(error => this.handleError(error))
        );
    }

    searchFields(search: any): Observable<any> {
      console.log('search', search);
      return this.http.get(this.servicesAccount + '?search=' + search, this.options).pipe(
          tap(data => console.log('data son', data)),
          // map((response: any) => response.json()),
          catchError(error => this.handleError(error))
      );
    }

    private handleError(err: any): Observable<never> {
        return throwError(err);
    }
}
