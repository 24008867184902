<div>
  <div class="panel-heading hbuilt default-df" (click)="showConfiguration = !showConfiguration" >
      <label *ngIf="!showCustomDfConfigurationToggle || disableConfigToggle" class="margin-bottom-zero cursor-pointer">
          PROVIDER CONFIGURATION
      </label>
      <span class="font16 fa cursor-pointer" *ngIf="!disableConfigToggle" (click)="toggleUseDefaultConfiguration($event, customDFConfigStatus); setDefaultStatus(dfProvidersData)" [ngClass]="{'fa-toggle-off':!hasCustomDfConfiguration, 'fa-toggle-on option-selected':hasCustomDfConfiguration}"></span>
      <label class="margin-bottom-zero ;" *ngIf="showCustomDfConfigurationToggle && !disableConfigToggle">
          <span class="cursor-pointer">CUSTOM DEMANDFUSION CONFIGURATION</span>
      </label>
      <span class="fa pull-right cursor-pointer" *ngIf="hasCustomDfConfiguration && !disableConfigToggle" [ngClass]="{'fa-chevron-up': showConfiguration, 'fa-chevron-down': !showConfiguration}"></span>
  </div>
  <!-- [hidden]='useDefaultConfiguration || !showConfiguration' -->
  <fieldset (change)= "hasUnsavedProviderConfig = true;">
      <table width="100%" class="table-bordered table table-striped border-r-l df-table" [hidden]="(!showConfiguration || !hasCustomDfConfiguration) && !disableConfigToggle" >
          <thead>
              <tr role="row" class="provider-border">
                  <th></th>
                  <th style="width:120px;">Provider</th>
                  <th style="width:7%;">Common Config</th>
                  <th style="width:7%;">Device</th>
                  <th>Configuration</th>
              </tr>
          </thead>
          <tbody>
              <tr role="row" *ngFor="let provider of dfProvidersSorted; let i=index;" [hidden]="(adTagType.indexOf(product)!=-1 ? dfProviderAdSupport[provider.name][0].indexOf(adType) == -1 : false || (provider.name=='Pulsepoint') )|| (provider.isActive == 'inactive')">
                  <td class="df-provider-checkbox vertical-align-middle-imp">
                      <input class="i-checks" type="checkbox" (change)="updateFlag(i);toggleProvider(provider, $event);" [(ngModel)]="provider.status"/>
                  </td>
                  <td class="df-provider-name vertical-align-middle-imp">
                      {{ provider.name }}
                  </td>
                  <td class="df-common-checkbox vertical-align-middle-imp">
                      <input class="i-checks" type="checkbox" [(ngModel)]="provider['is_configuration_common']" [disabled]="!provider.status || product == 'amp'" (change)="updateFlag(i);"/>
                  </td>
                  <td [ngClass]="{'devicePaddingZero': !provider['is_configuration_common']}" class="text-align-center vertical-align-middle-imp">
                      <span *ngFor="let platform of platforms">
                          <input [(ngModel)]="provider['display_' + platform]" class="i-checks df-device-icon-check" type="checkbox"/>
                          <div [ngStyle]="{'height': (22*((provider['configuration_'+ platform].length)-1))+'px'}" *ngIf="!provider['is_configuration_common'] && provider['configuration_'+ platform].length > 1" ><span >&nbsp;</span></div>
                          <span (click)="provider.status ? provider['display_' + platform]=!provider['display_' + platform] : '' " [ngClass]="{'deviceSelected':provider['display_' + platform] ,'displayTypeBlock':!provider['is_configuration_common'], 'displayTypeInline':provider['is_configuration_common'], 'pointer-event-disabled': !provider.status}" class="fa fa-{{ platform }} fa-lg df-device-icon" #devices></span>
                          <div *ngIf="!provider['is_configuration_common'] && provider['configuration_'+ platform].length > 1" [ngStyle]="{'height': (22*((provider['configuration_'+ platform].length)-1))+'px'}"><span >&nbsp;</span></div>
                          <hr *ngIf="platform != 'mobile' && !provider['is_configuration_common']" style="margin-top: 0px; margin-bottom: 0px;">
                      </span>
                  </td>
                  <td class="df-config">
                      <div *ngIf="!provider['is_configuration_common']">
                          <div *ngFor="let platform of platforms">
                              <div class="row" *ngFor="let providerconfig of provider['configuration_'+ platform], let j=index">
                                  <div class="clo-md-3 col-sm-3 margin-bottom-10" *ngFor="let conf of providerconfig | keyvalue">
                                      <!--<span *ngIf="provider['data_type'][conf.key]=='choices' && providerconfig[conf.key]">-->
                                      <span *ngIf="provider['data_type'][conf.key]=='choices'">
                                          <span *ngIf="provider['provider']=='pubmatic'">
                                              <select class="form-control fligt-opt" style="width:80%;" [(ngModel)]="providerconfig[conf.key]" name="platform" (ngModelChange)="changeSizeRespectiveValues($event, provider['configuration_'+platform], j, provider.name, providerconfig);" [disabled]="!provider['display_' + platform]">
                                                  <option value="" disabled selected hidden>Select Size</option>
                                                  <option [value]="option.split(' ')[0]" *ngFor="let option of provider['choices']">{{ option }}</option>
                                              </select>
                                          </span>
                                          <span *ngIf="provider['provider']!='pubmatic'">
                                              <select class="form-control fligt-opt" style="width:80%;" [(ngModel)]="providerconfig[conf.key]" name="platform" (ngModelChange)="changeSizeRespectiveValues($event, provider['configuration_'+platform], j, provider.name, providerconfig);" [disabled]="!provider['display_' + platform]">
                                                  <option value="" disabled selected hidden>Select Size</option>
                                                  <option [value]="option" *ngFor="let option of provider['choices']">{{ option }}</option>
                                              </select>
                                          </span>
                                      </span>
                                      <span *ngIf="provider['data_type'][conf.key]!='choices'">
                                          <input [ngClass]="{'control-group error': errorModel[product] && errorModel[product][provider.name.toLowerCase()] && errorModel[product][provider.name.toLowerCase()]['configuration_' + platform] && errorModel[product][provider.name.toLowerCase()]['configuration_' + platform][j][conf.key]}" type="text" class="form-control inputText width90 inline-provision  provision-width" required name={{conf.key}} [(ngModel)]="providerconfig[conf.key]" [disabled]="!provider['display_' + platform] || !provider.status" (ngModelChange)="updateFlag(i);clearErrorDemandfusion(product, provider.name.toLowerCase(), conf.key, 'configuration_' + platform, j);"><span *ngIf="provider['data_type'][conf.key]!='choices' && provider.name ==='AppNexus' || provider.name ==='AppNexusAst'" class="fa fa-product-hunt cursor-pointer inline-provision margin-provision"   title="Search Placements" [ngClass]="{'btnhoverblue': provider.status}" (click)="provider.status?modal.open() && getProvisionDemandfusion():'';" ></span>
                                          <div *ngIf="errorModel[product] && errorModel[product][provider.name.toLowerCase()] && errorModel[product][provider.name.toLowerCase()]['configuration_' + platform] && errorModel[product][provider.name.toLowerCase()]['configuration_' + platform][j][conf.key]" class="text-danger"> {{errorModel[product][provider.name.toLowerCase()]['configuration_' + platform][j][conf.key] }}</div>
                                          <span class="floating-label" [ngClass]="{'float': providerconfig[conf.key] && provider.status}">{{conf.key}}</span>
                                      </span>
                                      <!-- <modal #modal (onClose)="afterModelClose();">
                                          <modal-body class="modal-margin">
                                              <span  *ngIf="!provider['is_configuration_common']">
                                                  <div *ngIf="showPlacementLoader" class="placements-loader">
                                                      <div class="loader center modal-loader"></div>
                                                  </div>
                                                  <input *ngIf="!showPlacementLoader" auto-complete [source]="provider.name ==='AppNexus'?placements_list:placements_list_video" value-formatter="name" list-formatter="name" [match-formatted]="true"  max-num-list="20" loading-text="Loading Placements.. " type="text" class="form-control inputText width90  " placeholder= "Search Placement." required name={{conf.key}} [disabled]="!provider.status" [(ngModel)]="provision_model.search" (ngModelChange)="getPlacementIdForAppNexus($event, provider['configuration_'+platform], j, provider.name)">
                                              </span>
                                          </modal-body>
                                          <modal-footer [show-default-buttons]="false">
                                              <button type="button" class="btn btn-default" data-dismiss="modal" (click)="modal.dismiss()">Cancel</button>
                                              <button type="button" class="btn btn-primary" (click)="modal.close()">Ok</button>
                                          </modal-footer>
                                      </modal> -->
                                  </div>
                                  <div style="float:right; margin:10px; display:inline; margin-right:15px;">
                                      <span *ngIf="provider['configuration_'+ platform].length == 1 || j == provider['configuration_'+ platform].length - 1" class="glyphicon glyphicon-plus-sign cursor-pointer btnhoverblue" (click)="provider.status ? addNewDfConfigRow(provider['is_configuration_common'], platform, provider, providerconfig): ''" [ngClass]="{'pointer-event-disabled color-fade': !provider.status}"></span>
                                      <span class="glyphicon glyphicon-minus" (click)="provider.status && provider['configuration_' + platform].length > 1 ? removeDfConfigRow(provider['is_configuration_common'], platform, provider, providerconfig, j): ''" [ngClass]="{'cursor-pointer btnhoverblue': provider['configuration_'+ platform].length > 1 && provider.status, 'color-fade pointer-event-disabled': provider['configuration_'+ platform].length == 1 || !provider.status}"></span>
                                  </div>
                              </div>
                              <hr style="margin: 0px 0px 10px 0px;" *ngIf="platform != 'mobile'">
                          </div>
                      </div>
                      <div *ngIf="provider['is_configuration_common']">
                          <div class="row" *ngFor="let providerconfig of provider['configuration_common']; let idx=index">
                              <div class="col-md-3 col-sm-3 margin-bottom-10" *ngFor="let conf of providerconfig | keyvalue">
                                  <span *ngIf="provider['data_type'][conf.key]=='choices'">
                                      <span *ngIf="provider['provider']=='pubmatic'">
                                          <select class="form-control fligt-opt" style="width:80%;" [(ngModel)]="providerconfig[conf.key]" name="common" (ngModelChange)="changeSizeRespectiveValues($event, provider['configuration_common'], idx, provider.name, providerconfig);" [disabled]="!provider.status">
                                              <option value="" disabled selected hidden>Select Size</option>
                                              <option [value]="option.split(' ')[0]" *ngFor="let option of provider['choices']">{{ option }}</option>
                                          </select>
                                      </span>
                                      <span *ngIf="provider['provider']!='pubmatic'">
                                          <select class="form-control fligt-opt" style="width:80%;" [(ngModel)]="providerconfig[conf.key]" name="common" (ngModelChange)="changeSizeRespectiveValues($event, provider['configuration_common'], idx, provider.name, providerconfig);" [disabled]="!provider.status">
                                              <option value="" disabled selected hidden>Select Size</option>
                                              <option [value]="option" *ngFor="let option of provider['choices']" >{{option}}</option>
                                          </select>
                                      </span>
                                  </span>
                                  <span *ngIf="provider['data_type'][conf.key]!='choices'" >
                                      <input  [ngClass]="{'control-group error': errorModel[product] && errorModel[product][provider.name.toLowerCase()] && errorModel[product][provider.name.toLowerCase()]['configuration_common'] && errorModel[product][provider.name.toLowerCase()]['configuration_common'][idx][conf.key]}" type="text"  class="form-control inputText width90 inline-provision  provision-width" required name={{conf.key}} [disabled]="!provider.status" [(ngModel)]="providerconfig[conf.key]" (ngModelChange)="updateFlag(i);clearErrorDemandfusion(product, provider.name.toLowerCase(), conf.key, 'configuration_common', idx);" ><span *ngIf="provider['data_type'][conf.key]!='choices' && provider.name ==='AppNexus'||provider.name ==='AppNexusAst'" class="fa fa-product-hunt cursor-pointer inline-provision margin-provision" [ngClass]="{'btnhoverblue': provider.status}" title="Search Placements" (click)="provider.status?modal.open() && getProvisionDemandfusion():'';" ></span>
                                      <div *ngIf="errorModel[product]  && errorModel[product][provider.name.toLowerCase()] && errorModel[product][provider.name.toLowerCase()]['configuration_common'] && errorModel[product][provider.name.toLowerCase()]['configuration_common'][idx][conf.key]"  class="text-danger"> {{ errorModel[product][provider.name.toLowerCase()]['configuration_common'][idx][conf.key] }}</div>
                                      <span class="floating-label" [ngClass]="{'float': providerconfig[conf.key] && provider.status}">{{conf.key}}</span>

                                  </span>
                                  <!-- <modal #modal (onClose)="afterModelClose();">
                                      <modal-body class="modal-margin">
                                          <span>
                                              <div *ngIf="showPlacementLoader" class="placements-loader">
                                                  <div class="loader center modal-loader"></div>
                                              </div>
                                              <input *ngIf="!showPlacementLoader" auto-complete [source]="provider.name ==='AppNexus'?placements_list:placements_list_video" value-formatter="name" list-formatter="name" [match-formatted]="true" max-num-list="20" loading-text="Loading Placements.. " type="text" class="form-control inputText width90 " placeholder= "Search Placement." required name={{conf.key}} [disabled]="!provider.status" [(ngModel)]="provision_model.search" (ngModelChange)="getPlacementIdForAppNexus($event, provider['configuration_common'], idx, provider.name)">
                                          </span>
                                      </modal-body>
                                      <modal-footer [show-default-buttons]="false">
                                          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="modal.dismiss()">Cancel</button>
                                          <button type="button" class="btn btn-primary" (click)="modal.close()">Ok</button>
                                      </modal-footer>
                                  </modal> -->
                              </div>
                              <div style="float:right; margin:10px; display:inline; margin-right:15px;">
                                  <span *ngIf="idx == provider['configuration_common'].length - 1 || provider['configuration_common'].length == 1" [ngClass]="{'pointer-event-disabled color-fade': !provider.status, 'cursor-pointer btnhoverblue': provider.status}" (click)="provider.status? addNewDfConfigRow(provider['is_configuration_common'], '', provider, providerconfig): ''" class="glyphicon glyphicon-plus-sign" ></span>
                                  <span class="glyphicon glyphicon-minus" [ngClass]="{'cursor-pointer btnhoverblue': provider['configuration_common'].length > 1, 'color-fade pointer-event-disabled': provider['configuration_common'].length == 1 || !provider.status}" (click)="provider.status && provider['configuration_common'].length > 1? removeDfConfigRow(provider['is_configuration_common'], '', provider, providerconfig, idx): ''"></span></div>
                          </div>
                      </div>
                  </td>
              </tr>
              <tr>
                  <td colspan="12">
                      <button  [class.btn-visibility]="hasUnsavedProviderConfig"  type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveDemandfusionConfiguration(dfProvidersData, product);">Save</button>
                  </td>
              </tr>
          </tbody>
      </table>
  </fieldset>
</div>
