<!-- START ADS TAG -->
<div class="panel-body">
  <div class="cursor-pointer; col-sm-6" (click)="collapseEmptySlot = !collapseEmptySlot; saveDisplayConfiguration($event)">
      <span class="vertical-align-middle">Collapse empty ad slots</span>
      <span class="fa vertical-align-middle" style="font-size:20px; cursor:pointer;" [ngClass]="{'deviceSelected fa-toggle-on':collapseEmptySlot, 'fa-toggle-off':!collapseEmptySlot}"></span>
  </div>
</div>
<br>
<div class="hpanel panel-collapse tag-row" *ngFor="let tag of adTags; let tagId=index;">
  <div class="panel-heading hbuilt">
      <div class="panel-tools" *ngIf="tag || tag.id">
          <a class="showhide" (click)="adTagToggle[tag.id] = !adTagToggle[tag.id]" style="margin-left: 5%; width: 95%; "><i class="fa" [ngClass]="{'fa-chevron-up': !adTagToggle[tag.id], 'fa-chevron-down': adTagToggle[tag.id]}"></i></a>
      </div>
      <label style="margin-right:5px; margin-bottom:0px; vertical-align: sub;">
          <input id=active class="i-checks" type="checkbox"  [(ngModel)]="tag.is_active" style="display:none;" name="active" #active="ngModel"/>
          <span class="fa" title="Active" style="font-size:20px; cursor:pointer;" (click)="changeAdStackAdstatus($event, !tag.is_active, tag.id)" [ngClass]="{'deviceSelected fa-toggle-on':tag.is_active ,'fa-toggle-off':!tag.is_active}"></span>
      </label>
      <div style="display:inline;">
          <div style="display:inline-block;" class="ellipsis">UUID: {{tag.uuid}}</div>
          <span class="glyphicon glyphicon-copy fa-lg btnhoverblue align-copy-left" style="vertical-align: middle;"  title="Copy UUID" style="cursor: pointer;" aria-hidden="false" (click) = "addToClip(tag.uuid)"></span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          [{{tag.placement}} | Pos {{tag.position}}]
          <span *ngIf="tag || tag.tag_label"> &nbsp; {{ tag.tag_label }} </span>
          <span style="margin-left:10px;width:50%;display:inline-table;">
              <span class="fa fa-desktop" [ngClass]="{'deviceSelected':tag.display_desktop}"></span> {{ tag.dimension_desktop }} &nbsp;
              <span class="fa fa-tablet" [ngClass]="{'deviceSelected':tag.display_tablet}"></span> {{ tag.dimension_tablet }} &nbsp;
              <span class="fa fa-mobile" [ngClass]="{'deviceSelected':tag.display_mobile}"></span> {{ tag.dimension_mobile }}
          </span>
      </div>
      <span style="margin-left:10px;" *ngIf="tag.sticky">
          <span class="fa fa-sticky-note"></span> {{ tag.sticky }}
      </span>
      <div class="dropdown" *ngIf ="deviceWidth < 1400" style="float: right; margin-right: 22px;">
          <span class="fa fa-ellipsis-v dropbtn" style="padding-top:1px;"></span>
          <div class="dropdown-content" style="right:0px;">
              <span class="glyphicon glyphicon-trash cursor-pointer btnhoverblue ad-trash" title="Delete Tag" (click)="deleteAdTag(tag.id)" style="float:right; margin-right: 3%; margin:8px;"></span>
              <span class="glyphicon glyphicon-copy fa-lg btnhoverblue" title="Copy Ad Tag" style="float:right; margin-right: 1%; cursor: pointer; margin-left:6px; margin:8px;" aria-hidden="false" (click) = "addToClip(inputTarget)"></span>
              <span class="glyphicon glyphicon-duplicate cursor-pointer btnhoverblue" title="Clone Ad-Tag" (click)="cloneAdTag(tag)" style="float:right; margin-right:8px; font-size: 15px; margin:8px;"></span>
          </div>
      </div>

      <span [hidden] = "deviceWidth < 1400">
          <span class="glyphicon glyphicon-trash cursor-pointer btnhoverblue ad-trash delete-tag" title="Delete Tag" (click)="deleteAdTag(tag.id)"></span>
          <span class="glyphicon glyphicon-copy fa-lg btnhoverblue copy-tag" title="Copy Ad Tag" (click) = "addToClip(inputTarget)"></span>
          <span class="glyphicon glyphicon-duplicate cursor-pointer btnhoverblue clone-tag" title="Clone Ad-Tag" (click)="cloneAdTag(tag)"></span>
      </span>

      <span class="timestamp">{{ tag.imps_timestamp }}</span>
  </div>
  <div class="panel-body" appCollapse= "adTagToggle[tag.id]">
      <fieldset (change)="tag.changeColor=true;">
          <div>
              <app-tags-config [tag]="tag" [products]="products" [account]="account" [mmtUUId]="mmtUUId" [mmtAccountNumber]="mmtAccountNumber" [product]="product" [showservice]="showservice" [showCustomDfConfigurationToggle]="showCustomDfConfigurationToggle" [dfProviders]="dfProviders" [dfProviderAdSupport]="dfProviderAdSupport" [displayAdDfConfig]="displayAdDfConfig" [adTagOption]="adTagOption" [pin] = 'pin' [floorType]='floorType'></app-tags-config>
              <div class="col-sm-12" style="margin-top:22px;padding:0px;display:inline;">
                  <span style="margin-right:10px;">
                  <input value={{getAdTagString(tag.uuid)}} class="ad-tag-textbox" type="text" style="width:97%;" #inputTarget></span>
                  <span class="glyphicon glyphicon-copy fa-lg btnhoverblue" style="vertical-align: middle;"  title="Copy Script" style="cursor: pointer;font-size:20px;" aria-hidden="false" (click) = "addToClip(inputTarget)"></span>
              </div>
              <br>
          </div>
      </fieldset>

      <div class="row form-group" style="margin-top:15px;">
          <div class="col-sm-1" style="top:15px;">
              <label>Preset</label>
          </div>
          <div class="col-sm-10" style="top:9px;padding-left:0px;padding-right:4%;">
              <select class="form-control" [(ngModel)]="tag.preset" name="preset" #preset="ngModel" (ngModelChange)="tag.changeColor=true; (tag.preset == 'null'?tag.preset=null:'')">
                  <option disabled selected hidden value="">Select language</option>
                  <option [value]="tag_option.id" *ngFor="let tag_option of adTags" [hidden]="tag.id===tag_option.id">{{tag_option.placement}} | {{ tag_option.position }} {{ tag_option.dimension_desktop }} {{ tag_option.dimension_tablet}} {{tag_option.dimension_mobile}} [{{ tag_option.uuid }}]</option>
                  <option value='null'>N/A</option>
              </select>
          </div>
          <div class="col-sm-1">
              <button  [class.btn-visibility]="tag.changeColor" type="button" style="margin-top:10px;" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveDisplayAdTagData($event, tag); tag.changeColor=false" >Save</button></div>
      </div><br>
      <div *ngIf="showservice['demandfusion'] && products[product]" [ngClass]="{'isDisabled pointer-event-disabled':tag.preset!==null}">
          <app-default [product]="'display-ads'" [account]='account' [showCustomDfConfigurationToggle]='showCustomDfConfigurationToggle' [dfProviders]='dfProviders' [dfConfiguration]='tag.df_configurations' [dfProviderAdSupport]='dfProviderAdSupport' [adType]='(tag.ad_type).toLowerCase()' [tagPreset]='tag.preset' [tag]='tag' [hasCustomDfConfiguration]='tag.custom_df_configuration' [modelObjectId]='tag.id'></app-default>
      </div>
  </div>
</div>


<div class="panel-body">
  <div class="hpanel panel-collapse tag-row">
      <div class="panel-heading hbuilt">
          <div class="panel-tools">
              <a class="showhide" (click)="isCollapsedAdsTags = !isCollapsedAdsTags"><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsedAdsTags, 'fa-chevron-down': isCollapsedAdsTags}"></i></a>
          </div>
          Create AdTag
      </div>
      <div class="panel-body" appCollapse= "isCollapsedAdsTags">
          <fieldset (click)="display.changeColor=true;">
              <div>
                  <app-tags-config [tag]="display" [products]="products" [showCustomDfConfigurationToggle]="showCustomDfConfigurationToggle"  [adTagOption]="adTagOption" [pin] = 'pin' [floorType] = 'floorType'></app-tags-config>
              </div>
          </fieldset>
          <div class="form-group">
              <button [class.btn-visibility]="display.changeColor" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" style="margin-top:3px;" (click)="saveCreateTagData('')">Save</button>
      </div>

      </div>
  </div>
</div>
