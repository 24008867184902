import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { contentHeaders, Accountcreate } from '../../shared/index';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountCreateService {
    private companyUrl = environment.accountsUrl + '/company/';
    private accountUrl = environment.apiUrl + '/account/';
    private options = { headers: contentHeaders };
    constructor(private http: HttpClient) {}

    save(model: any): Observable<Accountcreate> {
      // model['alternate_urls'] = [model['alternate_urls']];
      let urls = model['alternate_urls'];
      urls = (typeof urls === 'string') ? (urls.split(',').map((url) => url.trim())) : urls;
      model['alternate_urls'] = urls;
      return this.http.post(this.accountUrl, model , this.options).pipe(
        map((data: any) => data.json()),
        catchError(error => this.handleError(error))
      );
    }

    public getAccount(id: number): Observable<any> {
      return this.http.get(this.companyUrl + id + '/', this.options).pipe(
        map((data: any) => data.json()),
        catchError(error => this.handleError(error))
      );
    }

    private handleError(err: any): Observable<any> {
        return throwError(err);
    }
}
