import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../shared/services/auth.service';
import { AccountDetailService } from '../shared/services/account-detail.service';
import { BaseList } from '../shared/index';
import { AccountListService } from '../account/account-list/account-list.service';
import { RouterEvent } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config, contentHeaders } from '../shared/index';
import { GlobalVaribleService } from '../shared/services/index';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  showSubSubMenu = false;

  searchModel = new BaseList('');
  data: any = { results: [] };
  token: any;
  ticket: any;
  showHeaderSync = false;
  baseHeader = false;
  searchHeader = false;
  accountId: any;
  isSyncLoader = false;
  dashboardUrl: any;
  downloadPluginUrl: any;
  downloadAdstxtUrl: any;
  errorModel: any;
  dashboardDetailUrl: any;
  accountData: any = { ac_number: '' };
  latestReleaseDate = '';
  issue: any = {
      'issue_type': 'Task', 'tm3_link': window.location.host + '/#' + this.router.url,
      'priority': '1 Tiny'
  };
  priorityList: any = ['People Are Dying', '120 Bonkers', '80 Extra Huge', '40 Huge',
      '21 Extra Large', '13 Large', '8 Medium-Large', '5 Medium', '3 Small-ish', '2 Small', '1 Tiny'];
  errorModal: any = {};
  user: any;
  showModalLoader = false;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('anCloseBtn') anCloseBtn: ElementRef;
  public divMargin = '180px';
  private options = {headers: contentHeaders};
  private accountUrl = Config.HOST + '/account/';

  constructor(
    private router: Router,
    private accountDetailService: AccountDetailService,
    // private accountService: AccountListService,
    // private route: ActivatedRoute,
    private cookieService: CookieService,
  // private toasterService: ToasterService,
    private authService: AuthService,
    private http: HttpClient,
    private globaleVaribleService: GlobalVaribleService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  @HostListener('window:scroll', [])
    onWindowScroll(): void {
        const scrollDistance = this.document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollDistance > 150 && this.showHeaderSync) {
            this.baseHeader = true;
            this.showHeaderSync === true ? this.baseHeader = true : this.baseHeader = false;
        } else if (!this.showHeaderSync) {
            scrollDistance < 150 ? this.searchHeader = false : this.searchHeader = true;
        } else {
            this.baseHeader = false;
        }
    }

    ngOnInit(): void {
      this.user = this.cookieService.get('loggedInUser');
      this.router.events.subscribe((event: RouterEvent) => {
        console.log('event url', event.url);
        if (event && event.url && event.url.indexOf('/detail/') !== -1) {
          this.accountId = parseInt(event.url.split('/detail/')[1]);
          this.showHeaderSync = true;
          this.searchHeader = true;
          this.getDetails();
        } else {
          this.showHeaderSync = false;
          this.searchHeader = false;
          this.baseHeader = false;
          this.searchModel.search = '';
        }
        this.latestReleaseDate = this.getCookiebyName('latest_release_date');
      });

      this.dashboardUrl = (document.domain === 'tags.monumetric.com' ?
        'console.monumetric.com' : 'console-4-staging.monumetric.com') + '/auth/login';
      this.downloadPluginUrl = (document.domain === 'tags.monumetric.com' ?
        'monu.delivery' : 'dev.monu.delivery') + '/wp-plugins/monumetric-ads/MonumetricAds.zip';
      this.downloadAdstxtUrl = (document.domain === 'tags.monumetric.com' ?
        'monu.delivery.s3.amazonaws.com' : 'dev.monu.delivery.s3.amazonaws.com') + '/adstxt/common/ads.txt';
    }

    getProviderFlags(): boolean {
      const flagList = [];
      if (Object.keys(this.globaleVaribleService.products).length) {
        Object.keys(this.globaleVaribleService.products).forEach((val) => {
          if ((val === 'inimage-ads' || val === 'highimpact-ads') && this.globaleVaribleService.products[val].providers) {
            this.globaleVaribleService.products[val].providers.forEach((d) => {
              flagList.push(d.isFieldChanged ? true : false);
            });
          }
        });
      }
      if (this.globaleVaribleService.providerListForFlags) {
        Object.keys(this.globaleVaribleService.providerListForFlags).forEach((val) => {
          this.globaleVaribleService.providerListForFlags[val].forEach((d) => {
            flagList.push(d.isFieldChanged ? true : false);
          });
        });
      }
      if (this.globaleVaribleService.adTags.length) {
        this.globaleVaribleService.adTags.forEach((val) => {
          flagList.push(val.changeColor ? true : false);
        });
      }
      if (this.globaleVaribleService.adDisplayTags.length) {
        this.globaleVaribleService.adDisplayTags.forEach((val) => {
          flagList.push(val.changeColor ? true : false);
        });
      }
      return flagList.some((a) => a);
    }

    getFlags(): any {
      // tslint:disable-next-line: max-line-length
      return this.globaleVaribleService && this.globaleVaribleService.allFlags && !this.getProviderFlags() && !this.globaleVaribleService.video.changeColor && !this.globaleVaribleService.display.changeColor && Object.keys(this.globaleVaribleService.allFlags).every((a) => {
        // tslint:disable-next-line: max-line-length
        return (a !== 'isFlightExclusionSelected' && a !== 'isFlightExclusionURLSelected' && a !== 'isFlightPositionFloorsSelected' && a !== 'isInimageExclusionSelected' && a !== 'historyResponse' && a !== 'amp') ? !this.globaleVaribleService.allFlags[a] : true;
      });
    }


    observableSource = (keyword: any): Observable<any[]> => {
      const url: string = this.accountUrl + '?search=' + keyword;
      console.log('url', url);
      if (keyword) {
          return this.http.get(url, this.options).pipe(
            map((res: any) => {
              const json = res.json();
              return json.results;
            }),
            catchError(error => this.handleError(error))
          );
      } else {
          return of([]);
      }
    }

    clearSearch(): void {
        this.searchModel.search = '';
    }

    getCookiebyName(name): any {
        const pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
        return !!pair ? pair[1] : null;
    };

    selectValues(event): void {
        if (event.length !== 0 && typeof (event) === 'object' && event.id !== 'undefined') {
            this.router.navigate(['/account/detail/', event.id]);
            setTimeout(() => {
                // window.open(location.origin+'/#'+'/account/detail/'+event.id, '_self');
                window.location.assign(location.origin + '/#' + '/account/detail/' + event.id);
                location.reload();
            }, 1000);
        }
    }

    syncAccountDetail(event: any): void {
        this.isSyncLoader = true;
        this.accountDetailService.syncDetails(this.accountId)
            .subscribe(
                data => this.getSyncScript(data),
                error => this.showErrorMessageSync(error));

    }

    getDetails(): void {
        this.accountDetailService.getAccountDetails(this.accountId)
            .subscribe(
                data => this.getData(data),
                error => this.errorMessage(error));
    }

    popToast(type_: any, message: any): void {
        let type = type_;
        // this.toasterService.pop(type, message);
    }

    logout(event: any): void {
        this.authService.logout()
            .subscribe();
    }

    change_margin(): void {
        if (this.divMargin === '0px') {
            this.divMargin = '180px';
        } else {
            this.divMargin = '0px';
        }
    }


    private getSyncScript(data: any): void {
        this.popToast('success', 'Synced Successfully.');
        this.isSyncLoader = false;
    }


    private getData(data: any): void {
        this.accountData = data;
        this.dashboardDetailUrl = this.dashboardUrl + '?ac_number=' + this.accountData.ac_number;
    }

    private showErrorMessageSync(error: any): void {
        this.popToast('error', 'Can Not Sync.');
        this.isSyncLoader = false;
    }

    private errorMessage(error: any): void {
        this.errorModel = error;
    }

    public reportIssue(event: any): void {
        this.issue.email = this.user;
        this.issue.ac_number = this.showHeaderSync ? this.accountData.ac_number : '';
        if (this.issue.subject && this.issue.description && this.issue.email) {
          this.showModalLoader = true;
          this.accountDetailService.reportAnIssue(this.issue).subscribe(res => {
            this.showModalLoader = false;
            this.popToast('success', 'Thanks for your feedback!.');
            this.closeBtn.nativeElement.click();
          }, err => {
            this.showModalLoader = false;
            this.popToast('error', 'Something went wrong, Please retry!');
          });
        }
        else {
          if (!this.issue.subject) {
            this.errorModal.subject = true;
          }
          if (!this.issue.description) {
            this.errorModal.description = true;
          }
          if (!this.issue.email) {
            this.errorModal.email = true;
          }
        }
    }
    modalChange(event: any): void {
        if (this.issue.subject) {
            this.errorModal.subject = false;
        }
        if (this.issue.description) {
            this.errorModal.description = false;
        }
    }

    clearData(event: any): void {
        this.issue = {
            'issue_type': 'Task',
            'tm3_link': window.location.host + '/#' + this.router.url,
            'priority': '1 Tiny'
        };
        this.errorModal = {};
    }

    clearAccountNotesData(event: any, accountNotes: any): void {
        this.accountData.account_notes = (this.document.getElementById('account_notes') as HTMLInputElement).value;
    }

    saveAccountNotes(event: any): void {
        (this.document.getElementById('account_notes') as HTMLInputElement).value = this.accountData.account_notes;
        this.anCloseBtn.nativeElement.click();
        setTimeout(() => document.getElementById('accountNotesSave').click());
    }

    scrollToCustomSettings(event: any): void {
        const customSettingsBox = document.getElementById('custom_settings');
        const offset = customSettingsBox.offsetTop;
        window.scrollTo({top: offset, behavior: 'smooth'})
        if (document.getElementById('cs_panel_body').getAttribute('aria-expanded') == 'false') {
            document.getElementById('cs_expand').click()
        }
    }
    private handleError(err: Response): Observable<Error> {
      return throwError(err);
    }

    mouseenter(): void {
      if (!this.isExpanded) {
        this.isShowing = true;
      }
    }

    mouseleave(): void {
      if (!this.isExpanded) {
        this.isShowing = false;
      }
    }

}
