import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountCreateService } from '../../shared/services/account-create.service';
import { SharedService } from '../../shared/services/account.service';
import { Accountcreate } from '../../shared/index';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';

@Component({
  selector: 'app-account-create',
  templateUrl: './account-create.component.html',
  styleUrls: ['./account-create.component.scss']
})
export class AccountCreateComponent implements OnInit {
  notFilled = false;
  errorMessage: string;
  mode = 'Observable';
  submitted = false;
  active = true;
  datas; any;
  data = '';
  errorModel: {} = {};
  tierOption: {} = {};
  // TODO: Accountcreate needed
  model = new Accountcreate(null, '', '', '', '', [''], '', 'inactive', 20842576, false, '');
  // statuses = [
  //     new Accountcreate(null,'','','','',[''],'','inactive', 20842576,false,''),
  //     new Accountcreate(null,'','','','',[''],'','active',20842576,false,'')
  // ];
  constructor(private accountcreateService: AccountCreateService,
              private sharedService: SharedService,
              private router: Router  ) { }

  ngOnInit(): void {
  }

  popToast(toastType: any, message: any): void {
    const type = toastType;
    // this.toasterService.pop(type , message);
  }


  onSubmit(): void { this.submitted = true; }

  create(event: any): void {
      this.accountcreateService.save(this.model)
          .subscribe(
              data => this.accountdata(data),
              error =>  this.errormessage(error));
  }

private accountdata(data: any): void {
    const url = '/account/detail/' + data.id;
    this.popToast('success', 'Account has been created successfully!');
    this.router.navigate([url]);

}

private errormessage(error: any): void {
    this.errorModel = error;
}


}
