export class Product {
  constructor(
      public status: string,
      public configuration: any
  ) { }
}

export class CustomAnalysis {
  constructor(
      public css_style: string,
      public js_prefix: string,
      public js_postfix: string,
  ) { }
}

export class Accountdetail {
  constructor(
      public ac_number: number,
      public crm_link: string,
      public url: string,
      public ad_unit: string,
      public company_name: string,
      public alternate_urls: Array<string>,
      public tier: string,
      public status: string,
      public dfp_account: number,
      public dfp_single_request: boolean,
      public updated_on: string,
      public uuid: string,
      public ticker: string,
      public css_style: string,
      public js_postfix: string,
      public js_prefix: string,
      public load_async: boolean,
      public monitor_redirect: boolean,
      public account_status: boolean,
      public enable_error_logging: boolean,
      public enable_analytics: boolean,
      public flight_imps_timestamp: string,
      public wp_plugin_version: string,
      public wp_plugin_reported_on: string,
      public delivery_s2s_enabled: boolean,
      public enable_safeframe: boolean,
      public publica_site_uuid: string,
      public uam_approved: boolean,
      public uam_public_id: string,
      public lotame_ac_id: number,
      public enable_lotame: boolean,
      public sandbox: number,
      public use_bid_cache: boolean,
      public require_interaction: boolean,
      public delivery_selected_version: string,
      public imp_preference: string,
      public program: string,
      public platform: string,
      public site_rank: string,
      public floor_type: string,
      // Additions dur to Template errors
      public account_notes?: any,
      public alternate_url?: any,
      public blogger_account_name?: any,
      public delivery_choices?: any,
      public pin?: any,
      //  public activity_tracking_code: string,
      //  public enable_activity_tracking: boolean,
  ) { }


}

export class Accountcreate {
  constructor(

      public ac_number: number,
      public url: string,
      public crm_link: string,
      public ad_unit: string,
      public company_name: string,
      public alternate_urls: Array<string>,
      public tier: string,
      public status: string,
      public dfp_account: number,
      public dfp_single_request: boolean,
      public ticker: string


  ) { }
}

export class AccountServiceDetail {
  constructor(
      public showhidedemandf: boolean,
      public showhideimage: boolean,
      public showhidehighimpact: boolean,
      public showhideflight: boolean,
      public showhidedfp: boolean,
      public showhideads: boolean,
      public showhidedisplayads: boolean,
      public showhidereferralprogram: boolean,
      public showhidebyba: boolean
  ) { }
}

export class TierOption {
  constructor(
      public tier: any
  ) { }
}

export class Entry {
  constructor(public id: number, public name: string) { }
}

export class AccountEdit {
  constructor(
      public siteurl: string,
      public inventory_name: string,
      public company: string
  ) { }
}

export class AccountAnalysis {
  constructor(
      public code_snapshot: string,
      public alternative_ads: string,
      public tier: string,
      public active_ads: string
  ) { }
}

export class DemandFusionConfig {
  constructor(
      public is_timeout_common: boolean,
      public timeout_common: string,
      public timeout_desktop: string,
      public timeout_tablet: string,
      public timeout_mobile: string,
      public console_log: boolean,
      public mediadotnet: boolean,
      public otb: string,
  ) { }
}

export class DemandFusion {
  constructor(
      public configuration: DemandFusionConfig,
      public status: string = 'active',
  ) { }
}


export class ProvidersConfig {
  configuration: string;
}

export class DFP {
  constructor(
      public primary_id: string,
      public flight_id: string,
      public primary_request: boolean,
      public flight_request: boolean
  ) { }
}

export class Visibility {
  constructor(
      public display_desktop: string | boolean,
      public display_mobile: string | boolean,
      public display_tablet: string | boolean,

  ) { }
}

export class DisplayAds {
  constructor(
      public placement: string,
      public dimension: string,
      public position: string,
      public branding: string,
      public exclusion_selector: string,
      public positioning: boolean,
      public is_repeatable: boolean,
      public line_break: string,
      public line_break_toggle: boolean,
      public weight: number,
      public is_active: boolean | string,
      public notification: boolean | string,
      public refresh_ad: boolean | string,
      public refresh_ad_time: number,
      public refresh_ad_cap: number,
      public sticky: string,
      public sticky_configuration: any = {},
      public dimension_desktop: any = [],
      public dimension_tablet: any = [],
      public dimension_mobile: any = [],
      public display_desktop: boolean,
      public display_tablet: boolean,
      public display_mobile: boolean,
      public collapse_empty_div: boolean,
      public enable_close_button: boolean,
      public ad_type: string,
      public token: string,
      public stick_for: any,
      public refresh_for: any,
      public custom_key_value: any,
      public preset: any,
      public manual_refresh: boolean,
      public tagCustomTargetingStatus: any = {},
      public error: any = {},
      public colorChange: boolean,
      public imps_timestamp: string,
      public advertise_with_us: string,
      public custom_dfp_account: number,
      public custom_ad_unit: string,
      public account: string,
      public configuration: any = {},
      public floor_desktop: boolean | string,
      public floor_tablet: boolean | string,
      public floor_mobile: boolean | string,
      public df_floor_desktop: number | string,
      public df_floor_tablet: number | string,
      public df_floor_mobile: number | string,
      public adx_floor_desktop: number | string,
      public adx_floor_tablet: number | string,
      public adx_floor_mobile: number | string,
      public tag_label: string,
      public ipin_desktop: any = {},
      public ipin_tablet: any = {},
      public ipin_mobile: any = {},
      public refresh_cutoff: boolean,
      public common_cutoff: boolean,
      public common_cutoff_count: number,
      public desktop_cutoff_count: number,
      public tablet_cutoff_count: number,
      public mobile_cutoff_count: number,
      public floor_type_desktop: string,
      public floor_type_tablet: string,
      public floor_type_mobile: string,
  ) { }
}

export class VideoAds {
  constructor(
      public placement: string,
      public position: string,
      public dimension: string,
      public exclusion_selector: string,
      public line_break: string,
      public line_break_toggle: boolean,
      public weight: number,
      public is_active: boolean | string,
      public sticky: string,
      public stick_for: any,
      public sticky_configuration: any = {},
      public enable_close_button: boolean,
      public dimension_desktop: any = [],
      public dimension_tablet: any = [],
      public dimension_mobile: any = [],
      public display_desktop: boolean,
      public display_tablet: boolean,
      public display_mobile: boolean,
      public ad_type: string,
      public token: string,
      public custom_key_value: any,
      public tagCustomTargetingStatus: any = {},
      public error: any = {},
      public colorChange: boolean,
      public imps_timestamp: string,
      public restrict_to: Array<string>,
      public restrict_to_status: boolean,
      public custom_dfp_account: number,
      public custom_ad_unit: string,
      public account: string,
      public configuration: any = {},
      public ipin_desktop: any = {},
      public ipin_tablet: any = {},
      public ipin_mobile: any = {}
  ) { }
}

export class InImage {
  constructor(
      public gumgum_id: string,
      public in_image: boolean,
      public sticky: boolean,
      public exclusions: boolean,
      public exclusions_url: string
  ) { }
}

export class HighImpact {
  constructor(
      public providers_list: string,
      public control_see: boolean
  ) { }
}

export class Exclusions {
  constructor(
      public selector: string,
      public expiry_date: string,
  ) { }
}

export class FlightExclusionURLs {
  constructor(
      public url_path: string,
      public expiry_date: string,
  ) { }
}

export class Flight2 {
  constructor(
      public entry_container: string,
      public every_element: string,
      public element_count: number,
      public pos: string,
      public max: number,
      public avoid_child: string,
      public avoid_parent: string,
      public skip_el: string,
      public devices: Array<string> | string
  ) { }
}

export class PositionFloors {
  constructor(
      public position: string,
      public df_floor_desktop: string,
      public df_floor_tablet: string,
      public df_floor_mobile: string,
      public adx_floor_desktop: string,
      public adx_floor_tablet: string,
      public adx_floor_mobile: string,
      public df_reduce_desktop: string,
      public df_reduce_tablet: string,
      public df_reduce_mobile: string,
      public adx_reduce_desktop: string,
      public adx_reduce_tablet: string,
      public adx_reduce_mobile: string,
      public floor_type_desktop: string,
      public floor_type_tablet: string,
      public floor_type_mobile: string,
  ) { }
}

export class Highimpact {
  constructor(
      public enabled_device: Array<string>,

  ) { }
}

export class FlightConfiguration {
  constructor(
      public m_enable: boolean,
      public t_enable: boolean,
      public d_enable: boolean,

      public m_ad_sizes: Array<string>,
      public t_ad_sizes: Array<string>,
      public d_ad_sizes: Array<string>,

      public m_selector: string,
      public t_selector: string,
      public d_selector: string,

      public m_refresh_ad: boolean | string,
      public t_refresh_ad: boolean | string,
      public d_refresh_ad: boolean | string,

      public m_refresh_time: number,
      public t_refresh_time: number,
      public d_refresh_time: number,

      public m_refresh_cap: number,
      public t_refresh_cap: number,
      public d_refresh_cap: number,

      public m_word_count: number,
      public t_word_count: number,
      public d_word_count: number,

      public m_vertical_pixel: number,
      public t_vertical_pixel: number,
      public d_vertical_pixel: number,

      public m_word_threshold: number,
      public t_word_threshold: number,
      public d_word_threshold: number,

      public exclusions: Array<Exclusions>,
      public exclusion_urls: Array<FlightExclusionURLs>,
      public custom_dfp: number,
      public advertise_with_us: string,
      public enable_uam: boolean | string,
      public position_floors: Array<PositionFloors>,

      public flight_version: number,
      public flight2: Array<Flight2>,

      public m_refresh_cutoff: boolean | string,
      public t_refresh_cutoff: boolean | string,
      public d_refresh_cutoff: boolean | string,

      public m_refresh_cutoff_count: number,
      public t_refresh_cutoff_count: number,
      public d_refresh_cutoff_count: number,

      public sticky_enabled: boolean | string,
      public stick_for: any[],
      public is_sticky_common: boolean | string,
      public common_offset_height: string,
      public common_stick_height: string,
      public common_multisticky: number,
      public common_stick_until_next_slot: boolean | string,
      public d_offset_height: string,
      public d_stick_height: string,
      public d_multisticky: number,
      public d_stick_until_next_slot: boolean | string,
      public t_offset_height: string,
      public t_stick_height: string,
      public t_multisticky: number,
      public t_stick_until_next_slot: boolean | string,
      public m_offset_height: string,
      public m_stick_height: string,
      public m_multisticky: number,
      public m_stick_until_next_slot: boolean | string,

      public d_df_floor: string,
      public t_df_floor: string,
      public m_df_floor: string,
      public d_adx_floor: string,
      public t_adx_floor: string,
      public m_adx_floor: string,
      public d_df_reduce_refresh: string,
      public t_df_reduce_refresh: string,
      public m_df_reduce_refresh: string,
      public d_adx_reduce_refresh: string,
      public t_adx_reduce_refresh: string,
      public m_adx_reduce_refresh: string,
      public d_floor_type: string,
      public t_floor_type: string,
      public m_floor_type: string,

  ) { }
}

export class Flight {
  constructor(
      public configuration: FlightConfiguration,
      //public status:string = 'active'
  ) { }
}

export class InimageConfiguration {
  constructor(
      public exclusions: Array<Exclusions>,
  ) { }
}

export class Inimage {
  constructor(
      public configuration: InimageConfiguration,
      //public status:string = 'active'
  ) { }
}

export class AdsTagResponsive {
  constructor(
      public device_type: string,
      public min_width: string,
      public dimentions: string
  ) { }
}

export class AdsTag {
  constructor(
      public folds: string,
      public ad_size: string,
      public display_ads: string,
      public responsive: any = {},
      public exclusions: Array<string>
  ) { }
}

export class AccountList {
  constructor(
      public search: string,
  ) { }
}

export class BaseList {
  constructor(
      public search: string,
  ) { }
}

export class Provision {
  constructor(
      public search: string,
  ) { }
}

export class ExclusionURL {
  constructor(
      public path: string,
      public till_date: string,
  ) { }
}

// export class ActivityTrackingURL {
//     constructor(
//         public path: string,
//         public till_date: string,
//     ) { }
// }
