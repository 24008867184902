// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: (window as any).APPLICATION_CONFIG.baseUrl.indexOf('<') === -1 ? (window as any).APPLICATION_CONFIG.baseUrl : 'http://local.console.monumetric.com:9009',
  apiUrl: (window as any).APPLICATION_CONFIG.apiUrl.indexOf('<') === -1 ? (window as any).APPLICATION_CONFIG.apiUrl : 'https://services-tags.monumetric.com/',
  deliveryUrl: (window as any).APPLICATION_CONFIG.apiUrl.indexOf("<") === -1 ? (window as any).APPLICATION_CONFIG.apiUrl : 'http://127.0.0.1:8000',
  tm3_base_url: (window as any).APPLICATION_CONFIG.tm3_base_url.indexOf('<') === -1 ? (window as any).APPLICATION_CONFIG.tm3_base_url : 'https://tags-dev.monumetric.com/#',
  accountsUrl: (window as any).APPLICATION_CONFIG.accountsUrl.indexOf('<') === -1 ? (window as any).APPLICATION_CONFIG.accountsUrl : 'https://account.monumetric.com',
  // google_client_id: ( window as any).APPLICATION_CONFIG.google_client_id.indexOf('<') === -1 ? (as as window  any).APPLICATION_CONFIG.google_client_id : '573700544346-0d67p5hd8dgul9gpu8hn3lvn763ep2bh.apps.googleusercontent.com',
  // facebook_client_id: ( window as any).APPLICATION_CONFIG.facebook_client_id.indexOf('<') === -1 ? ( window as any).APPLICATION_CONFIG.facebook_client_id : '1641959872739224',
  // labelUrl: ( window as any).APPLICATION_CONFIG.labelUrl.indexOf('<') === -1 ? (window as any).APPLICATION_CONFIG.labelUrl : 'https://s3.amazonaws.com/dev.monu.delivery/video-labels/',
  // dashboard_message: ( window as any).APPLICATION_CONFIG.dashboard_message.indexOf('<') === -1 ? ( window as any).APPLICATION_CONFIG.dashboard_message : 'Test works !',
  // latest_release_date: ( window as any).APPLICATION_CONFIG.latest_release_date.indexOf('<') === -1 ? ( window as any).APPLICATION_CONFIG.latest_release_date : '',
  // propel_fee: ( window as any).APPLICATION_CONFIG.propel_fee.indexOf('<') === -1 ? ( window as any).APPLICATION_CONFIG.propel_fee : '99',
  // approval_note : ( window as any).APPLICATION_CONFIG.approval_note.indexOf('<') === -1 ? ( window as any).APPLICATION_CONFIG.approval_note : '<i>PLEASE NOTE: Due to high demand, our Propel Program currently has a waiting list. You can expect an extra waiting period of <b>2-3 months</b> before we will be able to get your site approved and live. Please keep an eye on your inbox for updates from our team when your site is ready to be onboarded. Thank you for your understanding.</i>',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
