import { Component, Input, OnInit } from '@angular/core';
import { SharedService} from '../../../shared/services/index';
import { AccountDetailService } from '../../../shared/services/account-detail.service';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';
// import { Clipboard } from 'ts-clipboard';
import { PubsubService } from '../../pubsub.service';
@Component({
  selector: 'app-tags-config',
  templateUrl: './tags-config.component.html',
  styleUrls: ['./tags-config.component.scss']
})
export class TagsConfigComponent implements OnInit {
  @Input() account: string;
  @Input() mmtUUId: string;
  @Input() mmtAccountNumber: string;
  @Input() product: string;
  @Input() products: any;
  @Input() showservice: any;
  @Input() showCustomDfConfigurationToggle: any;
  @Input() dfProviders: any[];
  @Input() dfProviderAdSupport: any;
  @Input() displayAdDfConfig: any;
  @Input() tag: any;
  @Input() adTagOption: any;
  @Input() accountURL: string;
  @Input() pin: string;
  @Input() floorType: string;

  customAdUnit = true;
  hasUnsavedValues = false;
  isRestrictToSelected = false;
  showCustomTargeting = false;
  isCollapsedRestrictTo = false;

  constructor(
    // private toasterService: ToasterService,
    public pubsub: PubsubService) {
  }

  ngOnInit() {
      this.pubsub.displayAdsProvider.subscribe(data => {
          if(data['id'] == this.tag['id']){
              if('customAdUnit' in data){
                  this.customAdUnit = data['customAdUnit'];
              }
          }
      });
      this.customAdUnit = this.tag.custom_ad_unit?true:false;
      if(this.tag.ad_type === 'Video' || this.tag.ad_type === 'video') {
          //this.tag.configuration['enable_spotx'] = this.tag.configuration['enable_spotx'] === 'false'?false:true;
          this.tag.configuration['auto_play'] = this.tag.configuration['auto_play'] === 'false'?false:true;
          this.tag.configuration['sound_mute'] = this.tag.configuration['sound_mute'] === 'false'?false:true;
          this.tag.configuration['loop_playback'] = this.tag.configuration['loop_playback'] === 'false'?false:true;
          this.tag.configuration['aspect_ratio'] = this.tag.configuration['aspect_ratio'] || 'Auto';
          this.tag.configuration['player_style'] = this.tag.configuration['player_style'] || 'Basic';
      }
      if(this.tag.ad_type === 'Display' || this.tag.ad_type === 'display') {
          this.tag.configuration['enable_uam'] = this.tag.configuration['enable_uam'] === 'true'?true:false;
      }
      if(this.tag.ad_type === 'Video') {
          this.tag.dimension_desktop = ['640x480', '400x300', '1x1'];
          this.tag.dimension_mobile = ['640x480', '400x300', '1x1'];
          this.tag.dimension_tablet = ['640x480', '400x300', '1x1'];
      }
  }

  toggleCustonAdUnit() {
      this.tag.custom_ad_unit = null;
      this.customAdUnit = !this.customAdUnit;
  }

  popToast(type_:any, message:any) {
      let type = type_;
      // this.toasterService.pop(type , message);
  }

  addToClip(data: any) {
      if (typeof(data) === 'object') {
          // Clipboard.copy(data.value);
      } else {
          // Clipboard.copy(data);
      }
      this.popToast('info', 'Copied!');
  }

  setAdvertiseWithUs(tag) {
      this.tag.advertise_with_us = this.tag.advertise_with_us?null:'right';
      tag.error.advertise_with_us = {};

  }

  setAutoplay(tag) {
      this.tag.configuration['auto_play'] = !this.tag.configuration['auto_play'];
  }

  setUam(tag) {
      this.tag.configuration['enable_uam'] = !this.tag.configuration['enable_uam'];
  }

  setSoundMute(tag) {
      this.tag.configuration['sound_mute'] = !this.tag.configuration['sound_mute'];
  }

  setLoopPlayback(tag) {
      this.tag.configuration['loop_playback'] = !this.tag.configuration['loop_playback'];
  }

  setLineBreak(ad_tag) {
      if(ad_tag) {
          this.tag.isLineBreakEnable = !this.tag.isLineBreakEnable;
      }
      if (this.tag.isLineBreakEnable === false) {
          this.tag.line_break = '';
      }
      if (this.tag.isLineBreakEnable === true && this.tag.line_break === '') {
          this.tag.line_break = 'Both';
      }
  }

  setStickyAd(stickyTag) {
      if(stickyTag) {
          this.tag.isStickyEnable = !this.tag.isStickyEnable;
      }
      if (this.tag.isStickyEnable === false) {
          this.tag.sticky = '';
      } else {
          this.tag.sticky = stickyTag.ad_type == 'Video' ? 'bottom-right' : 'sidebar';
      }
  }

  updateKeyValueDevice(tag:any, device:any, index:number) {
      if(tag.custom_key_value[index]['device'].indexOf(device) !== -1) {
          tag.custom_key_value[index]['device'].splice(tag.custom_key_value[index]['device'].indexOf(device), 1);
      } else {
          tag.custom_key_value[index]['device'].push(device);
      }
  }
  addRestrictValue() {
      this.tag.restrict_to.push({'value':''});
  }

  toggleUseFloors(device) {
      this.tag['floor_'+device]=!this.tag['floor_'+device];
      if (!this.tag['floor_'+device]) {
          this.tag['reduce_floor_'+device] = false;
      }
  }

  getFloorType(site_type, tag_type) {
      if (tag_type == site_type || tag_type == 'site' || !tag_type) {
          return site_type
      }
      return tag_type
  }
}

