<div>

  <table width="100%" class="table-bordered table table-striped border-r-l" style="margin-bottom:5px;">

          <thead>
              <tr role="row" class="provider-border">
                  <th></th>
                  <th style="width:160px;">Provider</th>
                  <th *ngIf="product == 'highimpact-ads'" style="width:70px;">Device</th>
                  <th>Configuration</th>
                  <th>Action</th>
              </tr>
          </thead>

              <tbody *ngIf="configuration">

                  <tr role="row" *ngFor="let provider of configuration.providers">
                      <td style="width:40px; text-align:center; vertical-align: middle;">
                          <input type="checkbox" [(ngModel)]="provider.status" name="status" #status="ngModel" (ngModelChange)="errorModel[product]={};" (change)="provider['isFieldChanged']=true"/>
                      </td>
                      <td style ="font-weight: bold; vertical-align: middle;" >
                          {{ provider.name }}
                          <span *ngIf="provider.is_provisionable" class="fa fa-product-hunt cursor-pointer btnhoverblue" (click)="getProvisionId(provider)"></span>
                          <div class="provision-loader-div">
                              <span *ngIf="loaderList[provider.id]" class="provision-loader center" ></span>
                          </div>
                      </td>
                      <td *ngIf="product == 'highimpact-ads'">
                          <span *ngFor="let platform of platforms" (click) = "provider.status?updateEnabledDevice(platform, provider):''">
                              <input checked='false' class="i-checks df-device-icon-check" type="checkbox" [(ngModel)]='provider.enable_device'/>
                              <span [ngClass]="{'deviceSelected': provider.enable_device.indexOf(platform) != -1 , 'pointer-event-disabled': !provider.status}" class="fa fa-{{ platform }} fa-lg df-device-icon" #devices></span>

                          </span>
                      </td>
                      <td style="padding-top: 0px; padding-bottom: 0px; vertical-align:middle; line-height: 1;">
                          <div *ngIf="product!='demandfusion'" class="row" style="display:inline;">
                              <div class="col-md-4" *ngFor="let conf of provider.configuration" id="test">
                                  <div *ngIf="conf.data_type=='string' || conf.data_type=='integer'">
                                      <input [ngClass]="{'control-group error': errorModel[product] && errorModel[product][provider.id] && errorModel[product][provider.id].configuration && errorModel[product][provider.id].configuration[conf.key] }" [disabled]="!provider.status" type="text" class="form-control providerInputText" required name={{conf.key}} [(ngModel)]='conf.value' (ngModelChange)="errorModel[product]={};provider['isFieldChanged']=true" style="width:100%;"/>
                                      <div *ngIf="errorModel[product] && errorModel[product][provider.id] && errorModel[product][provider.id].configuration && errorModel[product][provider.id].configuration[conf.key]"  class="text-danger"> {{ errorModel[product][provider.id].configuration[conf.key] }} </div>

                                      <span class="floating-label">{{conf.label}}</span>
                                  </div>
                                  <fieldset (change)="provider['isFieldChanged']=true">
                                      <label *ngIf="conf.data_type=='boolean'" >
                                          <div><input type="checkbox" [disabled]="!provider.status || product == 'inimage-ads' && !stickyEnable[provider.name]" class="check-align cursor-pointer" style="margin-top:11px; margin-left:0px !important;" required name={{conf.key}} [(ngModel)]='conf.value' (ngModelChange)="gumgumStickyConfiguration(product,provider, conf.value)" ></div>
                                          <div><span style="position:absolute;top:12px; margin-left:17px;" class="cursor-pointer">{{ conf.label }}</span></div>
                                          </label>
                                  </fieldset>
                              </div>
                          </div>
                      </td>

                      <td style="width:40px; text-align:center; vertical-align: middle;">
                          <div>
                              <button  [class.btn-visibility]="provider['isFieldChanged']"  type="button" class="btn btn-sm btn-success pull-right btn-color media-prop " (click)="saveProviderConfiguration(product, provider)" > 💾</button>
                          </div>
                          <br>
                      </td>

                      <!-- <toaster-container [toasterconfig]="toasterconfig"></toaster-container>-->
                  </tr>
              </tbody>
  </table>
</div>
