<!-- Main Wrapper -->
<div class="content animate-panel">
  <div class="row media-margin">
    <div class="row">
      <div class="col-xs-9">
        <h2>{{ model.company_name }}</h2>
        <div id="hbreadcrumb">
          <ol class="breadcrumb pad0">
            <li><span><a routerLink="/account" [queryParams]="{}">Accounts</a></span> / {{ ac_number }}</li>
          </ol>
        </div>
      </div>
      <div class="col-xs-2 fr">
          <div class="panel-right-button pad-t-40">
              <div class="btn-group div-hw">
                  <label>
                      <input class="i-checks cursor-pointer" type="checkbox" (click)="(model.account_status?model.account_status=false:model.account_status=true); changeAccountStatus(model)" style="display:none;" [(ngModel)]="model.account_status" name="account_status"/>
                      <span style="font-size: 24px; margin-top:4px;" class="fa cursor-pointer" title="Account Status" [ngClass]="{'fa-toggle-on deviceSelected': model.account_status, 'fa-toggle-off':!model.account_status}"></span>
                  </label>
              </div>
              <div class="btn-group angrytext div-hw rotation-reverse" [ngClass]="{'stop':!historyCallLoader}">
                  <i class="fa fa-history tierhistory fa-lg btnhoverblue rotating-icon" title="History" aria-hidden="false" (click)="historyDetails=!historyDetails; historyDetails!=false?getAccountHistory(page):hasUnsavedValues.historyResponse=false;"></i>
              </div>
          </div>
      </div>
    </div>
    <div class="row  animate" [hidden]="!hasUnsavedValues.historyResponse">
        <div class="col-lg-12">
            <div class="hpanel">

                <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.history = !isCollapsed.history">
                    <div class="panel-tools">
                        <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.history, 'fa-chevron-down': isCollapsed.history}"></i></a>
                    </div>
                    History
                </div>

                <div class="panel-body" appCollapse="isCollapsed.history">
                    <div class="row">
                        <div *ngIf="showTableLoader" style="width:100%; height:100%; margin-top:60px; margin-bottom:60px;">
                            <div class="loader center"></div>
                        </div>
                        <div *ngIf="!showTableLoader">
                            <div class="col-sm-12 headers-set">
                                <div class="text-align-center" [hidden]="data_activity.results.length>0">
                                    <span class="font-bold" >No history record found.</span>
                                </div>
                                <table [hidden]="data_activity.results.length==0" role="grid" class="table  table-bordered table-hover dataTable no-footer table-striped table-fonts " >
                                    <thead>
                                        <tr role="row">
                                            <th style="width: 30%;">TimeStamp </th>
                                            <th>User</th>
                                            <th>Message</th>
                                            <th style="width:30px;"></th>
                                            <th></th>
                                    </thead>
                                    <tbody style="text-align: left !important;">
                                        <tr class="odd hand-point" role="row" *ngFor="let history of data_activity.results | paginate: config">
                                            <td  class="account-width">{{ history.timestamp ?(history.timestamp | stringToDate | date:'medium'):'N/A' }}</td>
                                            <td>{{ history.user.email?(history.user.email):history.user.username }}</td>
                                            <td class="border-right-none">
                                                {{ history.message }}
                                                <table class="table table-bordered table-hover dataTable no-footer table-striped table-fonts table  table-bordered table-hover dataTable no-footer table-striped table-fonts" *ngIf="history.show" style="margin-top:5px; margin-bottom:0px;">
                                                    <tr>
                                                        <th style="border-bottom-width:1px;">Field</th>
                                                        <th style="border-bottom-width:1px;">From</th>
                                                        <th style="border-bottom-width:1px;">To</th>
                                                    </tr>
                                                    <tr *ngFor="let historydata of history.data.new | keyvalue">
                                                        <td style="border-bottom-width:1px;">{{ historydata.key }}</td>
                                                        <td style="border-bottom-width:1px;">{{ history.data.old[historydata.key]?history.data.old[historydata.key].label:'N/A' | json }}</td>
                                                        <td style="border-bottom-width:1px;">{{ history.data.new[historydata.key].label | json }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td style="text-align:center">
                                                <span class="fa fa-info-circle" *ngIf="history.message" (click)="history.show = !history.show"></span>
                                            </td>
                                            <td style="text-align:center">
                                                <span class="btn btn-default" *ngIf="history.message" (click)="revertHistoryChanges(history)">Revert This Change</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-sm-7 center-pagination" [hidden]="data_activity.results.length==0">
                                <div class="dataTables_paginate paging_simple_numbers">
                                    <!-- <pagination-controls [id]="config.id"
                                                         [maxSize]="maxSize"
                                                         [directionLinks]="directionLinks"
                                                         [autoHide]="autoHide"
                                                         (pageChange)="onPageChange($event)">
                                    </pagination-controls> -->
                                    <mat-paginator
                                      [id]="config.id"
                                      [length]="maxSize"
                                      [pageSize]="config.itemsPerPage"
                                      (page)="pageEvent = onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Site Information
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse="isCollapsedSiteInformation" class="panel-body">
          <div [hidden]="submitted">

            <form *ngIf="active" data-toggle="validator" role="form"  #account="ngForm">
              <!--<form *ngIf="active" (ngSubmit)="save($event)" #dashForm="ngForm">-->
              <div class="row" >
                <!--<div class="col-sm-5">-->
                <div class="col-sm-4">
                  <div class="table-responsive project-list label-center">
                    <table class="table table-striped border-r-l font_size" >
                      <tr>
                        <td style="width:17%;">
                          <label for="name">Account #:</label>
                        </td>
                        <td width="57%">
                            <input  [ngClass]="{'control-group error': errorModel.ac_number && errorModel.ac_number.length}" class="form-control " type="text" value="{{ model.ac_number}}" [(ngModel)]="model.ac_number" (ngModelChange)="clearField('site_info', 'ac_number');" name="ac_number" #ac_number="ngModel"/>
                            <div *ngIf="errorModel.ac_number&& errorModel.ac_number.length"  class="text-danger"> {{ errorModel.ac_number[0] }} </div>
                        </td>
                      </tr>
                                              <tr>
                        <td style="width:17%;">
                          <label for="name">PIN:</label>
                        </td>
                        <td width="57%">
                            <input  [ngClass]="{'control-group error': errorModel.ac_number && errorModel.ac_number.length}" class="form-control " type="text" value="{{ model.pin}}" [(ngModel)]="model.pin" name="PIN" #pin="ngModel"/>
                        </td>
                      </tr>
                      <tr>
                        <td width="15%" height="56px">
                          UUID:
                        </td>
                        <td width="85%" style="height:60px;">
                          <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                            <input  value="{{ model.uuid }}" class="form-control disabled-field-color" type="text" #inputTarget readonly (ngModelChange)="clearField('site_info','uuid');">
                            <!--<button class="btn btn-default" type="button" [ngIIclipboard]="inputTarget">copy</button>-->
                          </div>

                          <div class="col-xs-2 pull-right">
                              <div class="btn-group" style= "margin-top:10px;">
                                 <span class="glyphicon glyphicon-copy fa-lg btnhoverblue"  title="Copy" style="cursor: pointer;" aria-hidden="false" (click) = "addToClip(inputTarget)" ></span>
                              </div>
                          </div>
                          <div class="clear"></div>
                        </td>
                      </tr>

                      <tr>
                          <td style="height:56px;">
                              <label for="name">Company:</label>
                          </td>
                          <td width="85%" style="height:60px;">
                              <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                                  <input  [ngClass]="{'control-group error': errorModel.company_name && errorModel.company_name.length}" type="text"  class="form-control disabled-field-color" required [(ngModel)]="model.company_name" (ngModelChange)="clearField('site_info','company_name');" name="company_name" placeholder="Enter Company Name" #company_name="ngModel" readonly>
                                  <div *ngIf="errorModel.company_name && errorModel.company_name.length"  class="text-danger"> {{ errorModel.company_name[0] }} </div>

                              </div>
                          </td>
                      </tr>
                      <tr>
                          <td style="height:56px;">
                              <label>CRM Link:</label>
                          </td>
                          <td>
                              <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                                  <input [ngClass]="{'control-group error': errorModel.crm_link && errorModel.crm_link.length}" type="text"  class="form-control" required [(ngModel)]="model.crm_link" (ngModelChange)="clearField('site_info','crm_link');" name="crm_link" placeholder="Enter CRM Link" #crm_link="ngModel">
                                  <div *ngIf="errorModel.crm_link && errorModel.crm_link.length"  class="text-danger"> {{ errorModel.crm_link[0] }} </div>
                              </div>
                              <div class="col-xs-2 pull-right">
                                  <div class="btn-group" style= "margin-top:10px;">
                                      <a href ="{{ model.crm_link? model.crm_link:'#'}}" target="_blank"><span class="glyphicon glyphicon-globe fa-lg btnhoverblue"  title="CRM Link" style="cursor: pointer;color:#6a6c6f;" aria-hidden="false"></span></a>
                                  </div>
                              </div>
                          </td>
                      </tr>
                      <tr>
                        <td style="height:56px;">
                            <label for="name">Site Rank:</label>
                        </td>
                        <td width="85%" style="height:60px;">
                            <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                                <input  [ngClass]="{'control-group error': errorModel.site_rank && errorModel.site_rank.length}" type="text"  class="form-control disabled-field-color" required [(ngModel)]="model.site_rank" (ngModelChange)="clearField('site_info','site_rank');" name="site_rank" placeholder="Site Rank" #site_rank="ngModel" readonly>
                                <div *ngIf="errorModel.site_rank && errorModel.site_rank.length"  class="text-danger"> {{ errorModel.site_rank[0] }} </div>

                            </div>
                        </td>
                      </tr>
                      <tr>
                          <td style="height:56px;">
                              <label for="name">Program:</label>
                          </td>
                          <td width="85%" style="height:60px;">
                              <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                                  <input  [ngClass]="{'control-group error': errorModel.program && errorModel.program.length}" type="text"  class="form-control disabled-field-color" required [(ngModel)]="model.program" (ngModelChange)="clearField('site_info','program');" name="program" placeholder="Program" #company_name="ngModel" readonly>
                                  <div *ngIf="errorModel.program && errorModel.program.length"  class="text-danger"> {{ errorModel.program[0] }} </div>

                              </div>
                          </td>
                      </tr>
                      <tr>
                        <td style="height:56px;">
                            <label for="name">Platform:</label>
                        </td>
                        <td width="85%" style="height:60px;">
                            <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                                <input  [ngClass]="{'control-group error': errorModel.platform && errorModel.platform.length}" type="text"  class="form-control disabled-field-color" required [(ngModel)]="model.platform" (ngModelChange)="clearField('site_info','platform');" name="platform" placeholder="Platform" #platform="ngModel" readonly>
                                <div *ngIf="errorModel.platform && errorModel.platform.length"  class="text-danger"> {{ errorModel.platform[0] }} </div>

                            </div>
                        </td>
                      </tr>
                      <tr>
                          <td style="height:56px;">
                              <label for="name">Implementation Preference:</label>
                          </td>
                          <td width="85%" style="height:60px;">
                              <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                                  <input  [ngClass]="{'control-group error': errorModel.imp_preference && errorModel.imp_preference.length}" type="text"  class="form-control disabled-field-color" required [(ngModel)]="model.imp_preference" (ngModelChange)="clearField('site_info','imp_preference');" name="imp_preference" placeholder="Implementation Preference" #company_name="ngModel" readonly>
                                  <div *ngIf="errorModel.imp_preference && errorModel.imp_preference.length"  class="text-danger"> {{ errorModel.imp_preference[0] }} </div>

                              </div>
                          </td>
                      </tr>
                      <tr *ngIf="model.blogger_account_name">
                          <td style="height:56px;">
                              <label for="name">Blogger Account Name:</label>
                          </td>
                          <td width="85%" style="height:60px;">
                              <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                                  <input type="text"  class="form-control disabled-field-color" required [(ngModel)]="model.blogger_account_name" name="blogger_account_name" #blogger_account_name="ngModel" readonly>
                              </div>
                          </td>
                      </tr>
                    </table>
                    <!-- <toaster-container [toasterconfig]="toasterconfig"></toaster-container> -->
                  </div>
                </div>  <!-- END Col sm 4 2nd -->
                <div class="col-sm-4">
                  <div class="table-responsive project-list label-center" style = "overflow-x: hidden !important;">
                    <table class="table table-striped border-r-l"  [hidden]="hidden">
                      <tr>
                        <td style="height:54px; width:10%;" >
                          URL:
                        </td>
                        <td  width="85%" style="height:54px;">
                            <div class="col-xs-9 padleft0" style="padding-right:0px; width:79%;">
                                <input  [ngClass]="{'control-group error': errorModel.url && errorModel.url.length}" type="text"  class="form-control"  required id="name" [(ngModel)]="model.url" (ngModelChange)="clearField('site_info','url');" name="url" #url="ngModel" placeholder="Enter site URL">
                                <div *ngIf="errorModel.url && errorModel.url.length"  class="text-danger"> {{ errorModel.url[0] }} </div>
                            </div>
                            <div class="col-xs-2 pull-right">
                                <div class="btn-group " style= "margin-top:10px;">
                                    <a href ="{{ 'http://'+ model.url }}" target="_blank"><span class="glyphicon glyphicon-link fa-lg btnhoverblue" title="Clik to open" style="cursor: pointer; color:#6a6c6f;" aria-hidden="false"></span></a>
                                </div>
                            </div>

                        </td>
                      </tr>

                      <tr>
                          <td style="height:56px;">
                              AD Unit:
                          </td>
                          <td style="height:60px;">
                              <div class="col-xs-9 padleft0" style="padding-right:0px; width:79%;">
                                  <input [ngClass]="{'control-group error': errorModel.ad_unit && errorModel.ad_unit.length}"  type="text"  class="form-control" required
                                         [(ngModel)]="model.ad_unit"  (ngModelChange)="clearField('site_info','ad_unit');" name="ad_unit" placeholder="Ad Unit" #ad_unit="ngModel">
                                  <div *ngIf="errorModel.ad_unit && errorModel.ad_unit.length"  class="text-danger"> {{ errorModel.ad_unit[0] }} </div>
                              </div>
                          </td>
                      </tr>

                      <tr>
                        <td style="height:54px; width:10%;" >
                            Ticker:
                        </td>
                        <td  width="85%" style="height:60px;">
                            <div class="col-xs-9 padleft0" style="padding-right:0px; width:79%;">
                                <input  type="text"  class="form-control"  id="ticker" [(ngModel)]="model.ticker" name="ticker" #ticker="ngModel" placeholder="Publisher Ticker Symbol " (ngModelChange)="clearField('site_info','ticker');">
                            </div>
                        </td>
                      </tr>

                      <tr>
                        <td width="15%" height="54px">
                          Other Site URLs:
                        </td>
                        <td width="85%" style="height:54px;">
                            <div class="col-xs-9 padleft0" style="padding-right:0px; width:79%;">
                                <input placeholder="Add Alternative URL(s)" class="form-control" rows="3" id="comment"  [(ngModel)]="model.alternate_url" (ngModelChange)="clearField('site_info','alternate_urls');" name="alternate_urls" #alternate_url placeholder="Enter site URL" >
                                <!--<div *ngIf="errorModel.alternate_urls && errorModel.alternate_urls.length"  class="text-danger"> {{ errorModel.alternate_urls[0] }} </div>-->
                                <div class= "margin-url" >
                                    <div *ngFor="let url of model.alternate_urls; let i=index">
                                        <div *ngIf="url && url.length">
                                            <li>
                                            <span class ="custom-url">{{ url }}</span>
                                            <span (click)="deleteToUrl(i)" class="delete-icon"><span class="glyphicon glyphicon-remove margin-cross"  title="Remove URL" style = "color:#45a7c9;"></span></span>
                                            </li>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xs-2 pull-right add-button-style">
                                <div class="btn-group">
                                    <span class="glyphicon glyphicon-plus-sign fa-lg btnhoverblue"  title="Add Site" style="cursor: pointer;" aria-hidden="false" (click) = "addToUrl(alternate_url)" ></span>
                                    <ul>
                                        <!--<li><a>Copy</a></li>-->
                                    </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Tier:
                        </td>
                        <td width="85%" style="height:54px;">
                          <div class="col-xs-9 padleft0" style="padding-right:0px; width:79%;">
                            <select class="form-control" [(ngModel)]="model.tier" name="tier" #tier="ngModel" (ngModelChange)="clearField('site_info','tier');">
                              <option [value]="tier.id" *ngFor="let tier of tier_option.results">{{ tier.name }}</option>
                            </select>
                          </div>
                          <div style="padding-right: 0px;">
                            <div class="btn-group col-xs-2 pull-right">
                              <span class=''>
                                  <i class="fa fa-history tierhistory fa-lg btnhoverblue" title="Tier History" style="cursor: pointer;padding-left: 0px;margin-top: 3px" aria-hidden="false" (click)="hide_history=!hide_history"></i>
                              </span>
                            </div>
                          </div>
                        </td>

                        <tr [hidden]="!hide_history" *ngFor="let tier of tier_history">
                          <td colspan="2">
                            <table width="100%" class="table table-bordered table-hover dataTable no-footer table-striped table-fonts tier-set" style="margin-bottom:5px;">
                              <thead>
                                <tr role="row" style="font-weight: bold;">
                                  <th>Since </th>
                                  <th>Tier</th>
                                 </tr>
                              </thead>
                              <tbody>
                                <tr class="odd hand-point tier-content" role="row">
                                  <th>
                                      {{ tier.since }}
                                  </th>
                                  <th>
                                  {{ tier.tier.name }}
                                </th>
                                <tr>
                              </tbody>
                            </table>
                          </td>
                          <tr></tr>
                           <tr>
                              <td style="width:30%; height:54px;">
                                  Delivery Core Version:
                              </td>
                              <td>
                                  <select class="form-control" [(ngModel)]="model.delivery_selected_version" name="delivery_selected_version" (ngModelChange)="clearField('site_info','delivery_selected_version');">
                                      <option *ngFor="let elem of model.delivery_choices" [value]="elem.core_name">{{ elem.label }}</option>
                                  </select>
                              </td>
                          </tr>
                          <tr>
                            <td style="height:56px;">
                                Publica UUID:
                            </td>
                            <td>
                                <div class="col-xs-9 padleft0" style="padding-right:0px; width:80%;">
                                    <input type="text"  class="form-control" required [(ngModel)]="model.publica_site_uuid" (ngModelChange)="clearField('site_info','publica_site_uuid');" name="publica_site_uuid" placeholder="Enter Publica Site UUID" #publica_site_uuid="ngModel">
                                </div>
                            </td>
                          </tr>
                          <tr>
                              <td style="height:54px;">
                                  Lotame #:
                              </td>
                              <td style="height:54px;">
                                  <div class="row">
                                      <div class="col-md-6">
                                          <input class="form-control"  required id="lotame_ac_id" [(ngModel)]="model.lotame_ac_id"  name="lotame_ac_id" #lotame_ac_id="ngModel" placeholder="Lotame ID" (ngModelChange)="clearField('site_info', 'lotame_ac_id');">

                                      </div>
                                      <div class="col-md-6" style="padding-top:5px;">
                                          <input id="enable_lotame" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.enable_lotame" name="enable_lotame" (ngModelChange)="clearField('site_info', 'enable_lotame');" #enable_lotame="ngModel"/><label class="cursor-pointer label-click-dfp " for="enable_lotame">Enable Lotame</label>
                                      </div>
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td style="width:30%; height:54px;">
                                  Confiant Sandbox:
                              </td>
                              <td>
                                  <select class="form-control" [(ngModel)]="model.sandbox" name="sandbox" (ngModelChange)="clearField('site_info','sandbox');">
                                      <option [value]=0>Default</option>
                                      <option [value]=1>All</option>
                                      <option [value]=2>Desktop</option>
                                      <option [value]=3>Mobile</option>
                                      <option [value]=4>IOS</option>
                                      <option [value]=5>Android</option>
                                  </select>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <input id="require_interaction" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.require_interaction" name="require_interaction" (ngModelChange)="clearField('site_info', 'require_interaction');" #require_interaction="ngModel"/><label class="cursor-pointer label-click-dfp " for="require_interaction">Require Interaction</label>
                              </td>
                              <td></td>
                          </tr>
                    </table>
                  </div>
                </div> <!-- END Col sm 4 2nd-->

                <!-- Starting col sm 4 3rd-->
                <div class="col-sm-4 glyphicon glyphicon icon-history">
                  <div class=" project-list label-center">
                      <table class="table table-striped border-r-l" [hidden]="hidden">
                          <tr>
                              <td style="width:10%; height:54px;">
                                  Status:
                              </td>
                              <td>
                                  <select class="form-control" [(ngModel)]="model.status" name="status" (ngModelChange)="clearField('site_info','status');">
                                      <option [value]="'active'">Active</option>
                                      <option [value]="'inactive'">Inactive</option>
                                  </select>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:54px;">
                                  Primary #(DFP):
                              </td>
                              <td style="height:54px;">
                                  <div class="row">
                                      <div class="col-md-7">
                                          <input  [ngClass]="{'control-group error': errorModel.dfp_account && errorModel.dfp_account.length}" type="text"  class="form-control"  required id="name" [(ngModel)]="model.dfp_account"  (ngModelChange)="clearField('site_info','dfp_account');" name="dfp_account" #dfp_account="ngModel" placeholder="Enter dfp_account">
                                          <div *ngIf="errorModel.dfp_account && errorModel.dfp_account.length"  class="text-danger"> {{ errorModel.dfp_account[0] }} </div>
                                      </div>
                                      <div class="col-md-5" style="padding-top:5px;">
                                          <input id="dfp_request" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.dfp_single_request" (ngModelChange)="clearField('site_info','dfp_single_request');" name="dfp_single_request" #dfp_single_request="ngModel"/><label class="cursor-pointer label-click-dfp " for="dfp_request">Single Request</label>
                                      </div>
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td colspan=2>
                                  <label class="cursor-pointer vertical-align-middle site-info-font" for="delivery_s2s_enabled">Enable S2S Bidding:</label>
                                  <input id="delivery_s2s_enabled" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.delivery_s2s_enabled" (ngModelChange)="clearField('site_info','delivery_s2s_enabled');" name="delivery_s2s_enabled" #delivery_s2s_enabled="ngModel"/>

                                  <label class="cursor-pointer vertical-align-middle site-info-font" style="margin-left:35px;" for="enable_safeframe">Enable SafeFrame:</label>
                                  <input id="enable_safeframe" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.enable_safeframe" (ngModelChange)="clearField('site_info','enable_safeframe');" name="enable_safeframe" #enable_safeframe="ngModel"/>

                                  <label class="cursor-pointer vertical-align-middle site-info-font" style="margin-left:35px;" for="bid_cache">Bid Cache:</label>
                                  <input id="bid_cache" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.use_bid_cache" (ngModelChange)="clearField('site_info','use_bid_cache');" name="bid_cache"/>
                              </td>
                          </tr>

                          <tr>
                              <td style="height:37px;">
                                  Updated:
                              </td>
                              <td>
                                  {{model.updated_on? (model.updated_on| stringToDate | date:'medium'):'NA' }}
                              </td>
                          </tr>
                          <tr>
                              <td style= "line-height: 18px;">
                                  Script:
                              </td>
                              <td>
                                  <div class="row col-sm-12">
                                      <div class="col-sm-3">
                                          <div [ngClass]="{'jump_view': script_view_jump_icon}">
                                              <i class="glyphicon glyphicon-eye-open fa-lg btnhoverblue" title="View" style="cursor: pointer;" aria-hidden="false"  (click)="hide_view=!hide_view" (click) ="accountScriptView($event)" ></i>
                                          </div>
                                      </div>
                                      <div class="col-sm-3">
                                          <div [ngClass]="{'stop': !is_sync_loader}" class="angrytext">
                                              <i class="glyphicon glyphicon-refresh fa-lg btnhoverblue" title="Sync" style="cursor: pointer;" aria-hidden="false" (click) ="syncAccountDetail($event)" ></i>
                                          </div>
                                      </div>
                                      <div class="col-sm-3">
                                          <i class="fa vertical-align-middle fa-download cursor-pointer btnhoverblue" title="Download Tags" style="font-size:20px; float:left;" href="javascript:void(0)" (click)="downloadTags()"></i>
                                      </div>
                                  </div>
                              </td>

                          </tr>
                          <tr>
                              <td class="async-font async-width" colspan=2 style= "height: 30px;" for="load_async">
                                  <label class="cursor-pointer vertical-align-middle" for="load_async">Enable Async:</label>
                                  <input id="load_async" class="i-checks cursor-pointer" checked="" type="checkbox" [(ngModel)]="model.load_async" (ngModelChange)="clearField('site_info','enable_async');" name="load_async" #load_async="ngModel"/>
                                  <label class="cursor-pointer vertical-align-middle" style=" margin-left:35px;" for="monitor_redirect">Monitor Redirect:</label>
                                  <input id="monitor_redirect" class="i-checks cursor-pointer" checked="" type="checkbox" [(ngModel)]="model.monitor_redirect" (ngModelChange)="clearField('site_info','monitor_redirect');" name="monitor_redirect" #monitor_redirect="ngModel"/>
                              </td>
                          </tr>
                          <tr>
                              <td class="async-font cursor-pointer async-width" colspan=2 style= "height: 30px;" for="enable_error_logging">
                                  <label class="cursor-pointer vertical-align-middle" for="enable_error_logging">Enable Error Logging:</label>
                                  <input id="enable_error_logging" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.enable_error_logging" (ngModelChange)="clearField('site_info','enable_error_logging');" name="enable_error_logging" #enable_error_logging="ngModel"/>

                                  <label class="cursor-pointer vertical-align-middle" style=" margin-left:40px;" for="enable_analytics">Enable Analytics:</label>
                                  <input id="enable_analytics" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.enable_analytics" (ngModelChange)="clearField('site_info','enable_analytics');" name="enable_analytics" #enable_analytics="ngModel"/>
                              </td>
                          </tr>
                          <tr>
                              <td class="async-font cursor-pointer async-width" colspan=2 style= "height: 50px;" for="enable_error_logging">
                                  <label style="font-weight:normal" for="uam_approved">UAM Approved:</label>
                                  <input id="uam_approved" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.uam_approved" (ngModelChange)="clearField('site_info','uam_approved');" name="uam_approved" #uam_approved="ngModel"/>
                                  <label class="vertical-align-middle site-info-font" style="margin-left:30px;margin-bottom:0px;" for="uam_public_id">UAM Pub Id:</label>
                                  <input type="text"  class="form-control" [disabled]="!model.uam_approved" style="display:inline;width:35%;" id="uam_public_id" [(ngModel)]="model.uam_public_id" name="uam_pub_id" #uam_public_id="ngModel" placeholder="UAM Pub Id">
                              </td>
                          </tr>
                          <tr>
                            <td style="width:30%; height:54px;">
                                Floor Type:
                            </td>
                            <td>
                                <select class="form-control" [(ngModel)]="model.floor_type" name="floor_type" (ngModelChange)="clearField('site_info','floor_type');">
                                    <option [value]="'target'">Target CPM</option>
                                    <option [value]="'hard'">Hard Floor</option>
                                </select>
                            </td>
                        </tr>
                          <tr>
                              <td colspan=2>
                                  <label style="font-weight:normal">WP Plugin Version:</label>
                                  {{ model.wp_plugin_version? model.wp_plugin_version:'NA'}}
                                  <label style="font-weight:normal; margin-left:25px;">WP Plugin Reported on:</label>
                                  {{model.wp_plugin_reported_on? (model.wp_plugin_reported_on| stringToDate | date:'medium'):'NA' }}
                              </td>
                          </tr>
                      </table>
                  </div>
                </div>

                <!-- end of col sm 4 3rd-->
              </div>
              <!--</form>-->

              <div class="form-group col-md-12" [hidden]="!hide_view" style="width: 100%; padding-left: 0px; padding-right: 0px;">
                  <div *ngIf="script_data">
                      <input type="text" class="form-control" style="display:inline; width:96%;" value='<script type="text/javascript" {{ model.load_async ? "async" : ""}} src="{{script_data}}" data-cfasync="false"></script>' #inputTarget>
                      <span class="glyphicon glyphicon-copy fa-lg btnhoverblue"  title="Copy" style="cursor: pointer; margin-left:1%; font-size:20px;" aria-hidden="false" (click) = "addToClip(inputTarget)"></span>
                  </div>
              </div>


              <div class="form-group">
                  <button  [class.btn-visibility]="hasUnsavedValues.accountDetail" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click) = "saveAccountDetail($event)"><div *ngIf="!btn_loader">Save</div><div *ngIf="btn_loader" class="loader save-button-style" ></div></button>
              </div>
            </form>
          </div>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Account Notes
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse= "isCollapsed.accountNotes" class="panel-body">
          <div [hidden]="submitted">
              <form *ngIf="active" data-toggle="validator" role="form" >
                  <div class="hpanel">
                      <div class="row margin-left-right-0">

                          <div style="position:relative; margin: 10px 0px;">
                              <textarea id='account_notes' name="Account Notes" class="width100 form-control" form="usrform" [(ngModel)]="model.account_notes" (ngModelChange) = "clearField('account_notes','account_notes');hasUnsavedValues.accountNotes=true"></textarea>
                              <span style="position:absolute; right:1px; top:1px; background:#f1f3f6; border-bottom-left-radius: 5px;  border-top-right-radius: 4px; color:#6A6C6F; ">
                                  &nbsp; Notes &nbsp;
                              </span>
                          </div>
                          <div class="form-group">
                              <button [class.btn-visibility]="hasUnsavedValues.accountNotes" id='accountNotesSave' type="button" class="btn  w-xs btn-sm btn-success pull-right btn-color media-prop" (click) = "saveAccountNotesDetail($event)">Save </button>
                          </div>
                      </div>
                  </div>
              </form>
          </div>
      </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Code Analysis
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse= "isCollapsedSiteAnalysis" class="panel-body">
          <div [hidden]="submitted">
            <form *ngIf="active" data-toggle="validator" role="form" >
              <!--<form *ngIf="active" (ngSubmit)="save($event)" #dashForm="ngForm">-->
              <div class="row" >
                <!--<div class="col-sm-5">-->
                <div class="col-sm-6">
                  <div class="project-list">
                    <table class="table table-striped border-r-l font_size ">

                        <tr>
                          <th colspan="4" align="center" class = "cell-color">
                            <label for="name"><b>Desktop</b></label>
                          </th>
                        </tr>
                      <tbody class="border-top-1px">
                        <tr>
                          <td>
                            <label for="name">Updated:</label>
                          </td>
                          <td>
                              {{ analysis_data['desktop']? (analysis_data['desktop'].shot_on| stringToDate | date:'medium' ):'NA' }}
                          </td>
                          <td>
                            <label for="name">Code Snapshots:</label>
                          </td>
                          <td>{{analysis_data['desktop'] && analysis_data['desktop']['file'].length>0?'':'N/A'}}
                              <a *ngIf="analysis_data['desktop'] && analysis_data['desktop']['file']" href="//{{codeshot_url + analysis_data['desktop']['file']}}" target='_blank' download>{{ analysis_data['desktop'] && analysis_data['desktop']['file']?'':'N/A' }}<i *ngIf="analysis_data['desktop'] && analysis_data['desktop']['file']" class="fa fa-download cursor-pointer btnblack btnhoverblue download-size" title="Download codeshot"></i></a>
                          </td>

                        </tr>
                        <tr>
                          <td>
                            <label for="name">Script on Site:</label>
                          </td>
                          <td *ngIf="analysis_data['desktop'] && analysis_data['desktop'].found_platform_script" >
                            <span class="glyphicon glyphicon-ok icon-success" ></span>
                          </td>
                          <td *ngIf="!analysis_data['desktop'] || !analysis_data['desktop'].found_platform_script">
                            <span class="glyphicon glyphicon-remove icon-failure" ></span>
                          </td>
                          <td >
                            <label for="name">Delivery Script:</label>
                          </td>
                          <td *ngIf="analysis_data['desktop'] && analysis_data['desktop'].found_delivery_url" >
                            <span class="glyphicon glyphicon-ok icon-success" ></span>
                          </td>
                          <td *ngIf="!analysis_data['desktop'] || !analysis_data['desktop'].found_delivery_url">
                            <span class="glyphicon glyphicon-remove icon-failure" ></span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="name">GA/Other Ad Code:</label>
                          </td>
                          <td colspan="3">
                              <!--<textarea rows=5 readonly=true></textarea>-->

                              <div class="row" *ngIf="analysis_data['desktop'] && analysis_data['desktop'].alternative_scripts" class="comma-wrapper">
                                  <span *ngFor="let key of analysis_data['desktop'].alternative_scripts | keyvalue">
                                      <div>
                                          {{ key.key }}
                                      <!--    <span *ngIf="key.value=='null'">: {{ 'NA' }}</span>-->
                                          <span *ngIf="key.value">: {{ key.value=='null'?'NA':key.value }}</span>
                                          <!--<span class="comma">, </span>-->
                                      </div>
                                  </span>

                              </div>

                              <div class="row" *ngIf="!analysis_data['desktop'] || !analysis_data['desktop'].alternative_scripts">
                                  <div class="col-md-6" >
                                      NA
                                  </div>

                              </div>

                          </td>

                        </tr>
                      </tbody>
                    </table>
                    <!--<toaster-container [toasterconfig]="toasterconfig"></toaster-container>-->
                  </div>
                </div>  <!-- END Col sm 4 2nd -->


                <!--<div class="col-sm-5">-->
                <div class="col-sm-6">
                  <div class="project-list">
                    <table class="table table-striped border-r-l font_size">
                        <tr>
                          <th colspan="4" align="center" class= "cell-color">
                            <label for="name"><b>Mobile</b></label>
                          </th>
                        </tr>
                      <tbody class="border-top-1px">
                        <tr>
                          <td>
                            <label for="name">Updated:</label>
                          </td>
                          <td>
                              {{ analysis_data['mobile']? (analysis_data['mobile'].shot_on | stringToDate | date:'medium' ):'NA' }}
                          </td>
                          <td>
                            <label for="name">Code Snapshots:</label>
                          </td>
                          <td>{{analysis_data['mobile'] && analysis_data['mobile']['file'].length>0?'':'N/A'}}
                              <a *ngIf="analysis_data['mobile'] && analysis_data['mobile']['file']"  href="//{{codeshot_url + analysis_data['mobile']['file']}}" target='_blank' download>{{ analysis_data['mobile'] && analysis_data['mobile']['file']?'':'N/A' }}<i *ngIf="analysis_data['mobile'] && analysis_data['mobile']['file']" class="fa fa-download cursor-pointer btnblack btnhoverblue download-size" title="Download codeshot"></i></a>

                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="name">Script on Site:</label>
                          </td>
                          <td *ngIf="analysis_data['mobile'] && analysis_data['mobile'].found_platform_script" >
                            <span class="glyphicon glyphicon-ok icon-success" ></span>
                          </td>
                          <td *ngIf="!analysis_data['mobile'] || !analysis_data['mobile'].found_platform_script">
                            <span class="glyphicon glyphicon-remove icon-failure"></span>
                          </td>

                          <td >
                            <label for="name">Delivery Script:</label>
                          </td>
                          <td *ngIf="analysis_data['mobile'] && analysis_data['mobile'].found_delivery_url" >
                            <span class="glyphicon glyphicon-ok icon-success" ></span>
                          </td>
                          <td *ngIf="!analysis_data['mobile'] || !analysis_data['mobile'].found_delivery_url">
                            <span class="glyphicon glyphicon-remove icon-failure" ></span>
                          </td>

                        </tr>
                        <tr>
                          <td>
                            <label for="name">GA/Other Ad Code:</label>
                          </td>
                          <td colspan="3">
                            <!--<textarea rows=5 readonly=true></textarea>-->
                            <div class="row" *ngIf="analysis_data['mobile'] && analysis_data['mobile'].alternative_scripts" class="comma-wrapper">
                                    <span *ngFor="let key of analysis_data['mobile'].alternative_scripts | keyvalue">
                                        <div>
                                            {{ key.key }}
                                            <span *ngIf="key.value">: {{ key.value=='null'?'NA':(key.value) }}</span>
                                            <!--<span class="comma">, </span>-->
                                        </div>
                                    </span>
                            </div>
                            <div class="row" *ngIf="!analysis_data['mobile'] || !analysis_data['mobile'].alternative_scripts">
                              <div class="col-md-6" >
                                NA
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                   <!-- <toaster-container [toasterconfig]="toasterconfig"></toaster-container>-->
                  </div>
                </div>  <!-- END Col sm 4 2nd -->
              </div>
              <!--</form>-->
            </form>
            <div class="row  animate">
                <div class="col-lg-12">
                    <div class="hpanel">
                        <div class="panel-heading hbuilt cursor-pointer border-history" (click)="isCollapsed.codeshotHistory = !isCollapsed.codeshotHistory;isCollapsed.codeshotHistory==false?getCodeshotHistory(page):'';">
                            <div class="panel-tools">
                                <a class="showhide" > <i class="fa fa-history tierhistory fa-lg btnhoverblue rotating-icon line-height-history" title="History" aria-hidden="false"></i></a>
                            </div>
                            Codeshot History
                        </div>
                        <div class="panel-body" appCollapse="isCollapsed.codeshotHistory">
                            <div class="row">
                                <div *ngIf="showTableLoader" style="width:100%; height:100%; margin-top:60px; margin-bottom:60px;">
                                    <div class="loader center"></div>
                                </div>
                                <div *ngIf="!showTableLoader">
                                    <div class="col-sm-12 headers-set">
                                        <div class="text-align-center" [hidden]="data_codeshot.results.length>0">
                                            <span class="font-bold" >No history record found.</span>
                                        </div>
                                        <table [hidden]="data_codeshot.results.length==0" role="grid" class="table  table-bordered table-hover dataTable no-footer table-striped table-fonts " >
                                            <thead>
                                                <tr role="row">
                                                    <th>Device Type</th>
                                                    <th>Updated</th>
                                                    <th class="codeshot-snap-width">Code snapshot</th>
                                            </thead>
                                            <tbody style="text-align: left !important;">
                                                <tr class="odd hand-point" role="row" *ngFor="let history of data_codeshot.results | paginate: codeConfig">
                                                    <td>{{ history.device_type }}</td>
                                                    <td>{{ history.shot_on ?(history.shot_on | stringToDate | date:'medium'):'N/A' }}</td>
                                                    <td class="codeshot-align">{{ history.file && history.file.length>0?'':'N/A'}}
                                                        <a *ngIf="history.file" href="//{{codeshot_url + history.file}}" target='_blank' download><i *ngIf="history.file" class="fa fa-download cursor-pointer btnblack btnhoverblue download-size" title="Download codeshot"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-sm-7 center-pagination" [hidden]="data_codeshot.results.length==0">
                                        <div class="dataTables_paginate paging_simple_numbers">
                                            <!-- <pagination-controls [id]="codeConfig.id"
                                                                 [maxSize]="maxSize"
                                                                 [directionLinks]="directionLinks"
                                                                 [autoHide]="autoHide"
                                                                 (pageChange)="onCodeshotChange($event)">
                                            </pagination-controls> -->
                                            <mat-paginator
                                              [id]="codeConfig.id"
                                              [length]="maxSize"
                                              [pageSize]="codeConfig.itemsPerPage"
                                              (page)="pageEvent = onCodeshotChange($event)">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Exclusion URLs
          </mat-panel-title>
        </mat-expansion-panel-header>

          <div appCollapse = "!isCollapsedExclusionURL" class="panel-body">
            <table class="table table-striped border-r-l align-middle">
                <tr>
                    <th>Exclusion URL</th>
                    <th></th>
                    <th>Expiry Date</th>
                    <th style="width:4%;">Action</th>
                </tr>
                <tr *ngIf="!isExclusionURLSelected">
                    <td colspan='4' class="emptyExclusion">NO EXCLUSION FOUND</td>
                </tr>
                <tr *ngFor="let value of url_exclusion_selector_value | paginate: exclusionConfig; let indx=index">
                    <td>
                        <label style="font-size:14px;">://{{model.url}}/</label>
                    </td>
                    <td style="width:73%">
                        <input type="text" class="form-control"  [(ngModel)]="value.path" placeholder="Path" [ngClass]="{'control-group error': errorModel['exclusionurl'] && errorModel['exclusionurl'][((exclusionConfig.currentPage-1)*exclusionConfig.itemsPerPage) + indx] && errorModel['exclusionurl'][((exclusionConfig.currentPage-1)*exclusionConfig.itemsPerPage) + indx].path}" (ngModelChange)="clearErrorForExclusionURL('exclusionurl', 'path', indx)">
                        <div *ngIf="errorModel['exclusionurl'] && errorModel['exclusionurl'][((exclusionConfig.currentPage-1)*exclusionConfig.itemsPerPage) + indx] && errorModel['exclusionurl'][((exclusionConfig.currentPage-1)*exclusionConfig.itemsPerPage) + indx].path"  class="text-danger">  {{ errorModel['exclusionurl'][value.path] }}</div>
                    </td>
                    <td>
                        <input type="date" class="form-control" [(ngModel)]="value.till_date" [ngClass]="{'control-group error': errorModel['exclusionurl'] && errorModel['exclusionurl'][((exclusionConfig.currentPage-1)*exclusionConfig.itemsPerPage) + indx] && errorModel['exclusionurl'][((exclusionConfig.currentPage-1)*exclusionConfig.itemsPerPage) + indx].till_date}" (ngModelChange)="clearErrorForExclusionURL('exclusionurl', 'till_date', indx)">
                        <div *ngIf="errorModel['exclusionurl'] && errorModel['exclusionurl'][((exclusionConfig.currentPage-1)*exclusionConfig.itemsPerPage) + indx] && errorModel['exclusionurl'][((exclusionConfig.currentPage-1)*exclusionConfig.itemsPerPage) + indx].till_date"  class="text-danger"> {{ errorModel['exclusionurl'][value.till_date] }} </div>
                    </td>
                    <td style="text-align:center;">
                        <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteExclusionURL(indx)"></span></a>
                    </td>
                </tr>
                <tr>
                    <td colspan='3'> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="addExclusionURL()">Add New</button></td>
                    <td> <button  [class.btn-visibility]="hasUnsavedValues['url']" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveExclusionURL(null)">Save</button></td>
                </tr>

            </table>
            <div class="col-sm-7 center-pagination">
              <div class="dataTables_paginate paging_simple_numbers">
                  <!-- <pagination-controls [id]="exclusionConfig.id" [directionLinks]="directionLinks"
                      (pageChange)="onExclusionURLChange($event)">
                  </pagination-controls> -->
                  <mat-paginator
                    [id]="exclusionConfig.id"
                    [length]="maxSize"
                    [pageSize]="exclusionConfig.itemsPerPage"
                    (page)="pageEvent = onExclusionURLChange($event)">
                  </mat-paginator>
              </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Ads.txt Additions
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse = "!isCollapsedAdstxtAddition" class="panel-body">
          <fieldset (change)="hasUnsavedValues.adstxtAddition=true;">
              Ads.txt Addition Providers:
              <div class="row container" style="margin-right: 0px; margin-left: 0px; margin-top: 5px; margin-bottom: 20px; width: 100%; border: 1px solid #e7eaec;">
                  <div class="m-t-12">
                      <div *ngFor="let provider of adstxt_additions" style="display:inline-block; " class="col-md-2 col-sm-3 col-xs-6">
                          <div style="padding:4px;">
                              <label class="hand-point">
                                  <input type="checkbox" [(ngModel)]="provider.is_enable" (change)="provider.is_enable = !provider.is_enable "/>
                                  <span style="margin-left:5px; font-weight:100;">{{ provider.provider }}</span>
                              </label>
                          </div>
                      </div>
                  </div>
                  <br><br>
                  <button [class.btn-visibility]="hasUnsavedValues.adstxtAddition" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveAdstxtAdditions(adstxt_additions)" style="margin-bottom:15px;">Save</button>
              </div>
          </fieldset>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Custom Settings
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse= "isCollapsed.customcode" class="panel-body" id='cs_panel_body'>
          <div [hidden]="submitted">
              <div *ngIf="active" data-toggle="validator">
                  <div class="hpanel">
                      <div class="row margin-left-right-0">
                        <!-- CSS Styles -->
                        <h5 class="custom-settings-title">CSS Styles</h5>
                        <div class="editor-container">
                          <ace-editor
                            #editor
                            id="editor"
                            [mode]="'css'"
                            [theme]="'dracula'"
                            [options]="options"
                            [(text)]="model.css_style"
                            (textChanged)="clearField('custom_scripts','css_style');hasUnsavedValues.customScript=true"
                          ></ace-editor>
                        </div>
                        <!-- Prefix JS -->
                        <h5 class="custom-settings-title">PreFix JS</h5>
                        <div class="editor-container">
                          <ace-editor
                            #editor
                            id="editor"
                            [theme]="'dracula'"
                            [options]="options"
                            [mode]="'javascript'"
                            [(text)]="model.js_prefix"
                            (textChanged)="clearField('custom_scripts','js_prefix');hasUnsavedValues.customScript=true"
                          ></ace-editor>
                        </div>
                        <!-- Postfix JS -->
                        <h5 class="custom-settings-title">PostFix JS</h5>
                        <div class="editor-container">
                          <ace-editor
                            #editor
                            id="editor"
                            [mode]="'javascript'"
                            [theme]="'dracula'"
                            [options]="options"
                            [(text)]="model.js_postfix"
                            (textChanged)="clearField('custom_scripts','js_postfix');hasUnsavedValues.customScript=true"
                          ></ace-editor>
                        </div>

                        <div class="form-group">
                            <button [class.btn-visibility]="hasUnsavedValues.customScript" type="button" class="btn  w-xs btn-sm btn-success pull-right btn-color media-prop" (click) = "saveAccountCustomAnalysisDetail($event)">Save </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Services
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse="isCollapsedServices" class="panel-body ">
          <div class="row form-group col-md-12 margin-bottom-zero">
            <div class="col-md-2 col-sm-6 col-xs-6" *ngFor="let option of service" style="width: 14%;">
              <label class="weight-style cursor-pointer margin-bottom-zero">
                  <span>
                      <input type="checkbox" name="service" value="{{option.product}}" [checked]="showservice[option.product]" (change)="productCheckedOptions(option, $event)"/>
                  </span><span style="vertical-align: text-bottom;">
                      {{option.product_label}}
                  </span>
              </label>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            In-Image Ads
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse= "isCollapsed.inimage" class="panel-body">
          <div class="row">
            <div class="col-sm-12">
              <fieldset (change)="hasUnsavedValues.inImageExclusion = true;">
                <table class="table table-striped border-r-l align-middle" style="margin-bottom: 10px;">
                  <tr>
                    <th>Exclusion Selector</th>
                    <th>Expiry Date</th>
                    <th style="width:4%;">Action</th>
                  </tr>
                  <tr *ngIf="!hasUnsavedValues.isInimageExclusionSelected">
                    <td colspan='3' class="emptyExclusion">NO EXCLUSION FOUND</td>
                  </tr>

                  <tr *ngFor="let value of inimage_exclusion_selector_value; let i=index">
                      <td style="width:70%;">
                          <input  [ngClass]="{'control-group error': errorModel['inimage'] && errorModel['inimage'].configuration && errorModel['inimage'].configuration.exclusions[i] && errorModel['inimage'].configuration.exclusions[i].selector }" (ngModelChange)="clearErrorForExclusion('inimage', 'selector', i);hasUnsavedValues.inImageExclusion=true" type="text" class="form-control" name="selector" [(ngModel)]="value['selector']" placeholder="selector">
                          <div *ngIf="errorModel['inimage'] && errorModel['inimage'].configuration && errorModel['inimage'].configuration && errorModel['inimage'].configuration.exclusions[i] && errorModel['inimage'].configuration.exclusions[i].selector"  class="text-danger"> {{ errorModel['inimage'].configuration.exclusions[i].selector }} </div>
                      </td>
                      <td>
                          <input  [ngClass]="{'control-group error': errorModel['inimage'] && errorModel['inimage'].configuration && errorModel['inimage'].configuration.exclusions[i] && errorModel['inimage'].configuration.exclusions[i].expiry_date }"  (ngModelChange)="clearErrorForExclusion('inimage', 'expiry_date', i);hasUnsavedValues.inImageExclusion=true" type="date" class="form-control" name="expiry_date" [(ngModel)]="value['expiry_date']" placeholder="expiry_date">
                          <div *ngIf="errorModel['inimage'] && errorModel['inimage'].configuration && errorModel['inimage'].configuration && errorModel['inimage'].configuration.exclusions[i] && errorModel['inimage'].configuration.exclusions[i].expiry_date"  class="text-danger"> {{ errorModel['inimage'] && errorModel['inimage'].configuration.exclusions[i].expiry_date}} </div>
                      </td>
                      <td style="text-align:center;">
                          <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteInimageExclusionSelector(i)"></span></a>
                      </td>

                  </tr>

                  <tr>
                      <!--<td colspan=3><a href="" (click)="inimageExclusionSelector()">Add New</a>-->
                      <td colspan=3> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="inimageExclusionSelector()">Add New</button>
                      </td>
                  </tr>
                </table>
              </fieldset>

              <div>
                <button [class.btn-visibility]="hasUnsavedValues.inImageExclusion" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" style="margin-bottom: 10px;" (click)="saveInimageExclusionData('')">Save</button>
              </div>
              <div class="project-list">
                <app-provider-config [configuration]="products['inimage-ads']" [product]="'inimage-ads'" [account]='accountUUID' [stickyEnable]="stickyEnable"></app-provider-config>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="showservice.demandfusion">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Demand Fusion
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse= "isCollapsed.demandfusion" class="panel-body">
          <div class=" project-list">
              <fieldset (change)="hasUnsavedValues.demandFusion=true;">
                  <table class="table table-striped border-r-l align-middle" >
                      <tr *ngIf="products && products.demandfusion">
                          <td class="width18percent">
                              Timeout (in miliseconds):
                          </td>
                          <td>
                            <label class="cursor-pointer"> <input class="i-checks position-checkbox-center" type="checkbox" [(ngModel)]="demandfusion_model.configuration.is_timeout_common"  name="is_timeout_common" #is_timeout_common="ngModel" /> Common Timeout </label>
                          </td>
                          <td [ngClass]="{'devicePaddingZero': demandfusion_model.configuration.is_timeout_common}" class="text-align-center vertical-align-middle-imp">
                            <span *ngFor="let platform of platforms">
                                <div *ngIf="!demandfusion_model.configuration.is_timeout_common && demandfusion_model.configuration['timeout_'+ platform].length > 1" ></div>
                                <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected" #devices></span>
                                <div *ngIf="!demandfusion_model.configuration.is_timeout_common && demandfusion_model.configuration['timeout_'+ platform].length > 1" ></div>
                                <hr *ngIf="platform != 'mobile' && !demandfusion_model.configuration['is_timeout_common']" style="margin-top: 0px; margin-bottom: 0px;">
                            </span>
                        </td>
                          <td class="timeout-config">
                            <div *ngIf="demandfusion_model.configuration.is_timeout_common">
                                <td>
                                    <input class="form-control " [ngClass]="{'control-group error': errorModel['demandfusion'] && errorModel['demandfusion'].configuration && errorModel['demandfusion'].configuration.timeout_common}"  type="text" [(ngModel)]="demandfusion_model.configuration.timeout_common" name="timeout_common" #timeout="ngModel"  (ngModelChange)="clearError('demandfusion', 'timeout_common');hasUnsavedValues.demandFusion=true;">
                                </td>
                            </div>
                            <div *ngIf="!demandfusion_model.configuration.is_timeout_common">
                                <td>
                                    <tr>
                                        <input style="margin:14px 0 14px" class="form-control" [ngClass]="{'control-group error': errorModel['demandfusion'] && errorModel['demandfusion'].configuration && errorModel['demandfusion'].configuration.timeout_desktop}"  type="text" [(ngModel)]="demandfusion_model.configuration.timeout_desktop" name="timeout_desktop" #timeout="ngModel"  (ngModelChange)="clearError('demandfusion', 'timeout_desktop');hasUnsavedValues.demandFusion=true;">
                                    </tr>
                                    <tr>
                                        <input style="margin:14px 0 14px" class="form-control" [ngClass]="{'control-group error': errorModel['demandfusion'] && errorModel['demandfusion'].configuration && errorModel['demandfusion'].configuration.timeout_tablet}"  type="text" [(ngModel)]="demandfusion_model.configuration.timeout_tablet" name="timeout_tablet" #timeout="ngModel"  (ngModelChange)="clearError('demandfusion', 'timeout_tablet');hasUnsavedValues.demandFusion=true;">
                                    </tr>
                                    <tr>
                                        <input style="margin:14px 0 14px" class="form-control" [ngClass]="{'control-group error': errorModel['demandfusion'] && errorModel['demandfusion'].configuration && errorModel['demandfusion'].configuration.timeout_mobile}"  type="text" [(ngModel)]="demandfusion_model.configuration.timeout_mobile" name="timeout_mobile" #timeout="ngModel"  (ngModelChange)="clearError('demandfusion', 'timeout_mobile');hasUnsavedValues.demandFusion=true;">
                                        <div *ngIf="errorModel['demandfusion'] && errorModel['demandfusion'].configuration && errorModel['demandfusion'].configuration.timeout"  class="text-danger"> {{ errorModel['demandfusion'].configuration.timeout }} </div>
                                    </tr>
                                </td>
                            </div>
                            </td>
                          <td>
                              <label class="cursor-pointer"> <input class="i-checks position-checkbox-center" type="checkbox" [(ngModel)]="demandfusion_model.configuration.console_log"  name="console_log" #console_log="ngModel" /> Enable Log </label>

                          </td>
                          <!-- <td>
                               <label class="cursor-pointer"> <input class="i-checks position-checkbox-center" type="checkbox" [(ngModel)]="demandfusion_model.configuration.mediadotnet"  name="mediadotnet" #mediadotnet="ngModel" /> Media.Net </label>

                               </td> -->
                          <td>
                              <div class="form-group video-align" >
                                  <button [class.btn-visibility]="hasUnsavedValues.demandFusion"  type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveDemandFusionConfigData('demandfusion')">Save</button>
                              </div>
                          </td>
                      </tr>
                  </table>
              </fieldset>
              <fieldset (change)="hasUnsavedValues.otb=true;">
                  Exclude Providers From Refresh:
                  <div class="row container" style="margin-right: 0px; margin-left: 0px; margin-top: 5px; margin-bottom: 20px; width: 100%; border: 1px solid #e7eaec;">
                      <div class="m-t-12">
                          <div *ngFor="let provider of otbList.sortedOrder" style="display:inline-block; " class="col-md-2 col-sm-3 col-xs-6">
                              <div style="padding:4px;">
                                  <label class="hand-point">
                                      <input type="checkbox" [(ngModel)]="otbList.status[otbList['providers'][provider]['bidder']]" (click)="excludeProvider(otbList['providers'][provider])"/>
                                      <span style="margin-left:5px; font-weight:100;">{{provider}}</span>
                                  </label>
                              </div>
                          </div>
                      </div>
                      <br><br>
                      <button [class.btn-visibility]="hasUnsavedValues.otb" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveExcludeRefreshProvider($event, 'demandfusion')" style="margin-bottom:15px;">Save</button>
                  </div>
              </fieldset>
              <app-demandfusion-config *ngIf="products.hasOwnProperty('demandfusion')" [product]="'demandfusion'" [account]='accountUUID' [showCustomDfConfigurationToggle]='false' [hasCustomDfConfiguration]='true' [dfProviders]='providerList.demandfusion.providers' [dfConfiguration]='products.demandfusion.df_configurations' (notifyParent)='getNotification()'></app-demandfusion-config>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel  *ngIf="showservice['demandfusion'] && products['amp']">
        <mat-expansion-panel-header>
          <mat-panel-title>
            AMP
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse= "isCollapsed.amp" class="panel-body">
          <div>
              <app-default [product]="'amp'" [adType]="'display'" [account]='accountUUID' [dfConfiguration]='products.amp.df_configurations' [dfProviderAdSupport]='dfProviderAdSupport' [hasCustomDfConfiguration]='products.amp.custom_df_configuration' [disableConfigToggle]='true' [dfProviders]='providerList.demandfusion.providers'></app-default>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel  *ngIf="showservice['highimpact-ads']">
        <mat-expansion-panel-header>
          <mat-panel-title>
            High Impact
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div appCollapse="isCollapsed.highimpact" class="panel-body">
          <div class="row">
            <div class="col-sm-12">
                <div class=" project-list">
                    <app-provider-config [configuration]="products['highimpact-ads']" [product]="'highimpact-ads'" [account]='accountUUID' [stickyEnable]="stickyEnable"></app-provider-config>
                </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel  *ngIf="showservice.flight">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Flight
          </mat-panel-title>
          <mat-panel-description>
            {{ timestamp }}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div appCollapse= "isCollapsed.flight" class="panel-body">
          <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive project-list">

                    <div class="panel-body" style="margin-bottom: 20px;">
                        <fieldset (click) ="hasUnsavedValues.flightCustom = true;">
                            <div class="hpanel col-sm-12" style="margin-bottom:0px; padding:0px;">

                            <!-- <div style="display:inline; padding:0px;" class="col-sm-2">
                                 <label (click)="advertiseWithUS = !advertiseWithUS">
                                 <input style="display:none;" type="checkbox" (click)="advertiseWithUS = !advertiseWithUS;"/>
                                 <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on': advertiseWithUS, 'fa-toggle-off': !advertiseWithUS}"></span>
                                 <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Advertise with Us</label>
                                 </label>
                                 </div> -->

                            <div style="display:inline; padding:0px;" class="col-sm-2">
                                <label >
                                    <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Version: </label>
                                    <select class="form-control vertical-align-middle" style="display:inline; padding:0px; width:auto; margin-left:10px;" [(ngModel)]="flight_version_value">
                                        <option *ngFor="let version of flight_versions" [value]="version" >{{ version }}</option>
                                    </select>
                                </label>
                            </div>
                            <div style="display:inline; padding:0px;" class="col-sm-2">
                                <label (click)="customDFP = !customDFP">
                                    <input style="display:none;" type="checkbox" (click)="customDFP = !customDFP;"/>
                                    <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on': customDFP, 'fa-toggle-off': !customDFP}"></span>
                                    <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Custom DFP #</label>
                                </label>
                            </div>
                            <div style="display:inline; padding:0px;" class="col-sm-2">
                                <input class="form-control" style="display:inline; width:83%;" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration['custom_dfp']}" (ngModelChange)="clearError('flight', 'custom_dfp');hasUnsavedValues.flightCustom = true;" [(ngModel)]="flight_model.configuration['custom_dfp']" type="textbox" placeholder="enter custom DFP #" [disabled]="!customDFP"/>
                                <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration['custom_dfp']"  class="text-danger"> {{ errorModel['flight'].configuration['custom_dfp'] }} </div>
                            </div>
                            <div style="display:inline; padding:0px;" class="col-sm-2">
                                <label (click)="setUAM()">
                                    <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on':enable_uam, 'fa-toggle-off': !enable_uam}"></span>
                                    <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Enable UAM</label>
                                </label>
                            </div>
                            <div style="display:inline; padding:0px;" class="col-sm-2">
                                <label (click)="flight_model.configuration['advertise_with_us']=flight_model.configuration['advertise_with_us']?null:'right'">

                                    <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on': flight_model.configuration['advertise_with_us'], 'fa-toggle-off': !flight_model.configuration['advertise_with_us']}"></span>
                                    <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Advertise with us</label>
                                </label>
                            </div>
                            <div *ngIf="flight_model.configuration['advertise_with_us']" style="display:inline; padding:0px;" class="col-sm-2">
                                <select class="form-control" [(ngModel)]="flight_model.configuration['advertise_with_us']">
                                    <option *ngFor="let position of advertiseWithUsPosition" [value]="position" >{{ position }}</option>
                                </select>
                                <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration['custom_dfp']"  class="text-danger"> {{ errorModel['flight'].configuration['custom_dfp'] }} </div>
                            </div>

                            <div style="display:inline; padding:0px; float:right;" class="col-sm-2">
                                <button [class.btn-visibility]="hasUnsavedValues.flightCustom" style="margin-top: 2px;" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveFlightData($event)">Save</button>
                            </div>
                        </div>
                        </fieldset>

                    </div>
                    <fieldset (change)= "hasUnsavedValues.flight = true;">
                        <table class="table table-striped table-bordered">
                            <tr class="text-center">
                                <th width="6%">Device</th>
                                <th width="6%" *ngIf="flight_version_value == 1">Word Count</th>
                                <th width="6%" *ngIf="flight_version_value == 1">Pixels</th>
                                <th width="6%" *ngIf="flight_version_value == 1">Word Threshold</th>
                                <th width="11%" *ngIf="flight_version_value == 1">Div Selector</th>
                                <th>Ads Sizes</th>
                                <th width="6%">Refresh Ad</th>
                                <th width="6%">Time (in sec)</th>
                                <th width="6%">Cap</th>
                                <th width="6%">Use Cutoff</th>
                                <th width="6%">Cutoff Count</th>
                            </tr>
                            <tr *ngFor="let device of deviceTypeList">
                                <td>
                                    <label class="cursor-pointer"> <input id="{{ device+'_enable' }}" class="i-checks cursor-pointer" type="checkbox" [(ngModel)]="flight_model.configuration[device+'_enable']" name="{{device+'_enable'}}" (ngModelChange)="flight_model.configuration[device+'_enable']==false?clearConfigError('flight', device):''"/> {{ deviceTypeAdSizeList[device] | upperString }} </label>
                                </td>
                                <td *ngIf="flight_version_value == 1">
                                    <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_word_count']}" [disabled]="!flight_model.configuration[device+'_enable']" value="1800" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device+'_word_count']" name="{{device+'_word_count'}}" #device+_word_count="ngModel" (ngModelChange)="clearError('flight', device+'_word_count')">
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_word_count']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_word_count'] }} </div>
                                </td>
                                <td *ngIf="flight_version_value == 1">
                                    <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_vertical_pixel']}" [disabled]="!flight_model.configuration[device+'_enable']" value="1800" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device+'_vertical_pixel']" name="{{device+'_vertical_pixel'}}" #d_vertical_pixel="ngModel" (ngModelChange)="clearError('flight', device+'_vertical_pixel')">
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_vertical_pixel']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_vertical_pixel'] }} </div>
                                </td>
                                <td *ngIf="flight_version_value == 1">
                                    <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_word_threshold']}" [disabled]="!flight_model.configuration[device+'_enable']" value="1800" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device+'_word_threshold']" name="{{device+'_word_threshold'}}" #d_word_threshold="ngModel" (ngModelChange)="clearError('flight', device+'_word_threshold')">
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_word_threshold']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_word_threshold'] }} </div>
                                </td>
                                <td *ngIf="flight_version_value == 1">
                                    <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_selector']}" [disabled]="!flight_model.configuration[device+'_enable']" value="1800" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device+'_selector']" name="{{device+'_selector'}}" #d_word_threshold="ngModel" (ngModelChange)="clearError('flight', device+'_selector')">
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_selector']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_selector'] }} </div>

                                </td>

                                <td>
                                    <div class="ads-size-for-flight">

                                        <div class="hpanel panel-collapse" *ngFor="let size of flight_model.configuration[device+'_ad_sizes']">
                                            <div class="panel-heading right5px flight-opt" *ngIf="flight_model.configuration[device+'_enable']">
                                                {{ size }} <a class="closebox" (click)="deleteFlightSizeOption(size, deviceTypeAdSizeList[device])"><i class="fa fa-times"></i></a>
                                            </div>
                                        </div>
                                        <div class="hpanel panel-collapse ">
                                            <select class="form-control fligt-opt " [disabled]="!flight_model.configuration[device+'_enable']" (change)="selectFlightSizeOption($event, flight_model, deviceTypeAdSizeList[device], flightSelectList); clearError('flight', device+'_ad_sizes');" #flightSelectList>
                                                <option selected disabled>select size</option>
                                                <option [value]="flightsize" [hidden]="(flightsize=='160x600' || flightsize=='300x600')" *ngFor="let flightsize of ad_size">{{ flightsize }}</option>
                                            </select>
                                            <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_ad_sizes']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_ad_sizes'] }}</div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                </td>
                                <td style="vertical-align:middle;">
                                    <div style="text-align:center;">

                                        <input id="{{device+'_refresh_ad'}}" class="i-checks" type="checkbox" style="display:none;" name="{{device+'_refresh_ad'}}" [(ngModel)]="flight_model.configuration[device+'_refresh_ad']"/>
                                        <label><span (click)="flight_model.configuration[device+'_enable']?flight_model.configuration[device+'_refresh_ad'] = !flight_model.configuration[device+'_refresh_ad']: ''" class="fa" title="Refresh Ad" style="font-size:20px; cursor:pointer; padding-top:5px;" [ngClass]="{'deviceSelected fa-toggle-on':flight_model.configuration[device+'_refresh_ad'], 'fa-toggle-off': !flight_model.configuration[device+'_refresh_ad'], 'pointer-event-disabled': !flight_model.configuration[device+'_enable']}"></span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_time'] && flight_model.configuration[device+'_refresh_ad']}" class="width100 form-control width80 display-block" [disabled]="!flight_model.configuration[device+'_refresh_ad'] || !flight_model.configuration[device+'_enable']" placeholder="Time" type="text" name="refresh_time"  [(ngModel)]="flight_model.configuration[device+'_refresh_time']" (ngModelChange)="clearError('flight', device+'_refresh_time')"/>
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_time'] && flight_model.configuration[device+'_refresh_ad']" class="text-danger"> {{ errorModel['flight'].configuration[device+'_refresh_time'] }} </div>
                                </td>
                                <td>
                                    <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_cap'] && flight_model.configuration[device+'_refresh_ad']}" class="width100 form-control width80 display-block" [disabled]="!flight_model.configuration[device+'_refresh_ad'] || !flight_model.configuration[device+'_enable']" placeholder="Cap" type="text" name="refresh_cap" [(ngModel)]="flight_model.configuration[device+'_refresh_cap']" (ngModelChange)="clearError('flight', device+'_refresh_cap')"/>
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_cap'] && flight_model.configuration[device+'_refresh_ad']" class="text-danger"> {{ errorModel['flight'].configuration[device+'_refresh_cap'] }} </div>
                                </td>
                                <td style="vertical-align:middle;">
                                  <div style="text-align:center;">
                                      <input id="{{device+'_refresh_cutoff'}}" class="i-checks" type="checkbox" style="display:none;" name="{{device+'_refresh_cutoff'}}" [(ngModel)]="flight_model.configuration[device+'_refresh_cutoff']"/>
                                      <label><span (click)="flight_model.configuration[device+'_enable']?flight_model.configuration[device+'_refresh_cutoff'] = !flight_model.configuration[device+'_refresh_cutoff']: ''" class="fa" title="Refresh Cutoff" style="font-size:20px; cursor:pointer; padding-top:5px;" [ngClass]="{'deviceSelected fa-toggle-on':flight_model.configuration[device+'_refresh_cutoff'], 'fa-toggle-off': !flight_model.configuration[device+'_refresh_cutoff'], 'pointer-event-disabled': !flight_model.configuration[device+'_enable']}"></span>
                                      </label>
                                  </div>
                              </td>
                              <td>
                                  <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_cutoff_count'] && flight_model.configuration[device+'_refresh_cutoff']}" class="width100 form-control width80 display-block" [disabled]="!flight_model.configuration[device+'_refresh_cutoff'] || !flight_model.configuration[device+'_enable']" placeholder="Cutoff Count" type="text" name="refresh_cutoff_count" [(ngModel)]="flight_model.configuration[device+'_refresh_cutoff_count']" (ngModelChange)="clearError('flight', device+'_refresh_cutoff_count')"/>
                                  <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_cutoff_count'] && flight_model.configuration[device+'_refresh_cutoff']" class="text-danger"> {{ errorModel['flight'].configuration[device+'_refresh_cutoff_count'] }} </div>
                              </td>
                            </tr>
                        </table>

                        <table class="table align-middle" style = "min-height:80px;" *ngIf="flight_version_value == 1 || flight_version_value == undefined">
                            <tr>
                            <td class="no-bottom vertical-align-middle-imp">
                                <input class="i-checks" style="display:none;" type="checkbox" [(ngModel)]="flight_model.configuration.sticky_enabled"  name="sticky_enabled"/>
                                <label class="display-vertical cursor-pointer"> Sticky: </label> <span (click)="flight_model.configuration.sticky_enabled = !flight_model.configuration.sticky_enabled; hasUnsavedValues.flight=true;"
                                    class="fa" title="Sticky" style="font-size:20px; cursor:pointer; padding-top:5px;"
                                    [ngClass]="{'deviceSelected fa-toggle-on':flight_model.configuration.sticky_enabled, 'fa-toggle-off': !flight_model.configuration.sticky_enabled}"></span>

                            </td>
                            <td *ngIf="flight_model.configuration.sticky_enabled" class="no-bottom text-align-center vertical-align-middle-imp">
                                <label class="cursor-pointer"> <input class="i-checks position-checkbox-center" type="checkbox"
                                    [(ngModel)]="flight_model.configuration.is_sticky_common"  name="is_sticky_common" #is_sticky_common="ngModel" /> Common </label>
                            </td>
                            <td *ngIf="flight_model.configuration.sticky_enabled" [ngClass]="{'devicePaddingZero': flight_model.configuration.is_sticky_common}" class="no-bottom text-align-center vertical-align-middle-imp">
                                <span *ngFor="let platform of platforms">
                                    <div *ngIf="!flight_model.configuration.is_sticky_common && flight_model.configuration.stick_for.includes(platform)" ></div>
                                    <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected"
                                          (click)="flight_model.configuration.stick_for.indexOf(platform) !== -1?flight_model.configuration.stick_for.splice(flight_model.configuration.stick_for.indexOf(platform), 1):flight_model.configuration.stick_for.push(platform); flight_model.changeColor=true; hasUnsavedValues.flight=true;"
                                          [ngClass]="{'deviceSelected': (flight_model.configuration.stick_for.indexOf(platform) != -1)}"></span>
                                    <div *ngIf="!flight_model.configuration.is_sticky_common && flight_model.configuration.stick_for.includes(platform)" ></div>
                                    <hr *ngIf="platform != 'mobile' && !flight_model.configuration.is_sticky_common" style="margin-top: 0px; margin-bottom: 0px;">
                                </span>
                            </td>
                            <td *ngIf="flight_model.configuration.sticky_enabled" class="no-bottom sticky-config">
                                <div style = "margin-top:-20px">
                                        <tr *ngFor="let device of  flight_model.configuration.is_sticky_common?['common'] : deviceTypeList; let i=index">
                                            <td class="no-bottom">
                                                <label *ngIf="i == 0" class="text-align-center"> Continuous Sticky</label>
                                                <input style='display:block; margin:10px;' class="i-checks position-checkbox-center" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+ '_stick_until_next_slot']}"  type="checkbox" [(ngModel)]="flight_model.configuration[device+ '_stick_until_next_slot']" name="{{device+ '_stick_until_next_slot'}}" #sticky="ngModel"  (ngModelChange)="clearError('flight', device+ '_stick_until_next_slot');hasUnsavedValues.flight=true;">

                                            </td>
                                            <td class="no-bottom">
                                                <label *ngIf="i == 0" class="text-align-center"> Stick Height</label>
                                                <input class="form-control " [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+ '_stick_height']}"  type="text" [(ngModel)]="flight_model.configuration[device+ '_stick_height']" name="{{device+ '_stick_height'}}" #sticky="ngModel"  (ngModelChange)="clearError('flight', device+ '_stick_height');hasUnsavedValues.flight=true;">

                                            </td>
                                            <td class="no-bottom">
                                                <label *ngIf="i == 0" class="text-align-center"> Offset</label>
                                                <input class="form-control" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_offset_height']}"  type="text" [(ngModel)]="flight_model.configuration[device+'_offset_height']" name="{{device+'_offset_height'}}" #sticky="ngModel"  (ngModelChange)="clearError('flight', device+'_offset_height');hasUnsavedValues.flight=true;">

                                            </td>
                                            <td class="no-bottom">
                                                <label *ngIf="i == 0" class="text-align-center"> # Flight Ads Per Slot</label>
                                                <input class="form-control" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_multisticky']}"  type="text" [(ngModel)]="flight_model.configuration[device+'_multisticky']" name="{{device+'_multisticky'}}" #sticky="ngModel"  (ngModelChange)="clearError('flight', device+'_multisticky');hasUnsavedValues.flight=true;">

                                            </td>
                                        </tr>
                                </div>
                            </td>
                        </tr>
                        </table>

                        <div>
                            <table class="table table-striped border-r-l align-middle" *ngIf="flight_version_value == 2">
                                <tr>
                                    <th style="width:6%;">Devices</th>
                                    <th style="width:11%;">Entry Container</th>
                                    <th style="width:6%;">Every Element</th>
                                    <th style="width:6%;">Element Count</th>
                                    <th style="width:8%;">Position</th>
                                    <th style="width:6%;">Max Ad Count</th>
                                    <th>Children to Avoid</th>
                                    <th>Parent to Avoid</th>
                                    <th>Skip Elements</th>
                                    <th style="width:4%;">Action</th>
                                </tr>
                                <tr *ngIf="!flight2_value || flight2_value.length == 0">
                                    <td [attr.colspan]="flight2_fields.length+1" class="emptyExclusion">NO FLIGHT 2 CONFIGURATIONS FOUND</td>
                                </tr>
                                <tr *ngFor="let value of flight2_value | paginate: flight2Config; let i=index">
                                    <td *ngFor="let field of flight2_fields">
                                    <div *ngIf="field == 'devices'">
                                        <span *ngFor="let platform of platforms">
                                            <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected"
                                                  (click)="value[field].indexOf(platform) !== -1?value[field].splice(value[field].indexOf(platform), 1):value[field].push(platform); flight_model.changeColor=true; hasUnsavedValues.flight=true;"
                                                  [ngClass]="{'deviceSelected': (value[field].indexOf(platform) != -1 && flight_model.configuration[platform[0]+'_enable'])}"></span>
                                        </span>
                                    </div>
                                        <input *ngIf="field != 'devices' && field != 'pos'" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.flight2 && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)] && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)][field] }" (ngModelChange)="clearErrorForFlight2('flight', field, i)" type="text" class="form-control" name={{field}} [(ngModel)]="value[field]" placeholder={{field}}>
                                        <select *ngIf="field != 'devices' && field == 'pos'" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.flight2 && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)] && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)][field] }" (ngModelChange)="clearErrorForFlight2('flight', field, i)" type="text" class="form-control" name={{field}} [(ngModel)]="value[field]">
                                            <option>before</option>
                                            <option>after</option>
                                            <option>prepend</option>
                                            <option>append</option>
                                            </select>
                                        <div  *ngIf="field != 'devices' && errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.flight2 && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)] && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)][field]" class="text-danger"> {{ errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)][field] }} </div>
                                    </td>
                                    <!-- <td>
                                        <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)].expiry_date }" (ngModelChange)="clearErrorForFlightExclusionURL('flight', 'expiry_date', i)" type="date" class="form-control" name="url_path" [(ngModel)]="value['expiry_date']" plsaceholder="path">
                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)].expiry_date"  class="text-danger"> {{ errorModel['flight'] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)].expiry_date}} </div>

                                    </td> -->
                                    <td style="text-align:center;">
                                        <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteFlight2(i)"></span></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td [attr.colspan]="flight2_fields.length+1"> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="flight2()">Add New</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td [attr.colspan]="flight2_fields.length+1">
                                        <div class="col-sm-7 center-pagination" *ngIf="flight_version_value == 2">
                                            <div class="dataTables_paginate paging_simple_numbers">
                                                <!-- <pagination-controls [id]="flight2Config.id" [directionLinks]="directionLinks"
                                                    (pageChange)="onFlight2Change($event)">
                                                </pagination-controls> -->
                                                <mat-paginator
                                                  [id]="flight2Config.id"
                                                  [length]="maxSize"
                                                  [pageSize]="flight2Config.itemsPerPage"
                                                  (page)="pageEvent = onFlight2Change($event)">
                                                </mat-paginator>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>


                        </div>

                        <div class="hbuilt cursor-pointer" (click)="isCollapsed.flightExclusionSettings = !isCollapsed.flightExclusionSettings">
                            <div class="panel-tools">
                                <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.flightExclusionSettings, 'fa-chevron-down': isCollapsed.flightExclusionSettings}"></i></a>
                            </div>
                          Flight Exclusion Settings
                        </div>
                        <div appCollapse= "isCollapsed.flightExclusionSettings" class="panel-body">
                        <table class="table table-striped border-r-l align-middle">
                            <tr>
                                <th>Exclusion Selector</th>
                                <th></th>
                                <th>Expiry Date</th>
                                <th style="width:4%;">Action</th>
                            </tr>
                            <tr *ngIf="!hasUnsavedValues.isFlightExclusionSelected">
                                <td colspan='4' class="emptyExclusion">NO EXCLUSION FOUND</td>
                            </tr>
                            <tr *ngFor="let value of flight_exclusion_selector_value | paginate: flightExclusionConfig; let i=index">
                                <td style="width:70%;">
                                    <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusions && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].selector }" (ngModelChange)="clearErrorForExclusion('flight', 'selector', ((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i)" type="text" class="form-control" name="selector" [(ngModel)]="value['selector']" placeholder="selector">

                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusions && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].selector"  class="text-danger"> {{ errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].selector }} </div>

                                </td>
                                <td></td>
                                <td>
                                    <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusions && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].expiry_date }" (ngModelChange)="clearErrorForExclusion('flight', 'expiry_date', i)" type="date" class="form-control" name="selector" [(ngModel)]="value['expiry_date']" plsaceholder="selector">
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusions && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].expiry_date"  class="text-danger"> {{ errorModel['flight'] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].expiry_date}} </div>

                                </td>
                                <td style="text-align:center;">
                                    <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteFlightExclusionSelector(i)"></span></a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan=4> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="flightExclusionSelector()">Add New</button>
                                    <div class="col-sm-7 center-pagination">
                                        <div class="dataTables_paginate paging_simple_numbers">
                                            <!-- <pagination-controls [id]="flightExclusionConfig.id"
                                                [directionLinks]="directionLinks"
                                                (pageChange)="onFlightExclusionChange($event)">
                                            </pagination-controls> -->
                                            <mat-paginator
                                              [id]="flightExclusionConfig.id"
                                              [length]="maxSize"
                                              [pageSize]="flightExclusionConfig.itemsPerPage"
                                              (page)="pageEvent = onFlightExclusionChange($event)">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table class="table table-striped border-r-l align-middle">
                            <tr>
                                <th>Exclusion URL</th>
                                <th></th>
                                <th>Expiry Date</th>
                                <th style="width:4%;">Action</th>
                            </tr>
                            <tr *ngIf="!hasUnsavedValues.isFlightExclusionURLSelected">
                                <td colspan='4' class="emptyExclusion">NO EXCLUSION URLS FOUND</td>
                            </tr>
                            <tr *ngFor="let value of flight_exclusion_url_value | paginate: flightExclusionURLConfig; let i=index">
                                <td>
                                    <label style="font-size:14px;">://{{model.url}}/</label>
                                </td>
                                <td style="width:70%;">
                                    <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].url_path }" (ngModelChange)="clearErrorForFlightExclusionURL('flight', 'url_path', i)" type="text" class="form-control" name="url_path" [(ngModel)]="value['url_path']" placeholder="path">

                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].url_path"  class="text-danger"> {{ errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].url_path }} </div>

                                </td>
                                <td>
                                    <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].expiry_date }" (ngModelChange)="clearErrorForFlightExclusionURL('flight', 'expiry_date', i)" type="date" class="form-control" name="url_path" [(ngModel)]="value['expiry_date']" plsaceholder="path">
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].expiry_date"  class="text-danger"> {{ errorModel['flight'] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].expiry_date}} </div>

                                </td>
                                <td style="text-align:center;">
                                    <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteFlightExclusionURL(i)"></span></a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan=4s> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="flightExclusionURL()">Add New</button>
                                </td>
                            </tr>
                        </table>
                        <div class="col-sm-7 center-pagination">
                            <div class="dataTables_paginate paging_simple_numbers">
                                <!-- <pagination-controls [id]="flightExclusionURLConfig.id" [directionLinks]="directionLinks"
                                    (pageChange)="onFlightExclusionURLChange($event)">
                                </pagination-controls> -->
                                <mat-paginator
                                  [id]="flightExclusionURLConfig.id"
                                  [length]="maxSize"
                                  [pageSize]="flightExclusionURLConfig.itemsPerPage"
                                  (page)="pageEvent = onFlightExclusionURLChange($event)">
                                </mat-paginator>
                            </div>
                        </div>

                        </div>

                        <div style="margin-top:20px" class="hbuilt cursor-pointer" (click)="isCollapsed.flightFloors = !isCollapsed.flightFloors">
                            <div class="panel-tools">
                                <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.flightFloors, 'fa-chevron-down': isCollapsed.flightFloors}"></i></a>
                            </div>
                          Flight Floor Settings
                        </div>
                        <div appCollapse= "isCollapsed.flightFloors" class="panel-body">
                        <table class="table table-striped table-bordered" *ngIf="flight_model.configuration['d_enable'] || flight_model.configuration['t_enable'] || flight_model.configuration['m_enable']">
                            <tr class="text-center">
                                <th width="16%">Device Floor Setting</th>
                                <th width="16%">Floor Type</th>
                                <th width="16%">DF Floor</th>
                                <th width="16%">AdX Floor</th>
                                <th width="16%">DF Reduce %</th>
                                <th width="16%">AdX Reduce %</th>
                            </tr>
                            <tr *ngFor="let device of deviceTypeList" >
                                <td style="text-align:center;vertical-align:middle;" *ngIf="flight_model.configuration[device[0]+'_enable']">
                                    <label> {{ deviceTypeAdSizeList[device] | upperString }}</label>
                                </td>
                                <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                    <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_floor_type']}" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device[0]+'_floor_type']" name="{{device+'_floor_type'}}" #device[0]+_floor_type="ngModel" (ngModelChange)="clearError('flight', device[0]+'_floor_type')">
                                        <option [value]="'site'">Use Site Setting ({{model.floor_type}})</option>
                                        <option [value]="'target'">Target CPM</option>
                                        <option [value]="'hard'">Hard Floor</option>
                                    </select>
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_floor_type']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_floor_type'] }} </div>
                                </td>
                                <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                    <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_floor']}" class="form-control"
                                            [(ngModel)]="flight_model.configuration[device[0]+'_df_floor']" name="{{device+'_df_floor'}}" #device[0]+_df_floor="ngModel" (ngModelChange)="clearError('flight', device[0]+'_df_floor')">
                                        <option *ngIf="floors['df_floors'].indexOf(flight_model.configuration[device[0]+'_df_floor']) < 0 && flight_model.configuration[device[0]+'_df_floor'] != undefined" [value]="flight_model.configuration[device[0]+'_df_floor']">{{flight_model.configuration[device[0]+'_df_floor']}}</option>
                                        <option *ngFor="let price of floors['df_floors']" [value]='price'>{{price}}</option>
                                    </select>
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_floor']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_df_floor'] }} </div>
                                </td>
                                <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                    <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_adx_floor']}" class="form-control"
                                            [(ngModel)]="flight_model.configuration[device[0]+'_adx_floor']" name="{{device+'_adx_floor'}}" #device[0]+_adx_floor="ngModel" (ngModelChange)="clearError('flight', device[0]+'_adx_floor')">
                                        <option *ngIf="floors[getFloorType(model.floor_type, flight_model.configuration[device[0]+'_floor_type'])+'_floors'].indexOf(flight_model.configuration[device[0]+'_adx_floor']) < 0 && flight_model.configuration[device[0]+'_adx_floor'] != undefined" [value]="flight_model.configuration[device[0]+'_adx_floor']">{{flight_model.configuration[device[0]+'_adx_floor']}}</option>
                                        <option *ngFor="let price of floors[getFloorType(model.floor_type, flight_model.configuration[device[0]+'_floor_type'])+'_floors']" [value]='price'>{{price}}</option>
                                    </select>
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_adx_floor']"  class="text-danger"> {{ errorModel['flight'].configuration[device[0]+'_adx_floor'] }} </div>
                                </td>
                                <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                    <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_reduce_refresh']}" class="form-control"
                                            [(ngModel)]="flight_model.configuration[device+'_df_reduce_refresh']" name="{{device+'_df_reduce_refresh'}}" #device[0]+_df_reduce_refresh="ngModel" (ngModelChange)="clearError('flight', device[0]+'_df_reduce_refresh')">
                                        <option *ngIf="floors['reduce_values'].indexOf(flight_model.configuration[device[0]+'_df_reduce_refresh']) < 0 && flight_model.configuration[device[0]+'_df_reduce_refresh'] != undefined" [value]="flight_model.configuration[device[0]+'_df_reduce_refresh']">{{flight_model.configuration[device[0]+'_df_reduce_refresh']}}</option>
                                        <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                    </select>
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_reduce_refresh']"  class="text-danger"> {{ errorModel['flight'].configuration[device[0]+'_df_reduce_refresh'] }} </div>
                                </td>
                                <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                    <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_reduce_refresh']}" class="form-control"
                                            [(ngModel)]="flight_model.configuration[device+'_adx_reduce_refresh']" name="{{device+'_adx_reduce_refresh'}}" #device[0]+_adx_reduce_refresh="ngModel" (ngModelChange)="clearError('flight', device[0]+'_adx_reduce_refresh')">
                                        <option *ngIf="floors['reduce_values'].indexOf(flight_model.configuration[device[0]+'_adx_reduce_refresh']) < 0 && flight_model.configuration[device[0]+'_adx_reduce_refresh'] != undefined" [value]="flight_model.configuration[device[0]+'_adx_reduce_refresh']">{{flight_model.configuration[device[0]+'_adx_reduce_refresh']}}</option>
                                        <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                    </select>
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_adx_reduce_refresh']"  class="text-danger"> {{ errorModel['flight'].configuration[device[0]+'_adx_floor'] }} </div>
                                </td>
                            </tr>
                        </table>

                        <table class="table table-striped border-r-l align-middle" *ngIf="flight_model.configuration['d_enable'] || flight_model.configuration['t_enable'] || flight_model.configuration['m_enable']">
                            <tr>
                                <th>Position Number</th>
                                <th>Floor Type</th>
                                <th>DF Floors</th>
                                <th>AdX Floors</th>
                                <th>DF Reduce %</th>
                                <th>AdX Reduce %</th>
                                <th style="width:4%;">Action</th>
                            </tr>
                            <tr *ngIf="!hasUnsavedValues.isFlightPositionFloorsSelected">
                                <td colspan='7' class="emptyPositionalFloors">NO POSITION FLOORS FOUND</td>
                            </tr>
                            <tr *ngFor="let value of flight_position_floors_value | paginate: flightPositionFloorConfig; let i=index">
                                <td style="width:25%">
                                    <input style='width:40%;float:left;' [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].position }" (ngModelChange)="clearErrorForPositionFloor('flight', 'position', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)" type="text" class="form-control" name="position" [(ngModel)]="value['position']" placeholder="position">
                                    <div *ngIf="value['position']" style="float:right;text-align:center;width:auto;margin-left:10%;margin-right:10%">
                                        <span *ngFor="let platform of platforms">
                                            <div></div>
                                            <strong class="flight_positions">{{model.pin}}.D{{platform[0].toUpperCase()}}L.{{getFlightSuffix(value['position'])}}</strong>
                                            <div></div>
                                            <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                        </span>
                                    </div>

                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].position"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].position }} </div>

                                </td>
                                <td>
                                    <div style="width: 20%; float:left;">
                                        <span *ngFor="let platform of platforms">
                                            <div></div>
                                            <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                            <div></div>
                                            <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                        </span>
                                    </div>
                                    <div style='float:right;width:80%'>
                                        <select  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].floor_type_desktop }" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)" class="form-control" name="floor_type_desktop" [(ngModel)]="value['floor_type_desktop']">
                                            <option [value]="'site'">Use Site Setting ({{model.floor_type}})</option>
                                            <option [value]="'target'">Target CPM</option>
                                            <option [value]="'hard'">Hard Floor</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop }} </div>

                                        <select  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].floor_type_tablet }" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)" class="form-control" name="floor_type_tablet" [(ngModel)]="value['floor_type_tablet']">
                                            <option [value]="'site'">Use Site Setting ({{model.floor_type}})</option>
                                            <option [value]="'target'">Target CPM</option>
                                            <option [value]="'hard'">Hard Floor</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet }} </div>

                                        <select  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].floor_type_mobile }" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)" class="form-control" name="floor_type_mobile" [(ngModel)]="value['floor_type_mobile']">
                                            <option [value]="'site'">Use Site Setting ({{model.floor_type}})</option>
                                            <option [value]="'target'">Target CPM</option>
                                            <option [value]="'hard'">Hard Floor</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile }} </div>
                                    </div>
                                </td>
                                <td>
                                    <div style="width: 20%; float:left;">
                                        <span *ngFor="let platform of platforms">
                                            <div></div>
                                            <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                            <div></div>
                                            <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                        </span>
                                    </div>
                                    <div style='float:right;width:80%'>
                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop }" class="form-control"
                                                name="df_floor_desktop" [(ngModel)]="value['df_floor_desktop']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['df_floors'].indexOf(value['df_floor_desktop']) < 0 && value['df_floor_desktop'] != undefined" [value]="value['df_floor_desktop']">{{value['df_floor_desktop']}}</option>
                                            <option *ngFor="let price of floors['df_floors']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop }} </div>

                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet }" class="form-control"
                                                name="df_floor_tablet" [(ngModel)]="value['df_floor_tablet']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['df_floors'].indexOf(value['df_floor_tablet']) < 0 && value['df_floor_tablet'] != undefined" [value]="value['df_floor_tablet']">{{value['df_floor_tablet']}}</option>
                                            <option *ngFor="let price of floors['df_floors']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet }} </div>

                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile }" class="form-control"
                                                name="df_floor_mobile" [(ngModel)]="value['df_floor_mobile']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['df_floors'].indexOf(value['df_floor_mobile']) < 0 && value['df_floor_mobile'] != undefined" [value]="value['df_floor_mobile']">{{value['df_floor_mobile']}}</option>
                                            <option *ngFor="let price of floors['df_floors']" [value]='price'>{{price}}</option>
                                        </select>
                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile }} </div>
                                    </div>
                                </td>
                                <td>
                                    <div style="width: 20%; float:left;">
                                        <span *ngFor="let platform of platforms">
                                            <div></div>
                                            <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                            <div></div>
                                            <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                        </span>
                                    </div>
                                    <div style='float:right;width:80%'>
                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_desktop }" class="form-control"
                                                name="adx_floor_desktop" [(ngModel)]="value['adx_floor_desktop']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors[getFloorType(model.floor_type, value['floor_type_desktop'])+'_floors'].indexOf(value['adx_floor_desktop']) < 0 && value['adx_floor_desktop'] != undefined" [value]="value['adx_floor_desktop']">{{value['adx_floor_desktop']}}</option>
                                            <option *ngFor="let price of floors[getFloorType(model.floor_type, value['floor_type_desktop'])+'_floors']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_desktop"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_desktop }} </div>

                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_tablet }" class="form-control"
                                                name="adx_floor_tablet" [(ngModel)]="value['adx_floor_tablet']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors[getFloorType(model.floor_type, value['floor_type_tablet'])+'_floors'].indexOf(value['adx_floor_tablet']) < 0 && value['adx_floor_tablet'] != undefined" [value]="value['adx_floor_tablet']">{{value['adx_floor_tablet']}}</option>
                                            <option *ngFor="let price of floors[getFloorType(model.floor_type, value['floor_type_tablet'])+'_floors']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_tablet"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_tablet }} </div>

                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_mobile }" class="form-control"
                                                name="adx_floor_mobile" [(ngModel)]="value['adx_floor_mobile']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors[getFloorType(model.floor_type, value['floor_type_mobile'])+'_floors'].indexOf(value['adx_floor_mobile']) < 0 && value['adx_floor_mobile'] != undefined" [value]="value['adx_floor_mobile']">{{value['adx_floor_mobile']}}</option>
                                            <option *ngFor="let price of floors[getFloorType(model.floor_type, value['floor_type_mobile'])+'_floors']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_mobile"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_mobile }} </div>
                                    </div>
                                </td>
                                <td>
                                    <div style="width: 20%; float:left;">
                                        <span *ngFor="let platform of platforms">
                                            <div></div>
                                            <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                            <div></div>
                                            <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                        </span>
                                    </div>
                                    <div style='float:right;width:80%'>
                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_desktop }" class="form-control"
                                                name="df_reduce_desktop" [(ngModel)]="value['df_reduce_desktop']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['reduce_values'].indexOf(value['df_reduce_desktop']) < 0 && value['df_reduce_desktop'] != undefined" [value]="value['df_reduce_desktop']">{{value['df_reduce_desktop']}}</option>
                                            <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_desktop"  class="text-danger"> {{ errorModel['flight'].position_floors[i].df_reduce_desktop }} </div>

                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_tablet }" class="form-control"
                                                name="df_reduce_tablet" [(ngModel)]="value['df_reduce_tablet']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['reduce_values'].indexOf(value['df_reduce_tablet']) < 0 && value['df_reduce_tablet'] != undefined" [value]="value['df_reduce_tablet']">{{value['df_reduce_tablet']}}</option>
                                            <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_tablet"  class="text-danger"> {{ errorModel['flight'].position_floors[i].df_reduce_tablet }} </div>

                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_mobile }" class="form-control"
                                                name="df_reduce_mobile" [(ngModel)]="value['df_reduce_mobile']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['reduce_values'].indexOf(value['df_reduce_mobile']) < 0 && value['df_reduce_mobile'] != undefined" [value]="value['df_reduce_mobile']">{{value['df_reduce_mobile']}}</option>
                                            <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                        </select>
                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_mobile"  class="text-danger"> {{ errorModel['flight'].position_floors[i].df_reduce_mobile }} </div>
                                    </div>
                                </td>
                                <td>
                                    <div style="width: 20%; float:left;">
                                        <span *ngFor="let platform of platforms">
                                            <div></div>
                                            <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                            <div></div>
                                            <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                        </span>
                                    </div>
                                    <div style='float:right;width:80%'>
                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_desktop }" class="form-control"
                                                name="adx_reduce_desktop" [(ngModel)]="value['adx_reduce_desktop']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['reduce_values'].indexOf(value['adx_reduce_desktop']) < 0 && value['adx_reduce_desktop'] != undefined" [value]="value['adx_reduce_desktop']">{{value['adx_reduce_desktop']}}</option>
                                            <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_desktop"  class="text-danger"> {{ errorModel['flight'].position_floors[i].adx_reduce_desktop }} </div>

                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_tablet }" class="form-control"
                                                name="adx_reduce_tablet" [(ngModel)]="value['adx_reduce_tablet']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['reduce_values'].indexOf(value['adx_reduce_tablet']) < 0 && value['adx_reduce_tablet'] != undefined" [value]="value['adx_reduce_tablet']">{{value['adx_reduce_tablet']}}</option>
                                            <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_tablet"  class="text-danger"> {{ errorModel['flight'].position_floors[i].adx_reduce_tablet }} </div>

                                        <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_mobile }" class="form-control"
                                                name="adx_reduce_mobile" [(ngModel)]="value['adx_reduce_mobile']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                            <option *ngIf="floors['reduce_values'].indexOf(value['adx_reduce_mobile']) < 0 && value['adx_reduce_mobile'] != undefined" [value]="value['adx_reduce_mobile']">{{value['adx_reduce_mobile']}}</option>
                                            <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                        </select>

                                        <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_mobile"  class="text-danger"> {{ errorModel['flight'].position_floors[i].adx_reduce_mobile }} </div>
                                    </div>
                                </td>
                                <td style="text-align:center;">
                                    <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteFlightPositionFloorSelector(i)"></span></a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan=7> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="flightPositionFloorSelector()">Add New</button>
                                </td>
                            </tr>
                        </table>
                        <div class="col-sm-7 center-pagination">
                            <div class="dataTables_paginate paging_simple_numbers">
                                <!-- <pagination-controls [id]="flightPositionFloorConfig.id"
                                    [directionLinks]="directionLinks"
                                    (pageChange)="onFlightPositionFloorChange($event)">
                                </pagination-controls> -->
                                <mat-paginator
                                  [id]="flightPositionFloorConfig.id"
                                  [length]="maxSize"
                                  [pageSize]="flightPositionFloorConfig.itemsPerPage"
                                  (page)="pageEvent = onFlightPositionFloorChange($event)">
                                </mat-paginator>
                            </div>
                        </div>
                        </div>
                        <table style="margin-top:20px" class="table table-striped table-bordered">
                            <tr>
                                <td colspan="11">
                                  <button [class.btn-visibility]="hasUnsavedValues.flight" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveFlightData($event)">Save </button>
                                </td>
                         </tr>
                        </table>
                    </fieldset>
                </div>
            </div><!-- END lg-12 -->
          </div><!-- END .row -->
          <div *ngIf="showservice['demandfusion'] && products['flight']">
              <app-default [product]="'flight'" [account]='accountUUID' [dfConfiguration]='products.flight.df_configurations' [tag]='products.flight' [hasCustomDfConfiguration]='products.flight.custom_df_configuration' [dfProviders]='providerList.demandfusion.providers'></app-default>
          </div>
        </div>
      </mat-expansion-panel>

    </mat-accordion>

    <!-- <div class="row animate">
        <div class="col-lg-12">
            <div class="hpanel">
                <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.accountNotes = !isCollapsed.accountNotes">
                    <div class="panel-tools">
                        <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.accountNotes, 'fa-chevron-down': isCollapsed.accountNotes}"></i></a>
                    </div>
                    Account Notes
                </div>


            </div>
        </div>
    </div> -->


    <!-- Site Analysis START *ngIf="show_div"-->
    <!-- <div class="row animate">
      <div class="col-lg-12">
        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsedSiteAnalysis = !isCollapsedSiteAnalysis">
            <div class="panel-tools">
              <a class="showhide"><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsedSiteAnalysis, 'fa-chevron-down': isCollapsedSiteAnalysis}"></i></a>
            </div>
            Code Analysis
          </div>

        </div>
      </div>
    </div>  -->
    <!-- Site Analysis END -->

    <!-- Exclusion URL START -->
    <!-- <div class="row animate">
        <div class="col-lg-12">
            <div class="hpanel">
                <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsedExclusionURL = !isCollapsedExclusionURL">
                    <div class="panel-tools">
                        <a class="showhide"><i class="fa" [ngClass]="{'fa-chevron-up': isCollapsedExclusionURL, 'fa-chevron-down': !isCollapsedExclusionURL}"></i></a>
                    </div>
                    Exclusion URLs
                </div>
            </div>
        </div>
    </div>  -->
    <!-- Exclusion URL END -->

    <!-- ADS.TXT ADDITIONS START -->
    <!-- <div class="row animate">
        <div class="col-lg-12">
            <div class="hpanel">
                <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsedAdstxtAddition = !isCollapsedAdstxtAddition">
                    <div class="panel-tools">
                        <a class="showhide"><i class="fa" [ngClass]="{'fa-chevron-up': isCollapsedAdstxtAddition, 'fa-chevron-down': !isCollapsedAdstxtAddition}"></i></a>
                    </div>
                    Ads.txt Additions
                </div>

            </div>
        </div>
    </div>  -->
    <!-- ADS.TXT ADDITIONS END -->

    <!-- Services START -->
    <!-- <div id='custom_settings' class="row animate">
        <div class="col-lg-12">
            <div class="hpanel">
                <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.customcode = !isCollapsed.customcode">
                    <div class="panel-tools">
                        <a class="showhide" ><i class="fa" id='cs_expand' [ngClass]="{'fa-chevron-up': !isCollapsed.customcode, 'fa-chevron-down': isCollapsed.customcode}"></i></a>
                    </div>
                    Custom Settings
                </div>

            </div>
        </div>
    </div> -->


    <!-- <div class="row animate">
      <div class="col-lg-12">

        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsedServices = !isCollapsedServices">
            <div class="panel-tools">
              <a class="showhide"><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsedServices, 'fa-chevron-down': isCollapsedServices}"></i></a>
            </div>
            Services
          </div>
        </div>
      </div>
    </div>  -->
    <!-- Services END -->


    <!-- In-images START -->

    <!-- <div class="row animate" [hidden]="!showservice['inimage-ads']">
      <div class="col-lg-12">
        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.inimage = !isCollapsed.inimage">
            <div class="panel-tools">
              <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.inimage, 'fa-chevron-down': isCollapsed.inimage}"></i></a>
            </div>
            In-Images
          </div>

        </div>
      </div>
    </div> -->
    <!-- In-images END -->


    <!-- Demand Fusion START -->
    <div class=row>
      <!-- <div class="col-lg-12 animate" *ngIf="showservice.demandfusion">
        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.demandfusion = !isCollapsed.demandfusion">
            <div class="panel-tools">
              <a class="showhide"><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.demandfusion, 'fa-chevron-down': isCollapsed.demandfusion}"></i></a>
            </div>
            Demand Fusion
          </div>
        </div>
      </div> -->
      <!-- Demand Fusion END -->

      <!-- AMP starts -->
      <div class="col-lg-12 animate" [hidden]="!showservice['amp']">
        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.amp = !isCollapsed.amp">
            <div class="panel-tools">
              <a class="showhide"><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.amp, 'fa-chevron-down': isCollapsed.amp}"></i></a>
            </div>
            AMP
          </div>

        </div>
      </div><!-- AMP END -->

      <!-- High Impact START -->
      <div class="col-lg-12 animate" [hidden]="!showservice['highimpact-ads']">
        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.highimpact = !isCollapsed.highimpact">
            <div class="panel-tools">
              <a class="showhide"><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.highimpact, 'fa-chevron-down': isCollapsed.highimpact}"></i></a>
            </div>
            High Impact
          </div>
          <div appCollapse="isCollapsed.highimpact" class="panel-body">
              <div class="row">
                <div class="col-sm-12">
                    <div class=" project-list">
                        <app-provider-config [configuration]="products['highimpact-ads']" [product]="'highimpact-ads'" [account]='accountUUID' [stickyEnable]="stickyEnable"></app-provider-config>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div> <!-- High Impact END -->

      <!-- Flight START -->
      <div class="col-lg-12 animate" [hidden]="!showservice.flight">

        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.flight = !isCollapsed.flight">
              <div class="panel-tools">
                  <span>{{ timestamp }}</span>
                  <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.flight, 'fa-chevron-down': isCollapsed.flight}"></i></a>
              </div>
            Flight
          </div>
          <div appCollapse= "isCollapsed.flight" class="panel-body">
            <div class="row">
              <div class="col-lg-12">
                  <div class="table-responsive project-list">

                      <div class="panel-body" style="margin-bottom: 20px;">
                          <fieldset (click) ="hasUnsavedValues.flightCustom = true;">
                              <div class="hpanel col-sm-12" style="margin-bottom:0px; padding:0px;">

                              <!-- <div style="display:inline; padding:0px;" class="col-sm-2">
                                   <label (click)="advertiseWithUS = !advertiseWithUS">
                                   <input style="display:none;" type="checkbox" (click)="advertiseWithUS = !advertiseWithUS;"/>
                                   <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on': advertiseWithUS, 'fa-toggle-off': !advertiseWithUS}"></span>
                                   <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Advertise with Us</label>
                                   </label>
                                   </div> -->

                              <div style="display:inline; padding:0px;" class="col-sm-2">
                                  <label >
                                      <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Version: </label>
                                      <select class="form-control vertical-align-middle" style="display:inline; padding:0px; width:auto; margin-left:10px;" [(ngModel)]="flight_version_value">
                                          <option *ngFor="let version of flight_versions" [value]="version" >{{ version }}</option>
                                      </select>
                                  </label>
                              </div>
                              <div style="display:inline; padding:0px;" class="col-sm-2">
                                  <label (click)="customDFP = !customDFP">
                                      <input style="display:none;" type="checkbox" (click)="customDFP = !customDFP;"/>
                                      <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on': customDFP, 'fa-toggle-off': !customDFP}"></span>
                                      <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Custom DFP #</label>
                                  </label>
                              </div>
                              <div style="display:inline; padding:0px;" class="col-sm-2">
                                  <input class="form-control" style="display:inline; width:83%;" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration['custom_dfp']}" (ngModelChange)="clearError('flight', 'custom_dfp');hasUnsavedValues.flightCustom = true;" [(ngModel)]="flight_model.configuration['custom_dfp']" type="textbox" placeholder="enter custom DFP #" [disabled]="!customDFP"/>
                                  <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration['custom_dfp']"  class="text-danger"> {{ errorModel['flight'].configuration['custom_dfp'] }} </div>
                              </div>
                              <div style="display:inline; padding:0px;" class="col-sm-2">
                                  <label (click)="setUAM()">
                                      <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on':enable_uam, 'fa-toggle-off': !enable_uam}"></span>
                                      <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Enable UAM</label>
                                  </label>
                              </div>
                              <div style="display:inline; padding:0px;" class="col-sm-2">
                                  <label (click)="flight_model.configuration['advertise_with_us']=flight_model.configuration['advertise_with_us']?null:'right'">

                                      <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on': flight_model.configuration['advertise_with_us'], 'fa-toggle-off': !flight_model.configuration['advertise_with_us']}"></span>
                                      <label class="cursor-pointer vertical-align-middle" style="margin-top:8px;">Advertise with us</label>
                                  </label>
                              </div>
                              <div *ngIf="flight_model.configuration['advertise_with_us']" style="display:inline; padding:0px;" class="col-sm-2">
                                  <select class="form-control" [(ngModel)]="flight_model.configuration['advertise_with_us']">
                                      <option *ngFor="let position of advertiseWithUsPosition" [value]="position" >{{ position }}</option>
                                  </select>
                                  <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration['custom_dfp']"  class="text-danger"> {{ errorModel['flight'].configuration['custom_dfp'] }} </div>
                              </div>

                              <div style="display:inline; padding:0px; float:right;" class="col-sm-2">
                                  <button [class.btn-visibility]="hasUnsavedValues.flightCustom" style="margin-top: 2px;" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveFlightData($event)">Save</button>
                              </div>
                          </div>
                          </fieldset>

                      </div>
                      <fieldset (change)= "hasUnsavedValues.flight = true;">
                          <table class="table table-striped table-bordered">
                              <tr class="text-center">
                                  <th width="6%">Device</th>
                                  <th width="6%" *ngIf="flight_version_value == 1">Word Count</th>
                                  <th width="6%" *ngIf="flight_version_value == 1">Pixels</th>
                                  <th width="6%" *ngIf="flight_version_value == 1">Word Threshold</th>
                                  <th width="11%" *ngIf="flight_version_value == 1">Div Selector</th>
                                  <th>Ads Sizes</th>
                                  <th width="6%">Refresh Ad</th>
                                  <th width="6%">Time (in sec)</th>
                                  <th width="6%">Cap</th>
                                  <th width="6%">Use Cutoff</th>
                                  <th width="6%">Cutoff Count</th>
                              </tr>
                              <tr *ngFor="let device of deviceTypeList">
                                  <td>
                                      <label class="cursor-pointer"> <input id="{{ device+'_enable' }}" class="i-checks cursor-pointer" type="checkbox" [(ngModel)]="flight_model.configuration[device+'_enable']" name="{{device+'_enable'}}" (ngModelChange)="flight_model.configuration[device+'_enable']==false?clearConfigError('flight', device):''"/> {{ deviceTypeAdSizeList[device] | upperString }} </label>
                                  </td>
                                  <td *ngIf="flight_version_value == 1">
                                      <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_word_count']}" [disabled]="!flight_model.configuration[device+'_enable']" value="1800" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device+'_word_count']" name="{{device+'_word_count'}}" #device+_word_count="ngModel" (ngModelChange)="clearError('flight', device+'_word_count')">
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_word_count']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_word_count'] }} </div>
                                  </td>
                                  <td *ngIf="flight_version_value == 1">
                                      <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_vertical_pixel']}" [disabled]="!flight_model.configuration[device+'_enable']" value="1800" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device+'_vertical_pixel']" name="{{device+'_vertical_pixel'}}" #d_vertical_pixel="ngModel" (ngModelChange)="clearError('flight', device+'_vertical_pixel')">
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_vertical_pixel']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_vertical_pixel'] }} </div>
                                  </td>
                                  <td *ngIf="flight_version_value == 1">
                                      <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_word_threshold']}" [disabled]="!flight_model.configuration[device+'_enable']" value="1800" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device+'_word_threshold']" name="{{device+'_word_threshold'}}" #d_word_threshold="ngModel" (ngModelChange)="clearError('flight', device+'_word_threshold')">
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_word_threshold']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_word_threshold'] }} </div>
                                  </td>
                                  <td *ngIf="flight_version_value == 1">
                                      <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_selector']}" [disabled]="!flight_model.configuration[device+'_enable']" value="1800" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device+'_selector']" name="{{device+'_selector'}}" #d_word_threshold="ngModel" (ngModelChange)="clearError('flight', device+'_selector')">
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_selector']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_selector'] }} </div>

                                  </td>

                                  <td>
                                      <div class="ads-size-for-flight">

                                          <div class="hpanel panel-collapse" *ngFor="let size of flight_model.configuration[device+'_ad_sizes']">
                                              <div class="panel-heading right5px flight-opt" *ngIf="flight_model.configuration[device+'_enable']">
                                                  {{ size }} <a class="closebox" (click)="deleteFlightSizeOption(size, deviceTypeAdSizeList[device])"><i class="fa fa-times"></i></a>
                                              </div>
                                          </div>
                                          <div class="hpanel panel-collapse ">
                                              <select class="form-control fligt-opt " [disabled]="!flight_model.configuration[device+'_enable']" (change)="selectFlightSizeOption($event, flight_model, deviceTypeAdSizeList[device], flightSelectList); clearError('flight', device+'_ad_sizes');" #flightSelectList>
                                                  <option selected disabled>select size</option>
                                                  <option [value]="flightsize" [hidden]="(flightsize=='160x600' || flightsize=='300x600')" *ngFor="let flightsize of ad_size">{{ flightsize }}</option>
                                              </select>
                                              <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_ad_sizes']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_ad_sizes'] }}</div>
                                          </div>
                                          <div class="clear"></div>
                                      </div>
                                  </td>
                                  <td style="vertical-align:middle;">
                                      <div style="text-align:center;">

                                          <input id="{{device+'_refresh_ad'}}" class="i-checks" type="checkbox" style="display:none;" name="{{device+'_refresh_ad'}}" [(ngModel)]="flight_model.configuration[device+'_refresh_ad']"/>
                                          <label><span (click)="flight_model.configuration[device+'_enable']?flight_model.configuration[device+'_refresh_ad'] = !flight_model.configuration[device+'_refresh_ad']: ''" class="fa" title="Refresh Ad" style="font-size:20px; cursor:pointer; padding-top:5px;" [ngClass]="{'deviceSelected fa-toggle-on':flight_model.configuration[device+'_refresh_ad'], 'fa-toggle-off': !flight_model.configuration[device+'_refresh_ad'], 'pointer-event-disabled': !flight_model.configuration[device+'_enable']}"></span>
                                          </label>
                                      </div>
                                  </td>
                                  <td>
                                      <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_time'] && flight_model.configuration[device+'_refresh_ad']}" class="width100 form-control width80 display-block" [disabled]="!flight_model.configuration[device+'_refresh_ad'] || !flight_model.configuration[device+'_enable']" placeholder="Time" type="text" name="refresh_time"  [(ngModel)]="flight_model.configuration[device+'_refresh_time']" (ngModelChange)="clearError('flight', device+'_refresh_time')"/>
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_time'] && flight_model.configuration[device+'_refresh_ad']" class="text-danger"> {{ errorModel['flight'].configuration[device+'_refresh_time'] }} </div>
                                  </td>
                                  <td>
                                      <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_cap'] && flight_model.configuration[device+'_refresh_ad']}" class="width100 form-control width80 display-block" [disabled]="!flight_model.configuration[device+'_refresh_ad'] || !flight_model.configuration[device+'_enable']" placeholder="Cap" type="text" name="refresh_cap" [(ngModel)]="flight_model.configuration[device+'_refresh_cap']" (ngModelChange)="clearError('flight', device+'_refresh_cap')"/>
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_cap'] && flight_model.configuration[device+'_refresh_ad']" class="text-danger"> {{ errorModel['flight'].configuration[device+'_refresh_cap'] }} </div>
                                  </td>
                                  <td style="vertical-align:middle;">
                                    <div style="text-align:center;">
                                        <input id="{{device+'_refresh_cutoff'}}" class="i-checks" type="checkbox" style="display:none;" name="{{device+'_refresh_cutoff'}}" [(ngModel)]="flight_model.configuration[device+'_refresh_cutoff']"/>
                                        <label><span (click)="flight_model.configuration[device+'_enable']?flight_model.configuration[device+'_refresh_cutoff'] = !flight_model.configuration[device+'_refresh_cutoff']: ''" class="fa" title="Refresh Cutoff" style="font-size:20px; cursor:pointer; padding-top:5px;" [ngClass]="{'deviceSelected fa-toggle-on':flight_model.configuration[device+'_refresh_cutoff'], 'fa-toggle-off': !flight_model.configuration[device+'_refresh_cutoff'], 'pointer-event-disabled': !flight_model.configuration[device+'_enable']}"></span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <input [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_cutoff_count'] && flight_model.configuration[device+'_refresh_cutoff']}" class="width100 form-control width80 display-block" [disabled]="!flight_model.configuration[device+'_refresh_cutoff'] || !flight_model.configuration[device+'_enable']" placeholder="Cutoff Count" type="text" name="refresh_cutoff_count" [(ngModel)]="flight_model.configuration[device+'_refresh_cutoff_count']" (ngModelChange)="clearError('flight', device+'_refresh_cutoff_count')"/>
                                    <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_refresh_cutoff_count'] && flight_model.configuration[device+'_refresh_cutoff']" class="text-danger"> {{ errorModel['flight'].configuration[device+'_refresh_cutoff_count'] }} </div>
                                </td>
                              </tr>
                          </table>

                          <table class="table align-middle" style = "min-height:80px;" *ngIf="flight_version_value == 1 || flight_version_value == undefined">
                              <tr>
                              <td class="no-bottom vertical-align-middle-imp">
                                  <input class="i-checks" style="display:none;" type="checkbox" [(ngModel)]="flight_model.configuration.sticky_enabled"  name="sticky_enabled"/>
                                  <label class="display-vertical cursor-pointer"> Sticky: </label> <span (click)="flight_model.configuration.sticky_enabled = !flight_model.configuration.sticky_enabled; hasUnsavedValues.flight=true;"
                                      class="fa" title="Sticky" style="font-size:20px; cursor:pointer; padding-top:5px;"
                                      [ngClass]="{'deviceSelected fa-toggle-on':flight_model.configuration.sticky_enabled, 'fa-toggle-off': !flight_model.configuration.sticky_enabled}"></span>

                              </td>
                              <td *ngIf="flight_model.configuration.sticky_enabled" class="no-bottom text-align-center vertical-align-middle-imp">
                                  <label class="cursor-pointer"> <input class="i-checks position-checkbox-center" type="checkbox"
                                      [(ngModel)]="flight_model.configuration.is_sticky_common"  name="is_sticky_common" #is_sticky_common="ngModel" /> Common </label>
                              </td>
                              <td *ngIf="flight_model.configuration.sticky_enabled" [ngClass]="{'devicePaddingZero': flight_model.configuration.is_sticky_common}" class="no-bottom text-align-center vertical-align-middle-imp">
                                  <span *ngFor="let platform of platforms">
                                      <div *ngIf="!flight_model.configuration.is_sticky_common && flight_model.configuration.stick_for.includes(platform)" ></div>
                                      <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected"
                                            (click)="flight_model.configuration.stick_for.indexOf(platform) !== -1?flight_model.configuration.stick_for.splice(flight_model.configuration.stick_for.indexOf(platform), 1):flight_model.configuration.stick_for.push(platform); flight_model.changeColor=true; hasUnsavedValues.flight=true;"
                                            [ngClass]="{'deviceSelected': (flight_model.configuration.stick_for.indexOf(platform) != -1)}"></span>
                                      <div *ngIf="!flight_model.configuration.is_sticky_common && flight_model.configuration.stick_for.includes(platform)" ></div>
                                      <hr *ngIf="platform != 'mobile' && !flight_model.configuration.is_sticky_common" style="margin-top: 0px; margin-bottom: 0px;">
                                  </span>
                              </td>
                              <td *ngIf="flight_model.configuration.sticky_enabled" class="no-bottom sticky-config">
                                  <div style = "margin-top:-20px">
                                          <tr *ngFor="let device of  flight_model.configuration.is_sticky_common?['common'] : deviceTypeList; let i=index">
                                              <td class="no-bottom">
                                                  <label *ngIf="i == 0" class="text-align-center"> Continuous Sticky</label>
                                                  <input style='display:block; margin:10px;' class="i-checks position-checkbox-center" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+ '_stick_until_next_slot']}"  type="checkbox" [(ngModel)]="flight_model.configuration[device+ '_stick_until_next_slot']" name="{{device+ '_stick_until_next_slot'}}" #sticky="ngModel"  (ngModelChange)="clearError('flight', device+ '_stick_until_next_slot');hasUnsavedValues.flight=true;">

                                              </td>
                                              <td class="no-bottom">
                                                  <label *ngIf="i == 0" class="text-align-center"> Stick Height</label>
                                                  <input class="form-control " [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+ '_stick_height']}"  type="text" [(ngModel)]="flight_model.configuration[device+ '_stick_height']" name="{{device+ '_stick_height'}}" #sticky="ngModel"  (ngModelChange)="clearError('flight', device+ '_stick_height');hasUnsavedValues.flight=true;">

                                              </td>
                                              <td class="no-bottom">
                                                  <label *ngIf="i == 0" class="text-align-center"> Offset</label>
                                                  <input class="form-control" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_offset_height']}"  type="text" [(ngModel)]="flight_model.configuration[device+'_offset_height']" name="{{device+'_offset_height'}}" #sticky="ngModel"  (ngModelChange)="clearError('flight', device+'_offset_height');hasUnsavedValues.flight=true;">

                                              </td>
                                              <td class="no-bottom">
                                                  <label *ngIf="i == 0" class="text-align-center"> # Flight Ads Per Slot</label>
                                                  <input class="form-control" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device+'_multisticky']}"  type="text" [(ngModel)]="flight_model.configuration[device+'_multisticky']" name="{{device+'_multisticky'}}" #sticky="ngModel"  (ngModelChange)="clearError('flight', device+'_multisticky');hasUnsavedValues.flight=true;">

                                              </td>
                                          </tr>
                                  </div>
                              </td>
                          </tr>
                          </table>

                          <div>
                              <table class="table table-striped border-r-l align-middle" *ngIf="flight_version_value == 2">
                                  <tr>
                                      <th style="width:6%;">Devices</th>
                                      <th style="width:11%;">Entry Container</th>
                                      <th style="width:6%;">Every Element</th>
                                      <th style="width:6%;">Element Count</th>
                                      <th style="width:8%;">Position</th>
                                      <th style="width:6%;">Max Ad Count</th>
                                      <th>Children to Avoid</th>
                                      <th>Parent to Avoid</th>
                                      <th>Skip Elements</th>
                                      <th style="width:4%;">Action</th>
                                  </tr>
                                  <tr *ngIf="!flight2_value || flight2_value.length == 0">
                                      <td [attr.colspan]="flight2_fields.length+1" class="emptyExclusion">NO FLIGHT 2 CONFIGURATIONS FOUND</td>
                                  </tr>
                                  <tr *ngFor="let value of flight2_value | paginate: flight2Config; let i=index">
                                      <td *ngFor="let field of flight2_fields">
                                      <div *ngIf="field == 'devices'">
                                          <span *ngFor="let platform of platforms">
                                              <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected"
                                                    (click)="value[field].indexOf(platform) !== -1?value[field].splice(value[field].indexOf(platform), 1):value[field].push(platform); flight_model.changeColor=true; hasUnsavedValues.flight=true;"
                                                    [ngClass]="{'deviceSelected': (value[field].indexOf(platform) != -1 && flight_model.configuration[platform[0]+'_enable'])}"></span>
                                          </span>
                                      </div>
                                          <input *ngIf="field != 'devices' && field != 'pos'" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.flight2 && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)] && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)][field] }" (ngModelChange)="clearErrorForFlight2('flight', field, i)" type="text" class="form-control" name={{field}} [(ngModel)]="value[field]" placeholder={{field}}>
                                          <select *ngIf="field != 'devices' && field == 'pos'" [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.flight2 && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)] && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)][field] }" (ngModelChange)="clearErrorForFlight2('flight', field, i)" type="text" class="form-control" name={{field}} [(ngModel)]="value[field]">
                                              <option>before</option>
                                              <option>after</option>
                                              <option>prepend</option>
                                              <option>append</option>
                                              </select>
                                          <div  *ngIf="field != 'devices' && errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.flight2 && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)] && errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)][field]" class="text-danger"> {{ errorModel['flight'].configuration.flight2[((flight2Config.currentPage-1)*flight2Config.itemsPerPage)][field] }} </div>
                                      </td>
                                      <!-- <td>
                                          <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)].expiry_date }" (ngModelChange)="clearErrorForFlightExclusionURL('flight', 'expiry_date', i)" type="date" class="form-control" name="url_path" [(ngModel)]="value['expiry_date']" plsaceholder="path">
                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)].expiry_date"  class="text-danger"> {{ errorModel['flight'] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURL.currentPage-1)*flightExclusionURL.itemsPerPage)].expiry_date}} </div>

                                      </td> -->
                                      <td style="text-align:center;">
                                          <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteFlight2(i)"></span></a>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td [attr.colspan]="flight2_fields.length+1"> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="flight2()">Add New</button>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td [attr.colspan]="flight2_fields.length+1">
                                          <div class="col-sm-7 center-pagination" *ngIf="flight_version_value == 2">
                                              <div class="dataTables_paginate paging_simple_numbers">
                                                  <!-- <pagination-controls [id]="flight2Config.id" [directionLinks]="directionLinks"
                                                      (pageChange)="onFlight2Change($event)">
                                                  </pagination-controls> -->
                                                  <mat-paginator
                                                    [id]="flight2Config.id"
                                                    [length]="maxSize"
                                                    [pageSize]="flight2Config.itemsPerPage"
                                                    (page)="pageEvent = onFlight2Change($event)">
                                                  </mat-paginator>
                                              </div>
                                          </div>
                                      </td>
                                  </tr>
                              </table>


                          </div>

                          <div class="hbuilt cursor-pointer" (click)="isCollapsed.flightExclusionSettings = !isCollapsed.flightExclusionSettings">
                              <div class="panel-tools">
                                  <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.flightExclusionSettings, 'fa-chevron-down': isCollapsed.flightExclusionSettings}"></i></a>
                              </div>
                            Flight Exclusion Settings
                          </div>
                          <div appCollapse= "isCollapsed.flightExclusionSettings" class="panel-body">
                          <table class="table table-striped border-r-l align-middle">
                              <tr>
                                  <th>Exclusion Selector</th>
                                  <th></th>
                                  <th>Expiry Date</th>
                                  <th style="width:4%;">Action</th>
                              </tr>
                              <tr *ngIf="!hasUnsavedValues.isFlightExclusionSelected">
                                  <td colspan='4' class="emptyExclusion">NO EXCLUSION FOUND</td>
                              </tr>
                              <tr *ngFor="let value of flight_exclusion_selector_value | paginate: flightExclusionConfig; let i=index">
                                  <td style="width:70%;">
                                      <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusions && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].selector }" (ngModelChange)="clearErrorForExclusion('flight', 'selector', ((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i)" type="text" class="form-control" name="selector" [(ngModel)]="value['selector']" placeholder="selector">

                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusions && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].selector"  class="text-danger"> {{ errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].selector }} </div>

                                  </td>
                                  <td></td>
                                  <td>
                                      <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusions && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].expiry_date }" (ngModelChange)="clearErrorForExclusion('flight', 'expiry_date', i)" type="date" class="form-control" name="selector" [(ngModel)]="value['expiry_date']" plsaceholder="selector">
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusions && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].expiry_date"  class="text-danger"> {{ errorModel['flight'] && errorModel['flight'].configuration.exclusions[((flightExclusionConfig.currentPage-1)*flightExclusionConfig.itemsPerPage) + i].expiry_date}} </div>

                                  </td>
                                  <td style="text-align:center;">
                                      <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteFlightExclusionSelector(i)"></span></a>
                                  </td>
                              </tr>
                              <tr>
                                  <td colspan=4> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="flightExclusionSelector()">Add New</button>
                                      <div class="col-sm-7 center-pagination">
                                          <div class="dataTables_paginate paging_simple_numbers">
                                              <!-- <pagination-controls [id]="flightExclusionConfig.id"
                                                  [directionLinks]="directionLinks"
                                                  (pageChange)="onFlightExclusionChange($event)">
                                              </pagination-controls> -->
                                              <mat-paginator
                                                [id]="flightExclusionConfig.id"
                                                [length]="maxSize"
                                                [pageSize]="flightExclusionConfig.itemsPerPage"
                                                (page)="pageEvent = onFlightExclusionChange($event)">
                                              </mat-paginator>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </table>
                          <table class="table table-striped border-r-l align-middle">
                              <tr>
                                  <th>Exclusion URL</th>
                                  <th></th>
                                  <th>Expiry Date</th>
                                  <th style="width:4%;">Action</th>
                              </tr>
                              <tr *ngIf="!hasUnsavedValues.isFlightExclusionURLSelected">
                                  <td colspan='4' class="emptyExclusion">NO EXCLUSION URLS FOUND</td>
                              </tr>
                              <tr *ngFor="let value of flight_exclusion_url_value | paginate: flightExclusionURLConfig; let i=index">
                                  <td>
                                      <label style="font-size:14px;">://{{model.url}}/</label>
                                  </td>
                                  <td style="width:70%;">
                                      <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].url_path }" (ngModelChange)="clearErrorForFlightExclusionURL('flight', 'url_path', i)" type="text" class="form-control" name="url_path" [(ngModel)]="value['url_path']" placeholder="path">

                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].url_path"  class="text-danger"> {{ errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].url_path }} </div>

                                  </td>
                                  <td>
                                      <input  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].expiry_date }" (ngModelChange)="clearErrorForFlightExclusionURL('flight', 'expiry_date', i)" type="date" class="form-control" name="url_path" [(ngModel)]="value['expiry_date']" plsaceholder="path">
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.exclusion_urls && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].expiry_date"  class="text-danger"> {{ errorModel['flight'] && errorModel['flight'].configuration.exclusion_urls[((flightExclusionURLConfig.currentPage-1)*flightExclusionURLConfig.itemsPerPage)].expiry_date}} </div>

                                  </td>
                                  <td style="text-align:center;">
                                      <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteFlightExclusionURL(i)"></span></a>
                                  </td>
                              </tr>
                              <tr>
                                  <td colspan=4s> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="flightExclusionURL()">Add New</button>
                                  </td>
                              </tr>
                          </table>
                          <div class="col-sm-7 center-pagination">
                              <div class="dataTables_paginate paging_simple_numbers">
                                  <!-- <pagination-controls [id]="flightExclusionURLConfig.id" [directionLinks]="directionLinks"
                                      (pageChange)="onFlightExclusionURLChange($event)">
                                  </pagination-controls> -->
                                  <mat-paginator
                                    [id]="flightExclusionURLConfig.id"
                                    [length]="maxSize"
                                    [pageSize]="flightExclusionURLConfig.itemsPerPage"
                                    (page)="pageEvent = onFlightExclusionURLChange($event)">
                                  </mat-paginator>
                              </div>
                          </div>

                          </div>

                          <div style="margin-top:20px" class="hbuilt cursor-pointer" (click)="isCollapsed.flightFloors = !isCollapsed.flightFloors">
                              <div class="panel-tools">
                                  <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.flightFloors, 'fa-chevron-down': isCollapsed.flightFloors}"></i></a>
                              </div>
                            Flight Floor Settings
                          </div>
                          <div appCollapse= "isCollapsed.flightFloors" class="panel-body">
                          <table class="table table-striped table-bordered" *ngIf="flight_model.configuration['d_enable'] || flight_model.configuration['t_enable'] || flight_model.configuration['m_enable']">
                              <tr class="text-center">
                                  <th width="16%">Device Floor Setting</th>
                                  <th width="16%">Floor Type</th>
                                  <th width="16%">DF Floor</th>
                                  <th width="16%">AdX Floor</th>
                                  <th width="16%">DF Reduce %</th>
                                  <th width="16%">AdX Reduce %</th>
                              </tr>
                              <tr *ngFor="let device of deviceTypeList" >
                                  <td style="text-align:center;vertical-align:middle;" *ngIf="flight_model.configuration[device[0]+'_enable']">
                                      <label> {{ deviceTypeAdSizeList[device] | upperString }}</label>
                                  </td>
                                  <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                      <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_floor_type']}" class="form-control" type="text" [(ngModel)]="flight_model.configuration[device[0]+'_floor_type']" name="{{device+'_floor_type'}}" #device[0]+_floor_type="ngModel" (ngModelChange)="clearError('flight', device[0]+'_floor_type')">
                                          <option [value]="'site'">Use Site Setting ({{model.floor_type}})</option>
                                          <option [value]="'target'">Target CPM</option>
                                          <option [value]="'hard'">Hard Floor</option>
                                      </select>
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_floor_type']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_floor_type'] }} </div>
                                  </td>
                                  <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                      <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_floor']}" class="form-control"
                                              [(ngModel)]="flight_model.configuration[device[0]+'_df_floor']" name="{{device+'_df_floor'}}" #device[0]+_df_floor="ngModel" (ngModelChange)="clearError('flight', device[0]+'_df_floor')">
                                          <option *ngIf="floors['df_floors'].indexOf(flight_model.configuration[device[0]+'_df_floor']) < 0 && flight_model.configuration[device[0]+'_df_floor'] != undefined" [value]="flight_model.configuration[device[0]+'_df_floor']">{{flight_model.configuration[device[0]+'_df_floor']}}</option>
                                          <option *ngFor="let price of floors['df_floors']" [value]='price'>{{price}}</option>
                                      </select>
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_floor']"  class="text-danger"> {{ errorModel['flight'].configuration[device+'_df_floor'] }} </div>
                                  </td>
                                  <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                      <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_adx_floor']}" class="form-control"
                                              [(ngModel)]="flight_model.configuration[device[0]+'_adx_floor']" name="{{device+'_adx_floor'}}" #device[0]+_adx_floor="ngModel" (ngModelChange)="clearError('flight', device[0]+'_adx_floor')">
                                          <option *ngIf="floors[getFloorType(model.floor_type, flight_model.configuration[device[0]+'_floor_type'])+'_floors'].indexOf(flight_model.configuration[device[0]+'_adx_floor']) < 0 && flight_model.configuration[device[0]+'_adx_floor'] != undefined" [value]="flight_model.configuration[device[0]+'_adx_floor']">{{flight_model.configuration[device[0]+'_adx_floor']}}</option>
                                          <option *ngFor="let price of floors[getFloorType(model.floor_type, flight_model.configuration[device[0]+'_floor_type'])+'_floors']" [value]='price'>{{price}}</option>
                                      </select>
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_adx_floor']"  class="text-danger"> {{ errorModel['flight'].configuration[device[0]+'_adx_floor'] }} </div>
                                  </td>
                                  <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                      <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_reduce_refresh']}" class="form-control"
                                              [(ngModel)]="flight_model.configuration[device+'_df_reduce_refresh']" name="{{device+'_df_reduce_refresh'}}" #device[0]+_df_reduce_refresh="ngModel" (ngModelChange)="clearError('flight', device[0]+'_df_reduce_refresh')">
                                          <option *ngIf="floors['reduce_values'].indexOf(flight_model.configuration[device[0]+'_df_reduce_refresh']) < 0 && flight_model.configuration[device[0]+'_df_reduce_refresh'] != undefined" [value]="flight_model.configuration[device[0]+'_df_reduce_refresh']">{{flight_model.configuration[device[0]+'_df_reduce_refresh']}}</option>
                                          <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                      </select>
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_reduce_refresh']"  class="text-danger"> {{ errorModel['flight'].configuration[device[0]+'_df_reduce_refresh'] }} </div>
                                  </td>
                                  <td *ngIf="flight_model.configuration[device[0]+'_enable']">
                                      <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_df_reduce_refresh']}" class="form-control"
                                              [(ngModel)]="flight_model.configuration[device+'_adx_reduce_refresh']" name="{{device+'_adx_reduce_refresh'}}" #device[0]+_adx_reduce_refresh="ngModel" (ngModelChange)="clearError('flight', device[0]+'_adx_reduce_refresh')">
                                          <option *ngIf="floors['reduce_values'].indexOf(flight_model.configuration[device[0]+'_adx_reduce_refresh']) < 0 && flight_model.configuration[device[0]+'_adx_reduce_refresh'] != undefined" [value]="flight_model.configuration[device[0]+'_adx_reduce_refresh']">{{flight_model.configuration[device[0]+'_adx_reduce_refresh']}}</option>
                                          <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                      </select>
                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration[device[0]+'_adx_reduce_refresh']"  class="text-danger"> {{ errorModel['flight'].configuration[device[0]+'_adx_floor'] }} </div>
                                  </td>
                              </tr>
                          </table>

                          <table class="table table-striped border-r-l align-middle" *ngIf="flight_model.configuration['d_enable'] || flight_model.configuration['t_enable'] || flight_model.configuration['m_enable']">
                              <tr>
                                  <th>Position Number</th>
                                  <th>Floor Type</th>
                                  <th>DF Floors</th>
                                  <th>AdX Floors</th>
                                  <th>DF Reduce %</th>
                                  <th>AdX Reduce %</th>
                                  <th style="width:4%;">Action</th>
                              </tr>
                              <tr *ngIf="!hasUnsavedValues.isFlightPositionFloorsSelected">
                                  <td colspan='7' class="emptyPositionalFloors">NO POSITION FLOORS FOUND</td>
                              </tr>
                              <tr *ngFor="let value of flight_position_floors_value | paginate: flightPositionFloorConfig; let i=index">
                                  <td style="width:25%">
                                      <input style='width:40%;float:left;' [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].position }" (ngModelChange)="clearErrorForPositionFloor('flight', 'position', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)" type="text" class="form-control" name="position" [(ngModel)]="value['position']" placeholder="position">
                                      <div *ngIf="value['position']" style="float:right;text-align:center;width:auto;margin-left:10%;margin-right:10%">
                                          <span *ngFor="let platform of platforms">
                                              <div></div>
                                              <strong class="flight_positions">{{model.pin}}.D{{platform[0].toUpperCase()}}L.{{getFlightSuffix(value['position'])}}</strong>
                                              <div></div>
                                              <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                          </span>
                                      </div>

                                      <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].position"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].position }} </div>

                                  </td>
                                  <td>
                                      <div style="width: 20%; float:left;">
                                          <span *ngFor="let platform of platforms">
                                              <div></div>
                                              <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                              <div></div>
                                              <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                          </span>
                                      </div>
                                      <div style='float:right;width:80%'>
                                          <select  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].floor_type_desktop }" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)" class="form-control" name="floor_type_desktop" [(ngModel)]="value['floor_type_desktop']">
                                              <option [value]="'site'">Use Site Setting ({{model.floor_type}})</option>
                                              <option [value]="'target'">Target CPM</option>
                                              <option [value]="'hard'">Hard Floor</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop }} </div>

                                          <select  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].floor_type_tablet }" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)" class="form-control" name="floor_type_tablet" [(ngModel)]="value['floor_type_tablet']">
                                              <option [value]="'site'">Use Site Setting ({{model.floor_type}})</option>
                                              <option [value]="'target'">Target CPM</option>
                                              <option [value]="'hard'">Hard Floor</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet }} </div>

                                          <select  [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].floor_type_mobile }" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)" class="form-control" name="floor_type_mobile" [(ngModel)]="value['floor_type_mobile']">
                                              <option [value]="'site'">Use Site Setting ({{model.floor_type}})</option>
                                              <option [value]="'target'">Target CPM</option>
                                              <option [value]="'hard'">Hard Floor</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile }} </div>
                                      </div>
                                  </td>
                                  <td>
                                      <div style="width: 20%; float:left;">
                                          <span *ngFor="let platform of platforms">
                                              <div></div>
                                              <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                              <div></div>
                                              <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                          </span>
                                      </div>
                                      <div style='float:right;width:80%'>
                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop }" class="form-control"
                                                  name="df_floor_desktop" [(ngModel)]="value['df_floor_desktop']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['df_floors'].indexOf(value['df_floor_desktop']) < 0 && value['df_floor_desktop'] != undefined" [value]="value['df_floor_desktop']">{{value['df_floor_desktop']}}</option>
                                              <option *ngFor="let price of floors['df_floors']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_desktop }} </div>

                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet }" class="form-control"
                                                  name="df_floor_tablet" [(ngModel)]="value['df_floor_tablet']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['df_floors'].indexOf(value['df_floor_tablet']) < 0 && value['df_floor_tablet'] != undefined" [value]="value['df_floor_tablet']">{{value['df_floor_tablet']}}</option>
                                              <option *ngFor="let price of floors['df_floors']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_tablet }} </div>

                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile }" class="form-control"
                                                  name="df_floor_mobile" [(ngModel)]="value['df_floor_mobile']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['df_floors'].indexOf(value['df_floor_mobile']) < 0 && value['df_floor_mobile'] != undefined" [value]="value['df_floor_mobile']">{{value['df_floor_mobile']}}</option>
                                              <option *ngFor="let price of floors['df_floors']" [value]='price'>{{price}}</option>
                                          </select>
                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_floor_mobile }} </div>
                                      </div>
                                  </td>
                                  <td>
                                      <div style="width: 20%; float:left;">
                                          <span *ngFor="let platform of platforms">
                                              <div></div>
                                              <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                              <div></div>
                                              <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                          </span>
                                      </div>
                                      <div style='float:right;width:80%'>
                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_desktop }" class="form-control"
                                                  name="adx_floor_desktop" [(ngModel)]="value['adx_floor_desktop']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors[getFloorType(model.floor_type, value['floor_type_desktop'])+'_floors'].indexOf(value['adx_floor_desktop']) < 0 && value['adx_floor_desktop'] != undefined" [value]="value['adx_floor_desktop']">{{value['adx_floor_desktop']}}</option>
                                              <option *ngFor="let price of floors[getFloorType(model.floor_type, value['floor_type_desktop'])+'_floors']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_desktop"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_desktop }} </div>

                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_tablet }" class="form-control"
                                                  name="adx_floor_tablet" [(ngModel)]="value['adx_floor_tablet']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors[getFloorType(model.floor_type, value['floor_type_tablet'])+'_floors'].indexOf(value['adx_floor_tablet']) < 0 && value['adx_floor_tablet'] != undefined" [value]="value['adx_floor_tablet']">{{value['adx_floor_tablet']}}</option>
                                              <option *ngFor="let price of floors[getFloorType(model.floor_type, value['floor_type_tablet'])+'_floors']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_tablet"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_tablet }} </div>

                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_mobile }" class="form-control"
                                                  name="adx_floor_mobile" [(ngModel)]="value['adx_floor_mobile']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors[getFloorType(model.floor_type, value['floor_type_mobile'])+'_floors'].indexOf(value['adx_floor_mobile']) < 0 && value['adx_floor_mobile'] != undefined" [value]="value['adx_floor_mobile']">{{value['adx_floor_mobile']}}</option>
                                              <option *ngFor="let price of floors[getFloorType(model.floor_type, value['floor_type_mobile'])+'_floors']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_mobile"  class="text-danger"> {{ errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_floor_mobile }} </div>
                                      </div>
                                  </td>
                                  <td>
                                      <div style="width: 20%; float:left;">
                                          <span *ngFor="let platform of platforms">
                                              <div></div>
                                              <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                              <div></div>
                                              <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                          </span>
                                      </div>
                                      <div style='float:right;width:80%'>
                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_desktop }" class="form-control"
                                                  name="df_reduce_desktop" [(ngModel)]="value['df_reduce_desktop']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['reduce_values'].indexOf(value['df_reduce_desktop']) < 0 && value['df_reduce_desktop'] != undefined" [value]="value['df_reduce_desktop']">{{value['df_reduce_desktop']}}</option>
                                              <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_desktop"  class="text-danger"> {{ errorModel['flight'].position_floors[i].df_reduce_desktop }} </div>

                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_tablet }" class="form-control"
                                                  name="df_reduce_tablet" [(ngModel)]="value['df_reduce_tablet']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['reduce_values'].indexOf(value['df_reduce_tablet']) < 0 && value['df_reduce_tablet'] != undefined" [value]="value['df_reduce_tablet']">{{value['df_reduce_tablet']}}</option>
                                              <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_tablet"  class="text-danger"> {{ errorModel['flight'].position_floors[i].df_reduce_tablet }} </div>

                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_mobile }" class="form-control"
                                                  name="df_reduce_mobile" [(ngModel)]="value['df_reduce_mobile']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['reduce_values'].indexOf(value['df_reduce_mobile']) < 0 && value['df_reduce_mobile'] != undefined" [value]="value['df_reduce_mobile']">{{value['df_reduce_mobile']}}</option>
                                              <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                          </select>
                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].df_reduce_mobile"  class="text-danger"> {{ errorModel['flight'].position_floors[i].df_reduce_mobile }} </div>
                                      </div>
                                  </td>
                                  <td>
                                      <div style="width: 20%; float:left;">
                                          <span *ngFor="let platform of platforms">
                                              <div></div>
                                              <span class="fa fa-{{ platform }} fa-lg df-device-icon deviceSelected flight_positions" #devices></span>
                                              <div></div>
                                              <hr *ngIf="platform != 'mobile'" style="margin-top: 0px; margin-bottom: 0px;">
                                          </span>
                                      </div>
                                      <div style='float:right;width:80%'>
                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_desktop }" class="form-control"
                                                  name="adx_reduce_desktop" [(ngModel)]="value['adx_reduce_desktop']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['reduce_values'].indexOf(value['adx_reduce_desktop']) < 0 && value['adx_reduce_desktop'] != undefined" [value]="value['adx_reduce_desktop']">{{value['adx_reduce_desktop']}}</option>
                                              <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_desktop"  class="text-danger"> {{ errorModel['flight'].position_floors[i].adx_reduce_desktop }} </div>

                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_tablet }" class="form-control"
                                                  name="adx_reduce_tablet" [(ngModel)]="value['adx_reduce_tablet']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['reduce_values'].indexOf(value['adx_reduce_tablet']) < 0 && value['adx_reduce_tablet'] != undefined" [value]="value['adx_reduce_tablet']">{{value['adx_reduce_tablet']}}</option>
                                              <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_tablet"  class="text-danger"> {{ errorModel['flight'].position_floors[i].adx_reduce_tablet }} </div>

                                          <select [ngClass]="{'control-group error': errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_mobile }" class="form-control"
                                                  name="adx_reduce_mobile" [(ngModel)]="value['adx_reduce_mobile']" (ngModelChange)="clearErrorForExclusion('flight', 'p_floor', ((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i)">
                                              <option *ngIf="floors['reduce_values'].indexOf(value['adx_reduce_mobile']) < 0 && value['adx_reduce_mobile'] != undefined" [value]="value['adx_reduce_mobile']">{{value['adx_reduce_mobile']}}</option>
                                              <option *ngFor="let price of floors['reduce_values']" [value]='price'>{{price}}</option>
                                          </select>

                                          <div *ngIf="errorModel['flight'] && errorModel['flight'].configuration && errorModel['flight'].configuration && errorModel['flight'].configuration.position_floors && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i] && errorModel['flight'].configuration.position_floors[((flightPositionFloorConfig.currentPage-1)*flightPositionFloorConfig.itemsPerPage) + i].adx_reduce_mobile"  class="text-danger"> {{ errorModel['flight'].position_floors[i].adx_reduce_mobile }} </div>
                                      </div>
                                  </td>
                                  <td style="text-align:center;">
                                      <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="deleteFlightPositionFloorSelector(i)"></span></a>
                                  </td>
                              </tr>
                              <tr>
                                  <td colspan=7> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="flightPositionFloorSelector()">Add New</button>
                                  </td>
                              </tr>
                          </table>
                          <div class="col-sm-7 center-pagination">
                              <div class="dataTables_paginate paging_simple_numbers">
                                  <!-- <pagination-controls [id]="flightPositionFloorConfig.id"
                                      [directionLinks]="directionLinks"
                                      (pageChange)="onFlightPositionFloorChange($event)">
                                  </pagination-controls> -->
                                  <mat-paginator
                                    [id]="flightPositionFloorConfig.id"
                                    [length]="maxSize"
                                    [pageSize]="flightPositionFloorConfig.itemsPerPage"
                                    (page)="pageEvent = onFlightPositionFloorChange($event)">
                                  </mat-paginator>
                              </div>
                          </div>
                          </div>
                          <table style="margin-top:20px" class="table table-striped table-bordered">
                              <tr>
                                  <td colspan="11">
                                    <button [class.btn-visibility]="hasUnsavedValues.flight" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveFlightData($event)">Save </button>
                                  </td>
                           </tr>
                          </table>
                      </fieldset>
                  </div>
              </div><!-- END lg-12 -->
            </div><!-- END .row -->
            <div *ngIf="showservice['demandfusion'] && products['flight']">
                <app-default [product]="'flight'" [account]='accountUUID' [dfConfiguration]='products.flight.df_configurations' [tag]='products.flight' [hasCustomDfConfiguration]='products.flight.custom_df_configuration' [dfProviders]='providerList.demandfusion.providers'></app-default>
            </div>
          </div>
        </div>

      </div> <!-- Flight END -->

      <!--Video Ads START -->
      <div class="col-lg-12 animate" *ngIf="showservice['video-ads']">

        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.videoads = !isCollapsed.videoads">
            <div class="panel-tools">
              <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.videoads, 'fa-chevron-down': isCollapsed.videoads}"></i></a>
            </div>
                Video Ads
          </div>
          <div appCollapse= "isCollapsed.videoads" class="panel-body" (window:resize)="onResize($event)">
              <app-video-ads [products]="products" [product]="'video-ads'" [account]='accountUUID' [showservice]="showservice" [showCustomDfConfigurationToggle]='true' [dfProviders]='providerList.demandfusion.providers' [dfProviderAdSupport]='dfProviderAdSupport' [mmtAccountNumber]='ac_number' [mmtUUId]='model.uuid' [deviceWidth]='deviceWidth' [accountURL]= 'model.url' [pin]= 'model.pin'></app-video-ads>
          </div>
        </div>
      </div><!--Video Ads END -->

      <!-- Display Ads START -->
      <div class="col-lg-12 animate" *ngIf="showservice['display-ads']">

        <div class="hpanel">
          <div class="panel-heading hbuilt cursor-pointer" (click)="isCollapsed.adstags = !isCollapsed.adstags">
            <div class="panel-tools">
              <a class="showhide" ><i class="fa" [ngClass]="{'fa-chevron-up': !isCollapsed.adstags, 'fa-chevron-down': isCollapsed.adstags}"></i></a>
            </div>
                Defined Ads
          </div>
          <div appCollapse= "isCollapsed.adstags" class="panel-body" (window:resize)="onResize($event)">
              <app-display-ads [products]="products" [product]="'display-ads'" [account]='accountUUID' [showservice]="showservice" [showCustomDfConfigurationToggle]='true' [dfProviders]='providerList.demandfusion.providers' [dfProviderAdSupport]='dfProviderAdSupport' [mmtAccountNumber]='ac_number' [mmtUUId]='model.uuid' [deviceWidth]='deviceWidth' [pin]='model.pin' [floorType] = 'model.floor_type'></app-display-ads>
          </div>
        </div><!--Display Ads END -->
      </div>

      <!-- Activity Tracking URL START -->
      <!-- <div class="row animate">
           <div class="col-lg-12" style="left:14px;padding-right:43px;">
           <div class="hpanel">
           <div class="panel-heading hbuilt cursor-pointer" (click)= "isCollapsedActivityTrackingURL = !isCollapsedActivityTrackingURL;">
           <div class="panel-tools">
           <a class="showhide">
           <i class="fa" [ngClass]="{'fa-chevron-up': isCollapsedActivityTrackingURL, 'fa-chevron-down': !isCollapsedActivityTrackingURL}"></i></a>
           </div>
           Activity Tracking URL
           </div>
           <div appCollapse = "!isCollapsedActivityTrackingURL" class="panel-body">
           <table class="table table-striped border-r-l align-middle">
           <tr>
           <td class="width12percent" (click) = "hasUnsavedValues.accountDetail = true;model.enable_activity_tracking = !model.enable_activity_tracking">
           <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle" [ngClass]="{'deviceSelected fa-toggle-on':model.enable_activity_tracking, 'fa-toggle-off':!model.enable_activity_tracking}"></span>
           <label class = "cursor-pointer"> Activity Tracking Code:
           </label>
           </td>
           <td style="width:80%;">
           <input class="form-control" [ngClass]="{'control-group error': errorModel.activity_tracking_code && errorModel.activity_tracking_code.length}" type="text" [(ngModel)]="model.activity_tracking_code" name="Activity Tracking Code" #activity_tracking_code="ngModel" (ngModelChange)="hasUnsavedValues.accountDetail = true; clearField('site_info','activity_tracking_code');" placeholder = "Activity Tracking Code" [disabled]="!model.enable_activity_tracking">
           <div *ngIf="errorModel.activity_tracking_code && errorModel.activity_tracking_code.length"  class="text-danger"> {{ errorModel.activity_tracking_code }} </div>
           </td>
           <td>
           <div class="form-group" >
           <button  [class.btn-visibility]="hasUnsavedValues.accountDetail"  type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveAccountDetail($event)">Save</button>
           </div>
           </td>
           </tr>
           </table>
           <table class="table table-striped border-r-l align-middle" *ngIf = "model.enable_activity_tracking">
           <tr>
           <th colspan='2'>Activity Tracking URL</th>

           <th>Expiry Date</th>
           <th style="width:4%;">Action</th>
           </tr>
           <tr *ngIf="!hasActivityTrackingURL">
           <td colspan='4' class="emptyExclusion">NO ACTIVITY TRACKING URL FOUND</td>
           </tr>
           <tr *ngFor="let value of activity_tracking_url_value; let activity_index=index">
           <td>
           <label style="font-size:14px;">://{{model.url}}/</label>
           </td>
           <td style="width:73%">
           <input type="text" class="form-control"  [(ngModel)]="value.path" placeholder="Path" [ngClass]="{'control-group error': errorModel['activitytrackingurl'] && errorModel['activitytrackingurl'][activity_index] && errorModel['activitytrackingurl'][activity_index].path}" (ngModelChange)="clearErrorForActivityTrackingURL('activitytrackingurl', 'path', activity_index)">
           <div *ngIf="errorModel['activitytrackingurl'] && errorModel['activitytrackingurl'][activity_index] && errorModel['activitytrackingurl'][activity_index].path"  class="text-danger"> {{ errorModel['activitytrackingurl'][activity_index].path }} </div>
           </td>
           <td>

           <input type="date" class="form-control" [(ngModel)]="value.till_date" [ngClass]="{'control-group error': errorModel['activitytrackingurl'] && errorModel['activitytrackingurl'][activity_index] && errorModel['activitytrackingurl'][activity_index].till_date}" (ngModelChange)="clearErrorForActivityTrackingURL('activitytrackingurl', 'till_date', activity_index)">
           <div *ngIf="errorModel['activitytrackingurl'] && errorModel['activitytrackingurl'][activity_index] && errorModel['activitytrackingurl'][activity_index].till_date"  class="text-danger"> {{ errorModel['activitytrackingurl'][activity_index].till_date }} </div>
           </td>
           <td style="text-align:center;">
           <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue" (click)="activity_tracking_url_value.splice(activity_index, 1); hasActivityTrackingURL=activity_tracking_url_value.length?true:false; hasUnsavedValues['isActivityTrackingURL']=true;"></span></a>
           </td>
           </tr>
           <tr>
           <td colspan='3'> <button type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="addActivityTrackingURL(); hasActivityTrackingURL = true; hasUnsavedValues['isActivityTrackingURL']=true;">Add New</button></td>
           <td> <button  [ngClass]="{'box-shadow-0': !hasUnsavedValues['isActivityTrackingURL']}" [class.btn-visibility]="hasUnsavedValues['isActivityTrackingURL']" type="button" class="btn w-xs btn-sm btn-success pull-right btn-color media-prop" (click)="saveActivityTrackingURL()">Save</button></td>
           </tr>
           </table>
           </div>
           </div>
           </div>
           </div> --> <!-- Activity Tracking URL END -->
    </div>
  </div>
</div>
