import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountDetailService } from '../../shared/services/account-detail.service';
import { GlobalVaribleService, SharedService } from '../../shared/services/index';
import { Accountdetail, DemandFusion, Flight, Flight2, FlightConfiguration, Inimage, AdsTag, AccountAnalysis, Exclusions, CustomAnalysis,
         Highimpact, ExclusionURL, PositionFloors, FlightExclusionURLs} from '../../shared/index';
import { environment } from '../../../environments/environment';
// import { Accountdetail, DemandFusion, Flight, Flight2, FlightConfiguration, Inimage, AdsTag, AccountAnalysis, Exclusions, CustomAnalysis,
//          Config, Highimpact, ExclusionURL, PositionFloors, FlightExclusionURLs} from '../../shared/index';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';
import { ProviderConfigComponent } from './provider-config/provider-config.component';
import { PubsubService } from '../pubsub.service';
import { DisplayAdsComponent } from './display-ads/display-ads.component';
import { PageEvent } from '@angular/material/paginator';
import { PaginationInstance } from '../../shared/pipes/pagination-service';

// import { Clipboard } from 'ts-clipboard';
// import { PaginationService, PaginationInstance } from 'ngx-pagination';
import 'brace';
import 'brace/mode/javascript';
import 'brace/mode/css';
import 'brace/ext/language_tools';
import 'brace/ext/searchbox';
import 'brace/theme/dracula';
@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {
  options: any = {
    enableBasicAutocompletion: true,
    maxLines: Infinity,
    wrapBehavioursEnabled: true, // boolean
    wrap: true,
    enableLiveAutocompletion: true,
    showPrintMargin: false,
    highlightActiveLine: false,
  };
  historyDetails: boolean;
  hide_history = true;
  hide_view = false;
  isCollapsedSiteInformation = true;
  isCollapsedServices = true;
  isCollapsedAdstxtAddition = true;
  isCollapsedExclusionURL = true;
  isCollapsedSiteAnalysis = true;
  hidden: boolean = false;
  show_div: boolean = false;
  btn_loader: boolean = false;
  submitted = false;
  active = true;
  script_view_jump_icon: boolean = false;
  is_sync_loader = false;
  historyCallLoader: boolean = false;
  showTableLoader: boolean;
  dfProviderAdSupport: any = {};
  isExclusionURLSelected: boolean = false;
  stickyEnable:{} = {};
  displayData:any;
  gdprDropdown: boolean = false;
  mode = 'Observable';
  sub: any;
  page:any;
  timestamp:any;
  otbList: any = {};
  deviceWidth: any;
  platforms: Array<string> = ['desktop', 'tablet', 'mobile'];
  public filter: string = '';
  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  pageEvent: any;
  public config: PaginationInstance = {
      id: 'advanced',
      itemsPerPage: 100,
      currentPage: 1,
  };

  public codeConfig: PaginationInstance = {
      id: 'advanced',
      itemsPerPage: 10,
      currentPage: 1,
  };

  public exclusionConfig: PaginationInstance = {
      id: 'exclusive',
      itemsPerPage: 10,
      currentPage: 1,
  };

  public flightPositionFloorConfig: PaginationInstance = {
      id: 'flightPosition',
      itemsPerPage: 5,
      currentPage: 1,
  };

  public flightExclusionConfig: PaginationInstance = {
      id: 'flightExclusion',
      itemsPerPage: 5,
      currentPage: 1,
  };

  public flightExclusionURLConfig: PaginationInstance = {
      id: 'flightExclusionURL',
      itemsPerPage: 5,
      currentPage: 1,
  };

  public flight2Config: PaginationInstance = {
    id: 'flight2',
    itemsPerPage: 5,
    currentPage: 1,
  };
  model = new Accountdetail(null,'','','','',[''],'','',20842576,false,'','',
                            '','','','',false,false,true,false,false,'', '', '',
                            false, false, '', false, '',null,true, 0, false, false, '', '', '', '', '','');
  demandfusion_model = new DemandFusion({is_timeout_common:true, timeout_common:'', timeout_desktop:'', timeout_tablet:'', timeout_mobile:'', console_log:false, mediadotnet:true, otb:''}, 'active');
  inimage_model = new Inimage({ exclusions:[] });
  highimpact_model = new Highimpact([]);
  custom_analysis_model = new CustomAnalysis('','','');
  adstag_model = new AdsTag('','','',{},['']);
  exclusion_url_model = new ExclusionURL('', '', );
//    activity_tracking_url_model = new ActivityTrackingURL('', '',);
  flight_model = new Flight({m_enable: false,t_enable: false, d_enable: false,
                             m_ad_sizes: [], t_ad_sizes: [], d_ad_sizes: [],
                             m_selector:'.entry-content', t_selector:'.entry-content', d_selector:'.entry-content',
                             m_refresh_ad:false, t_refresh_ad:false, d_refresh_ad:false,
                             exclusions:[], custom_dfp:20842576, advertise_with_us:'', enable_uam:'false',
                             m_refresh_time: 30, t_refresh_time: 30, d_refresh_time: 30,
                             m_refresh_cap: 5, t_refresh_cap: 5, d_refresh_cap: 5,
                             m_word_count: 275, t_word_count: 300, d_word_count: 500,
                             m_vertical_pixel: 1000, t_vertical_pixel: 1000,
                             d_vertical_pixel: 1200, m_word_threshold:1200,
                             d_word_threshold: 1500, t_word_threshold: 1200,
                             m_refresh_cutoff: false, t_refresh_cutoff: false,
                             d_refresh_cutoff: false, m_refresh_cutoff_count: 10,
                             t_refresh_cutoff_count: 10, d_refresh_cutoff_count: 10,
                             sticky_enabled: false, stick_for:['desktop','tablet','mobile'],
                             is_sticky_common: true, common_offset_height: '0px',common_stick_height: '',
                             common_multisticky: 1, common_stick_until_next_slot: false,
                             d_offset_height: '0px', d_stick_height: '',
                             d_multisticky: 1, d_stick_until_next_slot: false,
                             t_offset_height: '0px', t_stick_height: '',
                             t_multisticky: 1, t_stick_until_next_slot: false,
                             m_offset_height: '0px', m_stick_height: '',
                             m_multisticky: 1, m_stick_until_next_slot: false,
                             position_floors: [], d_df_floor: '', t_df_floor: '',
                             m_df_floor: '', d_adx_floor: '', t_adx_floor: '',
                             m_adx_floor: '', d_df_reduce_refresh: '', t_df_reduce_refresh: '',
                             m_df_reduce_refresh: '', d_adx_reduce_refresh: '',
                             t_adx_reduce_refresh: '', m_adx_reduce_refresh: '',
                             d_floor_type: 'site', t_floor_type: 'site', m_floor_type: 'site',
                             exclusion_urls: []} as FlightConfiguration);
  errorModel: any = {'flight': this.flight_model,
                    'demandfusion':this.demandfusion_model, 'adstag': this.adstag_model,
                    'inimage': this.inimage_model,
                    'exclusionurl': this.exclusion_url_model,
//                    'activitytrackingurl': this.activity_tracking_url_model,
                   };

  hasUnsavedValues: any = {'customScript': false, 'demandFusion': false,
                          'flight': false, 'flightCustom': false, 'amp': false,
                          'inImageExclusion': false, 'accountDetail': false,
                          'isFlightExclusionSelected': false, 'isInimageExclusionSelected': false,
                          'url': false, 'historyResponse': false, 'otb': false, 'adstxtAddition': false,
                          'account_notes': false, 'isFlightPositionFloorsSelected': false,
                          'isFlightExclusionURLSelected': false //'isActivityTrackingURL': false,
                         };

  data_activity : any = { results: []};
  data_codeshot : any = { results: []};
  flight_exclusion_selector_value: any;
  flight_exclusion_url_value: any;
  flight_position_floors_value: any;
  flight_version_value: any;
  flight2_value: any;
  flight2_fields: Array<string> = ['devices','entry_container', 'every_element', 'element_count', 'pos',
  'max', 'avoid_child', 'avoid_parent', 'skip_el'];
  flight_versions: Array<number> = [1,2]
  inimage_exclusion_selector_value: any;
  url_exclusion_selector_value: any;
  // activity_tracking_url_value: any;
  // hasActivityTrackingURL: boolean = false;
  enable_uam = false;
  ac_number: any;
  script_data: any;
  service: any;
  accountUUID : any;
  analysis_data: any;
  customDFP = false;
  time_stamp: any;
  errorurl:any;
  device_type = [];
  showservice: any = {};
  isCollapsed: any = {};
  tier_history: any = [];
  tier_option: any = {};
  products: any;
  alternate_urls = [];
  ad_size = [];
  floors: any = {};
  deviceTypeList: Array<string> = ['d', 't', 'm'];
  deviceTypeAdSizeList: {} = {'d':'desktop', 't':'tablet', 'm':'mobile'};
  deviceType = ['desktop', 'tablet', 'mobile'];
  advertiseWithUsPosition = ['left', 'center', 'right'];
  providerList: any = {};
  adstxt_additions = [];
  codeshot_url = (document.domain==='tags.monumetric.com'?
                      's3-us-west-2.amazonaws.com/delivery.thebloggernetwork.com/':'s3-us-west-1.amazonaws.com/delivery-dev.thebloggernetwork.com/');

  constructor (private sharedService: SharedService,
               private accountDetailService: AccountDetailService,
               public pubsub : PubsubService,
               private route: ActivatedRoute,
               private router : Router,
              //  private toasterService: ToasterService,
               private globaleVaribleService: GlobalVaribleService) {
            globaleVaribleService.setInitGlogalVaribles();
            this.alternate_urls = [''];
            this.hasUnsavedValues = globaleVaribleService.allFlags;
            this.products = globaleVaribleService.products;
  }

  onSubmit() { this.submitted = true; }

  ngOnInit() {
      this.isCollapsed = {'demandfusion': true, 'flight': true,
                          'highimpact': true, 'inimage': true,
                          'adstags': true, 'history': true,
                          'customcode': true, 'videoads': true,
                          'amp':true, 'codeshotHistory':true,
                          'accountNotes': false, 'SiteAnalysis': true,
                          'flightFloors': true, 'flightExclusionSettings': true};

      let _params: any = this.route.params;
      this.deviceWidth = window.innerWidth;
      this.accountUUID = _params.value.id;
      this.ad_size = ['728x90', '300x250', '160x600', '300x600', '1x1', '320x50', '468x60', '336x280'];
      this.floors = {'target_floors' : ["", "0.15","0.16","0.17","0.18","0.19","0.20","0.21","0.22","0.23","0.24",
                                        "0.25","0.26","0.27","0.28","0.29","0.30","0.31","0.32","0.33","0.34",
                                        "0.35","0.36","0.37","0.38","0.39","0.40","0.41","0.42","0.43","0.44",
                                        "0.45","0.46","0.47","0.48","0.49","0.50","0.51","0.52","0.53","0.54",
                                        "0.55","0.56","0.57","0.58","0.59","0.60","0.61","0.62","0.63","0.64",
                                        "0.65","0.66","0.67","0.68","0.69","0.70","0.71","0.72","0.73","0.74",
                                        "0.75","0.76","0.77","0.78","0.79","0.80","0.81","0.82","0.83","0.84",
                                        "0.85","0.86","0.87","0.88","0.89","0.90","0.91","0.92","0.93","0.94",
                                        "0.95","0.96","0.97","0.98","0.99","1.00","1.01"],
                     'hard_floors' : ["", "0.15","0.16","0.17","0.18","0.19","0.20","0.21","0.22","0.23","0.24",
                                      "0.25","0.26","0.27","0.28","0.29","0.30","0.31","0.32","0.33","0.34",
                                      "0.35","0.36","0.37","0.38","0.39","0.40","0.41","0.42","0.43","0.44",
                                      "0.45","0.46","0.47","0.48","0.49","0.50","0.55","0.60","0.65","0.70",
                                      "0.75","0.80","0.85","0.90","0.95","1.00"],
                     'df_floors' : ["", "0.15","0.16","0.17","0.18","0.19","0.20","0.21","0.22","0.23","0.24",
                                    "0.25","0.26","0.27","0.28","0.29","0.30","0.31","0.32","0.33","0.34",
                                    "0.35","0.36","0.37","0.38","0.39","0.40","0.41","0.42","0.43","0.44",
                                    "0.45","0.46","0.47","0.48","0.49","0.50","0.55","0.60","0.65","0.70",
                                    "0.75","0.80","0.85","0.90","0.95","1.00","1.05","1.10","1.15","1.20",
                                    "1.25","1.30","1.35","1.40","1.45","1.50","1.55","1.60","1.65","1.70",
                                    "1.75","1.80","1.85","1.90","1.95","2.00"],
                     'reduce_values' : ["", "5","10","15","20","25","30","35","40","45","50","55","60","65","70",
                                        "75","80","85","90","95","100"]
                    };
      this.getProductData('demandfusion');
      this.getProductData('inimage-ads');
      this.getProductData('highimpact-ads');
      this.getProductData('flight');
      this.getProductData('amp');
      this.getExclusionData();
      this.getAdstxtAdditions();
  //    this.getActivityTrackingURLData();
      this.clearErrormodel();

      this.sub = this.route
          .queryParams
          .subscribe(params => {
              this.page = +params['page'] || 1;
          });
      this.getactivity(this.page);

      this.route.data
          .subscribe((data: { tierHistory : any }) => {
              this.tier_history = data['tierHistory'];
          });

      this.route.data
          .subscribe((data: { tier : any }) => {
              this.tier_option = data['tier'];
          });

      this.route.data
          .subscribe((data: {productList: any }) => this.productListResponse(data));

      this.route.data
          .subscribe((data: { products: any }) => this.accountProductData(data));

      this.route.data
          .subscribe((data: { accountdetail: any }) =>
                     this.getAccountResponse(data['accountdetail']));

      this.route.data
          .subscribe((data: { codeAnalysis: AccountAnalysis }) =>
                     this.getAnalysisData(data['codeAnalysis']));
  };

  clearErrormodel () {
      this.errorModel= {};
  }

  getactivity(page_number) {
      this.accountDetailService.accountActivity(this.accountUUID, page_number)
          .subscribe(
              data  => this.getaccount(data),
              error =>  this.errorMessage(error));
  }

  onResize(event) {
      this.deviceWidth = event.target.innerWidth;
  }

  onPageChange(page_number: number) {
      this.showTableLoader = true;
      this.config.currentPage = page_number ;
      this.codeConfig.currentPage = page_number;
      this.getAccountHistory(page_number);
  }

  revertHistoryChanges(history){
      if(history.action === 'updated.account'){
          Object.keys(history.data['old']).forEach(val => {
              this.model[val] = history.data['old'][val]['data'];
          });
          this.saveAccountDetail(event);
      } else if(history.action === 'updated.exclusionurl'){
          this.url_exclusion_selector_value = this.convertInDict(history.data['old']['exclusionurl']['data'])
          this.saveExclusionURL(event)
      } else if(history.action === 'updated.adstxt-addition'){
          this.saveAdstxtAdditions(history.data['old']['providers']['data'])
      } else if(history.action === 'updated.service'){
          if(history.data['old']['status']){
              this.service.forEach((option) => {
                  if(option.product_label === history.data['meta']['accountproduct']['label']){
                      this.sharedService.saveProductStatus(option, this.accountUUID, history.data['old']['status']['data'] === 'active')
                          .subscribe(
                              response => this.getProductStatusResponse(response, option,  history.data['old']['status']['data'] === 'active'),
                              error => this.errorMessage = error as any);
                  }
              })
          } else if(history['data']['meta']['accountproduct']['label'] === 'In - Image' && history['data']['old']['exclusions']) {
              this.inimage_exclusion_selector_value = this.convertInDict(history['data']['old']['exclusions']['data'])
              this.saveInimageExclusionData(event);
          }
      } else if(history.message === 'Updated settings for GumGum (inimage-ads)'){
          this.pubsub.imImageProvider.next(history);
      }
      if(history.message === 'Updated service (Demand Fusion)'){
          let ls = ['otb', 'console_log', 'is_timeout_common', 'timeout_common', 'timeout_desktop', 'timeout_mobile', 'timeout_tablet']
          let flag = false;
          if(history['data']['old']['otb']){
              this.otbList['otbProviders'] = history['data']['old']['otb']['data'] ? history['data']['old']['otb']['data'].split(',') : [];
          }
          Object.keys(this.otbList['status']).forEach(key => {
              this.otbList['status'][key] = this.otbList['otbProviders'].includes(key);
          });
          Object.keys(history['data']['old']).forEach(key => {
              if(ls.includes(key)){
                  if(key === 'is_timeout_common' || key === 'console_log'){
                      this.demandfusion_model.configuration[key] = history['data']['old'][key]['data'] === 'true';
                  } else {
                      this.demandfusion_model.configuration[key] = history['data']['old'][key]['data'];
                  }
                  flag = true;
              }
          });
          if(flag){
              this.saveDemandFusionConfigData('demandfusion');
          } else {
              this.pubsub.dfProvider.next(history);
          }
      } else if(history.message === 'Updated service (AMP)'){
          this.pubsub.ampProvider.next(history);
      } else if(history.message.includes('highimpact-ads')){
          this.pubsub.highimpactProvider.next(history);
      } else if(history.message === 'Updated service (Flight)'){
          let hsKeys = Object.keys(history['data']['old']);
          let objKeys = Object.keys(this.flight_model['configuration']);
          let unique = objKeys.filter(function(obj) { return hsKeys.indexOf(obj) != -1; });
          if(unique.length) {
              hsKeys.forEach(key => {
                  if(key === 'custom_df_configuration'){
                      this.customDFP = history['data']['old']['custom_df_configuration']['data'];
                  } else if(key === 'enable_uam'){
                      this.enable_uam = history['data']['old']['enable_uam']['data'] === 'true';
                  } else if(key === 'exclusions'){
                      this.flight_exclusion_selector_value = this.convertInDict(history['data']['old']['exclusions']['data']);
                  } else if(key === 'position_floors'){
                      this.flight_position_floors_value = this.convertInDict(history['data']['old']['position_floors']['data']);
                  } else if(key.includes('ad_sizes')){
                      this.flight_model['configuration'][key] = history['data']['old'][key]['data'] ? history['data']['old'][key]['data'].split(',') : [];
                  } else if(key.endsWith('refresh_cutoff')){
                      this.flight_model['configuration'][key] = history['data']['old'][key]['data'] === 'true';
                  } else if(key === 'sticky_enabled'){
                      this.flight_model['configuration'][key] = history['data']['old'][key]['data'] === 'true';
                  } else if(key === 'is_sticky_common'){
                      this.flight_model['configuration'][key] = history['data']['old'][key]['data'] === 'true';
                  } else {
                      this.flight_model['configuration'][key] = history['data']['old'][key]['data'];
                  }
              });
              this.saveFlightData(event);
          } else {
              this.pubsub.flightProvider.next(history);
          }
      } else if(history.message.includes('Created display ad')) {
          this.pubsub.deleteTag.next(history);
      } else if(history.message.includes('Updated display ad')) {
          this.pubsub.videoProvider.next(history);
      } else if(history.message.includes('Updated service (Defined Ads)')) {
          this.pubsub.dfAdsProvider.next(history);
      }
  }

  convertInDict(strDict){
      let str = strDict.split("'").join('"');
      str = str.split("None").join('null');
      str = str.split("True").join('true');
      str = str.split("False").join('false');
      return JSON.parse(str);
  }

  saveAccountCustomAnalysisDetail(event:any) {
      this.btn_loader = true;
      this.accountDetailService.saveCustomDetail(this.model, this.accountUUID)
          .subscribe(
              data  => this.getdata(data),
              error =>  this.errorMessage(error));// = <any>error)
  }

  saveAccountNotesDetail(event:any) {
      this.btn_loader = true;
      this.accountDetailService.saveAccountNotes(this.model, this.accountUUID)
          .subscribe(
              data  => this.getAccountNotes(data),
              error =>  this.errorMessage(error));// = <any>error)
  }

  changeAccountStatus( obj:any) {
      this.model.account_status = obj.account_status;
      this.saveAccountDetail(event);
  }

  saveAccountDetail(event:any) {
      // if(!this.model.enable_activity_tracking) {
      //     this.model.activity_tracking_code = '';
      // }
      this.btn_loader = true;
      this.accountDetailService.save(this.model, this.accountUUID)
          .subscribe(
              data  => this.getdata(data),
              error =>  this.errorMessage(error));// = <any>error);
  }


  syncAccountDetail(event:any) {
      this.is_sync_loader = true;
      this.accountDetailService.syncDetails(this.accountUUID)
          .subscribe(
              data  => this.getSyncScript(data),
              error =>  this.showErrorMessageSync(error));// = <any>error);
  }

  accountScriptView(event:any) {
      this.script_view_jump_icon = true;
      this.script_data =  (document.domain==='tags.monumetric.com'?'//monu.delivery':'//dev.monu.delivery')
          +'/site/' + this.model.uuid[0] + '/' + this.model.uuid[1] + '/' +
          this.model.uuid.substr(2) + '.js';
      this.script_view_jump_icon = false;
  }

  productCheckedOptions(option:any, event:any) {
      this.sharedService.saveProductStatus(option, this.accountUUID, event.target.checked)
          .subscribe(
              response => this.getProductStatusResponse(response, option, event.target.checked ),
              error => this.errorMessage = <any>error);
  }

  getAccountHistory(page_number) {
      this.config.currentPage = page_number ;
      this.historyCallLoader = true;
      this.sharedService.getHistoryData(this.accountUUID,page_number)
          .subscribe(
              response => this.getHistoryResponse(response),
              error => this.errorMessageActivity(error));
      this.showTableLoader = true;

  }

  getHistoryResponse(data) {
      this.data_activity = data;
      this.historyCallLoader = false;
      this.showTableLoader = false;
      this.config.totalItems = data.meta.number_of_records;
      this.hasUnsavedValues['historyResponse'] = true;
  }



  onCodeshotChange(page: number) {
      this.codeConfig.currentPage = page ;
      this.showTableLoader = true;
      this.getCodeshotHistory(page);
  }

  getCodeshotHistory(page) {
      this.sharedService.getCodeshotHistory(this.accountUUID,page)
          .subscribe(
              response => this.getCodeshotResponse(response),
              error => this.errorMessageCodeshot(error));
      this.showTableLoader = true;

  }

  getCodeshotResponse(data) {
      this.data_codeshot = data;
      this.showTableLoader = false;
      this.codeConfig.totalItems = data.count;
  }

  private errorMessageCodeshot(error:any) {
      this.popToast('error', 'Something went wrong!');
  }

  excludeProvider(provider: any) {
      let index = this.otbList.otbProviders.indexOf(provider.bidder);
      index === -1 ?
          this.otbList.otbProviders.push(provider.bidder) :
          this.otbList.otbProviders.splice(index, 1);
  }

  saveExcludeRefreshProvider(event: any, product?: any) {
      this.hasUnsavedValues['otb'] = false;
      this.saveDemandFusionConfigData('demandfusion');

  }

  saveDemandFusionConfigData(productname:any) {
      this.demandfusion_model.configuration.otb =
          String(this.otbList.otbProviders);
      this.demandfusion_model['df_configurations'] = this.globaleVaribleService['products']['demandfusion']['df_configurations'];
      this.sharedService.saveDemandFusionConfigData(this.demandfusion_model, this.accountUUID,  productname)
          .subscribe(
              data => this.getProductResponse('demandFusion'),
              error => this.showErrorMessage(error, 'demandfusion'));
  }

  saveFlightData(event:any) {
      this.flight_model.configuration['enable_uam'] = this.enable_uam ? this.enable_uam.toString() : 'false';
      // if(!this.customDFP) {
      //     delete this.flight_model.configuration['custom_dfp'];
      // }
      // if(!this.enable_uam) {
      //     delete this.flight_model.configuration['enable_uam'];
      // }
      this.customDFP = (this.flight_model.configuration['custom_dfp'] && this.flight_model.configuration['custom_dfp'] !== 0?true:false);
      this.flight_model.configuration['exclusions'] = this.flight_exclusion_selector_value;
      this.flight_model.configuration['exclusion_urls'] = this.flight_exclusion_url_value;
      this.flight_model.configuration['position_floors'] = this.flight_position_floors_value;
      this.sharedService.saveFlightDataWithExclusion(this.flight_model, this.accountUUID)
          .subscribe(
              data => this.getProductResponse('flight'),
              error => this.showErrorMessage(error, 'flight'));
  }

  saveInimageExclusionData(event:any) {
      this.hasUnsavedValues['inImageExclusion'] = false;
      this.inimage_model.configuration['exclusions'] = this.inimage_exclusion_selector_value;
      this.sharedService.saveInImageDataWithExclusion(this.inimage_model, this.accountUUID)
          .subscribe(
              data => this.getProductResponse('inImageExclusion'),
              error => this.showErrorMessage(error, 'inimage'));
  }

  selectFlightSizeOption(e: any, obj: any, device_type:any, selectList: any) {
      let device = device_type.split('');
      device = device[0];
      obj['configuration'][device + '_ad_sizes'] = obj['configuration'][device + '_ad_sizes'] || [];
      if(obj['configuration'][device + '_ad_sizes'].indexOf(e.target.value) === -1) {
          obj['configuration'][device + '_ad_sizes'].push(e.target.value);
      }
      selectList.selectedIndex = 0;
  }

  deleteFlightSizeOption(size:any, device_type:any) {
      if(device_type === 'desktop') {
          this.flight_model.configuration.d_ad_sizes.splice(this.flight_model.configuration.d_ad_sizes.indexOf(size), 1);
      } else if(device_type === 'tablet') {
          this.flight_model.configuration.t_ad_sizes.splice(this.flight_model.configuration.t_ad_sizes.indexOf(size), 1);
      } else {
          this.flight_model.configuration.m_ad_sizes.splice(this.flight_model.configuration.m_ad_sizes.indexOf(size), 1);
      }
      this.hasUnsavedValues['flight'] = true;
  }

  flightExclusionSelector() {
      if (this.flight_exclusion_selector_value) {
          this.flight_exclusion_selector_value.push(new Exclusions('', ''));
          this.hasUnsavedValues['isFlightExclusionSelected'] = true;
      } else {
          this.flight_exclusion_selector_value = [new Exclusions('', '')];
          this.hasUnsavedValues['isFlightExclusionSelected'] = false;
      }
      let remainder = this.flight_exclusion_selector_value.length % 5 === 0 ? 0 : 1;
      this.flightExclusionConfig.currentPage = (Math.floor(this.flight_exclusion_selector_value.length / 5) + remainder)
      this.hasUnsavedValues['flight'] = true;
  }

  flightExclusionURL() {
      if(this.flight_exclusion_url_value) {
          this.flight_exclusion_url_value.push(new FlightExclusionURLs('', ''));
          this.hasUnsavedValues['isFlightExclusionURLSelected'] = true;
      } else {
          this.flight_exclusion_url_value = [new FlightExclusionURLs('', '')];
          this.hasUnsavedValues['isFlightExclusionURLSelected'] = false;
      }
      const remainder = this.flight_exclusion_url_value.length % 5 === 0 ? 0 : 1;
      this.flightExclusionURLConfig.currentPage = (Math.floor(this.flight_exclusion_url_value.length / 5) + remainder);
      this.hasUnsavedValues['flight'] = true;
  }

  flight2() {
    if(this.flight2_value) {
        this.flight2_value.push(new Flight2('.entry-content', 'p', null, 'before', null, '', '', '', ['desktop','tablet','mobile']));
        this.hasUnsavedValues['isFlight2Selected'] = true;
    } else {
        this.flight2_value = [new Flight2('.entry-content', 'p', null, 'before', null, '', '', '', ['desktop','tablet','mobile'])];
        this.hasUnsavedValues['isFlight2Selected'] = false;
    }
    let remainder = this.flight2_value.length % 5 === 0 ? 0 : 1;
    this.flight2Config.currentPage = (Math.floor(this.flight2_value.length / 5) + remainder);
    this.hasUnsavedValues['flight'] = true;
  }

  deleteFlight2(id: any) {
    let indx = this.flight2Config.currentPage === 1 ? id : (this.flight2Config.currentPage - 1) * 5 + id;
    this.flight2_value.splice(indx,1);
    if (this.flight2_value.length === 0) {
        this.hasUnsavedValues['isFlight2Selected'] = false;
    }
    this.hasUnsavedValues['flight'] = true;
  }

  deleteFlightExclusionSelector(id: any) {
      const indx = this.flightExclusionConfig.currentPage === 1 ? id : (this.flightExclusionConfig.currentPage - 1) * 5 + id;
      this.flight_exclusion_selector_value.splice(indx, 1);
      if (this.flight_exclusion_selector_value.length === 0) {
          this.hasUnsavedValues['isFlightExclusionSelected'] = false;
      }
      this.hasUnsavedValues['flight'] = true;
  }

  deleteFlightExclusionURL(id: any) {
      let indx = this.flightExclusionURLConfig.currentPage === 1 ? id : (this.flightExclusionURLConfig.currentPage - 1) * 5 + id;
      this.flight_exclusion_url_value.splice(indx,1);
      if (this.flight_exclusion_url_value.length === 0) {
          this.hasUnsavedValues['isFlightExclusionURLSelected'] = false;
      }
      this.hasUnsavedValues['flight'] = true;
  }

  getFlightSuffix(pos: any) {
      pos = Number(pos);
      if (pos > 0 && pos <= 26) {
          return String.fromCharCode(pos + 64);
      }
      return '?'
  }

  flightPositionFloorSelector() {
      if (this.flight_position_floors_value) {
          this.flight_position_floors_value.push(new PositionFloors('', '', '', '', '', '', '', '', '', '', '', '', '', 'site', 'site', 'site'));
          this.hasUnsavedValues['isFlightPositionFloorsSelected'] = true;
      } else {
          this.flight_position_floors_value = [new PositionFloors('', '', '', '', '', '', '', '', '', '', '', '', '', 'site', 'site', 'site')];
          this.hasUnsavedValues['isFlightPositionFloorsSelected'] = false;
      }
      let remainder = this.flight_position_floors_value.length % 5 === 0 ? 0 : 1;
      this.flightPositionFloorConfig.currentPage = (Math.floor(this.flight_position_floors_value.length / 5) + remainder)
      this.hasUnsavedValues['flight'] = true;
  }

  deleteFlightPositionFloorSelector(id: any) {
      let indx = this.flightPositionFloorConfig.currentPage === 1 ? id : (this.flightPositionFloorConfig.currentPage - 1) * 5 + id;
      this.flight_position_floors_value.splice(indx, 1);
      if (this.flight_position_floors_value.length === 0) {
          this.hasUnsavedValues['isFlightPositionFloorsSelected'] = false;
      }
      this.hasUnsavedValues['flight'] = true;
  }

  inimageExclusionSelector() {
      if(this.inimage_exclusion_selector_value) {
          this.inimage_exclusion_selector_value.push(new Exclusions('', ''));
          this.hasUnsavedValues['isInimageExclusionSelected'] = true;
      } else {
          this.inimage_exclusion_selector_value = [new Exclusions('', '')];
          this.hasUnsavedValues['isInimageExclusionSelected'] = false;
      }
      this.hasUnsavedValues['inImageExclusion'] = true;
  }

  deleteInimageExclusionSelector(id: any) {
      this.inimage_exclusion_selector_value.splice(id,1);
      if(this.inimage_exclusion_selector_value.length === 0) {
          this.hasUnsavedValues['isInimageExclusionSelected'] = false;
      }
      this.hasUnsavedValues['inImageExclusion'] = true;
  }

  addToUrl(url:any) {
      if (url.value ==='') {
          this.popToast('error', 'Enter valid URL. ');
      }
      this.errorModel = {};
      this.hasUnsavedValues['accountDetail'] = true;

      this.model['alternate_urls'].push(url.value);
      url.value = '';
  }

  addToClip(data:any) {
      // Clipboard.copy(data.value);
      this.popToast('info', 'Copied!');
  }

  deleteToUrl(index) {
      this.errorModel = {};
      this.hasUnsavedValues['accountDetail'] = true;
      this.model['alternate_urls'].splice(index, 1);
  }

  popToast(type_:any, message:any) {
      let type = type_;
      // this.toasterService.pop(type , message);
  }

  clearField(product:string,value:string) {
      if(this.errorModel[value] !== '' ) {
          this.errorModel[value] = '';
      }
      if(product==='site_info') {
          this.hasUnsavedValues['accountDetail'] = true;
      }
  }

  clearConfigError(product:string, device:string) {
      if(this.errorModel[product]) {
          for(let config in this.errorModel[product].configuration) {
              if( (config.split(''))[0] === device ) {
                  this.errorModel[product].configuration[config] = '';
              }
          }
      }
  }

  clearError(product:string, key:string) {
      if(this.errorModel[product]) {
          this.errorModel[product].configuration[key] = '';
      }
      if(product==='flight') {
          this.hasUnsavedValues['flight'] = true;

      }
  }

  // clearErrorForActivityTrackingURL(product:string, key:any, index:number,) {
  //     if(this.errorModel[product] &&
  //        this.errorModel[product][index] && key ==='till_date') {
  //         this.errorModel[product][index]['till_date'] = '';
  //     }
  //     if(this.errorModel[product] &&
  //        this.errorModel[product][index] && key==='path') {
  //         this.errorModel[product][index]['path'] = '';
  //     }
  //     this.hasUnsavedValues['isActivityTrackingURL'] = true;
  // }

  clearErrorForExclusion(product:string, key:any , index:number) {
      if(this.errorModel[product] &&
         this.errorModel[product].configuration &&
         this.errorModel[product].configuration.exclusions &&
         this.errorModel[product].configuration.exclusions[index] && key==='selector') {
          this.errorModel[product].configuration.exclusions[index]['selector'] = '';
      }
      if(this.errorModel[product] &&
         this.errorModel[product].configuration &&
         this.errorModel[product].configuration.exclusions &&
         this.errorModel[product].configuration.exclusions[index] && key==='expiry_date') {
          this.errorModel[product].configuration.exclusions[index]['expiry_date'] = '';
      }
      this.hasUnsavedValues['flight'] = true;
  }

  clearErrorForFlightExclusionURL(product:string, key:any , index:number) {
      if(this.errorModel[product] &&
         this.errorModel[product].configuration &&
         this.errorModel[product].configuration.exclusion_urls &&
         this.errorModel[product].configuration.exclusion_urls[index] && key==='url_path') {
          this.errorModel[product].configuration.exclusion_urls[index]['url_path'] = '';
      }
      if(this.errorModel[product] &&
         this.errorModel[product].configuration &&
         this.errorModel[product].configuration.exclusion_urls &&
         this.errorModel[product].configuration.exclusion_urls[index] && key==='expiry_date') {
          this.errorModel[product].configuration.exclusion_urls[index]['expiry_date'] = '';
      }
      this.hasUnsavedValues['flight'] = true;
  }

  clearErrorForFlight2(product:string, key:any , index:number) {

    for(let field in this.flight2_fields){
        if(this.errorModel[product] &&
            this.errorModel[product].configuration &&
            this.errorModel[product].configuration.flight2 &&
            this.errorModel[product].configuration.flight2[index] && key===field) {
             this.errorModel[product].configuration.flight2[index][field] = '';
         }
    }
    this.hasUnsavedValues['flight'] = true;
  }

  clearErrorForPositionFloor(product:string, key:any , index:number) {
      if(this.errorModel[product] &&
         this.errorModel[product].configuration &&
         this.errorModel[product].configuration.position_floors &&
         this.errorModel[product].configuration.position_floors[index] && key==='position') {
          this.errorModel[product].configuration.position_floors[index]['position'] = '';
      }
      if(this.errorModel[product] &&
         this.errorModel[product].configuration &&
         this.errorModel[product].configuration.position_floors &&
         this.errorModel[product].configuration.position_floors[index] && key==='df_floor_desktop') {
          this.errorModel[product].configuration.position_floors[index]['df_floor_desktop'] = '';
      }
      if(this.errorModel[product] &&
          this.errorModel[product].configuration &&
          this.errorModel[product].configuration.position_floors &&
          this.errorModel[product].configuration.position_floors[index] && key==='df_floor_tablet') {
           this.errorModel[product].configuration.position_floors[index]['df_floor_tablet'] = '';
       }
      if(this.errorModel[product] &&
          this.errorModel[product].configuration &&
          this.errorModel[product].configuration.position_floors &&
          this.errorModel[product].configuration.position_floors[index] && key==='df_floor_mobile') {
           this.errorModel[product].configuration.position_floors[index]['df_floor_mobile'] = '';
       }
      if(this.errorModel[product] &&
          this.errorModel[product].configuration &&
          this.errorModel[product].configuration.position_floors &&
          this.errorModel[product].configuration.position_floors[index] && key==='adx_floor_desktop') {
           this.errorModel[product].configuration.position_floors[index]['adx_floor_desktop'] = '';
       }
      if(this.errorModel[product] &&
          this.errorModel[product].configuration &&
          this.errorModel[product].configuration.position_floors &&
          this.errorModel[product].configuration.position_floors[index] && key==='adx_floor_tablet') {
           this.errorModel[product].configuration.position_floors[index]['adx_floor_tablet'] = '';
       }
      if(this.errorModel[product] &&
          this.errorModel[product].configuration &&
          this.errorModel[product].configuration.position_floors &&
          this.errorModel[product].configuration.position_floors[index] && key==='adx_floor_mobile') {
           this.errorModel[product].configuration.position_floors[index]['adx_floor_mobile'] = '';
       }
      if(this.errorModel[product] &&
          this.errorModel[product].configuration &&
          this.errorModel[product].configuration.position_floors &&
          this.errorModel[product].configuration.position_floors[index] && key==='df_reduce_desktop') {
           this.errorModel[product].configuration.position_floors[index]['df_reduce_desktop'] = '';
       }
      if(this.errorModel[product] &&
           this.errorModel[product].configuration &&
           this.errorModel[product].configuration.position_floors &&
           this.errorModel[product].configuration.position_floors[index] && key==='df_reduce_tablet') {
            this.errorModel[product].configuration.position_floors[index]['df_reduce_tablet'] = '';
        }
      if(this.errorModel[product] &&
           this.errorModel[product].configuration &&
           this.errorModel[product].configuration.position_floors &&
           this.errorModel[product].configuration.position_floors[index] && key==='df_reduce_mobile') {
            this.errorModel[product].configuration.position_floors[index]['df_reduce_mobile'] = '';
        }
      if(this.errorModel[product] &&
           this.errorModel[product].configuration &&
           this.errorModel[product].configuration.position_floors &&
           this.errorModel[product].configuration.position_floors[index] && key==='adx_reduce_desktop') {
            this.errorModel[product].configuration.position_floors[index]['adx_reduce_desktop'] = '';
        }
      if(this.errorModel[product] &&
           this.errorModel[product].configuration &&
           this.errorModel[product].configuration.position_floors &&
           this.errorModel[product].configuration.position_floors[index] && key==='adx_reduce_tablet') {
            this.errorModel[product].configuration.position_floors[index]['adx_reduce_tablet'] = '';
        }
      if(this.errorModel[product] &&
           this.errorModel[product].configuration &&
           this.errorModel[product].configuration.position_floors &&
           this.errorModel[product].configuration.position_floors[index] && key==='adx_floor_mobile') {
            this.errorModel[product].configuration.position_floors[index]['adx_reduce_mobile'] = '';
        }
      this.hasUnsavedValues['flight'] = true;
  }

  clearErrorForExclusionURL(product:string, key:any, index:number,) {
      if(this.errorModel[product] &&
         this.errorModel[product][index] && key ==='till_date') {
          this.errorModel[product][index]['till_date'] = '';
      }
      if(this.errorModel[product] &&
         this.errorModel[product][index] && key==='path') {
          this.errorModel[product][index]['path'] = '';
      }
      this.hasUnsavedValues['url'] = true;
  }

  getTags(displayTags, videoTags) {
      const tags = displayTags.ads;
      const async = this.model.load_async ? " async" : "";
      let content = '************* Start of Head Information *************************\r\n\r\n';
      content += '<script type=\"text/javascript\"' + async + ' src=\"//' +'Config.DELIEVERY_URL'
          +'/site/' + this.model.uuid[0] + '/' + this.model.uuid[1] + '/' +
          this.model.uuid.substr(2) + '.js\" data-cfasync="false"></script>\r\n\r\n';

      content += '************** End of Head Information **************************\r\n\r\n\r\n\r\n';

      content += '************* Start of Body (Widget/Gadget) Information ***********\r\n\r\n\r\n';
      for(let currentTag of tags) {
          let label = '[' + currentTag.placement + '|Pos ' + currentTag.position + '] ';
          if(currentTag.tag_label) {
              label = currentTag.tag_label + ' ';
          }
          for(let dtIndex in this.deviceType) {
              if(currentTag['display_' + this.deviceType[dtIndex]] &&
                 currentTag['dimension_' + this.deviceType[dtIndex]]) {

                  label += this.deviceType[dtIndex][0].toUpperCase() + ':' +
                      currentTag['dimension_' + this.deviceType[dtIndex]] + ' ';
              }
          }
          content += '<!--MONUMETRIC ' + label + 'START-->\r\n\r\n';
          content += '<div id=\"mmt-' + currentTag.uuid +
              '\"></div><script type="text/javascript" data-cfasync="false">$MMT = window.$MMT || {}; $MMT.cmd = $MMT.cmd || [];$MMT.cmd.push(function(){ $MMT.display.slots.push([\"' + currentTag.uuid + '\"]); })</script>\r\n\r\n';
          content += '<!--MONUMETRIC ' + label + ' ENDS-->\r\n\r\n\r\n';
      }
      if (videoTags) {
          let videotags =  videoTags.ads;
          for(let currentTag of videotags) {
              let label = '[' + currentTag.placement + '|Pos ' + currentTag.position + '] ';
              if(currentTag.tag_label) {
                  label = currentTag.tag_label + ' ';
              }
              for(let dtIndex in this.deviceType) {
                  if(currentTag['display_' + this.deviceType[dtIndex]] &&
                     currentTag['dimension_' + this.deviceType[dtIndex]]) {

                      label += this.deviceType[dtIndex][0].toUpperCase() + ':' +
                      currentTag['dimension_' + this.deviceType[dtIndex]] + ' ';
                  }
              }
              content += '<!--MONUMETRIC ' + label + 'START-->\r\n\r\n';
              content += '<div id=\"mmt-' + currentTag.uuid +
                  '\"></div><script type="text/javascript" data-cfasync="false">$MMT = window.$MMT || {}; $MMT.cmd = $MMT.cmd || [];$MMT.cmd.push(function(){ $MMT.video.slots.push([\"' + currentTag.uuid + '\"]); })</script>\r\n\r\n';
              content += '<!--MONUMETRIC ' + label + ' ENDS-->\r\n\r\n\r\n';
          }
      }
      content += '*************** End of Body (Widget/Gadget) Information ***********';
      return content;
  }



  downloadTags() {
      let anchor = document.createElement('a');
      anchor.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.getTags(this.products['display-ads'], this.products['video-ads'])));
      anchor.setAttribute('download', this.model.company_name + ' - MMT Tags '  + ' - ' + (Date.now()) + '.txt');
      anchor.click();

  }

  saveExclusionURL(event:any) {
      for(let exclusion of this.url_exclusion_selector_value) {
          exclusion.till_date=exclusion.till_date===''?null:exclusion.till_date;
      }
      this.exclusion_url_model= this.url_exclusion_selector_value;
      this.sharedService.saveExclusionURL(this.exclusion_url_model, this.accountUUID)
          .subscribe(
              data => this.getExclusionURLResponse(data),
              error => this.showErrorMessage(error, 'exclusionurl'));
  }

  getExclusionData() {
      this.sharedService.getExclusionURL(this.accountUUID)
          .subscribe(
              data => this.getExclusion(data),
              error => this.showErrorMessage(error, 'exclusionurl'));

  }

  sortProviders(providers) {
      providers.sort(function(a, b) {
          if (a.provider < b.provider) return -1;
          if (a.provider > b.provider) return 1;
          return 0;
      });
      return providers
  }

  getAdstxtAdditions() {
      this.hasUnsavedValues['adstxtAddition'] = false;
      this.sharedService.getAdstxtAddition(this.accountUUID)
          .subscribe(
              data => { this.adstxt_additions = this.sortProviders(data.providers) },
              error => this.popToast('error', 'Unable to fetch Ads.txt Additions!'));
  }

 saveAdstxtAdditions(data:any) {
      this.sharedService.saveAdstxtAddition(data, this.accountUUID)
         .subscribe(
             data => { this.adstxt_additions = this.sortProviders(data.providers);
                       this.hasUnsavedValues['adstxtAddition'] = false;
                       this.popToast('success', 'Saved successfully.');
                     },
             error => this.popToast('error', 'Unable to Save Ads.txt Additions!'));
  }

  getExclusion(res:any) {
      this.exclusion_url_model = res;
      this.url_exclusion_selector_value = res;
      this.isExclusionURLSelected = this.url_exclusion_selector_value.length>0?true:false;

  }

  getExclusionURLResponse(data:any) {
      this.hasUnsavedValues['url'] = false;
      this.popToast('success', 'Saved successfully.');

  }

  addExclusionURL() {
      if (this.url_exclusion_selector_value) {
          this.url_exclusion_selector_value.push(new ExclusionURL('', null));
          this.isExclusionURLSelected = true;
      } else {
          this.url_exclusion_selector_value = [new ExclusionURL('', null)];
          this.isExclusionURLSelected = false;
      }
      let remainder = this.url_exclusion_selector_value.length % 10 === 0 ? 0 : 1;
      this.exclusionConfig.currentPage = (Math.floor(this.url_exclusion_selector_value.length / 10) + remainder);
      this.hasUnsavedValues['url'] = true;
  }

  deleteExclusionURL(id: any) {
      let indx = this.exclusionConfig.currentPage === 1 ? id : (this.exclusionConfig.currentPage - 1) * 10 + id;
      this.url_exclusion_selector_value.splice(indx, 1);
      if (this.url_exclusion_selector_value.length === 0) {
          this.isExclusionURLSelected = false;
      }
      this.hasUnsavedValues['url'] = true;
  }

  // addActivityTrackingURL() {
  //     if(this.activity_tracking_url_value) {
  //         this.activity_tracking_url_value.push(new ActivityTrackingURL('', null));
  //         this.hasActivityTrackingURL = true;
  //     } else {
  //         this.activity_tracking_url_value = [new ActivityTrackingURL('', null)];
  //         this.hasActivityTrackingURL = false;
  //     }
  //     this.hasUnsavedValues['isActivityTrackingURL'] = true;
  // }

  //  saveActivityTrackingURL(event:any) {
  //      for(let exclusion of this.activity_tracking_url_value) {
  //          exclusion.till_date=exclusion.till_date===''?null:exclusion.till_date;
  //     }
  //     this.activity_tracking_url_model = this.activity_tracking_url_value;
  //     this.sharedService.saveActivityTrackingURL(this.activity_tracking_url_model, this.accountUUID)
  //         .subscribe(
  //             data => this.getActivityTrackingURLResponse(data),
  //             error => this.showErrorMessage(error, 'activitytrackingurl'));
  // }


  // getActivityTrackingURLData() {
  //     this.sharedService.getActivityTrackingURL(this.accountUUID)
  //         .subscribe(
  //             data => this.getActivityTrackingURL(data),
  //             error => this.showErrorMessage(error, 'activitytrackingurl'));
  // }

  // getActivityTrackingURL(res:any) {
  //     this.activity_tracking_url_model = res;
  //     this.activity_tracking_url_value = res;
  //     this.hasActivityTrackingURL = this.activity_tracking_url_value.length>0?true:false;
  // }

  // getActivityTrackingURLResponse(data:any) {
  //     this.hasUnsavedValues['isActivityTrackingURL'] = false;
  //     this.popToast('success', 'Saved successfully.');
  // }

  private getProductResponse(value) {
      this.hasUnsavedValues[value] = false;
      this.hasUnsavedValues['flightCustom'] = false;
      this.popToast('success', 'Saved successfully.');
  }

  private getaccount(data:any) {
      this.config.totalItems = data.meta.number_of_records;
  }

  private getProductData(product:string, ) {
      this.sharedService.getProductData(this.accountUUID, product)
          .subscribe(
              response => this.setProductDetail(product, response),
              error => this.errorMessage = <any>error);
  }

  private doProductStatusActiveInactive(product:any) {
      if(this.products[product] && this.products[product].providers) {
          for(let provider_data of this.products[product].providers) {
              provider_data.status = provider_data.status==='active'?true:false;
              if(product === 'highimpact-ads' && provider_data.status === false) {
                  provider_data.enable_device.push('desktop','mobile','tablet');
              }
          }
      }
  }

  private setProductDetail(product:string, details:any) {
      this.products[product] = details;
      if(product === 'demandfusion') {
          this.getDemandFusionResponse(this.products['demandfusion']);
      } else if(product === 'flight') {
          this.getFlightResponse(this.products['flight']);
      } else if(product === 'inimage-ads') {
          this.getInImage();
      }else if(product === 'amp') {
          details['custom_df_configuration'] = true;
      } else if(product === 'highimpact-ads') {
          this.getHighImpact(this.products['highimpact-ads']);
      }
  }

  private getAccountResponse(res:any) {
      this.model = res;
      this.ac_number = this.model.ac_number;
      this.timestamp = res.flight_imps_timestamp;
      this.model.tier = this.tier_history[0].tier.id;
  }

  generateFlags(){
      Object.keys(this.providerList).forEach((val) => {
          if(val != 'inimage-ads' && val != 'highimpact-ads'){
              this.globaleVaribleService.providerListForFlags[val] = JSON.parse(JSON.stringify(this.providerList['demandfusion'].providers));
          }
      });
  }

  private productListResponse(res:any) {
      for(let product of res.productList) {
          this.providerList[product.key] = product;
      }
      for(let provider of this.providerList['demandfusion'].providers) {
          this.dfProviderAdSupport[provider.name] = [provider.supported_ad_type];
      }
      this.generateFlags();
  }

  private getDemandFusionResponse(res:any) {
      this.demandfusion_model.configuration.is_timeout_common = res.configuration.is_timeout_common==='true'?true:false;
      this.demandfusion_model.configuration.timeout_common = res.configuration.timeout_common?res.configuration.timeout_common:'1000';
      this.demandfusion_model.configuration.timeout_desktop = res.configuration.timeout_desktop?res.configuration.timeout_desktop:'1000';
      this.demandfusion_model.configuration.timeout_tablet = res.configuration.timeout_tablet?res.configuration.timeout_tablet:'1000';
      this.demandfusion_model.configuration.timeout_mobile = res.configuration.timeout_mobile?res.configuration.timeout_mobile:'1000';
      this.demandfusion_model.configuration.console_log = res.configuration.console_log==='true'?true:false;
      this.demandfusion_model.configuration.mediadotnet = res.configuration.mediadotnet==='false'?false:true;
      this.otbList['providers'] = {};
      this.otbList['otbProviders'] = [];
      this.otbList['status'] = {}
      let itemToSort = [];
      for(let provider of res.providers) {
          itemToSort.push(provider.name);
          this.otbList['providers'][provider.name] = provider;
          if(res.configuration.otb && res.configuration.otb.length > 0) {
              if(res.configuration.otb.split(',').indexOf(provider.bidder) != -1) {
                  this.otbList['otbProviders'].push(provider.bidder);
                  this.otbList['status'][provider.bidder] = true;
              } else {
              this.otbList['status'][provider.bidder] = false;
              }
          }
      }
      this.otbList['sortedOrder'] = itemToSort.sort();
      if(res.configuration.otb === undefined || res.configuration.otb.length === 0) {
          this.otbList['otbProviders'] = [];
      }
  }

  private getFlightResponse(res) {
      this.customDFP = res.configuration['custom_dfp'] && res.configuration['custom_dfp'] !== 0?true:false;
      this.enable_uam = res.configuration['enable_uam'] && res.configuration['enable_uam'] === 'true'?true:false;
      //this.advertiseWithUS = res.configuration['advertise_with_us']==='true'?true:false;
      for(let device of this.deviceTypeList) {
          res.configuration[device+'_enable'] = res.configuration[device+'_enable'] === 'true'?true:false;
      }
      this.flight_model = (res.status === 'active')?res: this.flight_model;
      let flightAdSizes = ['d_ad_sizes', 'm_ad_sizes', 't_ad_sizes'];
      let flightRefreshAd = ['d_refresh_ad', 'm_refresh_ad', 't_refresh_ad'];
      let flightRefreshCutoff = ['d_refresh_cutoff', 'm_refresh_cutoff', 't_refresh_cutoff'];
      this.flight_model.configuration['d_floor_type'] = res.configuration['d_floor_type'] || 'site';
      this.flight_model.configuration['m_floor_type'] = res.configuration['m_floor_type'] || 'site';
      this.flight_model.configuration['t_floor_type'] = res.configuration['t_floor_type'] || 'site';
      for(let size of flightAdSizes) {
          this.flight_model.configuration[size] = res.configuration[size]?res.configuration[size].split(','):[];
      }
      for(let device of flightRefreshAd) {
          this.flight_model.configuration[device] = res.configuration[device] === 'true'?true:false;
      }
      for(let device of flightRefreshCutoff) {
          this.flight_model.configuration[device] = res.configuration[device] === 'true'?true:false;
      }
      let stickyFields = [{'key':'sticky_enabled','default':false, 'parser':JSON.parse},
                                {'key':'stick_for', 'default':'["desktop","tablet","mobile"]', 'parser':JSON.parse},
                                {'key':'is_sticky_common', 'default':true, 'parser':JSON.parse},
                                {'key':'common_offset_height','default':'0px'},
                                {'key':'common_stick_height', 'default':''},
                                {'key':'common_multisticky', 'default': 1},
                                {'key':'common_stick_until_next_slot', 'default': false,'parser':JSON.parse},
                                {'key':'d_offset_height','default':'0px'},
                                {'key':'d_stick_height','default':''},
                                {'key':'d_multisticky', 'default':1},
                                {'key':'d_stick_until_next_slot', 'default': false, 'parser':JSON.parse},
                                {'key':'t_offset_height','default':'0px'},
                                {'key':'t_stick_height','default':''},
                                {'key':'t_multisticky','default':1},
                                {'key':'t_stick_until_next_slot', 'default': false, 'parser':JSON.parse},
                                {'key':'m_offset_height', 'default':'0px'},
                                {'key':'m_stick_height', 'default':''},
                                {'key':'m_multisticky', 'default':1},
                                {'key':'m_stick_until_next_slot', 'default': false, 'parser':JSON.parse},]
      for (let field of stickyFields){
          let value = res.configuration[field['key']] || field['default'];
          if(field['parser']){
              value = field['parser'](value)
          }
          this.flight_model.configuration[field['key']] = value;
      }
      let ex = this.products['flight'].configuration.exclusions;
      let exclusion = ex?JSON.parse(ex.replace(/'/g, '"')):[];
      this.flight_exclusion_selector_value = exclusion;
      this.hasUnsavedValues['isFlightExclusionSelected'] = (this.flight_exclusion_selector_value.length>0?true:false);

      let exu = this.products['flight'].configuration.exclusion_urls;
      let exclusion_urls = exu?JSON.parse(exu.replace(/'/g, '"')):[];
      this.flight_exclusion_url_value = exclusion_urls;
      this.hasUnsavedValues['isFlightExclusionURLSelected'] = (this.flight_exclusion_url_value.length>0?true:false);

      let fpf = this.products['flight'].configuration.position_floors;
      let flightPosFloors = fpf?JSON.parse(fpf.replace(/'/g, '"')):[];
      this.flight_position_floors_value = flightPosFloors;
      this.hasUnsavedValues['isFlightPositionFloorsSelected'] = (this.flight_position_floors_value.length>0?true:false);
  }

  private getInImage() {
      this.doProductStatusActiveInactive('inimage-ads');
      let ex = this.products['inimage-ads'].configuration.exclusions;
      let exclusion = ex?JSON.parse(ex.replace(/'/g, '"')):[];
      this.inimage_exclusion_selector_value = exclusion;
      this.hasUnsavedValues['isInimageExclusionSelected'] = (this.inimage_exclusion_selector_value.length>0?true:false);
      for(let provider of this.products['inimage-ads'].providers) {
          for(let conf of provider.configuration) {
              if(conf.data_type === 'boolean') {
                  conf.value = conf.value === 'true'?true:false;
              }
              if(conf.key === 'gumgum_id' && conf.value !== '') {
                  this.stickyEnable[provider.name] = true;
              }
          }
      }
  }

  private getHighImpact(res:any) {
      if(this.products['highimpact-ads']['providers']) {
          this.highimpact_model = this.products['highimpact-ads']['providers'];
      }

      this.doProductStatusActiveInactive('highimpact-ads');
  }

  private getProductStatusResponse(serviceresponse:any, option:any, value:any) {
      if( serviceresponse.status === 'active') {
          this.showservice[option.product] = true;
      } else {
          this.showservice[option.product] = false;}
  }

  private accountProductData(response:any) {
      this.service = response['details'];
      for(let service of response['details']) {
          if(service.status === 'inactive') {
              this.showservice[service.product] = false;
          } else {
              this.showservice[service.product] = true; }
      }
      console.log('showService', this.showservice);
  }

  private getAnalysisData(response: any) {
      this.analysis_data = response;
  }
  public setUAM(): void {
      this.enable_uam=!this.enable_uam;
      this.flight_model.configuration['enable_uam'] = this.enable_uam;
      // if(this.enable_uam) {
      //     this.flight_model.configuration['enable_uam'] = 'true';
      // }
  }
  private getdata(data:any) {
      this.btn_loader = false;
      this.popToast('success', 'Changes have been saved successfully!');
      this.ac_number = data.ac_number;
      this.show_div = true;
      this.hasUnsavedValues['accountDetail'] = false;
      this.hasUnsavedValues['customScript'] = false;
  }

  private getAccountNotes(data:any){
      this.btn_loader = false;
      this.popToast('success', 'Changes have been saved successfully!');
      this.ac_number = data.ac_number;
      this.show_div = true;
      this.hasUnsavedValues['accountNotes'] = false;
  }

  private getSyncScript(data:any) {
      this.popToast('success', 'Synced Successfully.');
      this.is_sync_loader = false;
  }

  private showErrorMessage(error:any, product: string) {
      this.popToast('error', 'Please fix errors !');
      this.errorModel[product] = error;
      this.btn_loader = false;
      if(product === 'demandFusion')
          this.hasUnsavedValues['demandFusion'] = true;
      else if(product === 'flight')
          this.hasUnsavedValues['flight'] = true;
      else if(product === 'exclusionurl')
          this.hasUnsavedValues['url'] = true;
      this.hasUnsavedValues['flightCustom'] = false;
  }

  private errorMessageActivity(error:any) {
      this.historyCallLoader = false;
  }

  private errorMessage(error:any) {
      this.errorModel = error;
      this.errorurl = error.alternate_urls;
      if ( this.errorurl  ) {
          this.popToast('error', 'Invalid Domain.Please enter correct URL');
      } else if (error.uam_error) {
          this.popToast('error', error.uam_error);
      } else {
          this.popToast('error', 'Please fix errors below'); }
      this.btn_loader = false;
      this.historyCallLoader = false;
      this.hasUnsavedValues['accountDetail'] = true;
      this.hasUnsavedValues['customScript'] = true;
  }

  private showErrorMessageSync(error:any) {
      this.popToast('error', 'Can Not Sync.');
      this.btn_loader = false;
      this.is_sync_loader = false;
  }

  getFloorType(site_type, flight_type) {
      if (flight_type === site_type || flight_type === 'site' || !flight_type) {
          return site_type
      }
      return flight_type
  }

  onExclusionURLChange(pageEvent: any): void {
    const pageNumber: any = pageEvent.pageIndex + 1;
    this.exclusionConfig.currentPage = pageNumber;
  }

  onFlightPositionFloorChange(pageEvent: any): void {
    const pageNumber: any = pageEvent.pageIndex + 1;
    this.flightPositionFloorConfig.currentPage = pageNumber;
  }

  onFlightExclusionChange(pageEvent: any): void {
    const pageNumber: any = pageEvent.pageIndex + 1;
    this.flightExclusionConfig.currentPage = pageNumber;
  }

  onFlightExclusionURLChange(pageEvent: any): void {
    const pageNumber: any = pageEvent.pageIndex + 1;
    this.flightExclusionURLConfig.currentPage = pageNumber;
  }

  onFlight2Change(pageEvent: any): void {
    const pageNumber: any = pageEvent.pageIndex + 1;
    this.flight2Config.currentPage = pageNumber;
  }

  getNotification(): void {
    // TODO: What does this even do? It existed in the template, but not in the AccountDetail Class itself.
    console.log('This is not being used');
  }

}
