import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'accountFilter'
})

export class AccountFilterPipe implements PipeTransform {
    transform(value: any, filter: string): any {
    filter = filter ? filter.toLocaleLowerCase() : null;
    return filter ? value.filter((blog: any) =>
				 blog.company.toLocaleLowerCase().indexOf(filter) !== -1) : value;
}
}
