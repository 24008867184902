import { Company } from './index';

export class Account {
    id:string;
    company:any | Company ;
    site_id:any;
    updated:string;
    wp_plugin:string;
    url: string;
    inventory_name:string;
    status: string;

}
