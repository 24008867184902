// Feel free to extend this interface
// depending on your app specific config.
export interface EnvConfig {
    API?: string;
    ENV?: string;
    HOST?: string;
    DELIEVERY_URL?: string;
}

// export const Config: EnvConfig = JSON.parse('<%= ENV_CONFIG %>');
export const Config: EnvConfig = {};
