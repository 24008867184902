<div class="row">
  <div style="border-top-width:0px;padding-left: 16px;padding-right: 16px;" class="col-sm-12">
      <label>Tag Label</label>
      <div class="hpanel panel-collapse">
          <input class="form-control width80 display-block" #flightExclude style="width:100%;"
                 placeholder="Enter Tag Label value" type="text" [(ngModel)]="tag.tag_label" name="tag_label" #exclude="ngModel"
                 (ngModelChange)="tag.changeColor=true;tag.error.tag_label=''" />
          <div *ngIf="tag.error && tag.error.tag_label" class="text-danger"> {{ tag.error.tag_label}} </div>
      </div>
  </div>
  <div *ngIf="tag.ad_type == 'video' || tag.ad_type == 'Video'"
      style="border-top-width:0px;padding-left: 16px;padding-right: 16px;" class="col-sm-12">
      <label>Label</label>
      <div class="hpanel panel-collapse">
          <input class="form-control width80 display-block" #flightExclude style="width:100%;"
              placeholder="Enter Label value" type="text" [(ngModel)]="tag.label" name="label" #exclude="ngModel"
              (ngModelChange)="tag.changeColor=true;tag.error.label=''" />
          <div *ngIf="tag.error && tag.error.label" class="text-danger"> {{ tag.error.label}} </div>
      </div>
  </div>
  <table class="table" style="margin-bottom:0px;">
      <tr>
          <td style="border-top-width:0px;padding:7px 16px 0px 16px;"
              [ngClass]="{'col-sm-3' : tag.ad_type === 'Video' && tag.token, 'col-sm-4' : !tag.token}">
              <div>
                  <span (click)="toggleCustonAdUnit()">
                      <label class="cursor-pointer vertical-align-middle"
                          style="margin-top:0px;margin-bottom:0px;">Custom Ad Unit</label>
                      <span style="font-size:20px; cursor:pointer; display:inline;" class="fa vertical-align-middle"
                          [ngClass]="{'deviceSelected fa-toggle-on': customAdUnit , 'fa-toggle-off': !customAdUnit}"></span>
                      <input style="display:none;" type="checkbox" />
                  </span>
              </div>
              <div>
                  <input class="form-control" style="display:inline; margin-top:4px;"
                      (focusout)="this.customAdUnit = this.tag.custom_ad_unit==='' || this.tag.custom_ad_unit === null?false:true;"
                      type="textbox" placeholder="Enter custom ad unit" [disabled]="!customAdUnit"
                      [(ngModel)]="tag.custom_ad_unit"
                      (ngModelChange)="tag.changeColor=true;tag.error.custom_ad_unit={};" />
                  <div *ngIf="tag.error.custom_ad_unit && tag.error.custom_ad_unit[0]" class="text-danger">
                      {{ tag.error.custom_ad_unit[0] }} </div>
              </div>


          </td>
          <td style="border-top-width:0px;padding-left: 16px;padding-right: 16px;"
              [ngClass]="{'col-sm-3' : tag.ad_type === 'Video' && tag.token, 'col-sm-4' : !tag.token}">
              <label>Exclude</label>
              <div class="hpanel panel-collapse">
                  <input class="form-control width80 display-block" #flightExclude style="width:100%;"
                      placeholder="Enter exclude value" type="text" [(ngModel)]="tag.exclusion_selector"
                      name="exclude" #exclude="ngModel" (ngModelChange)="tag.changeColor=true;" />
              </div>
          </td>
          <td style="border-top-width:0px;padding-right:8px;width:33%">
              <label>Weight</label>
              <input class="form-control width80 display-block" style="width:100%;" type="text"
                  [(ngModel)]="tag.weight" name="weight" #weight="ngModel" (ngModelChange)="tag.changeColor=true;" />
          </td>
          <!-- <td *ngIf="tag.ad_type == 'video' && tag.token" style="border-top-width:0px;padding-right:15px;width:33%">
               <label>Connatix Token</label>
               <input class="form-control width80 display-block" style="width:95%;" placeholder="Token" type="text" [(ngModel)] ="tag.token" name="token" #token="ngModel" (ngModelChange)="tag.changeColor=true;tag.error.token={};" readonly/>
               <span class="glyphicon glyphicon-copy fa-lg btnhoverblue" style="vertical-align: middle;"  title="Copy Token" style="cursor: pointer;" aria-hidden="false" (click) = "addToClip(tag.token)"></span> -->
          <!-- <div [ngClass]="{'control-group error': tag.error.token && tag.error.token.length}" *ngIf="tag.error.token && tag.error.token[0]"  class="text-danger" > {{ tag.error.token[0] }}</div> -->
          <!-- </td> -->
  </table>
  <div class="col-sm-4 placement">
      <label>Placement</label>
      <div>
          <select class="form-control" [(ngModel)]="tag.placement"
              (ngModelChange)="tag.changeColor=true;tag.error.placement={};">
              <option value="" disabled selected hidden>Select Placement</option>
              <option *ngFor="let fold of adTagOption.folds" [value]=fold>{{ fold }}</option>
          </select>
          <!--<div *ngIf="tag.error.placement" > {{ tag.error| json }} </div>-->
          <div *ngIf="tag.error.placement && tag.error.placement[0]" class="text-danger"> {{ tag.error.placement[0] }}
          </div>
      </div>
  </div>
  <div class="col-sm-4">
      <label>Position</label>
      <select class="form-control" [(ngModel)]="tag.position" name="position" #position="ngModel"
          (ngModelChange)="tag.changeColor=true;">
          <option value="" disabled selected hidden>Select Position</option>
          <option [value]="pos" *ngFor="let pos of adTagOption.position">{{ pos }}</option>
      </select>
  </div>
  <div class="col-sm-4 hpanel" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
      <label>Branding</label>

      <select class="form-control" [(ngModel)]="tag.branding" name="branding" #branding="ngModel"
          (ngModelChange)="tag.changeColor=true;">
          <option value="" disabled selected hidden>Select Branding</option>
          <option [value]="brand.id" *ngFor="let brand of adTagOption.branding">{{ brand.label }}</option>
      </select>
  </div>

  <div class="col-sm-4 hpanel" *ngIf="tag.ad_type == 'Video' || tag.ad_type =='video'">
      <label>Aspect Ratio</label>
      <select class="form-control" [(ngModel)]="tag.configuration.aspect_ratio" name="aspect_ratio"
          #aspect_ratio="ngModel" (ngModelChange)="tag.changeColor=true;">
          <option value="" disabled selected hidden>Select Aspect Ratio</option>
          <option *ngFor="let ar of adTagOption.aspectRatio">{{ ar }}</option>
      </select>
  </div>

  <div class="col-sm-4 placement" *ngIf="tag.ad_type == 'Video' || tag.ad_type =='video'">
      <label>Player Style</label>
      <select class="form-control" [(ngModel)]="tag.configuration.player_style" name="player_style"
          #player_style="ngModel" (ngModelChange)="tag.changeColor=true;">
          <option value="" disabled selected hidden>Select Player Style</option>
          <option *ngFor="let ar of adTagOption.playerStyle">{{ ar }}</option>
      </select>
  </div>

  <!-- <div style="display:inline; padding-top:21px" class="col-sm-3" *ngIf="tag.ad_type == 'Video' || tag.ad_type =='video'">
       <span (click)="tag.changeColor=true; tag.configuration.enable_spotx=!tag.configuration.enable_spotx;" class="pad-t" style="margin-left:20px; margin-right:10px; float: left;">
       <span class="fa " title="Enable SpotX" style="font-size:20px; cursor:pointer; margin-top:8px;" [ngClass]="{'deviceSelected fa-toggle-on': tag.configuration.enable_spotx, 'fa-toggle-off': !tag.configuration.enable_spotx}"></span>
       <label class="display-vertical cursor-pointer">Enable SpotX</label>
       </span>
       <input class="form-control" style="display:inline; margin-top:4px;width:50%;margin-bottom: 10px;" type="textbox" placeholder="SpotX ID" [(ngModel)]="tag.configuration.spotx_id"/>
       </div> -->

  <div style="display:inline;padding-top:21px" class="col-sm-5"
      *ngIf="tag.ad_type == 'Video' || tag.ad_type =='video'">
      <span (click)="setAutoplay(tag); tag.changeColor=true;" class="pad-t col-xs-4"
          (ngModelChange)="tag.changeColor=true;">
          <span class="fa " title="Autoplay" style="font-size:20px; cursor:pointer; margin-top:8px;"
              [ngClass]="{'deviceSelected fa-toggle-on': tag.configuration.auto_play, 'fa-toggle-off': !tag.configuration.auto_play}"></span>
          <label class="display-vertical cursor-pointer">Autoplay</label>
      </span>
      <span (click)="setSoundMute(tag); tag.changeColor=true;" class="pad-t col-xs-4"
          (ngModelChange)="tag.changeColor=true;">
          <span class="fa " title="Sound Mute" style="font-size:20px; cursor:pointer; margin-top:8px;"
              [ngClass]="{'deviceSelected fa-toggle-on': tag.configuration.sound_mute, 'fa-toggle-off': !tag.configuration.sound_mute}"></span>
          <label class="display-vertical cursor-pointer">Sound Mute</label>
      </span>
      <span (click)="setLoopPlayback(tag); tag.changeColor=true;" class="pad-t col-xs-4"
          (ngModelChange)="tag.changeColor=true;">
          <span class="fa " title="Loop Playback" style="font-size:20px; cursor:pointer; margin-top:8px;"
              [ngClass]="{'deviceSelected fa-toggle-on': tag.configuration.loop_playback, 'fa-toggle-off': !tag.configuration.loop_playback}"></span>
          <label class="display-vertical cursor-pointer">Loop Playback</label>
      </span>
  </div>


  <div class="col-sm-2 position-bottom-8 hpanel" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
      <label class="position-label" style="margin-bottom:12px;">Positioning</label>
      <div>
          <label class="weight-style cursor-pointer margin-bottom-zero">
              <input type="checkbox" name="service" [(ngModel)]="tag.positioning" style="vertical-align: text-bottom;"
                  (ngModelChange)="tag.changeColor=true;"><span style="margin-left:5px;">Center</span>
          </label>
      </div>
  </div>

  <div class="col-sm-2 position-top-8" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
      <div style="display:inline;" class="div-height col-sm-7">
          <span (click)="tag.is_repeatable=!tag.is_repeatable; tag.changeColor=true;"
              style="margin-right:10px; float:left" (ngModelChange)="tag.changeColor=true;tag.error['is_repeatable']=[]">
              <span class="fa " title="Repeatable" style="font-size:20px; cursor:pointer; margin-top:8px;"
                  [ngClass]="{'deviceSelected fa-toggle-on': tag.is_repeatable, 'fa-toggle-off': !tag.is_repeatable}"></span>
              <label class="display-vertical cursor-pointer">Repeatable</label>
          </span>
      </div>
      <div style="text-align:center; margin-bottom:12px;" (ngModelChange)="tag.error['is_repeatable']=[]"
          *ngIf="tag.error.is_repeatable && tag.error.is_repeatable[0]" style="right: 18%; padding-bottom: 12px; text-align: center;" class="col-sm-12 text-danger">
          {{ tag.error.is_repeatable[0] }}
      </div>
  </div>

  <div class="col-sm-2 position-top-8" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
      <div style="display:inline;" class="div-height col-sm-10">
          <span (click)="setUam(tag);tag.changeColor=true;" style="margin-right:10px; float: left">
              <span class="fa " title="Enable UAM" style="font-size:20px; cursor:pointer; margin-top:8px;"
                  [ngClass]="{'deviceSelected fa-toggle-on': tag.configuration.enable_uam, 'fa-toggle-off': !tag.configuration.enable_uam}"></span>
              <label class="display-vertical cursor-pointer">Enable UAM</label>
          </span>
          <!-- <input class="form-control" style="display:inline; margin-top:4px;width:25%;margin-bottom: 10px;" type="textbox" placeholder="Pub ID" [(ngModel)]="tag.configuration.uam_public_id"/> -->
      </div>
  </div>

  <!-- [ngClass]="{'linebreak':(tag.ad_type=='Video' || tag.ad_type=='video')}" -->
  <div class="col-sm-3 position-top-8" *ngIf="tag.ad_type != 'Video' && tag.ad_type != 'video'">
      <div style="display:inline;" class="div-height col-sm-7">
          <span (click)="setLineBreak(tag); tag.changeColor=true;" style="margin-left:20px; float: right;"
              (ngModelChange)="tag.changeColor=true;">
              <span class="fa " title="Line Break" style="font-size:20px; cursor:pointer; margin-top:8px;"
                  [ngClass]="{'deviceSelected fa-toggle-on': tag.isLineBreakEnable, 'fa-toggle-off': !tag.isLineBreakEnable}"></span>
              <label class="display-vertical cursor-pointer">Line Break</label>
          </span>
      </div>
      <div *ngIf="tag.isLineBreakEnable" style="display:inline;" class="col-sm-5">
          <select class="form-control" [(ngModel)]="tag.line_break" (ngModelChange)="tag.changeColor=true;">
              <option *ngFor="let linebreak of adTagOption.line_break" [value]="linebreak">{{ linebreak }}</option>
          </select>
      </div>
  </div>
  <div class="col-sm-3 position-top-8" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
      <div style="display:inline; padding-top:6px;" class="div-height col-sm-7">
          <span (click)="setAdvertiseWithUs(tag); tag.changeColor=true;" style="margin-left:20px; float: right;">
              <span class="fa " title="Advertise with us" style="font-size:20px; cursor:pointer; margin-top:2px;"
                  [ngClass]="{'deviceSelected fa-toggle-on': tag.advertise_with_us, 'fa-toggle-off': !tag.advertise_with_us}"></span>
              <label class="display-vertical cursor-pointer">Advertise with us</label>
          </span>
      </div>
      <div *ngIf="tag.advertise_with_us" style="display:inline; padding:0;" class="col-sm-5">
          <select class="form-control" [(ngModel)]="tag.advertise_with_us" (ngModelChange)="tag.changeColor=true;">
              <option *ngFor="let position of adTagOption.advertiseWithUsPosition" [value]="position">{{ position }}
              </option>
          </select>
      </div>
      <div style="text-align:center; margin-bottom:12px;"
          *ngIf="tag.error.advertise_with_us && tag.error.advertise_with_us[0]" class="col-sm-12 text-danger">
          {{ tag.error.advertise_with_us[0] }} </div>
  </div>
  <div class="col-md-12">
      <table class="table table-striped table-bordered">
          <tr>
              <th style="width:8%;">Devices</th>
              <th style="width:15%;">I-Pin</th>
              <th>Dimensions</th>
          </tr>
          <tr *ngFor="let device of adTagOption.deviceType">
              <td>
                  <label class="cursor-pointer"><input [(ngModel)]="tag['display_'+device]"
                          class="i-checks cursor-pointer" type="checkbox" name="{{device+'_enableType'}}"
                          (ngModelChange)="tag.error['dimension_'+device]=[]" /> {{ device | upperString }} </label>
              </td>
              <td>
                  {{pin}} / <input class="form-control" style="display:inline; margin-top:4px;width:68%"
                      type="textbox" placeholder="Enter I-PIN"
                      [readonly]="tag['ipin_'+device].edit == 'False' || !tag['display_'+device]"
                      [(ngModel)]="tag['ipin_'+device].ipin" />
              </td>
              <td>
                  <div class="ads-size-for-flight">
                      <div class="hpanel panel-collapse" *ngFor="let size of tag['dimension_' + device]">
                          <div class="panel-heading right5px flight-opt">
                              {{ size }} <a class="closebox"
                                  [ngClass]="{'pointer-event-disabled': !tag['display_'+device]}"
                                  (click)="tag['display_'+device]?tag['dimension_' + device].splice(tag['dimension_' + device].indexOf(size), 1):'';tag.changeColor=true;"><i
                                      class="fa fa-times"></i></a>

                          </div>
                      </div>
                      <div class="hpanel panel-collapse ">
                          <select class="form-control fligt-opt" [disabled]="!tag['display_'+device]"
                              (change)="tag['dimension_' + device].indexOf($event.target.value)===-1?tag['dimension_' + device].push($event.target.value):'';adSelectList.selectedIndex=0;tag.error={};tag.changeColor=true;"
                              #adSelectList>
                              <option selected disabled>select size</option>
                              <option [value]="adsize" *ngFor="let adsize of adTagOption.size"
                                  [hidden]="(adsize=='640x480' || adsize=='400x300') && tag.ad_type == 'Display'">
                                  {{ adsize }}</option>
                          </select>
                          <div *ngIf="tag.error['dimension_'+device]" class="text-danger">
                              {{  tag.error['dimension_'+device] }} </div>
                      </div>
                      <div class="clear"></div>
                  </div>
              </td>
          </tr>
      </table>
  </div>
  <div *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'" class="col-md-12">
      <label>Tag Floor Settings</label>
      <table class="table table-striped table-bordered">
          <tr>
              <th style="width:10%;" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">Devices</th>
              <th style="width:15%;" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">Floor Type</th>
              <th style="width:12%;" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">Custom Floor</th>
              <th style="width:12%;" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">DF Floor</th>
              <th style="width:12%;" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">AdX Floor</th>
              <th style="width:12%;" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">Reduce Floor</th>
              <th style="width:12%;" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">DF %</th>
              <th style="width:12%;" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">AdX %</th>
          </tr>
          <tr *ngFor="let device of adTagOption.deviceType">
              <td>
                  <label>{{ device | upperString }} </label>
              </td>
              <td style="text-align:center" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
                  <select class="form-control" [(ngModel)]="tag['floor_type_'+device]" name="floor_type"
                  (ngModelChange)="tag.changeColor=true;">
                  <option [value]="'site'">Use Site Setting ({{floorType}})</option>
                  <option [value]="'target'">Target CPM</option>
                  <option [value]="'hard'">Hard Floor</option>
                  </select>
              </td>
              <td style="text-align:center" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
                  <input id="{{'floor_'+device}}" class="i-checks"
                      style="font-size:20px; cursor:pointer; margin-top:8px;" type="checkbox" style="display:none"
                      name="{{'floor_'+device}}" [(ngModel)]="tag['floor_'+device]" />
                  <span (click)="toggleUseFloors(device)" class="fa" title="Custom Floor"
                      style="font-size:20px; cursor:pointer;vertical-align: inherit;"
                      [ngClass]="{'deviceSelected fa-toggle-on': tag['floor_'+device], 'fa-toggle-off': !tag['floor_'+device], 'pointer-event-disabled': !tag['display_'+device]}"></span>
              </td>
              <td *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
                  <select [disabled]="!tag['floor_'+device] || !tag['display_'+device]" class="form-control"
                          [(ngModel)]="tag['df_floor_'+device]" name="{{'df_floor_'+device}}">
                      <option *ngIf="adTagOption.df_floors.indexOf(tag['df_floor_'+device]) < 0 && tag['df_floor_'+device] != undefined" [value]="tag['df_floor_'+device]">{{tag['df_floor_'+device]}}</option>
                      <option *ngFor="let price of adTagOption.df_floors" [value]='price'>{{price}}</option>
                  </select>
              </td>
              <td *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
                  <select [disabled]="!tag['floor_'+device] || !tag['display_'+device]" class="form-control"
                          [(ngModel)]="tag['adx_floor_'+device]" name="{{'adx_floor_'+device}}">
                      <option *ngIf="adTagOption[getFloorType(floorType, tag['floor_type_'+device])+'_floors'].indexOf(tag['adx_floor_'+device]) < 0 && tag['adx_floor_'+device] != undefined" [value]="tag['adx_floor_'+device]">{{tag['adx_floor_'+device]}}</option>
                      <option *ngFor="let price of adTagOption[getFloorType(floorType, tag['floor_type_'+device])+'_floors']" [value]='price'>{{price}}</option>
                  </select>
              </td>
              <td style="text-align:center" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
                  <input id="{{'reduce_floor_'+device}}" class="i-checks"
                      [disabled]="!tag['floor_'+device]"
                      style="font-size:20px; cursor:pointer; margin-top:8px;" type="checkbox" style="display:none"
                      name="{{'reduce_floor_'+device}}" [(ngModel)]="tag['reduce_floor_'+device]" />
                  <span (click)="tag['reduce_floor_'+device]=!tag['reduce_floor_'+device]" class="fa" title="Reduce Floor"
                      style="font-size:20px; cursor:pointer;vertical-align: inherit;"
                      [ngClass]="{'deviceSelected fa-toggle-on': tag['reduce_floor_'+device], 'fa-toggle-off': !tag['reduce_floor_'+device], 'pointer-event-disabled': !tag['display_'+device]}"></span>
              </td>
              <td *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
                  <select [disabled]="!tag['reduce_floor_'+device] || !tag['display_'+device] || !tag['floor_'+ device]" class="form-control"
                          [(ngModel)]="tag['df_reduce_floor_'+device]" name="{{'df_reduce_floor_'+device}}">
                      <option *ngIf="adTagOption.reduce_values.indexOf(tag['df_reduce_floor_'+device]) < 0 && tag['df_reduce_floor_'+device] != undefined" [value]="tag['df_reduce_floor_'+device]">{{tag['df_reduce_floor_'+device]}}</option>
                      <option *ngFor="let rv of adTagOption.reduce_values" [value]='rv'>{{rv}}</option>
                  </select>
              </td>
              <td *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
                  <select [disabled]="!tag['reduce_floor_'+device] || !tag['display_'+device] || !tag['floor_'+ device]" class="form-control"
                          [(ngModel)]="tag['adx_reduce_floor_'+device]" name="{{'adx_reduce_floor_'+device}}">
                      <option *ngIf="adTagOption.reduce_values.indexOf(tag['adx_reduce_floor_'+device]) < 0 && tag['adx_reduce_floor_'+device] != undefined" [value]="tag['adx_reduce_floor_'+device]">{{tag['adx_reduce_floor_'+device]}}</option>
                      <option *ngFor="let rv of adTagOption.reduce_values" [value]='rv'>{{rv}}</option>
                  </select>
              </td>
          </tr>
      </table>
  </div>
  <div class="col-md-12" style="padding:0px;margin-bottom:22px;"
      *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
      <div class="col-sm-2" style="margin-top:10px;">
          <label class="cursor-pointer display-vertical" style="margin-right:5px; margin-bottom:6px;">
              <input id=refresh_ad class="i-checks" type="checkbox" [(ngModel)]="tag.refresh_ad" style="display:none;"
                  name="refresh_ad" #refresh_ad="ngModel" />Refresh Ad:
              <span class="fa" title="Refresh Ad" style="font-size:20px; cursor:pointer;vertical-align: inherit;"
                  [ngClass]="{'deviceSelected fa-toggle-on':tag.refresh_ad ,'fa-toggle-off':!tag.refresh_ad}"></span>
          </label>
          <div style="display:inline;"
              *ngIf="tag.refresh_ad && (tag.ad_type == 'Display' || tag.ad_type =='display')">
              <span *ngFor="let device of adTagOption.deviceType">
                  <span class="fa fa-{{ device }} cursor-pointer"
                      (click)="tag.refresh_for.indexOf(device) !== -1?tag.refresh_for.splice(tag.refresh_for.indexOf(device), 1):tag.refresh_for.push(device); tag.changeColor=true;"
                      [ngClass]="{'deviceSelected': (tag.refresh_for.indexOf(device) != -1)}"
                      style="font-size: 16px;"></span>
              </span>
          </div>
      </div>
      <div class="col-sm-2" style="margin-top:10px; display:inline" *ngIf="tag.refresh_ad">
          <label class="cursor-pointer display-vertical" style="margin-right:5px; margin-bottom:6px;">
              <input id=refresh_ad class="i-checks" type="checkbox" [(ngModel)]="tag.manual_refresh"
                  style="display:none;" name="manual_refresh" #manual_refresh="ngModel" />Manual Refresh:
              <span class="fa" title="Manual Refresh" style="font-size:20px; cursor:pointer;vertical-align: inherit;"
                  [ngClass]="{'deviceSelected fa-toggle-on':tag.manual_refresh ,'fa-toggle-off':!tag.manual_refresh}"></span>
          </label>
      </div>
      <div class="col-sm-2">
          <label>Refresh Time:</label>
          <input [disabled]="!tag.refresh_ad || tag.manual_refresh" [(ngModel)]="tag.refresh_ad_time"
              class="form-control width80 display-block" style="width:50%;" placeholder="Refresh Time" type="text"
              name="refresh_time" (ngModelChange)="tag.changeColor=true;" />
      </div>
      <div class="col-sm-2">
          <label>Refresh Cap:</label>
          <input [disabled]="!tag.refresh_ad" [(ngModel)]="tag.refresh_ad_cap"
              class="form-control width80 display-block" style="width:50%;" placeholder="Refresh Cap" type="text"
              name="refresh_time" (ngModelChange)="tag.changeColor=true" />
      </div>
  </div>
  <div class="col-md-12" style="padding:0px;margin-bottom:22px;"
      *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
      <div class="col-sm-2" style="margin-top:10px; display:inline" *ngIf="tag.refresh_ad">
          <label class="cursor-pointer display-vertical" style="margin-right:5px; margin-bottom:6px;">
              <input id="refresh_cutoff" class="i-checks" type="checkbox" [(ngModel)]="tag.refresh_cutoff"
                  style="display:none;" name="refresh_cutoff" #refresh_cutoff="ngModel" />Use Cutoff:
              <span class="fa" title="Use Cutoff" style="font-size:20px; cursor:pointer;vertical-align: inherit;"
                  [ngClass]="{'deviceSelected fa-toggle-on':tag.refresh_cutoff ,'fa-toggle-off':!tag.refresh_cutoff}"></span>
          </label>
      </div>
      <div class="col-sm-2" style="margin-top:10px; display:inline" *ngIf="tag.refresh_cutoff">
          <label class="cursor-pointer display-vertical" style="margin-right:5px; margin-bottom:6px;">
              <input id="common_cutoff" class="i-checks" type="checkbox" [(ngModel)]="tag.common_cutoff"
                  style="display:none;" name="common_cutoff" #common_cutoff="ngModel" />Common Cutoff:
              <span class="fa" title="Common Cutoff" style="font-size:20px; cursor:pointer;vertical-align: inherit;"
                  [ngClass]="{'deviceSelected fa-toggle-on':tag.common_cutoff ,'fa-toggle-off':!tag.common_cutoff}"></span>
          </label>
      </div>
          <div *ngIf="tag.common_cutoff && tag.refresh_cutoff">
              <div class="col-sm-2" *ngIf="tag.refresh_ad">
                  <label>Common Cutoff Count:</label>
                  <input [disabled]="!tag.refresh_cutoff" [(ngModel)]="tag.common_cutoff_count"
                      class="form-control width80 display-block" style="width:50%;" placeholder="Common Cutoff Count" type="text"
                      name="common_cutoff_count" (ngModelChange)="tag.changeColor=true" />
              </div>
          </div>
          <div *ngIf="!tag.common_cutoff && tag.refresh_cutoff">
              <div class="col-sm-2" *ngIf="tag.refresh_ad">
                  <label>Desktop Cutoff Count:</label>
                  <input [disabled]="!tag.refresh_cutoff" [(ngModel)]="tag.desktop_cutoff_count"
                      class="form-control width80 display-block" style="width:50%;" placeholder="Desktop Cutoff Count" type="text"
                      name="desktop_cutoff_count" (ngModelChange)="tag.changeColor=true" />
              </div>
              <div class="col-sm-2" *ngIf="tag.refresh_ad">
                  <label>Tablet Cutoff Count:</label>
                  <input [disabled]="!tag.refresh_cutoff" [(ngModel)]="tag.tablet_cutoff_count"
                      class="form-control width80 display-block" style="width:50%;" placeholder="Tablet Cutoff Count" type="text"
                      name="tablet_cutoff_count" (ngModelChange)="tag.changeColor=true" />
              </div>
              <div class="col-sm-2" *ngIf="tag.refresh_ad">
                  <label>Mobile Cutoff Count:</label>
                  <input [disabled]="!tag.refresh_cutoff" [(ngModel)]="tag.mobile_cutoff_count"
                      class="form-control width80 display-block" style="width:50%;" placeholder="Mobile Cutoff Count" type="text"
                      name="mobile_cutoff_count" (ngModelChange)="tag.changeColor=true" />
              </div>
          </div>
      </div>
  <div class="col-sm-2 marginTop6 marginBottom6" *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'">
      <label class="display-vertical;" style="margin-right:5px;cursor:pointer">
          <input id=notification class="i-checks cursor-pointer" type="checkbox" [(ngModel)]="tag.notification"
              style="display:none;" name="notification" #notification="ngModel" />Notification:
          <span class="fa fa-info" style="font-size:20px; cursor:pointer;vertical-align:middle;"
              [ngClass]="{'deviceSelected fa-toggle-on':tag.notification, 'fa-toggle-off': !tag.notification}"></span>
      </label>
  </div>
  <div class="col-sm-4 marginTop6"
      *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display' || tag.ad_type == 'Video' || tag.ad_type == 'video'">
      <span (click)="setStickyAd(tag); tag.changeColor=true;">
          <label class="display-vertical cursor-pointer">Sticky:</label>
          <span class="fa " title="Sticky Ad" style="font-size:20px; cursor:pointer;"
              [ngClass]="{'deviceSelected fa-toggle-on': tag.isStickyEnable, 'fa-toggle-off': !tag.isStickyEnable}">
          </span>
      </span>
      <div *ngIf="tag.isStickyEnable && (tag.ad_type == 'Display' || tag.ad_type =='display' || tag.ad_type == 'Video' || tag.ad_type == 'video')"
          style="display:inline; margin-left:10px;" class="sticky-radio">
          <div style="display:inline; margin-right:10px;">
              <span *ngFor="let device of adTagOption.deviceType">
                  <span class="fa fa-{{ device }} cursor-pointer"
                      (click)="tag.stick_for.indexOf(device) !== -1?tag.stick_for.splice(tag.stick_for.indexOf(device), 1):tag.stick_for.push(device); tag.changeColor=true;"
                      [ngClass]="{'deviceSelected': (tag.stick_for.indexOf(device) != -1)}"
                      style="font-size: 16px;"></span>
              </span>
          </div>

          <div *ngIf="tag.ad_type == 'Video' || tag.ad_type =='video'" style="display:inline;vertical-align: middle;">
              <select class="form-control" [(ngModel)]="tag.sticky" (ngModelChange)="tag.changeColor=true;" style="display:inline;vertical-align: middle;width:300px;margin-bottom:10px;">
                  <option *ngFor="let sticky_ad of adTagOption.sticky" [value]="sticky_ad.toLowerCase()">{{ sticky_ad }}
                  </option>
              </select>
          </div>
          <div *ngIf="tag.ad_type == 'Display' || tag.ad_type =='display'" style="display:inline;vertical-align: middle;">
              <div *ngFor="let sticky_ad of adTagOption.sticky" style="display:inline;vertical-align: middle;">
                  <input type="radio" name={{tag.id}} [checked]="tag.sticky==sticky_ad.toLowerCase()" [value]="sticky_ad"
                         (change)="tag.sticky=sticky_ad.toLowerCase();" style="display:inline;">
                  <span (click)="tag.sticky=sticky_ad.toLowerCase();tag.changeColor=true;"
                        style="margin-right:10px; vertical-align: text-bottom;"
                        class="cursor-pointer">{{ sticky_ad }}</span>
              </div>
          </div>
      </div>
  </div>
  <div class="col-sm-3 padleft0" *ngIf="tag.isStickyEnable && tag.sticky == 'sidebar' && (tag.ad_type == 'Display' || tag.ad_type =='display')">
      <div class="col-sm-6" style="padding-left:0px;">
          <input class="form-control width80 display-block" style="width:100%;"
              [(ngModel)]="tag.sticky_configuration['offset']" type="text" name="offset"
              (ngModelChange)="tag.changeColor=true;" />
          <span class="floating-label">Offset</span>
      </div>
      <div class="col-sm-6" style="padding-left:0px;">
          <input class="form-control inputText width80 display-block"
              [(ngModel)]="tag.sticky_configuration['footer-element']" style="width:100%;" type="text"
              name="Footer-Element" (ngModelChange)="tag.changeColor=true;" />
          <span class="floating-label">Footer-Element</span>
      </div>
  </div>
  <div class="col-sm-3 padleft0" *ngIf="tag.isStickyEnable && (tag.sticky == 'bottom-left' || tag.sticky == 'bottom-right' || tag.sticky == 'top-left' || tag.sticky == 'top-right') && (tag.ad_type == 'Video' || tag.ad_type =='video')">
      <div class="col-sm-12" style="padding-left:0px;">
          <input class="form-control width80 display-block" style="width:100%;"
              [(ngModel)]="tag.sticky_configuration['offset']" type="text" name="offset"
              (ngModelChange)="tag.changeColor=true;" />
          <span class="floating-label">Offset</span>
      </div>
   </div>
  <div class="col-sm-3 padleft0" *ngIf="tag.isStickyEnable && tag.sticky == 'header' && (tag.ad_type == 'Display' || tag.ad_type =='display')">
      <div class="col-sm-6">
          <span>Time:</span>
          <input [(ngModel)]="tag.sticky_configuration['stick-time']" class="form-control width80 display-block"
              style="width:60%;" placeholder="In sec" type="text" name="stick_time"
              (ngModelChange)="tag.changeColor=true;" />
      </div>
      <div class="col-sm-6">
          <span>Offset:</span>
          <input [(ngModel)]="tag.sticky_configuration['offset']" class="form-control width80 display-block"
              style="width:50%;" placeholder="offset" type="text" name="Offset"
              (ngModelChange)="tag.changeColor=true;" />
      </div>
  </div>
  <div class="col-sm-3 padleft0" *ngIf="tag.isStickyEnable && tag.sticky == 'pillar' && (tag.ad_type == 'Display' || tag.ad_type =='display')">
      <div class="col-sm-6">
          <span>Pillar Stick Side:</span>
          <select class="form-control" [(ngModel)]="tag.sticky_configuration['stickSide']" name="stickSide" #stickSide="ngModel"
          (ngModelChange)="tag.changeColor=true;">
          <option value="" disabled selected hidden>Pillar Side</option>
          <option [value]="side" *ngFor="let side of adTagOption.pillar_sides">{{ side }}</option>
      </select>
          <!-- <input [(ngModel)]="tag.sticky_configuration['stickSide']" class="form-control width80 display-block"
              style="width:50%;" placeholder="stickSide" type="text" name="stickSide"
              (ngModelChange)="tag.changeColor=true;" /> -->
      </div>
  </div>
  <div *ngIf="tag.isStickyEnable && (tag.ad_type == 'Display' || tag.ad_type =='display' || tag.ad_type == 'Video' || tag.ad_type == 'video')"
      class="col-sm-3 cursor-pointer" style="margin-top: 6px;"
      (click)="tag.enable_close_button = !tag.enable_close_button; tag.changeColor=true">
      <span class="vertical-align-middle" style="margin-right:10px;">Enable Close Button</span>
      <span class="fa vertical-align-middle" style="font-size:20px; cursor:pointer;"
          [ngClass]="{'deviceSelected fa-toggle-on':tag.enable_close_button, 'fa-toggle-off':!tag.enable_close_button}"></span>
  </div>
  <div class="col-sm-12" *ngIf="tag.ad_type == 'Video' || tag.ad_type =='video'" style="padding-bottom:15px;">
      <div class="panel-heading hbuilt default-df cursor-pointer"
          (click)="tag.restrict_to_status?isCollapsedRestrictTo = !isCollapsedRestrictTo:''">
          <span class="fa pull-right cursor-pointer"
              [ngClass]="{'fa-chevron-up': isCollapsedRestrictTo, 'fa-chevron-down': !isCollapsedRestrictTo}"></span>
          <label class="margin-bottom-zero ;">
              <span class="fa " title="Restrict to URL" style="font-size:16px; cursor:pointer;"
                  (click)="tag.restrict_to_status = !tag.restrict_to_status"
                  [ngClass]="{'deviceSelected fa-toggle-on': tag.restrict_to_status || tag.restrict_to.length > 0, 'fa-toggle-off': !tag.restrict_to_status || tag.restrict_to.length === 0 || !isCollapsedRestrictTo }"></span>
              <span class="display-vertical cursor-pointer">RESTRICT TO URL</span>
          </label>
      </div>
      <div appCollapse="!isCollapsedRestrictTo  || !tag.restrict_to_status" class="panel-body">
          <table class="table table-striped border-r-l align-middle">
              <tr>
                  <th></th>
                  <th></th>
                  <th style="width:4%;">Action</th>
              </tr>
              <tr *ngIf="!tag.restrict_to.length">
                  <td colspan='3' class="emptyExclusion">NO RECORD FOUND</td>
              </tr>
              <tr *ngFor="let restrict_to_value of tag.restrict_to; let indx=index"
                  [hidden]="!tag.restrict_to.length">
                  <td>
                      <label style="font-size:14px;">://{{accountURL}}/</label>
                  </td>
                  <td style="width:100%">
                      <input type="text" class="form-control" placeholder="Path"
                          [(ngModel)]="tag.restrict_to[indx].value"
                          (ngModelChange)="tag.changeColor=true;tag.error['restrict_to_'+indx]=[];">
                      <div *ngIf="tag.error && tag.error['restrict_to_'+indx]" class="text-danger">
                          {{ tag.error['restrict_to_'+indx] }}</div>
                  </td>
                  <td style="text-align:center;">
                      <a><span style="color:#6A6C6F;" class="glyphicon glyphicon-remove btnhoverblue"
                              (click)="tag.restrict_to.splice(indx, 1);tag.changeColor=true"></span></a>
                  </td>
              </tr>
              <tr>
                  <td colspan='3'> <button type="button"
                          class="btn w-xs btn-sm btn-success pull-right btn-color media-prop"
                          (click)="tag.changeColor=true;addRestrictValue();">Add New</button></td>
              </tr>
          </table>
      </div>
  </div>
  <br>
  <div class="col-sm-12">
      <div>
          <div class="panel-heading hbuilt default-df"
              (click)="tag.tagCustomTargetingStatus['showTargeting'] = !tag.tagCustomTargetingStatus['showTargeting']">
              <label class="margin-bottom-zero ;">
                  <span class="fa font16" title="Custom key value Targeting"
                      (click)="tag.tagCustomTargetingStatus['status'] = !tag.tagCustomTargetingStatus['status']"
                      style="font-size:16px; cursor:pointer;"
                      [ngClass]="{'deviceSelected fa-toggle-on':tag.custom_key_value && tag.custom_key_value.length > 0 || tag.tagCustomTargetingStatus['status'],'fa-toggle-off':tag.custom_key_value && tag.custom_key_value.length === 0 || !tag.tagCustomTargetingStatus['status']}"></span>
                  <span class="cursor-pointer " style="vertical-align: top;">CUSTOM KEY VALUE TARGETING</span>
              </label>
              <span class="fa pull-right cursor-pointer"
                  [ngClass]="{'fa-chevron-up': showCustomTargeting, 'fa-chevron-down': !showCustomTargeting}"></span>
          </div>

          <div *ngIf="tag.tagCustomTargetingStatus.showTargeting && tag.tagCustomTargetingStatus.status">
              <table class="table-bordered table table-striped border-r-l df-table" style="margin-bottom: 10px;">
                  <thead>
                      <tr>
                          <th style="width:37%;">Key</th>
                          <th style="width:37%;">Value</th>
                          <th style="width:10%;">Device</th>
                          <th style="width:6%;">Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngIf="!tag.custom_key_value.length">
                          <td colspan="3" style="text-align:center; font-size: 13px; font-weight: bold;">No record
                              found.</td>
                          <td><span style="margin-left: 42%; font-size:16px;"
                                  class="glyphicon glyphicon-plus-sign cursor-pointer btnhoverblue"
                                  (click)="tag.custom_key_value.push({'key':'', 'value': '', 'device': ['desktop', 'tablet', 'mobile']})"></span>
                          </td>
                      </tr>
                      <tr *ngFor="let customTarget of tag.custom_key_value; let i=index;"
                          [hidden]="!tag.custom_key_value.length">
                          <td>
                              <input class="form-control"
                                  [ngClass]="{'error': (tag.error['custom_key_value_'+i] && customTarget['key'].length < 1)}"
                                  type="text" [(ngModel)]="customTarget.key">
                              <div *ngIf="tag.error['custom_key_value_'+i] && customTarget['key'].length < 1"
                                  class="text-danger"> {{ tag.error['custom_key_value_'+i] }} </div>
                          </td>
                          <td>
                              <input class="form-control" type="text" [(ngModel)]="customTarget['value']"
                                  [ngClass]="{'error': (tag.error['custom_key_value_'+i] && customTarget['value'].length < 1)}">
                              <div *ngIf="tag.error['custom_key_value_'+i] && customTarget['value'].length < 1"
                                  class="text-danger"> {{ tag.error['custom_key_value_'+i] }} </div>
                          </td>
                          <td style="padding-left:4%; vertical-align:middle;">
                              <span *ngFor="let device of adTagOption.deviceType">
                                  <span class="fa fa-{{ device }} cursor-pointer"
                                      (click)="updateKeyValueDevice(tag, device, i)"
                                      [ngClass]="{'deviceSelected': (customTarget.device.indexOf(device) != -1)}"
                                      style="font-size: 16px;"></span>
                              </span>
                          </td>
                          <td style="text-align:center;">
                              <span style="margin-left: 2%; font-size:16px; vertical-align: middle; padding-top: 9px;"
                                  class="glyphicon glyphicon-plus-sign cursor-pointer btnhoverblue"
                                  (click)="tag.custom_key_value.push({'key':'', 'value': '', 'device': ['desktop', 'tablet', 'mobile']})"></span>
                              <span
                                  style=" font-size:16px; margin-left:8px; vertical-align: middle; padding-top: 9px;"
                                  class="glyphicon glyphicon-minus-sign cursor-pointer btnhoverblue"
                                  (click)="tag.custom_key_value.splice(i, 1)"></span>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
