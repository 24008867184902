import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// import { NgxPaginationModule } from 'ngx-pagination';
import { AceEditorModule } from 'ng2-ace-editor';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountCreateComponent } from './account/account-create/account-create.component';
import { AccountDetailComponent } from './account/account-detail/account-detail.component';
import { AccountListComponent } from './account/account-list/account-list.component';
import { LoginComponent } from './auth/login/login.component';
import { BaseComponent } from './base/base.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Material Imports
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule} from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';

import { DefaultComponent } from './account/account-detail/default/default.component';
import { TagsConfigComponent } from './account/account-detail/tags-config/tags-config.component';
import { ProviderConfigComponent } from './account/account-detail/provider-config/provider-config.component';
import { DisplayAdsComponent } from './account/account-detail/display-ads/display-ads.component';
import { DemandfusionConfigComponent } from './account/account-detail/demandfusion-config/demandfusion-config.component';
import { VideoAdsComponent } from './account/account-detail/video-ads/video-ads.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { ApiInterceptor } from './shared/interceptors/api-interceptor';
import { CollapseDirective,
  SharedModule,
  AccountFilterPipe,
  ServiceFontPipe,
  StringFilterPipe,
  // PubsubService,
  // SharedService,
  // GlobaleVaribleService,
  KeyFilterPipe,
  StringToDatePipe,
  PaginatePipe,
  OrderBy
} from './shared/index';
import { SharedService } from './shared/services/account.service';
// import { PaginationService } from './shared/pipes/pagination-service';

import {
  AccountCreateResolver,
  AccountDetailResolver,
  AccountProductResolver,
  AccountTierHistoryResolver,
  CodeAnalysisResolver,
  ProductResolver,
  DemandFusionResolver
} from './shared/services/account-resolver.service';

// Pipes
@NgModule({
  declarations: [
    AppComponent,
    AccountCreateComponent,
    AccountDetailComponent,
    AccountListComponent,
    LoginComponent,
    BaseComponent,
    DefaultComponent,
    TagsConfigComponent,
    ProviderConfigComponent,
    DisplayAdsComponent,
    DemandfusionConfigComponent,
    VideoAdsComponent,
    LogoutComponent,
    StringToDatePipe,
    PaginatePipe,
    CollapseDirective,
    AccountFilterPipe,
    ServiceFontPipe,
    StringFilterPipe,
    AccountFilterPipe,
    ServiceFontPipe,
    StringFilterPipe,
    KeyFilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatProgressBarModule,
    SharedModule,
    HttpClientModule,
    // NgxPaginationModule,
    AceEditorModule
  ],
  exports: [
    CollapseDirective
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    AccountCreateResolver,
    AccountDetailResolver,
    AccountProductResolver,
    AccountTierHistoryResolver,
    CodeAnalysisResolver,
    DemandFusionResolver,
    ProductResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
