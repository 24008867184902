import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'upperString' })

export class ServiceFontPipe implements PipeTransform {
    transform(value: string, filter: string): any {
	value = value.toLowerCase()
	    .split('_')
	    .map(i => i[0].toUpperCase() + i.substring(1))
	    .join(' ');

	if (value === 'Dfp Settings') {
	    value = 'DFP Settings';
	}
	return value;

    }
}
