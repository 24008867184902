<!-- Main Wrapper -->
<div  class="content animate-panel">
  <div class="row">
      <div class="row">
          <div class="col-sm-7">
              <h2>Create Account</h2>
              <div class="link-path">
                  <a routerLink="/account">Accounts</a>
              </div>
          </div>
          <div class="col-sm-5">
              <div class="panel-right-button">
              </div>
          </div>
      </div>
      <!-- <toaster-container></toaster-container> -->
      <div class="row animate">
          <div class="col-lg-12">
              <div class="hpanel">
                  <div [hidden]="submitted">
                      <div class="panel-body">
                          <form *ngIf="active" data-toggle="validator" role="form" #account="ngForm" (keyup.enter)= "create($event)">
                              <div class="form-group" style="margin-top: 25px;">
                                  <div [hidden]="submitted">
                                      <form *ngIf="active" data-toggle="validator" role="form"  #account="ngForm">
                                          <!--<form *ngIf="active" (ngSubmit)="save($event)" #dashForm="ngForm">-->
                                          <div class="row" >
                                              <!--<div class="col-sm-5">-->
                                              <div class="col-sm-4">
                                                  <div class="table-responsive project-list label-center">
                                                      <table class="table table-striped border-r-l font_size">
                                                          <tr>
                                                              <td style="width:10%;">
                                                                  <label for="name">Account#:</label>
                                                              </td>
                                                              <td>
                                                                  <input [ngClass]="{'control-group error': errorModel.ac_number && errorModel.ac_number.length}" type="text" value="" class="form-control"  required id="name" [(ngModel)]="model.ac_number"  (ngModelChange)="errorModel.ac_number={}" name="account_no" #account_no="ngModel" placeholder="Enter Account No" readonly={{read_only}} autofocus>
                                                                  <div *ngIf="errorModel.ac_number&& errorModel.ac_number.length"  class="text-danger"> {{ errorModel.ac_number[0] }} </div>
                                                                  <!--<input class="form-control" type="text" value="10271" />-->
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td style="height:54px;">
                                                                  <label for="name">Company:</label>
                                                              </td>
                                                              <td style="height:54px;">
                                                                  <input  [ngClass]="{'control-group error': errorModel.company_name && errorModel.company_name.length}"  type="text"  class="form-control" required readonly={{read_only}}
                                                                          [(ngModel)]="model.company_name" (ngModelChange)="errorModel.company_name={}"  name="company_name" placeholder="Enter Company Name" #company_name="ngModel">
                                                                  <div *ngIf="errorModel.company_name && errorModel.company_name.length"  class="text-danger"> {{ errorModel.company_name[0] }} </div>
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td style="height:54px;">
                                                                  Ticker Symbol:
                                                              </td>
                                                              <td style="height:54px;">
                                                                  <input type="text"  class="form-control"  required id="name" [(ngModel)]="model.ticker"  name="ticker" #ticker="ngModel" placeholder="Enter Publisher Ticker Symbol.">
                                                              </td>
                                                          </tr>

                                                      </table>
                                                      <!-- <toaster-container [toasterconfig]="toasterconfig"></toaster-container> -->
                                                  </div>
                                              </div>  <!-- END Col sm 4 2nd -->
                                              <div class="col-sm-4">
                                                  <div class=" project-list label-center">
                                                      <table class="table table-striped border-r-l" [hidden]="hidden">
                                                          <tr>
                                                              <td style="height:54px;">
                                                                  URL:
                                                              </td>
                                                              <td style="height:55px;">
                                                                  <input  [ngClass]="{'control-group error': errorModel.url && errorModel.url.length}" type="text"  class="form-control"  required id="name" [(ngModel)]="model.url"  (ngModelChange)="errorModel.url={}" name="url" #url="ngModel" placeholder="Enter Site URL" readonly={{read_only}}>
                                                                  <div *ngIf="errorModel.url && errorModel.url.length"  class="text-danger"> {{ errorModel.url[0] }} </div>
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td style="height: 54px;">
                                                                  Tier:
                                                              </td>
                                                              <td>
                                                                  <select  [ngClass]="{'control-group error': errorModel.tier && errorModel.tier.length}" class="form-control uneditable-input" [(ngModel)]="model.tier" (ngModelChange)="errorModel.tier={}" name="tier"  #tier="ngModel" required >
                                                                      <option value="" disabled selected hidden >Please Select Tier</option>
                                                                      <option [value]="tier.id" *ngFor="let tier of tier_option.tier.results" >{{ tier.name }}</option>
                                                                  </select>
                                                                  <div *ngIf="errorModel.tier && errorModel.tier.length"  class="text-danger"> {{ errorModel.tier[0] }} </div>

                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td style="width:10%; height:58px;">
                                                                  Status:
                                                              </td>
                                                              <td>
                                                                  <!--<select class="form-control">-->
                                                                  <select class="form-control uneditable-input" [(ngModel)]="model.status" name="status" #status="ngModel" >
                                                                      <option *ngFor="let s of statuses" value= {{s.status}}>
                                                                          {{s.status}}
                                                                      </option>
                                                                  </select>
                                                              </td>
                                                          </tr>
                                                      </table>
                                                  </div>
                                              </div> <!-- END Col sm 4 2nd-->

                                              <!-- Starting col sm 4 3rd-->
                                              <div class="col-sm-4">
                                                  <div class=" project-list label-center">
                                                      <table class="table table-striped border-r-l" [hidden]="hidden">

                                                          <tr>
                                                              <td style="height:54px;">
                                                                  CRM Link
                                                              </td>
                                                              <td style="height:54px;">
                                                                  <input [(ngModel)]="model.crm_link" class="form-control" id="crm_link" name="crm_link" placeholder="Enter CRM Link" #crm_link="ngModel">
                                                              </td>

                                                          </tr>

                                                          <tr>
                                                              <td style="height:54px;">
                                                                  Primary#(DFP):
                                                              </td>
                                                              <td style="height:54px;">
                                                                  <div class="row">
                                                                      <div class="col-md-7">
                                                                          <input  [ngClass]="{'control-group error': errorModel.dfp_account && errorModel.dfp_account.length}" type="text"  class="form-control"  required id="name" [(ngModel)]="model.dfp_account" (ngModelChange)="errorModel.dfp_account={}"  name="dfp_account" #dfp_account="ngModel" placeholder="Enter DFP Primary ID" readonly={{read_only}}>
                                                                          <div *ngIf="errorModel.dfp_account && errorModel.dfp_account.length"  class="text-danger"> {{ errorModel.dfp_account[0] }} </div>

                                                                          <!--<input type="text" class="form-control" required name="company" placeholder="Enter DFP Primary ID">-->
                                                                      </div>
                                                                      <div class="col-md-5" style="padding-top:5px;">
                                                                          <!--<input type="checkbox" value="">Single Request-->
                                                                          <input id="dfp_request" class="i-checks" checked="" type="checkbox" [(ngModel)]="model.dfp_single_request" name="dfp_single_request" #dfp_single_request="ngModel"/><label class="cursor-pointer label-click-dfp "for="dfp_request">Single Request</label>

                                                                      </div>
                                                                  </div>
                                                              </td>
                                                      </table>
                                                  </div>
                                              </div>
                                              <!-- end of col sm 4 3rd-->
                                          </div>
                                          <!--</form>-->
                                      </form>
                                  </div>

                                  <div class="col-sm-12">
                                      <button type="button" class="btn pull-right w-xs btn-sm btn-success center-block btn-color" (click)= "create($event)" >Create</button>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
