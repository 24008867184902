import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalVaribleService, SharedService } from '../../../shared/services/index';
import { VideoAds } from '../../../shared/index';
import { AccountDetailService } from '../../../shared/services/account-detail.service';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';
// import { Clipboard } from 'ts-clipboard';
import { PubsubService } from '../../pubsub.service';

@Component({
  selector: 'app-video-ads',
  templateUrl: './video-ads.component.html',
  styleUrls: ['./video-ads.component.scss']
})
export class VideoAdsComponent implements OnInit {
  @Input() account: string;
  @Input() mmtUUId: string;
  @Input() mmtAccountNumber: string;
  @Input() product: string;
  @Input() products: any;
  @Input() showservice: any;
  @Input() showCustomDfConfigurationToggle: any;
  @Input() dfProviders: any[];
  @Input() dfProviderAdSupport: any;
  @Input() getDisplayDataConfig: any;
  @Input() deviceWidth: any;
  @Input() accountURL: string;
  @Input() pin: string;

  adTagToggle = {};
  adTagOption = {};
  sortedAdTags = [];
  btnLoader = false;
  dfpId = 20842576;
  displayAdDfConfig: any;
  accountID: any;
  isCollapsedAdsTags: boolean;
  presetTagData: {} = {};
  parseTagId: {} = {};
  tagCustomTargetingStatus: {} = {};
  enableCloseButton: boolean;
  collapseEmptySlot: boolean;
  hasUnsavedValues = false;
  adTags: any;
  fixScrollbar: boolean;

  video: any;
  constructor (private sharedService: SharedService,
               public globaleVaribleService: GlobalVaribleService,
              //  private toasterService: ToasterService,
               public pubsub: PubsubService,
               private accountDetailService: AccountDetailService,
               private route: ActivatedRoute) {
globaleVaribleService.video = new VideoAds('', '', '', '', 'Both', false, 1, true, '', ['desktop', 'tablet', 'mobile'], {'offset':''}, false, [], [],
                                      [], true, true, true, 'Video', '', [],
                                      {}, {}, false, '', [], false, this.dfpId, '', '', {}, {"edit": "True", "ipin": ""}, );
this.video = globaleVaribleService.video;
}

  convertInDict(strDict: any): void {
    if (Object.keys(strDict).length === 0) {
      return strDict;
    }
    let str = strDict.split("'").join('"');
    str = str.split("None").join("null");
    str = str.split("True").join("true");
    str = str.split("False").join("false");
    return JSON.parse(str);
  }

  ngOnInit(): void {
    this.pubsub.deleteTag.subscribe(history => {
      this.adTags.forEach((tag) => {
          if (history.message.includes(tag.uuid)) {
              this.deleteAdTag(tag.id);
          }
      });
  });
    this.pubsub.videoProvider.subscribe(history => {
      this.adTags.forEach((tag) => {
          if(history.message.includes(tag.uuid)){
              var hsKeys = Object.keys(history['data']['old']);
              var objKeys = Object.keys(tag);
              var unique = objKeys.filter(function(obj) { return hsKeys.indexOf(obj) != -1; });
              if((hsKeys.includes('sticky_configuration_offset') && (unique.length + 1 == hsKeys.length)) || (!hsKeys.includes('sticky_configuration_offset') && (unique.length == hsKeys.length))){
                  hsKeys.forEach((key) => {
                      if(key == 'restrict_to'){
                          tag['restrict_to'] = []
                          history['data']['old'][key]['data'].forEach(res => {
                              tag['restrict_to'].push({'value': res})
                          });
                      } else if(key == 'sticky_configuration_offset'){
                          tag['sticky_configuration'] = {'offset': history['data']['old'][key]['data']};
                      } else {
                          tag[key] = key.includes('ipin') ?  this.convertInDict(history['data']['old'][key]['data']) :  history['data']['old'][key]['data'];
                      }
                  });
                  this.saveVideoAdTagData(event, tag)
              } else {
                  this.pubsub.videoDFProvider.next({'history': history, 'tag_id': tag.id});
              }
          }
      });
  });
    const params: any = this.route.params;
    this.accountID = params.value.id;
    this.adTagOption = {'size':['1x1', '160x600', '300x250', '300x600', '320x50', '320x100', '400x300', '640x480', '728x90', '970x250'],
                      'folds':['TBL', 'OUT', 'MBL', 'BTF', 'ATF'],
                      'position':['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15'],
                      'sticky':['Top-left', 'Top-right', 'Bottom-right', 'Bottom-left'],
                      'line_break':['Above','Below','Both'],
                      'ad_type': ['Display', 'Video'],
                      'deviceType': ['desktop', 'tablet', 'mobile'],
                      'videoAdSize': ['400x300', '640x480'],
                      'advertiseWithUsPosition': ['left', 'center', 'right'],
                      'deviceTypeAdSizeList': {'d':'desktop', 't':'tablet', 'm':'mobile'},
                      'aspectRatio': ['16x9', '4x3', 'Auto'],
                      'playerStyle': ['Basic', 'Standard', 'Premium']
                     };
    this.getAdTagBranding();
    this.getDisplayAdTagData();
  }

  getAdTagString(uuid): string {
    return '<div id=\"mmt-%s\"><\/div><script type=\"text\/javascript\" data-cfasync=\"false">$MMT = window.$MMT || {}; $MMT.cmd = $MMT.cmd || [];$MMT.cmd.push(function(){ $MMT.video.slots.push([\"%s\"]); }) <\/script>'.replace('%s', uuid).replace('%s', uuid);
  }

  deleteAdTag(tag_id): void {
      let index = this.adTags.findIndex(
          function (element) {
              return element.id === tag_id;
          }
      );
      this.accountDetailService.deleteDisplayAdTag(this.accountID, tag_id)
          .subscribe(
              data => this.successDeleteTag(data, index),
              error => {this.popToast('error', 'Please fix errors below');});
  }


  changeAdStackAdstatus(event, status, id): void {
      this.sharedService.saveAdStackStatus(this.accountID, status, id)
          .subscribe(
              data => data,
              error => {this.popToast('error', 'Please fix errors below');});
  }

  cloneAdTag(tag: any): void {
      let newTag = JSON.parse(JSON.stringify(tag));
      let hasCustomDf = newTag['custom_df_configuration'];
      delete newTag['id'];
      delete newTag['uuid'];
      this.video = newTag;
      this.saveCreateTagData(hasCustomDf);
  }

  addToClip(data: any): void {
      if (typeof(data) === 'object') {
          // Clipboard.copy(data.value);
      } else {
          // Clipboard.copy(data);
      }
      this.popToast('info', 'Copied!');
  }

  popToast(type_:any, message:any): void {
      this.btnLoader = false;
      let type = type_;
      // this.toasterService.pop(type , message);
  }

  saveCreateTagData(hasCustomDf:any): void {
      this.btnLoader = true;
      this.video.is_active = true;
      //this.video.configuration.enable_spotx = this.video.configuration.enable_spotx?'true':'false';
      this.video.configuration.auto_play = this.video.configuration.auto_play?'true':'false';
      this.video.configuration.sound_mute = this.video.configuration.sound_mute?'true':'false';
      this.video.configuration.loop_playback = this.video.configuration.loop_playback?'true':'false';
      let customDfConfig: any;
      if(hasCustomDf) {
          customDfConfig = this.video['df_configurations'];
          delete this.video['df_configurations'];
      }
      if(this.video.custom_ad_unit != null && this.video.custom_ad_unit === '') {
          this.video.custom_ad_unit = null;
      }
      let displaydata = this.video;
      displaydata.ad_type = 'video';
      displaydata.account = this.accountID;
      this.sharedService.saveVideoAdData(displaydata, this.accountID)
          .subscribe(
              data => this.getResponse(data, hasCustomDf, customDfConfig),
              error => this.createAdTagError(displaydata, error));
  }

  saveVideoAdTagData(event:any, data:any): void {
      data.ad_type = 'video';
      data.account = this.accountID;
      this.btnLoader = true;
      //data.configuration.enable_spotx = data.configuration.enable_spotx?'true':'false';
      data.configuration.auto_play = data.configuration.auto_play?'true':'false';
      data.configuration.sound_mute = data.configuration.sound_mute?'true':'false';
      data.configuration.loop_playback = data.configuration.loop_playback?'true':'false';
      if(data.custom_ad_unit != null && data.custom_ad_unit === '') {
          data.custom_ad_unit = null;
      }
      this.sharedService.saveVideoAdTagData(data, this.accountID)
          .subscribe(
              responseData => {
                  //data.configuration['enable_spotx'] = responseData.configuration['enable_spotx'] === 'true'?true:false;
                  data.configuration['auto_play'] = responseData.configuration['auto_play'] === 'true'?true:false;
                  data.configuration['sound_mute'] = responseData.configuration['sound_mute'] === 'true'?true:false;
                  data.configuration['loop_playback'] = responseData.configuration['loop_playback'] === 'true'?true:false;
                  this.popToast('success', 'Changes have been saved successfully!');
              },
              error => this.displayAdTagError(data, error));
  }


  changeFixScrollbar(): void {
      this.sharedService.saveDisplayConfiguration(this.accountID, {
          fix_scrollbar: this.fixScrollbar.toString()
      }, 'video-ads').subscribe(
          data => {this.popToast('success', 'Changes have been saved successfully!');},
          error => {this.popToast('error', 'Please fix errors below');});
  }

  saveDisplayConfiguration(e): void {
      this.sharedService.saveDisplayConfiguration(this.accountID, {
          enable_close_button: this.enableCloseButton.toString(),
          collapse_empty_div: this.collapseEmptySlot.toString(),
      },  'video-ads').subscribe(
          data => {this.popToast('success', 'Changes have been saved successfully!');},
          error => {this.popToast('error', 'Please fix errors below');});
      }

  getDisplayAdTagData(): void {
      this.sharedService.getProductData(this.accountID, 'video-ads')
          .subscribe(
              data => this.getDisplayData(data),
              error => {this.popToast('error', 'Please fix errors below');});
  }

  getAdTagBranding(): void {
      this.sharedService.getAdTagBrandingData()
          .subscribe(
              data => this.onSuccess(data),
              error => {this.popToast('error', 'Please fix errors below');});
  }

  private getTagData(tags:any): void {
      tags = !tags.length?[tags]:tags;
      for(let ad of tags) {
          ad.error = {};
          ad.changeColor = false;
          ad.isStickyEnable = (ad.sticky==='')?false:true;
          ad.branding = ad.branding === null?'None':ad.branding;
          ad.isLineBreakEnable = (ad.line_break==='' || ad.line_break === null)?false:true;
          ad.line_break = ad.isLineBreakEnable&&ad.line_break?ad.line_break.charAt(0).toUpperCase() + ad.line_break.slice(1):'';
          ad.ad_type = 'video';
          ad.restrict_to_status = ad.restrict_to && ad.restrict_to.length === 0?false:true;
          ad.tagCustomTargetingStatus = ad.custom_key_value && ad.custom_key_value.length > 0 ?
              {'status':true, 'showTargeting':false}:{'status':false, 'showTargeting':false};
      }
  }

  private getWeightValue(tags:any): void {
      if(tags && tags.length !== 0) {
          this.video['weight'] = tags.slice(-1)[0]['weight']!==null?tags.slice(-1)[0]['weight'] + 1:0;
      }
      if(this.video['weight'] === 0) {
          this.sortedAdTags = tags.filter(function(n){ return n['weight'] !== null; });
          this.video['weight'] = this.sortedAdTags.length <1?1:this.sortedAdTags.slice(-1)[0]['weight']+1;
      }
  }

  private getResponse(res: any, hasCustomDf: any, customDfConfig: any): void {
      this.btnLoader = false;
      this.getTagData(res);
      if(res.restrict_to) {
          for(let index in res.restrict_to) {
              res.restrict_to[index] = {'value': res.restrict_to[index]};
          }
      }
      if (!hasCustomDf) {
          this.adTags.push(res);
      } else {
          const objectId = res.id;
          for (const conf of customDfConfig) {
              conf.status = conf.status === 'active' ? true : false;
          }

          this.sharedService.saveDemandfusionProductData(this.account, this.product, customDfConfig, objectId, hasCustomDf)
              .subscribe(
                  data => this.onDfSuccess(data, res),
                  error => {this.popToast('error', 'Cloning error!');});
      }
      this.video = new VideoAds('', '', '', '', 'Both', false, 1, true, '', ['desktop', 'tablet', 'mobile'], {'offset':''}, false, [], [], [], true, true, true,
                                'Video', '', '', {}, {}, false, '',[], false, this.dfpId, '', '', {}, {"edit": "True", "ipin": ""});

      this.getWeightValue(this.adTags);
      this.isCollapsedAdsTags = true;

      this.popToast('success', 'Changes have been saved successfully!');
  }

  private getDisplayData(res: any): void {
      this.fixScrollbar = res.configuration.fix_scrollbar == 'true'? true: false;
      if(res.ads) {
          this.getTagData(res.ads);
      }
      if(res.ads) {
          for(const ad of res.ads) {
              if (ad.restrict_to) {
                  for (let index in ad.restrict_to) {
                      ad.restrict_to[index] = {'value': ad.restrict_to[index]};
                  }
              }
              // let intPreset = String(ad.preset);
              // this.presetTagData[ad.id] = intPreset;
              // this.parseTagId[ad.id] = ad.uuid;
              this.adTagToggle[ad.id] = true;
          }
      }
      this.enableCloseButton = res.configuration.enable_close_button==='true';
      this.collapseEmptySlot = res.configuration.collapse_empty_div==='true';
      this.adTags = res.ads;
      this.globaleVaribleService.adTags = this.adTags;
      this.getWeightValue(this.adTags);
      this.products[this.product] = res;
  }

  private onDfSuccess(dfResponse: any, tagRes: any): void {
      tagRes['df_configurations'] = dfResponse['df_configurations'];
      this.adTags.push(tagRes);
      this.popToast('success', 'Cloned successfully!');
  }

  private successDeleteTag(res: any, index: number): void {
      this.popToast('success', 'Deleted!');
      this.adTags.splice(index, 1);
  }

  private onSuccess(data: any): void {
      this.adTagOption['branding'] = data.results;
      this.adTagOption['branding'].push({'label':'None', 'id':'None'});
  }

  private createAdTagError(data: any, error: string): void {
      this.btnLoader = false;
      this.video['error'] = error;
      this.video['changeColor'] = false;
      data.ad_type = 'video';
      if(error['non_field_errors']) {
          this.video.error['label'] = "Label must be unique.";
      }
      this.popToast('error', 'Please fix errors below');
  }

  private displayAdTagError(data: any, error: string): void {
      this.btnLoader = false;
      for (const tag of this.adTags) {
          if (tag.id === data.id) {
              tag.ad_type = 'video';
              tag.error = error;
              tag.changeColor = true;
              if (error['non_field_errors']) {
                  tag.error['label'] = "Label must be unique.";
              }
          }
      }
      this.popToast('error', 'Please fix errors below');
  }

}
