import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountCreateComponent } from './account/account-create/account-create.component';
import { AccountDetailComponent } from './account/account-detail/account-detail.component';
import { AccountListComponent } from './account/account-list/account-list.component';
import { BaseComponent } from './base/base.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';


import { AuthGuard } from "./shared/services/auth-gaurd.service";
import {
  AccountCreateResolver,
  AccountDetailResolver,
  AccountProductResolver,
  AccountTierHistoryResolver,
  CodeAnalysisResolver,
  ProductResolver,
} from './shared/services/account-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'account',
    component: BaseComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AccountListComponent,
      },
      {
        path: 'create',
        component: AccountCreateComponent,
        resolve: {
          tier: AccountCreateResolver,
        },
      },
      {
        path: 'detail/:id',
        component: AccountDetailComponent,
        resolve: {
          details: AccountProductResolver,
          accountdetail: AccountDetailResolver,
          tierHistory: AccountTierHistoryResolver,
          tier: AccountCreateResolver,
          codeAnalysis: CodeAnalysisResolver,
          productList: ProductResolver,
        },
      },
      { path: '**', component: AccountListComponent },
    ],
  },
  { path: '**', component: LoginComponent },
  { path: 'account/create', component: AccountCreateComponent },
  { path: 'account/detail/:id', component: AccountDetailComponent },
  { path: '', component: AccountListComponent },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
