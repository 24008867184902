import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../services/index';
import { catchError, map } from 'rxjs/operators';

import { ActivatedRoute, Router, Resolve, RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Accountdetail, AccountAnalysis } from '../constructor/index';
import { AccountDetailService } from '../services/account-detail.service';

@Injectable()
export class DemandFusionResolver implements Resolve<any> {
    constructor(private sharedAccountService: SharedService,
                private router: Router) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        let _params: any = route.params;

        return this.sharedAccountService.getProductData(_params['id'], 'demandfusion').toPromise().then(demandfusiondata => {
            if(demandfusiondata) {
                return demandfusiondata;
            } else {
                return null;
            }
        });

    }
}

@Injectable()
export class ProductResolver implements Resolve<any> {
    constructor(private sharedAccountService: SharedService,
                private router: Router) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {

        return this.sharedAccountService.getProductList().toPromise().then(productList => {
            if(productList) {
                return productList;
            } else {
                return [];
            }
        });

    }
}


@Injectable()
export class AccountCreateResolver implements Resolve<any> {
    constructor(private sharedAccountService: SharedService,
                private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
      return this.sharedAccountService.getTier().pipe(
        map(tier => {
          if (tier) {
            return tier;
          } else {
              return null;
          }
        })
      );
    }
}


@Injectable()
export class AccountProductResolver implements Resolve<any> {

    constructor(private sharedAccountService: SharedService,
                private router: Router,
                private activeroute: ActivatedRoute) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {

        let _params: any = route.params;

        return this.sharedAccountService.getAccountProduct(_params['id']).toPromise().then(products => {
            if(products) {
                return products;
            } else {
                return null;
            }
        });
    }
}

@Injectable()
export class AccountDetailResolver implements Resolve<Accountdetail> {

    constructor(private sharedAccountService: SharedService,
                private router: Router,
                private activeroute: ActivatedRoute) {}


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Accountdetail> {

        let _params: any = route.params;
        return  this.sharedAccountService.getAccountDetail(_params['id']).toPromise().then(accountdetail => {
            if(accountdetail) {
                return accountdetail;
            } else {
                return null;
           }
        });
    }
}


@Injectable()
export class AccountTierHistoryResolver implements Resolve<any> {

    constructor(private accountDetailService: AccountDetailService,
                private router: Router,
                private activeroute: ActivatedRoute) {}


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {

        let _params: any = route.params;
        return  this.accountDetailService.getTierHistory(_params['id']).toPromise().then(tierHistory => {
            if(tierHistory) {
                return tierHistory;
            } else {
                return null;
            }
        });
    }
}

@Injectable()
export class CodeAnalysisResolver implements Resolve<AccountAnalysis> {

    constructor(private sharedService: SharedService,
                private router: Router,
                private activeroute: ActivatedRoute) {}


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<AccountAnalysis> {

        let _params: any = route.params;
        return  this.sharedService.getCodeAnalysisData(_params['id']).toPromise().then(codeAnalysis => {
            if(codeAnalysis) {
                return codeAnalysis;
            } else {
                return null;
            }
        });
    }
}
