import { Component, OnInit, Input } from '@angular/core';
import { GlobalVaribleService, SharedService } from '../../../shared/services/index';
// TODO: Add Material Toaster Solution
// import { ToasterService } from 'angular2-toaster/angular2-toaster';
import { Router } from '@angular/router';
import { Provision } from '../../../shared/constructor';
import { PubsubService } from '../../pubsub.service';

@Component({
  selector: 'app-demandfusion-config',
  templateUrl: './demandfusion-config.component.html',
  styleUrls: ['./demandfusion-config.component.scss'],
})
export class DemandfusionConfigComponent implements OnInit {
  @Input() account: string;
  @Input() product: string;
  @Input() showCustomDfConfigurationToggle: boolean;
  @Input() hasCustomDfConfiguration: boolean;
  @Input() dfProviders: any[];
  @Input() dfConfiguration: any[];
  @Input() modelObjectId: any;
  @Input() dfProviderAdSupport: any;
  @Input() adType: any;
  @Input() tag: any;
  @Input() tagPreset: any;
  @Input() disableConfigToggle: boolean;

  provisionModel = new Provision('');
  errorModel: {} = {};
  choiceFieldProviderConfig = {};
  showConfiguration = false;
  showPlacementLoader = false;
  dfProvidersData: any[] = [];
  dfProvidersSorted: any[] = [];
  placementsList: any[] = [];
  placementsListVideo: any[] = [];
  hasUnsavedProviderConfig = false;
  platforms: any[] = ['desktop', 'tablet', 'mobile'];
  sizeOption: any[] = [];
  placements: any[] = [];
  adTagType: any[] = ['display-ads', 'video-ads', 'amp'];

  constructor(private pubsub: PubsubService,
              private globaleVaribleService: GlobalVaribleService,
              private sharedService: SharedService,
              private router: Router) {}

  changeProvider(history): any {
    Object.keys(history['data']['old']).forEach((key) => {
      this.dfProvidersData.forEach((data) => {
        if (data['provider'] == key) {
          Object.keys(history['data']['old'][key]['data']).forEach((res) => {
            data[res] = history['data']['old'][key]['data'][res];
          });
        }
      });
    });
    this.dfProvidersData = this.initProviderConf(this.dfProvidersData);
    this.dfProvidersSorted = this.sortBy(this.dfProvidersData, 'name');
    this.saveDemandfusionConfiguration(this.dfProvidersData, this.product);
  }

  initProviderConf(dfConfiguration): any {
    const dfProvidersData = [];
    const dfConfigurationCache = {};
    for (const dfConfig of dfConfiguration) {
      dfConfigurationCache[dfConfig.provider] = dfConfig;
    }
    for (const dfProviderIndex in this.dfProviders) {
      if (this.dfProviders.hasOwnProperty(dfProviderIndex)) {
        const provider = this.dfProviders[dfProviderIndex].key;
        const isActive = this.dfProviders[dfProviderIndex].status;
        const setting = dfConfigurationCache[provider] || [{}];
        let configFields = this.dfProviders[dfProviderIndex].configuration;
        configFields =
          configFields instanceof Array ? configFields : [configFields];

        const providerData = {
          provider,
          name: this.dfProviders[dfProviderIndex]['name'],
          isActive,
          status:
            (typeof setting.status !== 'undefined' &&
              setting.status === 'active') ||
            (typeof setting.status === 'boolean' && setting.status),
          is_configuration_common:
            (this.product === 'amp'
              ? false
              : typeof setting.is_configuration_common === 'undefined') ||
            setting.is_configuration_common === true,
          display_desktop:
            (this.product === 'amp'
              ? false
              : typeof setting.display_desktop === 'undefined') ||
            setting.display_desktop === true,
          display_tablet:
            (this.product === 'amp'
              ? false
              : typeof setting.display_tablet === 'undefined') ||
            setting.display_tablet === true,
          display_mobile:
            typeof setting.display_mobile === 'undefined' ||
            setting.display_mobile === true,
          configuration_common: this.mergeProviderConfiguration(
            configFields,
            provider,
            setting.configuration_common || [{}]
          ),
          configuration_desktop: this.mergeProviderConfiguration(
            configFields,
            provider,
            setting.configuration_desktop || [{}]
          ),
          configuration_tablet: this.mergeProviderConfiguration(
            configFields,
            provider,
            setting.configuration_tablet || [{}]
          ),
          configuration_mobile: this.mergeProviderConfiguration(
            configFields,
            provider,
            setting.configuration_mobile || [{}]
          ),
          data_type: this.getDataType(configFields),
          choices: this.getChoicesData(configFields, provider),
        };
        dfProvidersData.push(providerData);
      }
    }
    const orderedProviders = dfProvidersData.sort();
    return orderedProviders;
  }

  ngOnInit(): void {
    if (this.product === 'amp') {
      this.pubsub.ampProvider.subscribe((history) => {
        this.changeProvider(history);
      });
    }

    if (this.product === 'demandfusion') {
      this.pubsub.dfProvider.subscribe((history) => {
        this.changeProvider(history);
      });
    }

    if (this.product === 'flight') {
      this.pubsub.flightProvider.subscribe((history) => {
        this.changeProvider(history);
      });
    }

    this.pubsub.videoDFProvider.subscribe((data) => {
      if (this.modelObjectId === data.tag_id) {
        this.changeProvider(data.history);
      }
    });

    if (this.product === 'amp') {
      this.platforms = ['mobile'];
    }
    this.dfConfiguration = this.dfConfiguration || [];
    this.dfProvidersData = this.initProviderConf(this.dfConfiguration);
    this.dfProvidersSorted = this.sortBy(this.dfProvidersData, 'name');

  }

  sortBy(array: any[], orderBy: string): any[] {
    if (!orderBy || orderBy.trim() === '') {
      return array;
    }
    return array.sort((a, b) => a[orderBy] - b[orderBy]);
  }

  updateFlag(ind) {
    this.globaleVaribleService.providerListForFlags[this.product][ind][
      'isFieldChanged'
    ] = true;
  }

  toggleProvider(provider, event) {
    if (provider.provider === 'appnexus' && !event.target.checked) {
      this.placementsList = [];
      this.placementsListVideo = [];
    }
    provider.status = provider.status === 'active';
  }

  afterModelClose() {
    this.placementsList = [];
    this.placementsListVideo = [];
    this.provisionModel.search = '';
  }

  getProvisionDemandfusion() {
    this.showPlacementLoader = true;
    this.sharedService.getDfProvision(this.account).subscribe(
      (data) => this.onSuccessProvision(data),
      (error) => this.showError(error)
    );
  }

  changeSizeRespectiveValues(event, providerData, index, provider, config) {
    if (this.choiceFieldProviderConfig[provider.toLowerCase()]) {
      let providerConfigValue = null;
      let providerConfigKey = '';
      if (provider.toLowerCase() != 'pubmatic') {
        providerConfigKey =
          Object.keys(
            this.choiceFieldProviderConfig[provider.toLowerCase()]
          ).indexOf(event) > -1
            ? Object.keys(
                this.choiceFieldProviderConfig[provider.toLowerCase()][event]
              )[0]
            : '';
        providerConfigValue =
          event !== 'Select Size'
            ? this.choiceFieldProviderConfig[provider.toLowerCase()][event][
                providerConfigKey
              ]
            : '';
      } else {
        Object.keys(
          this.choiceFieldProviderConfig[provider.toLowerCase()]
        ).forEach((key) => {
          if (key.split(' ')[0] == event) {
            providerConfigKey =
              Object.keys(
                this.choiceFieldProviderConfig[provider.toLowerCase()]
              ).indexOf(key) > -1
                ? Object.keys(
                    this.choiceFieldProviderConfig[provider.toLowerCase()][key]
                  )[0]
                : '';
            providerConfigValue =
              event !== 'Select Size'
                ? this.choiceFieldProviderConfig[provider.toLowerCase()][key][
                    providerConfigKey
                  ]
                : '';
          }
        });
      }
      providerData[index][providerConfigKey] = providerConfigValue
        ? providerConfigValue
        : '';
    }
    this.hasUnsavedProviderConfig = true;
  }

  getPlacementIdForAppNexus(event, providerData, index, provider) {
    if (provider === 'AppNexus' || provider === 'AppNexusAst') {
      providerData[index]['placementId'] = String(event['id'])
        ? String(event['id'])
        : '';
    }
  }

  getDataType(fields) {
    let data: any = {};
    for (let field of fields) {
      data[field.key] = field['data_type'];
    }
    return data;
  }

  getChoicesData(options: any, provider: any) {
    for (let size of options) {
      if (size['data_type'] === 'choices') {
        this.choiceFieldProviderConfig[provider] = size['configuration'];
        this.sizeOption = Object.keys(size['configuration']);
        return Object.keys(size['configuration']);
      }
    }
    return [];
  }

  mergeProviderConfiguration(configFields, provider, savedConfig) {
    let mergedConfigList = [];
    let mergedConfig = {};

    if (savedConfig.length === 0) {
      let data: {} = {};
      for (let indx in configFields) {
        data[configFields[indx].key] = '';
      }
      savedConfig.push(data);
      return savedConfig;
    }
    for (let configVal of savedConfig) {
      for (let index in configFields) {
        mergedConfig[configFields[index].key] = configVal[
          configFields[index].key
        ]
          ? configVal[configFields[index].key]
          : configFields[index].default
          ? configFields[index].default
          : '';
      }
      mergedConfigList.push(mergedConfig);
      mergedConfig = {};
    }
    return mergedConfigList;
  }

  toggleUseDefaultConfiguration(event) {
    this.hasCustomDfConfiguration = !this.hasCustomDfConfiguration;
    if (!this.hasCustomDfConfiguration) {
      this.showConfiguration = true;
      this.saveDemandfusionConfiguration([], this.product);
    }
  }

  setDefaultStatus(dfProvidersData) {
    if (this.adType === 'video') {
      for (let provider of dfProvidersData) {
        if (provider.name === 'VertaMedia') {
          provider.status = true;
        }
      }
    }
  }

  addNewDfConfigRow(
    is_common_config: boolean,
    platform: string,
    provider,
    providerconfig
  ) {
    let data = JSON.parse(JSON.stringify(providerconfig));
    if (is_common_config) {
      provider['configuration_common'].push(data);
    } else {
      provider['configuration_' + platform].push(data);
    }
  }

  removeDfConfigRow(
    is_common_config: boolean,
    platform: string,
    provider,
    providerconfig,
    index
  ) {
    if (is_common_config) {
      provider['configuration_common'].splice(index, 1);
    } else {
      provider['configuration_' + platform].splice(index, 1);
    }
  }

  setSaveFlagTrue() {
    this.globaleVaribleService.providerListForFlags[this.product].forEach(
      (data) => {
        data['isFieldChanged'] = false;
      }
    );
  }

  saveDemandfusionConfiguration(providerConfiguration: any, product: any) {
    if (!this.dfConfiguration.length && providerConfiguration.length) {
      let newDfConfiguration = [];
      providerConfiguration.forEach((conf, index) => {
        if (conf.isActive == 'active') {
          newDfConfiguration.push(conf);
        }
      });
      providerConfiguration = newDfConfiguration;
    }
    this.sharedService
      .saveDemandfusionProductData(
        this.account,
        product,
        providerConfiguration,
        this.modelObjectId,
        this.hasCustomDfConfiguration
      )
      .subscribe(
        (data) => {
          this.onSuccess(data, product);
          if (providerConfiguration.length) {
            this.setSaveFlagTrue();
          }
        },
        (error) => this.showErrorMessage(error, product, providerConfiguration)
      );
  }

  popToast(type_: any, message: any) {
    let type = type_;
    // this.toasterService.pop(type, message);
  }

  clearError(product: string, providerId: any, key: string, platform: string) {
    if (
      this.errorModel[product] &&
      this.errorModel[product].hasOwnProperty(providerId)
    ) {
      this.errorModel[product][providerId].configuration[platform][key] = '';
    }
  }

  clearErrorDemandfusion(
    product: string,
    providername: string,
    key: string,
    platform: string,
    i: number
  ) {
    if (this.errorModel[product]) {
      this.errorModel[product][providername][platform][i][key] = '';
    }

    this.hasUnsavedProviderConfig = true;
  }

  private showErrorMessage(
    error: any,
    product: any,
    providerConfiguration: any
  ) {
    if (error && error[0]) {
      this.popToast('error', error[0]);
    } else this.popToast('error', 'Please fix errors below');
    this.errorModel[product] = error.df_configurations;
    this.hasUnsavedProviderConfig = true;
  }

  private onSuccess(data: any, product: any) {
    this.popToast('success', 'Changes have been saved successfully!');
    if (
      (this.tag &&
        data['custom_df_configuration'] &&
        (product === 'display' ||
          product === 'display-ads' ||
          product === 'flight')) ||
      product === 'video-ads'
    ) {
      this.tag['custom_df_configuration'] = data['custom_df_configuration'];
      this.tag['df_configurations'] = data['df_configurations'];
    }
    if (product == 'demandfusion') {
      this.globaleVaribleService['products']['demandfusion'] = data;
    }
    this.hasUnsavedProviderConfig = false;
    this.router.navigate([this.router.url]);
    return data;
  }

  private onSuccessProvision(data: any) {
    this.placements = data.account_placements.response.placements;
    if (this.placements.length !== 0) {
      for (let p in this.placements) {
        let placementData = {
          id: this.placements[p]['id'],
          name: this.placements[p].name,
        };
        if (this.placements[p].name.search('_INLINE_') !== -1) {
          this.placementsListVideo.push(placementData);
        }
        this.placementsList.push(placementData);
        this.showPlacementLoader = false;
      }
    } else {
      this.showPlacementLoader = false;
    }
  }

  private showError(error: any) {
    this.popToast('error', 'Can not connect to Appnexus!');
  }
}
