import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalVaribleService, SharedService } from '../../../shared/services/index';
import { AccountDetailService } from '../../../shared/services/account-detail.service';
import { DisplayAds } from '../../../shared/index';
import { PubsubService } from '../../pubsub.service';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';
// import { Clipboard } from 'ts-clipboard';

@Component({
  selector: 'app-display-ads',
  templateUrl: './display-ads.component.html',
  styleUrls: ['./display-ads.component.scss']
})
export class DisplayAdsComponent implements OnInit {
  @Input() account: string;
  @Input() mmtUUId: string;
  @Input() mmtAccountNumber: string;
  @Input() product: string;
  @Input() products: any;
  @Input() showservice: any;
  @Input() showCustomDfConfigurationToggle: any;
  @Input() dfProviders: Array<any>;
  @Input() dfProviderAdSupport: any;
  @Input() getDisplayDataConfig: any;
  @Input() deviceWidth: any;
  @Input() pin: string;
  @Input() floorType: string;

  adTagToggle: any = {};
  adTagOption: any = {};
  sortedAdTags = [];
  dfpId = 20842576;
  accountID: any;
  isCollapsedAdsTags: boolean;
  presetTagData: {} = {};
  parseTagId: {} = {};
  displayAdDfConfig: any;
  tagCustomTargetingStatus: {} = {};
  enableCloseButton: boolean;
  collapseEmptySlot: boolean;
  adTags: any;
  display: any;
  constructor(private sharedService: SharedService,
              public pubsub: PubsubService,
              // private toasterService: ToasterService,
              public globaleVaribleService: GlobalVaribleService,
              private accountDetailService: AccountDetailService,
              private route: ActivatedRoute) {
    globaleVaribleService.display = new DisplayAds('', '', '', '', '', false, false, 'Both', false, 1, false, false,
        false, 60, 5, '', { offset: '', 'footer-element': '', 'stick-time': '' },
        [], [], [], true, true, true, false, false, 'Display', '',
        ['desktop', 'tablet', 'mobile'], ['desktop', 'tablet', 'mobile'],
        [], '', false, {}, {}, false, '', null, this.dfpId, '', '', {},
        false, false, false, '', '', '', '', '', '', '', { 'edit': 'True', 'ipin': '' },
        { 'edit': 'True', 'ipin': '' }, { 'edit': 'True', 'ipin': '' }, false, false, 10, 10, 10, 10, 'site', 'site', 'site');
    this.display = globaleVaribleService.display;
}

  ngOnInit() {
    let _params: any = this.route.params;
    this.accountID = _params.value.id;
    this.adTagOption = {
        size: ['1x1', '160x600', '300x50', '300x100', '300x250', '300x600', '320x50', '320x100', '400x300', '640x480', '728x90', '970x90', '970x125', '970x250', '468x60', '336x280'],
        folds: ['TBL', 'OUT', 'MBL', 'BTF', 'ATF'],
        position: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
        sticky: ['Bottom', 'Sidebar', 'Header', 'Pillar'],
        pillar_sides: ['left', 'right'],
        line_break: ['Above', 'Below', 'Both'],
        ad_type: ['Display', 'Video'],
        deviceType: ['desktop', 'tablet', 'mobile'],
        videoAdSize: ['400x300', '640x480'],
        advertiseWithUsPosition: ['left', 'center', 'right'],
        deviceTypeAdSizeList: { d: 'desktop', t: 'tablet', m: 'mobile' },
        target_floors : ['', '0.15','0.16','0.17','0.18','0.19','0.20','0.21','0.22','0.23','0.24',
                           '0.25','0.26','0.27','0.28','0.29','0.30','0.31','0.32','0.33','0.34',
                           '0.35','0.36','0.37','0.38','0.39','0.40','0.41','0.42','0.43','0.44',
                           '0.45','0.46','0.47','0.48','0.49','0.50','0.51','0.52','0.53','0.54',
                           '0.55','0.56','0.57','0.58','0.59','0.60','0.61','0.62','0.63','0.64',
                           '0.65','0.66','0.67','0.68','0.69','0.70','0.71','0.72','0.73','0.74',
                           '0.75','0.76','0.77','0.78','0.79','0.80','0.81','0.82','0.83','0.84',
                           '0.85','0.86','0.87','0.88','0.89','0.90','0.91','0.92','0.93','0.94',
                           '0.95','0.96','0.97','0.98','0.99','1.00','1.01'],
        hard_floors : ['', '0.15','0.16','0.17','0.18','0.19','0.20','0.21','0.22','0.23','0.24',
                         '0.25','0.26','0.27','0.28','0.29','0.30','0.31','0.32','0.33','0.34',
                         '0.35','0.36','0.37','0.38','0.39','0.40','0.41','0.42','0.43','0.44',
                         '0.45','0.46','0.47','0.48','0.49','0.50','0.55','0.60','0.65','0.70',
                         '0.75','0.80','0.85','0.90','0.95','1.00'],
        df_floors : ['', '0.15','0.16','0.17','0.18','0.19','0.20','0.21','0.22','0.23','0.24',
                       '0.25','0.26','0.27','0.28','0.29','0.30','0.31','0.32','0.33','0.34',
                       '0.35','0.36','0.37','0.38','0.39','0.40','0.41','0.42','0.43','0.44',
                       '0.45','0.46','0.47','0.48','0.49','0.50','0.55','0.60','0.65','0.70',
                       '0.75','0.80','0.85','0.90','0.95','1.00','1.05','1.10','1.15','1.20',
                       '1.25','1.30','1.35','1.40','1.45','1.50','1.55','1.60','1.65','1.70',
                       '1.75','1.80','1.85','1.90','1.95','2.00'],
        reduce_values : ['', '5','10','15','20','25','30','35','40','45','50','55','60','65','70',
                           '75','80','85','90','95','100']
    };

    this.pubsub.dfAdsProvider.subscribe((history) => {
        Object.keys(history.data['old']).forEach(key => {
            if (key == 'collapse_empty_div') {
                this.collapseEmptySlot = history.data['old'].collapse_empty_div['data'] == 'true';
            } else if (key == 'enable_close_button') {
                this.enableCloseButton = history.data['old'].enable_close_button['data'] == 'true';
            }
        });
        this.saveDisplayConfiguration(event);
    });
    this.pubsub.deleteTag.subscribe(history => {
        this.adTags.forEach((tag) => {
            if (history.message.includes(tag.uuid)) {
                this.deleteAdTag(tag.id);
            }
        });
    });
    this.pubsub.videoProvider.subscribe(history => {
        this.adTags.forEach((tag) => {
            if (history.message.includes(tag.uuid)){
                let hsKeys = Object.keys(history.data['old']);
                let objKeys = Object.keys(tag);
                let unique = hsKeys.filter(function(obj) {
                    if (objKeys.indexOf(obj) != -1) {
                        return true;
                    }
                    else if (obj.includes('sticky_configuration')) {
                        return true;
 }
                    return false;
                });
                if (unique.length === hsKeys.length){
                    const data: any = {id: tag.id};
                    hsKeys.forEach((key) => {
                        if (key === 'restrict_to'){
                            tag.restrict_to = [];
                            history.data['old'][key].data.forEach(res => {
                                tag.restrict_to.push({value: res});
                            });
                        } else if (key.includes('sticky_configuration_')){
                            tag.sticky_configuration[key.split('sticky_configuration_')[1]] = history.data['old'][key].data ? history.data['old'][key].data : '';
                        } else if (key === 'sticky'){
                            tag[key] = history.data['old'][key].data;
                            tag.isStickyEnable = tag[key].length !== 0;
                        } else if (key === 'custom_ad_unit'){
                            tag[key] = history.data['old'][key].data;
                            data.customAdUnit = tag[key] != null;
                        } else if (key === 'line_break'){
                            tag[key] = history.data['old'][key].data;
                            tag[key] = tag[key].length ? (tag[key].charAt(0).toUpperCase() + tag[key].slice(1)) : '';
                            tag.isLineBreakEnable = tag[key].length != 0;
                        } else if (key == 'configuration'){
                            tag[key] = this.convertInDict(history.data['old'][key].data);
                            tag[key].enable_uam = tag[key].enable_uam == 'true';
                        } else {
                            tag[key] = key.includes('ipin') ?  this.convertInDict(history.data['old'][key].data) :  history.data['old'][key].data;
                        }
                    });
                    this.pubsub.displayAdsProvider.next(data);
                    this.saveDisplayAdTagData(event, tag);
                } else {
                    this.pubsub.videoDFProvider.next({history: history, tag_id: tag.id});
                }
            }
        });
    });
    this.getAdTagBranding();
    this.getDisplayAdTagData();
  }

  convertInDict(strDict) {
      if (Object.keys(strDict).length === 0) {
          return strDict;
      }
      let str = strDict.split('\'').join('"');
      str = str.split('None').join('null');
      str = str.split('True').join('true');
      str = str.split('False').join('false');
      return JSON.parse(str);
  }

  getAdTagString(uuid) {
      return '<div id=\"mmt-%s\"><\/div><script type=\"text\/javascript\" data-cfasync=\"false">$MMT = window.$MMT || {}; $MMT.cmd = $MMT.cmd || [];$MMT.cmd.push(function(){ $MMT.display.slots.push([\"%s\"]); }) <\/script>'.replace('%s', uuid).replace('%s', uuid);
  }

  deleteAdTag(tag_id) {
      const index = this.adTags.findIndex(
          function(element) {
              return element.id === tag_id;
          }
      );
      this.accountDetailService.deleteDisplayAdTag(this.accountID, tag_id)
          .subscribe(
              data => this.successDeleteTag(data, index),
              error => { this.popToast('error', 'Please fix errors below'); });
  }


  changeAdStackAdstatus(event, status, id) {
      this.sharedService.saveAdStackStatus(this.accountID, status, id)
          .subscribe(
              data => data,
              error => { this.popToast('error', 'Please fix errors below'); });
  }

  cloneAdTag(tag: any) {
      const newTag = JSON.parse(JSON.stringify(tag));
      const hasCustomDf = newTag.custom_df_configuration;
      delete newTag.id;
      delete newTag.uuid;
      this.display = newTag;
      this.saveCreateTagData(hasCustomDf);
  }

  addToClip(data: any) {
      if (typeof (data) === 'object') {
          // Clipboard.copy(data.value);
      } else {
          // Clipboard.copy(data);
      }
      this.popToast('info', 'Copied!');
  }

  popToast(type_: any, message: any) {
      const type = type_;
      // this.toasterService.pop(type, message);
  }

  saveCreateTagData(hasCustomDf: any) {
      this.display.is_active = true;
      this.display.configuration.enable_uam = this.display.configuration.enable_uam ? 'true' : 'false';
      let customDfConfig: any;
      if (hasCustomDf) {
          customDfConfig = this.display.df_configurations;
          delete this.display.df_configurations;
      }
      const displaydata = this.display;
      if (displaydata.custom_ad_unit != null && displaydata.custom_ad_unit === '') {
          displaydata.custom_ad_unit = null;
      }
      displaydata.sticky = this.display.sticky.toLowerCase();
      displaydata.ad_type = 'display';
      displaydata.account = this.accountID;
      this.sharedService.saveDisplayAdData(displaydata, this.accountID)
          .subscribe(
              data => this.getResponse(data, hasCustomDf, customDfConfig),
              error => this.createAdTagError(displaydata, error));
  }


  saveDisplayAdTagData(event: any, data: any) {
      const intPreset = String(data.preset);
      data.ad_type = 'display';
      data.configuration.enable_uam = data.configuration.enable_uam ? 'true' : 'false';
      if (data.custom_ad_unit != null && data.custom_ad_unit === '') {
          data.custom_ad_unit = null;
      }
      if (data.preset !== null) {
          data.preset = parseInt(data.preset);
      }
      let presetData = parseInt(data.preset);
      this.presetTagData[data.id] = intPreset; // {'preset': intPreset, 'uuid': data.uuid};
      this.parseTagId[data.id] = data.uuid;
      if (this.presetTagData[presetData] === String(data.id)) {
          this.popToast('error', this.parseTagId[data.id] + ' and ' + data.uuid + ' are cross set preset.\n Please change');
      } else {
          this.sharedService.saveDisplayAdTagData(data, this.accountID)
              .subscribe(
                  responseData => {
                      data.configuration.enable_uam = responseData.configuration.enable_uam === 'true' ? true : false;
                      this.popToast('success', 'Changes have been saved successfully!');
                  },
                  error => this.displayAdTagError(data, error));
      }
  }


  saveDisplayConfiguration(e) {
      this.sharedService.saveDisplayConfiguration(this.accountID, {
          enable_close_button: this.enableCloseButton.toString(),
          collapse_empty_div: this.collapseEmptySlot.toString(),
      }, 'display-ads').subscribe(
          data => { this.popToast('success', 'Changes have been saved successfully!'); },
          error => { this.popToast('error', 'Please fix errors below'); });
  }

  getDisplayAdTagData() {
      this.sharedService.getProductData(this.accountID, 'display-ads')
          .subscribe(
              data => this.getDisplayData(data),
              error => { this.popToast('error', 'Please fix errors below'); });
  }

  getAdTagBranding() {
      this.sharedService.getAdTagBrandingData()
          .subscribe(
              data => this.onSuccess(data),
              error => { this.popToast('error', 'Please fix errors below'); });
  }



  private getTagData(tags: any) {
      tags = !tags.length ? [tags] : tags;
      for (const ad of tags) {
          ad.error = {};
          ad.changeColor = false;
          ad.isStickyEnable = (ad.sticky === '') ? false : true;
          ad.branding = ad.branding === null ? 'None' : ad.branding;
          ad.isLineBreakEnable = (ad.line_break === '' || ad.line_break === null) ? false : true;
          ad.line_break = ad.isLineBreakEnable && ad.line_break ? ad.line_break.charAt(0).toUpperCase() + ad.line_break.slice(1) : '';
          ad.ad_type = 'display';
          ad.tagCustomTargetingStatus = ad.custom_key_value && ad.custom_key_value.length > 0 ?
              { status: true, showTargeting: false } : { status: false, showTargeting: false };
      }
  }

  private getWeightValue(tags: any) {
      if (tags.length !== 0) {
          this.display.weight = tags.slice(-1)[0].weight !== null ? tags.slice(-1)[0].weight + 1 : 0;
      }
      if (this.display.weight === 0) {
          this.sortedAdTags = tags.filter(function(n) { return n.weight !== null; });
          this.display.weight = this.sortedAdTags.length < 1 ? 1 : this.sortedAdTags.slice(-1)[0].weight + 1;
      }
  }

  private getResponse(res: any, hasCustomDf: any, customDfConfig: any) {
      this.getTagData(res);
      if (!hasCustomDf) {
          this.adTags.push(res);
      } else {
          const objectId = res.id;
          for (const conf of customDfConfig) {
              conf.status = conf.status === 'active' ? true : false;
          }
          this.sharedService.saveDemandfusionProductData(this.account, this.product, customDfConfig, objectId, hasCustomDf)
              .subscribe(
                  data => this.onDfSuccess(data, res),
                  error => { this.popToast('error', 'Cloning error!'); });
      }
      this.display = new DisplayAds('', '', '', '', '', false, false, 'Both', false, 1, false, false,
          false, 60, 5, '', { offset: '', 'footer-element': '', 'stick-time': '' },
          [], [], [], true, true, true, false, false, 'Display', '',
          ['desktop', 'tablet', 'mobile'],
          ['desktop', 'tablet', 'mobile'],
          [], '', false,
          { status: false, showTargeting: false }, {}, false, '', '',
          this.dfpId, '', '', {},
          false, false, false, '', '', '', '', '', '', '', { 'edit': 'True', 'ipin': '' },
          { 'edit': 'True', 'ipin': '' }, { 'edit': 'True', 'ipin': '' }, false, false, 10, 10, 10, 10, 'site', 'site', 'site');

      this.getWeightValue(this.adTags);
      this.isCollapsedAdsTags = true;

      this.popToast('success', 'Changes have been saved successfully!');
  }

  private getDisplayData(res: any) {
      if (res.ads) {
          this.getTagData(res.ads);
          for (const ad of res.ads) {
              const intPreset = String(ad.preset);
              this.presetTagData[ad.id] = intPreset;
              this.parseTagId[ad.id] = ad.uuid;
              this.adTagToggle[ad.id] = true;
          }
      }
      this.enableCloseButton = res.configuration.enable_close_button === 'true';
      this.collapseEmptySlot = res.configuration.collapse_empty_div === 'true';
      this.adTags = res.ads;
      this.globaleVaribleService.adDisplayTags = this.adTags;
      this.getWeightValue(this.adTags);
      this.products[this.product] = res;
  }

  private onDfSuccess(dfResponse: any, tagRes: any) {
      tagRes.df_configurations = dfResponse.df_configurations;
      this.adTags.push(tagRes);
      this.popToast('success', 'Cloned successfully!');
  }

  private successDeleteTag(res: any, index: number) {
      this.popToast('success', 'Deleted!');
      this.adTags.splice(index, 1);
  }

  private onSuccess(data: any) {
      this.adTagOption.branding = data.results;
      this.adTagOption.branding.push({ label: 'None', id: 'None' });
  }

  private createAdTagError(data: any, error: string) {
      this.display.error = error;
      this.display.changeColor = false;
      data.ad_type = 'display';
      this.popToast('error', 'Please fix errors below');
  }

  private displayAdTagError(data: any, error: string) {
      for (const tag of this.adTags) {
          if (tag.id === data.id) {
              tag.ad_type = 'display';
              tag.error = error;
              tag.changeColor = true;
          }
      }
      this.popToast('error', 'Please fix errors below');
  }

}
