import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { contentHeaders } from '../index';
// import { contentHeaders, Config } from '../index';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  token: any;
  body: any;
  showLoader = false;
  redirectUrl: '/';
  private userUrl = environment.apiUrl + 'user/me/';
  private authUrl = environment.apiUrl + 'user/auth/';
  private options = {headers: contentHeaders};

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  checkLoggedIn(token): any {
    this.showLoader = true;
    if (!contentHeaders.get('Authorization')) {
      contentHeaders.append(
        'Authorization',
        'Token ' + this.cookieService.get('token')
      );
    }
    return this.http
      .get<any[]>(this.userUrl, this.options).pipe(
        map(res => this.handleUserResponse(res)),
        catchError(error => this.handleUserError(error))
      );
  }

  login(ticket: any, service: any): Observable<any> {
    console.log('arguments for login', arguments);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json'
        // 'Authrization' : 'Token ' + ticket
      })
    };
    console.log('httpOptions', httpOptions);
    console.log('this.options', this.options);
    this.showLoader = true;
    const body = JSON.stringify({ service, ticket });
    console.log('authURL', this.authUrl);
    console.log('body', body);
    return this.http.post(this.authUrl, body, httpOptions).pipe(
      map(res => this.getLoginData(res)),
      catchError(error => this.handleloginError(error))
    );
  }

  logout(): Observable<any> {
    if (!contentHeaders.get('Authorization')) {
      contentHeaders.append(
        'Authorization',
        'Token ' + this.cookieService.get('token')
      );
    }
    return this.http.delete(this.authUrl, this.options).pipe(
      map(res => this.getLogoutResponse(res)),
      catchError(error => this.getLogoutError(error))
    );
  }

  private getLoginData(res: any): any {
    console.log('res Data getLoginData', res);
    this.body = res;
    this.showLoader = true;
    this.cookieService.set('loggedInUser', this.body.user.email);
    this.cookieService.set('token', this.body.token, 1);
    this.cookieService.set(
      'latest_release_date',
      this.body.latest_release_date
    );
    if (!contentHeaders.get('Authorization')) {
      contentHeaders.append(
        'Authorization',
        'Token ' + this.cookieService.get('token')
      );
    }
    console.log('cookies', this.cookieService.getAll());
    return this.body;
  }

  private handleUserError(error: any): Observable<any> {
    const body = error.json();
    this.showLoader = true;
    this.logout().subscribe();
    return throwError(body);
  }

  private handleUserResponse(response: any): any {
    this.showLoader = true;
    const body = response.json();
    this.cookieService.set('latest_release_date', body.latest_release_date);
    return body;
  }

  private getLogoutResponse(res: any): JSON {
    this.cookieService.delete('token');
    (window as any).location = res.json().redirect_to;
    return res.json();
  }

  private handleloginError(err: any): Observable<any> {
    return throwError('Internal server error');
  }

  private getLogoutError(err: any): Observable<any> {
    this.cookieService.delete('token');
    (window as any).location = 'account';
    return throwError('Internal server error');
  }
}
