import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  // Do not set headers as there is no auth for these requests
  private dontAuthenticateUrls: string[] = [
      '/auth/login',
      '/auth/logout',
      environment.apiUrl + '/user/signup',
  ];

  constructor(private cookieService: CookieService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set('Content-Type', 'application/json')
    });
    console.log('request URL', request.url);
    // Get token if request is not found in the [dontAuthenticateUrls]
    if (this.dontAuthenticateUrls.indexOf(request.url) === -1) {
      const token = this.cookieService.get('token');
      console.log('token-intercept', token);
      if (token) {
        request = request.clone({
          setHeaders: { Authorization: 'Token ' + token }
        });
      }
    }
    if (request.url.indexOf('/') === 0) {
      request = request.clone({
        url: environment.apiUrl + request.url
      });
    }
    if (request.url.indexOf('?') === -1 && request.url[request.url.length - 1] !==  '/') {
      request = request.clone({
        url: request.url + '/'
      });
    }
    return next.handle(request)
    .pipe(
      tap(
        event => { },
        error => {
          // If 401 unauthorized error occurs, remove the token and nav to Login view
          if (error.status === 401 || error.status === 403) {
            this.cookieService.delete('token');
            this.router.navigate(['/auth/logout']);
          }
        }
      ),
    );
  }
}
