<div *ngIf="showLoader"  >
  <div class="loader center"></div>
</div>
<div [hidden]="showLoader" class="blank login-top-div">
  <div class="login-container">
    <div class="login-cta">
      <div class="col-md-12 ">
        <div class="text-center">
          <span><img class="logo" src="../../../assets/monu-logo-full.svg"></span>
          <br>
          <h5><span class="msg">You have been logged out.</span></h5>
        </div>
        <div>
          <a href="https://account.monumetric.com/login?service={{ encodeService(service) }}"><button (click)="activateLoader()" class="btn btn-primary btn-bg-color border-zero"><span class="msg">CLICK HERE TO LOGIN</span></button></a>
        </div>
      </div>
    </div>
    <!-- Footer-->
    <footer class="footer" *ngIf="!showLoader">
      2016 &copy; Copyrights
      <span class="pull-right">
          monumetric.com
      </span>
    </footer>
  </div>
</div>
